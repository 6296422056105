import { RootState } from 'store/rootReducer';
import { templateTypes, templateSelector, templateActions } from 'store/template';
import { StructuredTags } from 'store/uiState/types.uiState';

export interface StateProps {
    template?: templateTypes.Template;
    structuredTags: StructuredTags[] | undefined;
}

export interface DispatchProps {
    loadTemplateById(payload: string): void;
}

export type AllProps = StateProps & DispatchProps;

export const getAllProps = (props: AllProps) => {
    const { template, structuredTags, loadTemplateById } = props;
    const stateProps: StateProps = { template, structuredTags };
    const dispatchProps: DispatchProps = { loadTemplateById };
    return { stateProps, dispatchProps };
};

export const mapStateToProps = (state: RootState): StateProps => ({
    template: templateSelector.selectCurrentTemplateWithPreview(state),
    structuredTags: state.uiState.settings?.structuredTags,
});

export const mapDispatchToProps: DispatchProps = {
    loadTemplateById: templateActions.loadTemplateById,
};
