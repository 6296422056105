import { AxiosError } from 'axios';
import { logError } from 'loggingManager';
import { auth } from 'providers/AuthContext';
import { axiosWithAuth } from 'interceptors/auth.interceptor';
import { TenantPermissions, LocalStorage } from 'utility/constants';
import { PrincipalPermissionsResponse, ResourceReference, Tenant, Resource } from './types.tenant';

const PERMISSIONS = [
    TenantPermissions.ReadAsset,
    TenantPermissions.CreateAsset,
];

async function getPrincipalPermissions(sub: string) {
    const urlEncodedSub = encodeURIComponent(sub);

    return axiosWithAuth.get<PrincipalPermissionsResponse>(`https://api.cimpress.io/auth/access-management/v1/principals/${urlEncodedSub}/permissions`)
        .then(({ data }) => data)
        .catch((error: AxiosError) => {
            logError('Could not retrieve principal permissions for user');
            throw error;
        });
}

const buildAvailableTenant = (resourceReference: ResourceReference) => {
    const { resource, resourceType } = resourceReference;
    const tenantId = resource.identifier;
    const tenantType = resourceType;
    const canReadAsset = resource.permissions.includes(TenantPermissions.ReadAsset);
    const canCreateAsset = resource.permissions.includes(TenantPermissions.CreateAsset);
    const canReadSetting = resource.permissions.includes(TenantPermissions.ReadSetting);
    const canManageSetting = resource.permissions.includes(TenantPermissions.ManageSetting);

    return {
        tenantId,
        tenantType,
        permissions: {
            asset: {
                canRead: canReadAsset,
                canCreate: canCreateAsset,
            },
            setting: {
                canRead: canReadSetting,
                canManage: canManageSetting,
            },
        },
        tenantDisplayName: tenantId,
    };
};

const reduceNestedResources = (resourceReferences: ResourceReference[], [resourceType, resources]: [string, Resource[]]) =>
    [...resources.map((resource) => ({ resourceType, resource })), ...resourceReferences]; // eslint-disable-line implicit-arrow-linebreak

const containsPermissions = (resourceReference: ResourceReference) => resourceReference.resource.permissions.some((permission) => PERMISSIONS.includes(permission));

const buildAvailableTenants = (principalPermissions: PrincipalPermissionsResponse) => Object.entries(principalPermissions)
    .reduce(reduceNestedResources, [])
    .filter(containsPermissions)
    .map(buildAvailableTenant);

/**
 * Get users available tenants
 */
async function getAvailableTenants(): Promise<Tenant[]> {
    let availableTenants: Tenant[] = [];
    try {
        const { sub } = auth.getProfile();

        const principalPermissions = await getPrincipalPermissions(sub);
        availableTenants = buildAvailableTenants(principalPermissions);
    } catch (e) {
        logError('Failed to find available tenants');
    }

    return availableTenants;
}

function setLastTenant(tenant?: Tenant) {
    if (tenant) {
        localStorage.setItem(LocalStorage.TenantKey, JSON.stringify(tenant));
    } else {
        localStorage.removeItem(LocalStorage.TenantKey);
    }
}

function getLastTenant() {
    const lastTenant = localStorage.getItem(LocalStorage.TenantKey);
    if (lastTenant) {
        try {
            return JSON.parse(lastTenant) as Tenant;
        } catch {
            setLastTenant(); // clear last known tenant
        }
    }
    return null;
}

export {
    getAvailableTenants,
    getLastTenant,
    setLastTenant,
};
