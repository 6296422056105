/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState } from 'react';
import { shapes, colors } from '@cimpress/react-components';
import IconZoomIn from '@cimpress-technology/react-streamline-icons/lib/IconZoomIn';
import IconGiftsCategory from '@cimpress-technology/react-streamline-icons/lib/IconGiftsCategory';

import './previewThumbnail.scss';

const { Spinner } = shapes;
const { platinum } = colors;

interface Props {
    setOpen?(payload: boolean): void;
    onLoaded?(): void;
    isHidden?: boolean;
    isDraft?: boolean;
    src?: string;
}

export default function PreviewThumbnail({ setOpen, onLoaded, isHidden, isDraft, src }: Props) {
    const [loading, setIsLoading] = useState(true);
    const onImgLoad = () => {
        setIsLoading(false);
        if (onLoaded) {
            onLoaded();
        }
    };
    const openModal = () => setOpen && setOpen(true);

    return (
        <div className={isDraft ? 'preview__thumbnail preview__thumbnail-no-preview' : 'preview__thumbnail'}>
            {!isDraft && loading && !isHidden ? <Spinner size='medium' />
                : (
                    setOpen && (
                        <div className='preview__thumbnail-hover' onClick={openModal} role='button'>
                            <div className='preview__thumbnail-zoom-icon'>
                                <IconZoomIn size='lg' />
                            </div>
                        </div>
                    )
                )}
            {!isDraft && <img className={loading || isHidden ? 'hide' : ''} src={src} alt='preview' onLoad={onImgLoad} />}
            {isDraft && <IconGiftsCategory size='5x' color={platinum} />}
        </div>
    );
}
