import { tenantTypes } from 'store/tenant';
import { URLs } from './constants';
import { UPLOAD_URL_VARIANTS } from './enums';

interface Preview {
    assetId: string;
    width: number;
    view: string;
    panelNumber?: number;
    revisionId?: string;
}

interface PreviewURLParams {
    width: number;
    view: string;
    panelNumber?: number;
    documentReferenceUri: string;
}

export const getDocRefUrl = (assetId: string, revisionId?: string) => {
    if (revisionId) {
        return `${URLs.AssetBase}/${assetId}/revisions/${revisionId}/content`;
    }
    return `${URLs.AssetBase}/${assetId}/content?timestamp=${new Date().getTime()}`; // adding timestamp to avoid caching for this url
};

/**
 * Parse out the query parameters from a given URL.
 * @param {string} property - key want to extract
 * @param {string} queryString - url
 */

export const getQueryVariable = (property: string, queryString: string) => {
    const params = new URLSearchParams(queryString);
    return params.get(property);
};

/**
 * returns documentReferenceUrl used in Designer Config
 * @param {string} id - current asset id
 * @param {string} revisionId - historical asset id
 * @param {string} queryString - url
 */
export const generateDocRefUrl = (id: string, revisionId: string, queryString: string): string | undefined => {
    const docRefQueryString = getQueryVariable('docRefUrl', queryString);
    return docRefQueryString || (id && getDocRefUrl(id, revisionId));
};

export const getAssetContentUrl = (assetId: string) => `${URLs.AssetBase}/${assetId}/content`;

export const getPreviewUrl = (paramsObj: PreviewURLParams) => {
    const params = urlParamString(paramsObj);
    return `${URLs.PreviewBase}?${params}`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const urlParamString = (paramsObj: any) => {
    let paramUrl = '';
    const parameters = Object.keys(paramsObj);
    parameters.forEach((key, i) => {
        if (paramsObj[key]) {
            paramUrl += `${key}=${paramsObj[key]}`;
            if (i !== parameters.length - 1) {
                paramUrl += '&';
            }
        }
    });
    return paramUrl;
};

export const getAssetPreview = (preview: Preview) => {
    const { assetId, width, view, panelNumber, revisionId } = preview;
    const documentReferenceUri = encodeURIComponent(getDocRefUrl(assetId, revisionId));
    return getPreviewUrl({ width, view, panelNumber, documentReferenceUri });
};

export const getUploadUrl = (
    uploadId: string,
    tenantId: string,
    variant: UPLOAD_URL_VARIANTS = UPLOAD_URL_VARIANTS.Original,
) => `${URLs.UploadBase}/${uploadId}/${variant}?tenant=${tenantId}`;

interface StitchXConfiguration {
    filePath: string;
    id: string;
    sourceApplication: string;
    substrateColor?: string;
    tenant?: string;
}

const getStitchXUrl = (tenant?: tenantTypes.Tenant) => (tenant?.tenantDisplayName?.includes('YSD')
    ? URLs.StitchXYSD
    : URLs.StitchX);

export const getStitchXEditorUrl = (
    assetId: string,
    uploadTenant: string,
    tenant?: tenantTypes.Tenant,
) => {
    const parameters: StitchXConfiguration = {
        filePath: getDocRefUrl(assetId),
        id: assetId,
        sourceApplication: 'template-maker',
        tenant: uploadTenant,
    };
    const queryParameter = encodeURIComponent(`{"parameters": ${JSON.stringify(parameters)}}`);
    const url = `${getStitchXUrl(tenant)}/?configurations=${queryParameter}`;
    return url;
};

export const getCamDesignUrl = (
    designId: string,
    tenant?: tenantTypes.Tenant,
) => `${URLs.Cam}/designs/${designId}`;
