export const AuthClientId = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
export const IsDevMode = process.env.NODE_ENV !== 'production';
export const CustomerId = process.env.REACT_APP_CUSTOMER_ID || '';
export const Basename = process.env.REACT_APP_ROUTER_BASENAME || '';
export const CoamEnvironment = (process.env.REACT_APP_COAM_ENVIRONMENT as 'int' | 'prd') || 'int';
export const PublicUrl = process.env.PUBLIC_URL || '';

export const CatalogPageSize = 24;
export const RecurringExportTemplatePageSize = 1000;
export const RecentTemplatesPageSize = 4;
export const CollectionTagPrefix = 'cId:';
export const SettingName = 'templateMakerSettings';
export const SupportGroupEmail = 'Templatemakersupport@cimpress.com';
export const MaxSubCollectionLevel = 1;
export const MinLabelsToShowTableView = 3;
export const SkulessTag = '__skuless__';
export const SKULessTemplateRetrivalId = 'TM-SKULess-shape';

export const StructuredTagsSeparator = ',';
export const TagStringSeparator = ';';

export const OriginalPreviewSize = 600;
export const ThumbnailPreviewSize = 200;

export const DefaultUploadTenant = 'default';

export const AssetTypeTemplate = 'template';

export const AllCollection = 'All Collection';

export const LocalStorage = {
    TemplateConfigKey: 'TEMPLATE_CONFIG_CACHE_KEY',
    TenantKey: 'TEMPLATE_MAKER_TENANT',
    RecentSkusKey: 'RECENT_SKUS',
    SelectedItemsToCopy: 'SELECTED_ITEMS_TO_COPY',
};

export const SessionStorage = {
    CimDocKey: 'CIMDOC',
};

export const TenantPermissions = {
    ReadAsset: 'read:scenes',
    CreateAsset: 'create:scenes',
    ReadSetting: 'read:setting',
    ManageSetting: 'manage:setting',
};

export const SupportedLanguages = [
    { text: 'English', code: 'en-US', default: true },
    { text: 'Nederlands', code: 'nl-NL', default: false },
    { text: 'German', code: 'de-DE', default: false },
    { text: 'Italian', code: 'it-IT', default: false },
];

export const ResponsiveDesignBreakpoints = {
    Zero: 0,
    MobileMax: 600,
    TabletMin: 601,
    TabletMax: 1450,
    DesktopMin: 1451,
    BigScreenMin: 1824,
};

export const URLs = {
    TemplateMakerDocumentation: 'https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/326045354/Template+Maker',
    TemplateMakerSupportSlack: 'https://slack.com/app_redirect?channel=mcp-support-content-management',
    AssetBase: 'https://assets.documents.cimpress.io/v3/assets',
    CollectionBase: 'https://templatecollection.contentauthoring.cimpress.io/v0',
    Prepress: 'https://print.prepress.cimpress.io/v2/print-prep?asynchronous=true&noRedirect=true&withoutRetry=true',
    DocumentInstructionDrawing: 'https://uds.documents.cimpress.io/v3/instructions:drawing',
    DesignTransfer: 'https://transformation.documents.cimpress.io/v1/pipelines/designTransfers:processDocRef',
    SettingsBase: 'https://settings.cimpress.io',
    DesignerBase: 'https://static.designer.ddt.cimpress.io',
    ValidateApiKey: 'https://api.cimpress.io/auth/anonymous/v1/tokens',
    GetProduct: 'https://introduction.products.cimpress.io/v3/mcpSkus',
    FontRepository: 'https://fonts.documents.cimpress.io/v1/repositories',
    AccessManagementSearchBySubstring: 'https://api.cimpress.io/auth/access-management/v1/search/canonicalPrincipals/bySubstring',
    ClipArtBase: 'https://clipartgalleryapi.buildasign.io',
    UploadBase: 'https://uploads.documents.cimpress.io/v1/uploads',
    PreviewBase: 'https://previews.cimpress.io/v2/preview',
    DraftTemplateImageURL: 'https://static.ux.cimpress.io/assets/icons/gifts-category-l.svg',
    CustomizrBase: 'https://customizr.at.cimpress.io/v1/resources',
    AssetLinkQuery: 'https://assets.documents.cimpress.io/v3/assets/-/links:query',
    UdsBase: 'https://uds.documents.cimpress.io/v3/documents',
    ProductSurfacesService: 'https://surface.products.cimpress.io',
    ProductApiService: 'https://api.products.cimpress.io',
    ProductRuleService: 'https://rule.products.cimpress.io',
    CalculatedSurfaceSetsBase: 'https://surface.products.cimpress.io/v3/calculatedSurfaceSets',
    StitchX: 'https://stitchx.embroidery.cimpress.io',
    StitchXYSD: 'https://stitchx-embroidery.yinshida.com.cn',
    Cam: 'https://cam.contentauthoring.cimpress.io',
};
export const FeedbackCampaignId = 'b3916674-2384-11eb-a357-06e765523a47';
export const FeedbackQuestionId = 'd994c15e-2384-11eb-a357-06e765523a47';

export const SVGNameSpace = 'http://www.w3.org/2000/svg';
export const MaskColor: {[key: string]: string} = {
    bleed: '#888888',
    trim: '#ff0000',
    safe: '#0000ff',
};

export const EmbroideryProcessType = 'embroideryFlat';
