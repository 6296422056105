import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { shapes, Button, Modal, Alert } from '@cimpress/react-components';

import { RootState } from 'store/rootReducer';

import './vortexPreview.scss';
import { BS_STYLE } from 'utility/enums';
import { Preview } from '@rendering/vortex-core/preview';
import { useAuth } from 'providers/AuthContext';
import { ProductState, VortexProduct } from '@rendering/vortex-core/products';
import { getProductConfig, loadProduct, ProductConfigOptions } from '@rendering/vortex-product-loader/loaders';
import { useTranslation } from 'react-i18next';
import { axiosWithAuth } from 'interceptors/auth.interceptor';
import { getProduct } from 'screens/create/ProductSelector/productClient';
import { AllProps, getAllProps, StateProps, OwnProps, mapStateToProps, DispatchProps, mapDispatchToProps } from './props.vortexPreview';
import { initializeVortexPreview } from './vortexUtility';
import { VortexProductConfig } from './vortexProductConfig';

const { Spinner } = shapes;

const vortexDemoPageBaseUrl = 'https://vortex.documents.cimpress.io/demo';

function VortexPreview(allProps: AllProps) {
    const { ownProps, stateProps } = getAllProps(allProps);
    const { t } = useTranslation();

    const [isVortexPreviewShown, setIsVortexPreviewShown] = useState(false);

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [preview, setPreview] = useState<Preview | undefined>(undefined);
    const [states, setStates] = useState<ProductState[]>([]);
    const [isVortexLoading, setIsVortexLoading] = useState(false);
    const [vortexError, setVortexError] = useState('');
    const [vortexPreviewUrl, setVortexPreviewUrl] = useState<string | undefined>('');

    const { auth } = useAuth();
    const accessToken = auth.getAccessToken();

    const onVortexPreviewToggle = async () => {
        setIsVortexPreviewShown(!isVortexPreviewShown);
    };

    const changeState = async (stateId: string) => {
        await preview?.changeState(stateId);
    };

    useEffect(() => {
        const getVortexDemoPagePreviewUrl = async (): Promise<string | undefined> => {
            if (!ownProps.designer) {
                return undefined;
            }

            const documentJson = ownProps.designer.getDocumentJson();
            if (!documentJson?.documentReference) {
                return undefined;
            }
            const assetDetailResponse = await axiosWithAuth.get(documentJson.documentReference);

            const parameters: Record<string, any> = {
                mcpSku: documentJson.sku,
                skuVariables: documentJson.skuVariables ? JSON.stringify(documentJson.skuVariables) : undefined,
                prdVerison: (await getProduct(documentJson.sku)).productServiceVersion,
                renderingInstructions: assetDetailResponse.data.renderingInstructionsUrl,
            };
            return `${vortexDemoPageBaseUrl}?${(new URLSearchParams(parameters)).toString()}`;
        };

        const initVortex = async (): Promise<void> => {
            setIsVortexLoading(true);
            setVortexError('');
            const canvas = canvasRef.current;
            if (canvas === null) {
                return;
            }

            if (!stateProps.currentTenant || !ownProps.designer) {
                return;
            }

            try {
                setVortexPreviewUrl(await getVortexDemoPagePreviewUrl());
                const vortexPreview = initializeVortexPreview(
                    canvas,
                    { renderingInstructionsUrl: await ownProps.designer.api.design.preview.getTemporaryPreviewInstructionsUrl() },
                    stateProps.currentTenant,
                );
                setPreview(vortexPreview);
                const configOptions: ProductConfigOptions = {
                    sku: ownProps.designer.getDocumentJson().sku,
                    attributes: ownProps.designer.getDocumentJson().skuVariables,
                    tenant: { id: stateProps.currentTenant.tenantId, type: stateProps.currentTenant.tenantType },
                };

                const productConfig: VortexProductConfig = await getProductConfig(configOptions);
                const vortexProductFromSku: VortexProduct = await loadProduct(productConfig);

                setStates(vortexProductFromSku?.getStates() ?? []);

                if (vortexProductFromSku) {
                    await vortexPreview.load(vortexProductFromSku);
                } else {
                    setVortexError(t('notifications.danger.vortexNoConfiguration'));
                }
            } catch (e) {
                setVortexError(e?.message ?? t('notifications.danger.generic'));
            } finally {
                setIsVortexLoading(false);
            }
        };

        if (isVortexPreviewShown) {
            initVortex();
        } else {
            setPreview(undefined);
        }
    }, [accessToken, isVortexPreviewShown, ownProps.designer, stateProps.currentTenant, t]);

    return (
        <>
            {isVortexPreviewShown && (
                <Modal
                    bsStyle={BS_STYLE.info}
                    show={isVortexPreviewShown}
                    title='Vortex Preview'
                    onRequestHide={onVortexPreviewToggle}
                    closeOnOutsideClick={true}
                    closeButton={true}
                    bsSize='lg'
                >
                    {isVortexLoading && (
                        <div className='centered'>
                            <Spinner size='large' />
                        </div>
                    )}

                    {vortexError && (
                        <Alert
                            title={t('notifications.danger.vortex')}
                            message={vortexError}
                            dismissible={false}
                        />
                    )}
                    <canvas ref={canvasRef} className='display-canvas' />
                    <div className='centered'>
                        {states.map((state) => <Button size='lg' className='state-button' onClick={() => changeState(state.id)}>{state.id}</Button>) }
                        {vortexPreviewUrl && <Button size='lg' type='link' href={vortexPreviewUrl} target='_blank'>Open in Vortex</Button>}
                    </div>
                </Modal>
            )}

            <Button className='dcl-action-btn dcl-action-btn--preview preview--product' onClick={onVortexPreviewToggle}>
                <img className='dcl-action-btn__icon' src='/images/3d-preview.svg' alt='3D Preview' />
                <div className='dcl-action-btn__description'>Preview</div>
            </Button>

        </>
    );
}

export default connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)(VortexPreview);
