import React, { useState, useEffect, ChangeEvent } from 'react';
import { Redirect } from 'react-router-dom';
import { Card, Button, TextField, Tooltip, colors, TextFieldProps } from '@cimpress/react-components';
import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { formatNotification, uiStateTypes } from 'store/uiState';
import { SNACKBAR_NOTIFICATION_TYPES, BS_STYLE } from 'utility/enums';
import IconView from '@cimpress-technology/react-streamline-icons/lib/IconView';
import cx from 'classnames';

import './configuration.scss';
import { useTranslation } from 'react-i18next';
import IconAlertTriangle from '@cimpress-technology/react-streamline-icons/lib/IconAlertTriangle';
import IconInformationCircle from '@cimpress-technology/react-streamline-icons/lib/IconInformationCircle';
import { useResponsiveDesign } from 'providers/ResponsiveDesign';
import { StructuredTags } from 'store/uiState/types.uiState';
import { mapDispatchToProps, AllProps, getAllProps, StateProps, DispatchProps, mapStateToProps } from './props.configuration';
import { StructuredTag } from './StructuredTag';

const { ocean, warning } = colors;

const initialSettings = (settings: uiStateTypes.DesignerApiSettings | undefined) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const settingsObj: any = {};
    settings && Object.keys(settings).forEach((key) => {
        settingsObj[key] = settings[key];
    });
    return settingsObj;
};

const defaultInputTypes: {[key: string]: TextFieldProps['type']} = {
    apiKey: 'password',
    fontRepository: 'password',
    uploadTenant: 'text',
    fonts: 'textarea',
    designerVersion: 'text',
};

const defaultErrorMessages: {[key in ConfigurationProperties['property']]: string} = {
    apiKey: '',
    uploadTenant: '',
    fontRepository: '',
    fonts: '',
    designerVersion: '',
};

interface ConfigurationProperties {
    property: 'apiKey' | 'uploadTenant' | 'fontRepository' | 'fonts' | 'designerVersion';
}

export function Configuration(allProps: AllProps) {
    const { t } = useTranslation();
    const { isTabletOrMobile } = useResponsiveDesign();

    const { stateProps, dispatchProps } = getAllProps(allProps);
    const [settings, setSettings] = useState<uiStateTypes.DesignerApiSettings>({
        apiKey: '',
        designerVersion: '',
        uploadTenant: '',
        structuredTags: [],
    });
    const [errorMessages, setErrorMessages] = useState(defaultErrorMessages);
    const [inputTypes, setInputTypes] = useState(defaultInputTypes);
    const [isEditing, setIsEditing] = useState(false);

    const canReadSetting = stateProps.currentTenant ? stateProps.currentTenant.permissions.setting.canRead : false;
    const canManageSetting = stateProps.currentTenant ? stateProps.currentTenant.permissions.setting.canManage : false;

    const setInitialValues = () => {
        setSettings(initialSettings(stateProps.settings));
        setInputTypes(defaultInputTypes);
        setIsEditing(false);
    };

    const onEditClick = () => {
        stateProps.settings && setSettings({ ...stateProps.settings });
        setInputTypes({ ...inputTypes,
            apiKey: 'text',
            fontRepository: 'text',
        });
        setIsEditing(true);
    };

    const save = () => {
        if (settings && settings.apiKey && settings.designerVersion && settings.uploadTenant) {
            const newSettings: uiStateTypes.DesignerApiSettings = {
                id: stateProps.settings && stateProps.settings.id,
                apiKey: settings.apiKey,
                uploadTenant: settings.uploadTenant,
                fontRepository: settings.fontRepository,
                fonts: settings.fonts,
                designerVersion: settings.designerVersion,
                enableEditingAceTemplates: stateProps.settings?.enableEditingAceTemplates,
                enableIncompleteSkuVariableSelection: stateProps.settings?.enableIncompleteSkuVariableSelection,
                structuredTags: settings.structuredTags !== undefined ? settings.structuredTags : [],
            };
            dispatchProps.setTenantSettings(newSettings);
        }
    };

    useEffect(() => {
        const validationErrorKeys = Object.keys(stateProps.settingsValidationError);
        if (validationErrorKeys.length !== 0) {
            validationErrorKeys.forEach((key) => {
                setErrorMessages({ ...errorMessages, ...stateProps.settingsValidationError });
            });
        } else {
            setErrorMessages({ ...defaultErrorMessages });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateProps.settingsValidationError]);

    const cannotResetOrSaveSettings = () => !canManageSetting || stateProps.isSaving;

    useEffect(setInitialValues, [stateProps.settings]);

    const onTextChange = (key: string, e: ChangeEvent<HTMLInputElement>) => {
        let value = e.currentTarget.value.trim().replace('/\r?\n|\r/g', '');
        const commaSeparatedValues = e.currentTarget.value.split(',');
        if (commaSeparatedValues && commaSeparatedValues.length > 0) {
            value = commaSeparatedValues.map((v) => v.trim().replace(/['"]/g, '')).join(',');
        }
        setSettings({ ...settings, [key]: value });
    };

    useEffect(() => {
        if (!stateProps.isTenantLoading && !canReadSetting) {
            dispatchProps.addNotification([formatNotification(SNACKBAR_NOTIFICATION_TYPES.Danger, { key: 'notifications.danger.settingsPermission' }, 0)]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateProps.isTenantLoading, canReadSetting, dispatchProps.addNotification]);

    if (!canReadSetting) {
        return (<Redirect to={{ pathname: '/' }} />);
    }

    const toggleMasking = (key: string) => {
        setInputTypes({ ...inputTypes, [key]: inputTypes[key] === 'password' ? 'text' : 'password' });
    };

    const getHelpText = (key: ConfigurationProperties['property']): string => {
        let helpText = '';
        if (errorMessages[key]) {
            helpText = errorMessages[key];
        } else if (!settings[key]) {
            helpText = t('configuration.requiredError');
        }
        return helpText;
    };

    const handleStructuredTagChange = (key: StructuredTags[]) => {
        setSettings({ ...settings, structuredTags: key });
    };

    return (
        <div className={`container configuration ${cx({ 'tablet-mobile': isTabletOrMobile })}`}>
            {(stateProps.isSettingsLoaded && !stateProps.isTenantLoading && settings)
                && (
                    <Card header={t('configuration.title')}>
                        <div className='setting'>
                            <TextField
                                label={t('configuration.apiKey.label')}
                                className={!settings.apiKey ? 'has-error' : ''}
                                value={settings.apiKey || ''}
                                type={inputTypes.apiKey}
                                onChange={(e) => onTextChange('apiKey', e)}
                                disabled={!canManageSetting || stateProps.isSaving || !isEditing}
                                helpText={getHelpText('apiKey')}
                                bsStyle={(errorMessages.apiKey) ? BS_STYLE.error : BS_STYLE.none}
                                required={true}
                                rightAddon={(
                                    <>
                                        {!isEditing
                                            && (
                                                <Button className='show-property' onClick={() => toggleMasking('apiKey')}>
                                                    <IconView />
                                                </Button>
                                            )}
                                    </>
                                )}
                            />
                            <div className='setting-item__info'>
                                <Tooltip direction='top' contents={t('configuration.apiKey.description')}>
                                    <IconInformationCircle
                                        color={ocean.darker}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                        <div className='setting'>
                            <TextField
                                label={t('configuration.uploadTenant.label')}
                                className={!settings.uploadTenant ? 'has-error' : ''}
                                value={settings.uploadTenant || ''}
                                type={inputTypes.uploadTenant}
                                onChange={(e) => onTextChange('uploadTenant', e)}
                                disabled={!canManageSetting || stateProps.isSaving || !isEditing}
                                helpText={getHelpText('uploadTenant')}
                                bsStyle={errorMessages.uploadTenant ? BS_STYLE.error : BS_STYLE.none}
                                required={true}
                            />
                            <div className='setting-item__info'>
                                <Tooltip direction='top' contents={t('configuration.uploadTenant.description')}>
                                    <IconInformationCircle
                                        color={ocean.darker}
                                    />
                                </Tooltip>
                                <Tooltip direction='top' contents={t('configuration.uploadTenant.information')}>
                                    <IconAlertTriangle
                                        color={warning.darker}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                        <div className='setting'>
                            <TextField
                                label={t('configuration.fontRepository.label')}
                                value={settings.fontRepository || ''}
                                type={inputTypes.fontRepository}
                                onChange={(e) => onTextChange('fontRepository', e)}
                                disabled={!canManageSetting || stateProps.isSaving || !isEditing}
                                required={false}
                                rightAddon={(
                                    <>
                                        {(!isEditing && settings.fontRepository)
                                            && (
                                                <Button className='show-property' onClick={() => toggleMasking('fontRepository')}>
                                                    <IconView />
                                                </Button>
                                            )}
                                    </>
                                )}
                            />
                            <div className='setting-item__info'>
                                <Tooltip direction='top' contents={t('configuration.fontRepository.description')}>
                                    <IconInformationCircle
                                        color={ocean.darker}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                        <div className='setting'>
                            <TextField
                                label={t('configuration.fonts.label')}
                                value={settings.fonts || ''}
                                type={inputTypes.fonts}
                                onChange={(e) => onTextChange('fonts', e)}
                                disabled={!canManageSetting || stateProps.isSaving || !isEditing}
                                required={false}
                            />
                            <div className='setting-item__info'>
                                <Tooltip direction='top' contents={t('configuration.fonts.description')}>
                                    <IconInformationCircle
                                        color={ocean.darker}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                        <div className='setting'>
                            <TextField
                                label={t('configuration.designerVersion.label')}
                                className={!settings.designerVersion ? 'has-error' : ''}
                                value={settings.designerVersion || ''}
                                type={inputTypes.designerVersion}
                                onChange={(e) => onTextChange('designerVersion', e)}
                                disabled={!canManageSetting || stateProps.isSaving || !isEditing}
                                helpText={getHelpText('designerVersion')}
                                bsStyle={errorMessages.designerVersion ? BS_STYLE.error : BS_STYLE.none}
                                required={true}
                            />
                            <div className='setting-item__info'>
                                <Tooltip direction='top' contents={t('configuration.designerVersion.description')}>
                                    <IconInformationCircle
                                        color={ocean.darker}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                        <div className='setting'>
                            <StructuredTag
                                onStructuredTagChange={handleStructuredTagChange}
                                loadedStructuredTags={settings.structuredTags}
                                disabled={!canManageSetting || stateProps.isSaving || !isEditing}
                            />
                        </div>
                        {
                            !cannotResetOrSaveSettings() && (
                                <div className='configuration__footer'>
                                    { isEditing
                                        ? (
                                            <>
                                                <Button className='cancel-button' disabled={cannotResetOrSaveSettings()} onClick={setInitialValues}>Cancel</Button>
                                                <Button type='primary' disabled={cannotResetOrSaveSettings()} onClick={save}>Save</Button>
                                            </>
                                        )
                                        : <Button onClick={onEditClick}>{t('common.edit')}</Button>}
                                </div>
                            )
                        }
                    </Card>
                )}
        </div>
    );
}

export default connect<StateProps, DispatchProps, {}, RootState>(mapStateToProps, mapDispatchToProps)(Configuration);
