import { useTranslation } from 'react-i18next';
import React, { ChangeEvent, useState } from 'react';
import { TextField, Tag, Tooltip, colors } from '@cimpress/react-components';
import { templateTypes } from 'store/template';
import { handleEnterKeyPress } from 'utility/keyEvent.utility';
import { CollectionTagPrefix } from 'utility/constants';

import './templateInformation.scss';
import IconInformationCircle from '@cimpress-technology/react-streamline-icons/lib/IconInformationCircle';
import { BS_STYLE } from 'utility/enums';
import { deduplicateTrimTags, getDisallowedTags, tagArrayToString, tagStringToArray, trimValidateTags } from 'utility/tags.utility';
import { StructuredTags } from 'store/uiState/types.uiState';

const { ocean } = colors;

interface Props {
    inFlightTemplate: templateTypes.InFlightTemplate;
    structuredTags: StructuredTags[] | undefined;
    tags?: string[];
    setInFlightTemplateName(payload: string): void;
    setInFlightTemplateDescription(payload: string): void;
    setInFlightTemplateTags(payload: string[]): void;
    removeInFlightTemplateTags(payload: string[]): void;
}

export default function TemplateInformationMinimal({
    inFlightTemplate,
    structuredTags,
    tags,
    setInFlightTemplateName,
    setInFlightTemplateDescription,
    setInFlightTemplateTags,
    removeInFlightTemplateTags,
}: Props) {
    const { t } = useTranslation();
    const [tag, setTag] = useState('');
    const [isDuplicateTag, setDuplicateTag] = useState(false);
    const [hasDisallowedTags, setHasDisallowedTags] = useState(false);
    const hasName = !!inFlightTemplate.name;
    const hasTags = tags !== undefined && tags?.length > 0;

    const filterTag = (templateTag: string) => !templateTag.includes(CollectionTagPrefix);
    const setName = (e: ChangeEvent<HTMLInputElement>) => setInFlightTemplateName(e.currentTarget.value);
    const setDescription = (e: ChangeEvent<HTMLInputElement>) => setInFlightTemplateDescription(e.currentTarget.value);
    const setTagValue = (e: ChangeEvent<HTMLInputElement>) => {
        isDuplicateTag && setDuplicateTag(false);
        hasDisallowedTags && setHasDisallowedTags(false);
        setTag(e.currentTarget.value);
    };

    const addTag = () => {
        if (!tag) {
            return;
        }

        const tagSet = tagStringToArray(tag);
        setDuplicateTag(false);
        setHasDisallowedTags(false);

        if (!tagSet.length) {
            return;
        }

        const alreadySetTags = trimValidateTags(inFlightTemplate.tags, structuredTags).resultTags;

        // no new tags are added, only duplicates
        if (tagSet && tagSet.length > 0 && tagSet.every((item) => alreadySetTags.indexOf(item) > -1)) {
            setDuplicateTag(true);
            return;
        }

        // some structured tags have values outside allowed ones
        const disallowedTags = getDisallowedTags(structuredTags, tagSet);
        if (disallowedTags.length > 0) {
            setHasDisallowedTags(true);
            return;
        }

        setTag('');

        // everything is ok, combine existing and freshly added tags + deduplicate and trim
        const resultTags = deduplicateTrimTags([...alreadySetTags, ...tagSet]);
        setInFlightTemplateTags(resultTags);
    };

    const removeTag = (tagToBeRemoved: React.ReactNode) => {
        removeInFlightTemplateTags([tagToBeRemoved] as string[]);
    };

    const copyTags = () => {
        if (tag === '') {
            setTag(tags !== undefined ? tagArrayToString(tags) : '');
        } else {
            const combinedTagString = combineUserSpecifiedTagsWithSourceTags();
            setTag(combinedTagString);
        }
    };

    const combineUserSpecifiedTagsWithSourceTags = (): string => {
        if (!tags) {
            return tag;
        }

        const userSpecifiedTags = tagStringToArray(tag);

        const result = deduplicateTrimTags([...userSpecifiedTags, ...tags]);
        return tagArrayToString(result);
    };

    const tagErrorMessage = (): string => {
        if (isDuplicateTag) {
            return t('editor.tabs.templateInfo.addTagError');
        }

        if (hasDisallowedTags) {
            return t('editor.tabs.templateInfo.addTagStructuredTagError');
        }

        return '';
    };

    return (
        <>
            <TextField
                label={t('editor.tabs.templateInfo.name')}
                value={inFlightTemplate.name}
                onChange={setName}
                required={hasName}
                bsStyle={hasName ? BS_STYLE.none : BS_STYLE.error}
                helpText={hasName ? '' : t('editor.tabs.templateInfo.nameError')}
            />
            <TextField
                label={t('editor.tabs.templateInfo.description')}
                value={inFlightTemplate.description}
                onChange={setDescription}
            />
            <div className='input-tags-vertical'>
                <div className='input-tags'>
                    <TextField
                        label={t('editor.tabs.templateInfo.addTag')}
                        value={tag}
                        onChange={setTagValue}
                        onKeyPress={handleEnterKeyPress(addTag)}
                        rightAddon={(
                            <>
                                <button type='button' onClick={addTag} className='btn btn-default' disabled={!tag}>
                                    {t('common.add')}
                                </button>
                            </>

                        )}
                        bsStyle={isDuplicateTag || hasDisallowedTags ? BS_STYLE.error : BS_STYLE.none}
                        helpText={isDuplicateTag || hasDisallowedTags ? tagErrorMessage() : ''}
                    />
                    <div className='input-tags-info'>
                        <Tooltip direction='top' contents={t('editor.tabs.templateInfo.tagInfo')}>
                            <IconInformationCircle
                                color={ocean.darker}
                            />
                        </Tooltip>
                    </div>
                </div>
                <div className='clickable-text' role='button' tabIndex={0} onClick={copyTags} onKeyDown={copyTags} hidden={!hasTags}>
                    {t('common.copy')}
                </div>
            </div>
            <div className='info-container__tag-set'>
                {inFlightTemplate.tags.map((eachTag) => filterTag(eachTag) && <Tag value={eachTag} key={eachTag} removable={true} onRemoveClick={removeTag} />)}
            </div>
        </>
    );
}
