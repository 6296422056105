/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import { Button, Label } from '@cimpress/react-components';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { templateTypes } from 'store/template';

import './versionItem.scss';

interface Props {
    version: templateTypes.TemplateVersion;
    isSelected: boolean;
    versionsToCompare?: templateTypes.TemplateVersion[];
    publishedVersionId?: string;
    onVersionChange(payload: templateTypes.TemplateVersion): void;
    addVersionToCompare?(payload: templateTypes.TemplateVersion): void;
}

const FormatString = 'MMMM do yyyy, h:mm:ss a';

export default function VersionItem({ version, isSelected, versionsToCompare, publishedVersionId, onVersionChange, addVersionToCompare }: Props) {
    const { t } = useTranslation();
    const selectVersion = () => { onVersionChange(version); };
    const addToCompare = () => { addVersionToCompare && addVersionToCompare(version); };
    const compareIndex = versionsToCompare && versionsToCompare.findIndex((v) => v.id === version.id) + 1;
    return (
        <div className={`version template-version-item list-group-item${isSelected ? ' active' : ''}${compareIndex && compareIndex > 0 ? ' version--comparing' : ''}`} onClick={selectVersion}>
            <div className='version__version'>
                {!!version.versionNumber && <Label text={`V${version.versionNumber}`} type='info' />}
                <div className='version__line'>
                    <div className='version__line-top' />
                    <div className={`version__circle ${version.versionNumber && 'label-info'}`} />
                    <div className='version__line-bottom' />
                </div>
            </div>
            <div className='version__item'>
                <div className='version__details'>
                    {version.id === publishedVersionId && <Label text={t('version.versionItem.currentVersion')} type='success' />}
                    <div className='version__date'>{t('version.versionItem.created')}: {format(new Date(version.created), FormatString)}</div>
                    <div className='version__creator'>{t('version.versionItem.author')}: {version.createdBy}</div>
                    {version.published && <div className='version__published'>{t('version.versionItem.lastPublished')}: {format(new Date(version.published), FormatString)}</div>}
                    {version.publishedBy && <div className='version__publisher'>{t('version.versionItem.publisher')}: {version.publishedBy}</div>}
                </div>
                {addVersionToCompare && (
                    <div className='version__actions'>
                        { compareIndex && compareIndex > 0 ? <Label text={`${t('common.preview')} ${compareIndex}`} type='warning' />
                            : <Button onClick={addToCompare}>{t('version.versionItem.btnCompare')}</Button>}
                    </div>
                )}
            </div>
        </div>
    );
}
