import { RootState } from 'store/rootReducer';
import { createSelector } from '@reduxjs/toolkit';
import { getAssetPreview } from 'utility/url.utility';
import { OriginalPreviewSize, ThumbnailPreviewSize } from 'utility/constants';
import { PREVIEW_TYPE_VIEW } from 'utility/enums';
import { templateTypes } from './index';

const addPreview = (template: templateTypes.Template): templateTypes.Template => ({
    ...template,
    original: getAssetPreview({
        assetId: template.id,
        width: OriginalPreviewSize,
        view: PREVIEW_TYPE_VIEW.CloseUp,
    }),
    thumbnail: getAssetPreview({
        assetId: template.id,
        width: ThumbnailPreviewSize,
        view: PREVIEW_TYPE_VIEW.CloseUp,
    }),
});

const selectCurrentOffset = (state: RootState): string | undefined => state.templateState.offset;
const selectCurrentTemplate = (state: RootState) => state.templateState.currentTemplate;
const selectAllTemplates = (state: RootState) => state.templateState.templates;
const selectHasMoreTemplates = (state: RootState): boolean => !!state.templateState.offset;
const selectBulkProcess = (state: RootState): templateTypes.BulkProcess | undefined => state.templateState.bulkProcess;
const selectInflightTemplate = (state: RootState): templateTypes.InFlightTemplate => state.templateState.inFlightTemplate;
const selectInflightTemplateId = (state: RootState): string | undefined => state.templateState.inFlightTemplate.id;
const selectBulkProgress = (state: RootState): templateTypes.BulkProgress => {
    const result = {
        total: 0,
        processed: 0,
    };
    if (state.templateState.bulkProcess) {
        const { beforeProcessing, afterProcessing } = state.templateState.bulkProcess;
        const beforeProcessingCount = beforeProcessing ? beforeProcessing.length : 0;
        const afterProcessingCount = afterProcessing ? afterProcessing.length : 0;
        result.total = beforeProcessingCount + afterProcessingCount;
        result.processed = afterProcessingCount;
    }

    return result;
};
const addPreviewRevision = (version: templateTypes.TemplateVersion): templateTypes.TemplateVersion => ({
    ...version,
    original: getAssetPreview({
        assetId: version.assetId,
        width: OriginalPreviewSize,
        view: PREVIEW_TYPE_VIEW.CloseUp,
        revisionId: version.id,
    }),
    thumbnail: getAssetPreview({
        assetId: version.assetId,
        width: ThumbnailPreviewSize,
        view: PREVIEW_TYPE_VIEW.CloseUp,
        revisionId: version.id,
    }),
});

const selectTemplateVersions = (state: RootState) => state.templateState.templateVersions;
const selectRecentTemplates = (state: RootState) => state.templateState.recentTemplates;

const selectAllTemplatesWithPreview = createSelector(
    [selectAllTemplates],
    (templates) => templates && templates.map(addPreview),
);

const selectRecentTemplatesWithPreview = createSelector(
    [selectRecentTemplates],
    (templates) => templates && templates.map(addPreview),
);

const selectCurrentTemplateWithPreview = createSelector(
    [selectCurrentTemplate],
    (currentTemplate) => currentTemplate && addPreview(currentTemplate),
);

const selectTemplateVersionsWithPreview = createSelector(
    [selectTemplateVersions],
    (templateVersions) => templateVersions.map(addPreviewRevision),
);

export default {
    selectCurrentOffset,
    selectAllTemplatesWithPreview,
    selectRecentTemplatesWithPreview,
    selectCurrentTemplateWithPreview,
    selectCurrentTemplate,
    selectAllTemplates,
    selectHasMoreTemplates,
    selectBulkProcess,
    selectBulkProgress,
    selectTemplateVersionsWithPreview,
    selectInflightTemplate,
    selectInflightTemplateId,
    selectTemplateVersions,
    selectRecentTemplates,
};
