export function extractManufacturingProcessType(document?: DesignerDocument): string {
    if (document === undefined || document.canvases === undefined || document.canvases.length === 0) {
        return 'unknown';
    }

    const frontCanvas = document.canvases[0];
    return frontCanvas.processType;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function extractCimDocManufacturingProcessType(document?: any): string {
    if (document === undefined) {
        return 'unknown';
    }

    if (document.panels === undefined && document.surfaces === undefined && document.document !== undefined) {
        return extractCimDocManufacturingProcessType(document.document);
    }

    if (document.panels === undefined && document.surfaces === undefined) {
        return 'unknown';
    }

    const panels = document.panels || document.surfaces;
    if (panels.length === 0) {
        return 'unknown';
    }

    return panels[0].decorationTechnology;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function extractCimDocManufacturingSkuVariables(document?: any): string | undefined {
    if (document === undefined || document.skuVariables === undefined) {
        return undefined;
    }

    const firstLetterUpperCase = (str: string) => str[0].toUpperCase() + str.slice(1);

    const skuVariables = Object.assign({}, ...Object.entries(document.skuVariables).map(
        ([k, v]) => ({ [firstLetterUpperCase(k)]: v }),
    ));

    return JSON.stringify(skuVariables);
}
