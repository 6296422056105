import { logError } from 'loggingManager';
import { axiosWithAuth } from 'interceptors/auth.interceptor';

export interface McpProduct {
    mcpSku: string;
    name: string;
}

export async function getProduct(sku: string) {
    const url = `https://introduction.products.cimpress.io/v3/mcpSkus/${sku}`;
    return axiosWithAuth.get(url)
        .then((response) => (response.data))
        .catch((error) => {
            logError(`Could not fetch product ${error}`);
        });
}
