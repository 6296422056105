import { SVGNameSpace } from 'utility/constants';
import { getMaskColor, getStrokeWidthByCanvasSize } from 'utility/helper';

const NameSpace = 'http://www.w3.org/2000/svg';

const createSVG = (width: number, height: number) => {
    const svg = document.createElementNS(NameSpace, 'svg');
    // TODO: Revisit the logic of viewbox
    svg.setAttribute('viewBox', `-1 -1 ${width + 2} ${height + 2}`);

    return svg;
};

export const createSvgElement = (tag: string) => document.createElementNS(SVGNameSpace, tag);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createPathDescription = (instructions: { start: any; directions: any[]; closePath: any }) => `${instructions.start} ${instructions.directions.join(' ')} ${instructions.closePath}`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createPaths = (svgData: { type: any; paths?: any }, width: number, height: number) => svgData.paths.map((pathInstruction: { start: any; directions: any[]; closePath: any }) => {
    const path = createSvgElement('path');
    const pathDescription = createPathDescription(pathInstruction);
    path.setAttribute('d', pathDescription);
    const strokeWidth = getStrokeWidthByCanvasSize({ attributes: { width, height } });
    path.setAttribute('stroke-width', strokeWidth.toString());
    path.setAttribute('fill', 'transparent');
    const strokeColor = getMaskColor(svgData.type);
    path.setAttribute('stroke', strokeColor);

    return path;
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const drawSvg = (width: number, height: number, allSvgs: { type: string; paths: any }[]) => {
    const svg = createSVG(width, height);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    allSvgs && allSvgs.forEach((eachSVG: { type: string; paths: any }) => {
        const pathElement = createPaths(eachSVG, width, height);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        pathElement.forEach((p: any) => svg.appendChild(p));
    });

    return svg;
};
