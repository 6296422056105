import { RootState } from 'store/rootReducer';
import { tenantTypes, tenantSelector } from 'store/tenant';
import { editorActions } from 'store/editor';
import { templateActions, templateTypes, templateSelector } from 'store/template';
import { uiStateSelector } from 'store/uiState';
import { StructuredTags } from 'store/uiState/types.uiState';

export interface OwnProps {
    designer?: Designer;
    designerMode: string;
}
export interface StateProps {
    tenant: tenantTypes.Tenant | undefined;
    hidden: boolean;
    inFlightTemplate: templateTypes.InFlightTemplate;
    structuredTags: StructuredTags[] | undefined;
    currentLocale: string | undefined;
}

export interface DispatchProps {
    toggleHide(payload: boolean): void;
    setInFlightTemplateName(payload: string): void;
    setInFlightTemplateDescription(payload: string): void;
    setInFlightTemplateTags(payload: string[]): void;
    removeInFlightTemplateTags(payload: string[]): void;
}

export type AllProps = StateProps & DispatchProps & OwnProps;

export const getAllProps = (props: AllProps) => {
    const { designer, designerMode, tenant, hidden, inFlightTemplate, currentLocale, structuredTags,
        toggleHide, setInFlightTemplateName, setInFlightTemplateDescription, setInFlightTemplateTags, removeInFlightTemplateTags } = props;
    const ownProps: OwnProps = { designer, designerMode };
    const stateProps: StateProps = { tenant, hidden, inFlightTemplate, currentLocale, structuredTags };
    const dispatchProps: DispatchProps = { toggleHide, setInFlightTemplateName, setInFlightTemplateDescription, setInFlightTemplateTags, removeInFlightTemplateTags };

    return { ownProps, stateProps, dispatchProps };
};

export const mapStateToProps = (state: RootState): StateProps => ({
    tenant: tenantSelector.selectCurrentTenant(state),
    hidden: state.editorState.editorLoadSettings.tabsHidden,
    inFlightTemplate: templateSelector.selectInflightTemplate(state),
    currentLocale: uiStateSelector.selectCurrentLocale(state),
    structuredTags: state.uiState.settings?.structuredTags,
});

export const mapDispatchToProps: DispatchProps = {
    toggleHide: editorActions.toggleTabsHidden,
    setInFlightTemplateName: templateActions.setInFlightTemplateName,
    setInFlightTemplateDescription: templateActions.setInFlightTemplateDescription,
    setInFlightTemplateTags: templateActions.setInFlightTemplateTags,
    removeInFlightTemplateTags: templateActions.removeInFlightTemplateTags,
};
