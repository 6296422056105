/* eslint-disable no-constant-condition */
import React, { useEffect, useState, ReactNode, useCallback } from 'react';
import CollectionList from 'components/collectionList/CollectionList';
import { RootState } from 'store/rootReducer';
import { connect } from 'react-redux';
import { collectionTypes, collectionUtils } from 'store/collection';
import { queryAssets } from 'store/template/service.template';
import { CollectionTagPrefix, MaxSubCollectionLevel, AllCollection } from 'utility/constants';
import { formatNotification } from 'store/uiState';
import { SNACKBAR_NOTIFICATION_TYPES } from 'utility/enums';
import ConfirmDialog from 'shared/confirmDialog/confirmDialog';
import { Button, shapes } from '@cimpress/react-components';
import { findCollectionById } from 'store/collection/collectionUtils';
import CollectionModal from 'components/collectionModal/CollectionModal';
import { useTranslation } from 'react-i18next';
import { getAllProps, AllProps, StateProps, DispatchProps, mapStateToProps, mapDispatchToProps } from './props.collectionWrapper';

const { Spinner } = shapes;

interface ModalDialogConfig {
    title: string;
    body: ReactNode;
    footer: ReactNode;
}

function CollectionWrapper(allProps: AllProps) {
    const { stateProps, dispatchProps } = getAllProps(allProps);

    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [showCollectionModal, setShowCollectionModal] = useState<boolean>(false);
    const [confirmModalConfig, setConfirmModalConfig] = useState<ModalDialogConfig>();
    const [collectionToBeDeleted, setCollectionToBeDeleted] = useState<collectionTypes.Collection>();
    const [selectedCollections, setSelectedCollections] = useState<collectionTypes.Collection[]>([]);

    const onChangeSelection = useCallback((selectedCollection: collectionTypes.Collection) => {
        selectedCollection && dispatchProps.updateActiveCollection(selectedCollection.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatchProps.updateActiveCollection]);

    const resetCollectionSelection = () => stateProps.activeCollection && stateProps.activeCollection.id && dispatchProps.updateActiveCollection(AllCollection);

    const { t } = useTranslation();

    useEffect(() => {
        if (stateProps.tenant) {
            dispatchProps.onCollectionLoad();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatchProps.onCollectionLoad, stateProps.tenant]);

    const onAddOrEditCollection = (label: string, parentId: string, id?: string) => dispatchProps.onAddOrEditCollection({ label, parentId, id });

    const onConfirmModalCancel = () => setShowConfirmModal(false);

    const onConfirmModalNo = (collection: collectionTypes.Collection) => {
        setShowConfirmModal(false);
        if (collection) {
            const parentCollection = findCollectionById(stateProps.collections, collection.parentId);
            const targetCollections = parentCollection ? [parentCollection] : [];
            collection && dispatchProps.moveAllTemplatesAndDeleteCollection({ sourceCollectionId: collection.id, targetCollections });
        }
    };

    const onConfirmModalYes = () => {
        setShowConfirmModal(false);
        setShowCollectionModal(true);
    };

    const onCollectionModalCancel = () => {
        setShowCollectionModal(false);
        setShowConfirmModal(true);
        setSelectedCollections([]);
    };

    const onCollectionModalSelect = () => {
        if (selectedCollections && selectedCollections.length) {
            setShowCollectionModal(false);
            setShowConfirmModal(false);
            collectionToBeDeleted && dispatchProps.moveAllTemplatesAndDeleteCollection({ sourceCollectionId: collectionToBeDeleted.id, targetCollections: selectedCollections });
        }
    };

    const getConfirmTargetModalConfig = (collection: collectionTypes.Collection) => {
        const body = collection.parentId ? t('modals.deleteCollection.moveToParent') : t('modals.deleteCollection.moveToAllTemplates');
        return {
            title: t('modals.deleteCollection.title'),
            body,
            footer: (
                <div>
                    <Button onClick={onConfirmModalCancel}>{t('common.cancel')}</Button>
                    <Button onClick={() => onConfirmModalNo(collection)}>{t('common.no')}</Button>
                    <Button type='primary' onClick={onConfirmModalYes}>{t('common.yes')}</Button>
                </div>
            ),
        };
    };

    const getDeleteEmptyCollectionConfirmModalConfig = (collectionToBeDeletedId: string) => ({
        title: t('modals.deleteCollection.title'),
        body: t('modals.deleteCollection.confirmDeleteText'),
        footer: (
            <div>
                <Button onClick={() => setShowConfirmModal(false)}>{t('common.no')}</Button>
                <Button
                    type='primary'
                    onClick={() => {
                        setShowConfirmModal(false);
                        dispatchProps.deleteCollection(collectionToBeDeletedId);
                    }}
                >{t('common.yes')}
                </Button>
            </div>
        ),
    });

    const handleDeleteCollection = (collection: collectionTypes.Collection) => {
        setCollectionToBeDeleted({ ...collection });
        dispatchProps.setManualLoader();
        stateProps.tenant && queryAssets({
            tenantId: stateProps.tenant.tenantId,
            tenantType: stateProps.tenant.tenantType,
            includeDeleted: true,
            pageSize: 1,
            tag: `${CollectionTagPrefix}${collection.id}`,
        }).then(({ templates }) => {
            dispatchProps.resetManualLoader();
            const modalConfig = templates.length > 0 ? getConfirmTargetModalConfig(collection) : getDeleteEmptyCollectionConfirmModalConfig(collection.id);
            setConfirmModalConfig(modalConfig);
            setShowConfirmModal(true);
        }).catch(() => {
            dispatchProps.resetManualLoader();
            dispatchProps.addNotification([formatNotification(SNACKBAR_NOTIFICATION_TYPES.Danger, { key: 'notifications.danger.generic' })]);
        });
    };

    const onExpand = (collectionExpanded: collectionTypes.Collection) => {
        const collection = collectionUtils.findCollectionById(stateProps.collections, collectionExpanded.id);
        collection && !collection.children && dispatchProps.onGetChildren(collection);
    };

    const onChangeSelectedCollection = (collections: collectionTypes.Collection[]) => setSelectedCollections(collections);
    const resetCollectionAddedSuccess = () => dispatchProps.resetIsCollectionAddedSuccess && dispatchProps.resetIsCollectionAddedSuccess();

    return (
        <>
            {stateProps.isCollectionLoading ? <Spinner />
                : (
                    <CollectionList
                        collections={stateProps.collections}
                        currentCollectionId={stateProps.activeCollection && stateProps.activeCollection.id}
                        onChangeSelection={onChangeSelection}
                        onAddOrEditCollection={onAddOrEditCollection}
                        levelToAddChild={MaxSubCollectionLevel}
                        onExpand={onExpand}
                        resetCollectionSelection={resetCollectionSelection}
                        resetButtonText={t('catalog.actions.allTemplates')}
                        addButtonText={t('catalog.actions.addCollection')}
                        onDeleteCollection={handleDeleteCollection}
                        isCollectionSaving={stateProps.isCollectionSaving}
                        isCollectionAddedSuccess={stateProps.isCollectionAddedSuccess}
                        resetIsCollectionAddedSuccess={resetCollectionAddedSuccess}
                        tenant={stateProps.tenant}
                        addNotification={dispatchProps.addNotification}
                    />
                )}
            { confirmModalConfig && (
                <ConfirmDialog isDialogOpen={showConfirmModal}>
                    {confirmModalConfig}
                </ConfirmDialog>
            )}

            { showCollectionModal && collectionToBeDeleted && (
                <CollectionModal
                    title={t('modals.collection.moveTitle')}
                    footer={(
                        <div className='collection-modal-btn'>
                            <Button className='btn btn-default' onClick={onCollectionModalCancel}>{t('common.cancel')}</Button>
                            <Button
                                disabled={!selectedCollections.length}
                                className='btn btn-primary'
                                onClick={onCollectionModalSelect}
                            >{t('common.move')}
                            </Button>
                        </div>
                    )}
                    onRequestHide={onCollectionModalCancel}
                    onChangeSelectedCollection={onChangeSelectedCollection}
                    collections={stateProps.collections}
                    isOpen={showCollectionModal}
                    selectedCollectionArea={{
                        title: t('modals.collection.moveToSelected'),
                        description: t('modals.collection.moveToSelectedDescription'),
                        emptyMessage: t('modals.collection.moveToSelectedEmptyMessage'),
                    }}
                />
            )}
        </>
    );
}

export default connect<StateProps, DispatchProps, {}, RootState>(mapStateToProps, mapDispatchToProps)(CollectionWrapper);
