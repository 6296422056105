import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { clipArtTypes } from 'store/clipart';
import { directoryTypes } from 'shared/directory';
import { DirectoryList } from 'shared/directory/directoryList/DirectoryList';
import './clipartWrapper.scss';
import { shapes } from '@cimpress/react-components';
import ClipArtList from 'shared/clipart/clipartList/ClipartList';
import { mapStateToProps, StateProps, DispatchProps, OwnProps, mapDispatchToProps, AllProps, getAllProps } from './props.clipartWrapper';

const { Spinner } = shapes;

const formatClipArtCategories = (categories: clipArtTypes.ClipArtCategory[]): directoryTypes.Directory[] => categories.map((category) => ({
    label: category.name,
    id: category.id,
    subDirectories: category.subCategory ? formatClipArtCategories(category.subCategory) : [],
}));

function ClipArtWrapper(allProps: AllProps) {
    const { ownProps, stateProps, dispatchProps } = getAllProps(allProps);
    const [selectedCategoryId, setSelectedCategoryId] = useState<string | undefined>(); // This can be maintained in store also as activeClipArtCategory
    useEffect(() => {
        if (!stateProps.clipArtCategories.length) {
            dispatchProps.loadClipArtCategories();
        }
        if (!selectedCategoryId && stateProps.clipArtCategories) {
            if (stateProps.clipArtCategories[0] && stateProps.clipArtCategories[0].subCategory && stateProps.clipArtCategories[0].subCategory[0]) {
                setSelectedCategoryId(stateProps.clipArtCategories[0].subCategory[0].id);
                dispatchProps.loadClipArts(stateProps.clipArtCategories[0].subCategory[0].id);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatchProps.loadClipArts, dispatchProps.loadClipArtCategories, stateProps.clipArts, stateProps.clipArtCategories, selectedCategoryId]);

    const onCategorySelection = (directory: directoryTypes.Directory) => {
        const directoryId = (directory.subDirectories && directory.subDirectories[0]) ? directory.subDirectories[0].id : directory.id;
        dispatchProps.loadClipArts(directoryId);
        setSelectedCategoryId(directoryId);
    };

    return (
        <div className='clipart-container'>
            {(!!stateProps.clipArtCategories.length && !!selectedCategoryId) && (
                <div className='clipart-container__left-panel'>
                    <DirectoryList
                        directories={formatClipArtCategories(stateProps.clipArtCategories)}
                        selectedDirectoryIds={[selectedCategoryId]}
                        onSelect={onCategorySelection}
                        expandOnLabelClick={true}
                    />
                </div>
            )}
            <div className='clipart-container__right-panel'>
                {stateProps.isClipArtLoading ? <Spinner className='clipart-items-spinner' size='medium' /> : <ClipArtList clipArts={stateProps.clipArts} onSelectClipArt={ownProps.onSelectClipArt} />}
            </div>
        </div>
    );
}

export default connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)(ClipArtWrapper);
