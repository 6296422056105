export const handleEnterKeyPress = (functionToBeExecuted: () => void) => (event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyCode = event.which;
    if (keyCode === 13) {
        functionToBeExecuted();
    }
};

export const handleEscKeyUp = (functionToBeExecuted: () => void) => (event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyCode = event.which;
    if (keyCode === 27) {
        functionToBeExecuted();
    }
};
