import React, { useEffect, useRef, useState } from 'react';
import { InlineEdit } from '@cimpress/react-components';

interface Props {
    name: string;
    value: string;
    placeholder: string;
    isRequired: boolean;
    errorMessage?: string;
    isError?(payload: string): boolean;
    onCancel(): void;
    onSave(name: string, value?: string): void;
}

export default function InlineTextEdit({ name, value, placeholder, isRequired, errorMessage, isError, onCancel, onSave }: Props) {
    const [disable, setDisable] = useState(false);
    const node = useRef<HTMLDivElement>(null);

    const changeToEditingState = () => {
        if (node && node.current) {
            const editElement = node.current.getElementsByClassName('resting-text')[0];
            if (editElement) {
                const el = (editElement.getElementsByTagName('p')[0] as HTMLElement);
                el.click();
            }
        }
    };

    const onSaveValue = (inputTarget: { name?: string; value?: string }) => {
        onSave(name, inputTarget.value);
        onCancel();
    };

    const validateInput = (newText: string) => {
        let message = null;
        if (isError && isError(newText)) {
            setDisable(true);
            message = errorMessage || null;
        } else if (newText === value) {
            setDisable(true);
        } else if (newText === '' && isRequired) {
            setDisable(true);
        } else {
            setDisable(false);
        }
        return message;
    };

    useEffect(() => {
        changeToEditingState();
    }, []);

    return (
        <div ref={node}>
            <InlineEdit
                value={value}
                placeholder={placeholder}
                onSave={onSaveValue}
                onCancel={onCancel}
                validateInput={validateInput}
                className={disable ? 'save--disabled' : ''}
            />
        </div>
    );
}
