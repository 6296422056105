import React, { useState, useCallback } from 'react';
import { TabCard } from '@cimpress/react-components';

import Overlay from 'shared/overlay/Overlay';
import './create.scss';

import { useResponsiveDesign } from 'providers/ResponsiveDesign';
import { productTypes } from 'store/product';
import { useTranslation } from 'react-i18next';
import { CREATE_MODE } from 'utility/enums';
import TabFooter from './TabFooter';
import CreateTemplate from '../../connectedComponents/createTemplate/CreateTemplate';

export interface Props {
    location?: {
        search: string;
    };
}

function Create({ location }: Props) {
    const { isMobile } = useResponsiveDesign();
    const [isTemplateInformationOpen, setIsTemplateInformationOpen] = useState(false);
    const [currentProduct, setCurrentProduct] = useState<productTypes.Product>();
    const [isProductCompletelyConfigured, setIsProductCompletelyConfigured] = useState<boolean>(true);
    const [surfaceSpecifications, setSurfaceSpecifications] = useState<productTypes.SurfaceSpecification>();
    const { t } = useTranslation();

    const onValidProduct = useCallback((product: productTypes.Product, complete: boolean) => {
        setCurrentProduct(product);
        setIsProductCompletelyConfigured(complete);
    }, []);

    const onInValidProduct = useCallback(() => {
        setCurrentProduct(undefined);
    }, []);

    const onTabSwitch = () => {
        setCurrentProduct(undefined);
        setSurfaceSpecifications(undefined);
    };

    const onTemplateInformationToggle = (isNowOpen: boolean) => {
        setIsTemplateInformationOpen(isNowOpen);
    };

    const onValidSurfaceSpecification = useCallback((surfaceSpec: productTypes.SurfaceSpecification) => {
        setSurfaceSpecifications(surfaceSpec);
    }, []);

    const onInValidSurfaceSpecification = useCallback(() => {
        setSurfaceSpecifications(undefined);
    }, []);

    return (
        <div className='create'>
            <Overlay routeName='create' />
            <h2>{t('create.createTemplate')}</h2>
            <TabCard
                onSelect={onTabSwitch}
                tabs={[
                    {
                        name: isMobile ? t('create.tabs.sku_md') : t('create.tabs.sku_lg'),
                        block: (
                            <CreateTemplate
                                isTemplateInformationOpen={isTemplateInformationOpen}
                                onTemplateInformationToggle={onTemplateInformationToggle}
                                onValidProduct={onValidProduct}
                                onInValidProduct={onInValidProduct}
                                createMode={CREATE_MODE.skuBased}
                            />
                        ),
                        footer: <TabFooter currentProduct={currentProduct} completelyConfiguredProduct={isProductCompletelyConfigured} />,
                    },
                    {
                        name: isMobile ? t('create.tabs.custom_md') : t('create.tabs.custom_lg'),
                        block: (
                            <CreateTemplate
                                isTemplateInformationOpen={isTemplateInformationOpen}
                                onTemplateInformationToggle={onTemplateInformationToggle}
                                onValidSurfaceSpecification={onValidSurfaceSpecification}
                                onInValidSurfaceSpecification={onInValidSurfaceSpecification}
                                surfaceSpecifications={surfaceSpecifications}
                                createMode={CREATE_MODE.custom}
                            />
                        ),
                        footer: <TabFooter surfaceSpecifications={surfaceSpecifications} completelyConfiguredProduct={isProductCompletelyConfigured} />,
                    },
                ]}
            />

        </div>
    );
}

export default Create;
