import { connect } from 'react-redux';
import React, { ChangeEvent, useState, useEffect, useCallback } from 'react';
import { Select, TextField, Button } from '@cimpress/react-components';
import IconRemoveCircle from '@cimpress-technology/react-streamline-icons/lib/IconRemoveCircle';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'customHooks/useDebounce';
import cx from 'classnames';
import IconSearch from '@cimpress-technology/react-streamline-icons/lib/IconSearch';

import { handleEnterKeyPress } from 'utility/keyEvent.utility';
import { SEARCH_OPTIONS } from 'utility/enums';
import { RootState } from 'store/rootReducer';
import { fetchOwnerProfiles } from 'services/accessManagement.service';
import { useResponsiveDesign } from 'providers/ResponsiveDesign';
import { mapStateToProps, StateProps, DispatchProps, mapDispatchToProps, getAllProps, AllProps } from './props.searchTemplate';

import './searchTemplate.scss';

const searchOptionsDropDownData = [
    { value: SEARCH_OPTIONS.Name, labelKey: 'catalog.filters.searchTypes.name.label', placeholderKey: 'catalog.filters.searchTypes.name.placeholder' },
    { value: SEARCH_OPTIONS.Description, labelKey: 'catalog.filters.searchTypes.description.label', placeholderKey: 'catalog.filters.searchTypes.description.placeholder' },
    { value: SEARCH_OPTIONS.ReferenceId, labelKey: 'catalog.filters.searchTypes.referenceId.label', placeholderKey: 'catalog.filters.searchTypes.referenceId.placeholder' },
    { value: SEARCH_OPTIONS.Tags, labelKey: 'catalog.filters.searchTypes.tags.label', placeholderKey: 'catalog.filters.searchTypes.tags.placeholder' },
    { value: SEARCH_OPTIONS.Author, labelKey: 'catalog.filters.searchTypes.author.label', placeholderKey: 'catalog.filters.searchTypes.author.placeholder' },
];

function SearchTemplate(allProps: AllProps) {
    const { stateProps, dispatchProps } = getAllProps(allProps);
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState('');
    const [ownerName, setOwnerName] = useState('');
    const [matchedOwners, setMatchedOwners] = useState<{name: string;emailId: string}[]|undefined>(undefined);
    const { isMobile, isTablet, isDesktopOrLaptop } = useResponsiveDesign();

    const debounceOwnerName = useDebounce(ownerName, 500);

    const [searchOption, setSearchOption] = useState(searchOptionsDropDownData[0]);
    const onSearch = () => {
        dispatchProps.setSearch({ searchBy: searchOption.value, searchString: searchValue });
    };

    const onSearchByAuthor = (emailId: string) => {
        dispatchProps.setSearch({ searchBy: searchOption.value, searchString: emailId });
    };

    const clearSearch = useCallback(() => {
        setOwnerName('');
        setMatchedOwners(undefined);
        dispatchProps.resetSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const clearText = useCallback(() => {
        setSearchValue('');
        clearSearch();
    }, [clearSearch]);

    const onChangeSearchValue = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.currentTarget.value);
        if (searchOption.value === SEARCH_OPTIONS.Author) {
            setOwnerName(e.currentTarget.value);
        }
        if (e.currentTarget.value === '') {
            clearSearch();
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onChangeSearchOption = (selectedOption: any) => {
        setSearchOption(searchOptionsDropDownData.find((option) => option.value === selectedOption.value) || searchOptionsDropDownData[0]);
        setMatchedOwners(undefined);
        setOwnerName('');
        if (selectedOption.value === SEARCH_OPTIONS.Author) {
            setOwnerName(searchValue);
        }
    };

    const onSelectOwner = ({ name, emailId }: {name: string;emailId: string}) => {
        const { includeDeleted } = stateProps.filterCriteria;
        dispatchProps.setUserPreference({ filter: { includeDeleted, includeOwnTemplates: false } });
        dispatchProps.setShowOwnTemplates(false);
        setSearchValue(name);
        setMatchedOwners(undefined);
        setOwnerName('');
        onSearchByAuthor(emailId);
    };

    const isDisabled = () => searchOption.value === SEARCH_OPTIONS.Author || !searchValue;

    useEffect(() => {
        if (debounceOwnerName && searchOption.value === SEARCH_OPTIONS.Author) {
            fetchOwnerProfiles(debounceOwnerName)
                .then((owners) => setMatchedOwners(owners));
        }
    }, [debounceOwnerName, searchOption]);

    useEffect(() => {
        setSearchOption(searchOptionsDropDownData[0]);
        clearText();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateProps.currentTenant, clearText]);

    return (
        <div className={cx('search-container', { mobile: isMobile })} id='joy-ride__template-search'>
            <div className={cx('search-container__search-option', { mobile: isMobile })}>
                <Select
                    label={t('catalog.filters.ddSearchTypes')}
                    value={{ value: searchOption.value, label: t(searchOption.labelKey) }}
                    options={searchOptionsDropDownData.map((option) => ({
                        value: option.value,
                        label: t(option.labelKey),
                        placeholder: t(option.placeholderKey),
                    }))}
                    onChange={onChangeSearchOption}
                    tether={true}
                    clearable={false}
                />
            </div>
            <div className={cx('search-container__search-value', { mobile: isMobile })}>
                <TextField
                    label={t(searchOption.placeholderKey)}
                    className='search-text'
                    value={searchValue}
                    onChange={onChangeSearchValue}
                    onKeyPress={handleEnterKeyPress(onSearch)}
                    rightAddon={(
                        <>
                            {searchValue
                                && (
                                    <Button className='search-clear-button' onClick={clearText}>
                                        <IconRemoveCircle weight='fill' />
                                    </Button>
                                )}
                        </>
                    )}
                />
                {isMobile && !isDisabled() && (
                    <IconSearch className='btn-search-mobile' onClick={onSearch} size='lg' />
                )}
                {matchedOwners && (
                    <div className='search-container__owners'>
                        {matchedOwners.map((owner) => owner.name && (<button type='button' className='list-group-item' onClick={() => onSelectOwner(owner)}>{owner.name}</button>))}
                    </div>
                )}

            </div>
            {(isTablet || isDesktopOrLaptop) && (
                <Button disabled={isDisabled()} className='btn btn-default search-btn ' onClick={onSearch}>
                    {t('catalog.filters.btnSearch')}
                </Button>
            )}
        </div>
    );
}

export default connect<StateProps, DispatchProps, {}, RootState>(mapStateToProps, mapDispatchToProps)(SearchTemplate);
