import { IsDevMode } from 'utility/constants';

export function logInfo(message: string) {
    if (IsDevMode) {
        console.log(message); // eslint-disable-line no-console
    }
}

export function logError(message: string) {
    if (IsDevMode) {
        console.error(message); // eslint-disable-line no-console
    }
}
