import { tenantTypes, tenantActions } from 'store/tenant';
import { RootState } from 'store/rootReducer';
import { uiStateActions, uiStateTypes, uiStateSelector } from 'store/uiState';

export interface StateProps {
    userPreference: uiStateTypes.UserPreference;
    currentTenant?: tenantTypes.Tenant;
    settings?: uiStateTypes.DesignerApiSettings;
    hasMandatoryDesignerSettings: boolean;
    isSettingsLoaded: boolean;
    isHydrated: boolean;
}

export interface DispatchProps {
    loadUserPreference(): void;
    loadTenantSettings(): void;
    fetchTenant(): void;
}

export type AllProps = StateProps & DispatchProps;

export const getAllProps = (props: AllProps) => {
    const { currentTenant, userPreference, settings, hasMandatoryDesignerSettings, isSettingsLoaded, isHydrated, loadUserPreference, loadTenantSettings, fetchTenant } = props;
    const stateProps: StateProps = { currentTenant, userPreference, settings, hasMandatoryDesignerSettings, isSettingsLoaded, isHydrated };
    const dispatchProps: DispatchProps = { loadUserPreference, loadTenantSettings, fetchTenant };

    return { stateProps, dispatchProps };
};

export const mapStateToProps = (state: RootState): StateProps => ({
    userPreference: state.uiState.userPreference,
    currentTenant: state.tenantState.currentTenant,
    settings: state.uiState.settings,
    hasMandatoryDesignerSettings: uiStateSelector.selectHasMandatoryDesignerSettings(state),
    isSettingsLoaded: state.uiState.isSettingsLoaded,
    isHydrated: uiStateSelector.selectIsHydrated(state),
});

export const mapDispatchToProps: DispatchProps = {
    loadUserPreference: uiStateActions.loadUserPreference,
    loadTenantSettings: uiStateActions.loadTenantSettings,
    fetchTenant: tenantActions.fetchTenant,
};
