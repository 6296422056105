import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import copyToClipboard from 'clipboard-copy';
import { colors } from '@cimpress/react-components';
import ProductCard from '@cimpress-technology/product-card';
import IconPencilAlt from '@cimpress-technology/react-streamline-icons/lib/IconPencilAlt';
import IconCopy from '@cimpress-technology/react-streamline-icons/lib/IconCopy';
import IconCopyPaste from '@cimpress-technology/react-streamline-icons/lib/IconCopyPaste';
import IconHierarchyFlow from '@cimpress-technology/react-streamline-icons/lib/IconHierarchyFlow';
import IconNavigationNext from '@cimpress-technology/react-streamline-icons/lib/IconNavigationNext';
import IconBin from '@cimpress-technology/react-streamline-icons/lib/IconBin';
import IconFloppyDiskAlt from '@cimpress-technology/react-streamline-icons/lib/IconFloppyDiskAlt';
import IconUndo from '@cimpress-technology/react-streamline-icons/lib/IconUndo';
import { useTranslation } from 'react-i18next';
import PreviewThumbnail from 'components/preview/Thumbnail';
import { TEMPLATE_ACTION_TYPE, DESIGNER_MODE } from 'utility/enums';
import { getAssetContentUrl } from 'utility/url.utility';
import { URLs } from 'utility/constants';
import { templateTypes } from 'store/template';

import './templateCard.scss';
import 'components/preview/previewThumbnail.scss';
import CopyDesignModal from 'components/copyDesignModal/CopyDesignModal';

const { success, warning, alloy } = colors;

interface Props {
    canEdit: boolean;
    template: templateTypes.Template;
    isUpdating: boolean;
    setConfirmTemplateAction(payload: templateTypes.TemplateActionConfirmation): void;
    setTemplateToPreview(payload: templateTypes.Template): void;
    onCopyUrl(): void;
}

export default function TemplateCard({
    canEdit,
    template,
    isUpdating,
    setConfirmTemplateAction,
    setTemplateToPreview,
    onCopyUrl,
}: Props) {
    const { t } = useTranslation();
    const [redirectObj, setRedirectObj] = useState({ redirectTo: '', isDuplicate: false, transferDesign: false });
    const [isLoading, setIsLoading] = useState(true);
    const [docRefUrlToCopy, setDocRefUrlToCopy] = useState<string>();

    const resetLoader = () => setIsLoading(false);
    const setPreviewModalOpen = () => setTemplateToPreview(template);
    const onClickCopy = () => {
        copyToClipboard(getAssetContentUrl(template.id));
        onCopyUrl();
    };

    const isDraft = false;

    if (redirectObj.redirectTo) {
        let search = '';
        if (redirectObj.isDuplicate) {
            search = '?mode=duplicate';
        } else if (redirectObj.transferDesign) {
            search = `?docRefUrl=${getAssetContentUrl(template.id)}`;
        }
        return <Redirect to={{ pathname: redirectObj.redirectTo, search }} push={true} />;
    }

    const actions = isDraft
        ? [
            {
                title: `${t('catalog.actionsDropdown.publishLabel')}`,
                icon: <IconFloppyDiskAlt />,
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onClick: () => {}, // setOpenDraftModal(true),
            },
        ]
        : [
            {
                title: `${t('catalog.actionsDropdown.restoreLabel')}`,
                icon: <IconUndo />,
                onClick: () => setConfirmTemplateAction({
                    template,
                    templateActionType: TEMPLATE_ACTION_TYPE.Restore,
                }),
            },
        ];

    const getCardTag = () => {
        let resultTag;
        if (isUpdating) {
            resultTag = {
                title: t('catalog.templateStatus.updating'),
                color: warning.base,
            };
        } else if (template.deleted) {
            resultTag = {
                title: t('catalog.templateStatus.deleted'),
                color: alloy,
            };
        } else if (isDraft) {
            resultTag = {
                title: t('catalog.templateStatus.draft'),
                color: warning.base,
            };
        } else {
            resultTag = {
                title: t('catalog.templateStatus.published'),
                color: success.darker,
            };
        }
        return resultTag;
    };
    return (
        <div className='template-container'>
            {docRefUrlToCopy && (
                <CopyDesignModal
                    onCancel={() => setDocRefUrlToCopy(undefined)}
                    docRefUrl={docRefUrlToCopy}
                />
            )}
            <PreviewThumbnail src={template.thumbnail} onLoaded={resetLoader} setOpen={setPreviewModalOpen} isHidden={true} isDraft={isDraft} />
            <ProductCard
                orientation='vertical'
                size='l'
                loading={(!isDraft && isLoading)}
                imageUrl={isDraft ? URLs.DraftTemplateImageURL : (template.thumbnail || '')}
                productName={template.name}
                productDetails={template.collectionLabels && template.collectionLabels.length
                    ? [{ key: t('catalog.lblCollections'), value: template.collectionLabels.map((c) => c.label).join(', ') }] : []}
                notSelectable={true}
                allowImageClick={!isDraft}
                onClick={setPreviewModalOpen}
                tag={getCardTag()}
                actions={
                    !template.deleted && !isDraft
                        ? [
                            ...(canEdit && !template.attributes?.camDesignId ? [{
                                title: `${t('catalog.actionsDropdown.editLabel')}`,
                                icon: <IconPencilAlt weight='fill' />,
                                onClick: () => setRedirectObj({ redirectTo: `/editor/${DESIGNER_MODE.TemplateEditor}/${template.id}`, isDuplicate: false, transferDesign: false }),
                            }] : []),
                            ...(canEdit ? [{
                                title: `${t('catalog.actionsDropdown.duplicateLabel')}`,
                                icon: <IconCopy />,
                                onClick: () => setConfirmTemplateAction({
                                    template,
                                    templateActionType: TEMPLATE_ACTION_TYPE.Duplicate,
                                }),
                            }] : []),
                            {
                                title: `${t('catalog.actionsDropdown.copyLabel')}`,
                                icon: <IconCopyPaste />,
                                onClick: onClickCopy,
                            },
                            {
                                title: `${t('catalog.actionsDropdown.versionsLabel')}`,
                                icon: <IconHierarchyFlow />,
                                onClick: () => setRedirectObj({ redirectTo: `/versions/${template.id}`, isDuplicate: false, transferDesign: false }),
                            },
                            ...(canEdit ? [{
                                title: `${t('catalog.actionsDropdown.transferDesignLabel')}`,
                                icon: <IconNavigationNext />,
                                onClick: () => setDocRefUrlToCopy(getAssetContentUrl(template.id)),
                            }] : []),
                            {
                                title: `${t('catalog.actionsDropdown.deleteLabel')}`,
                                icon: <IconBin />,
                                onClick: () => setConfirmTemplateAction({
                                    template,
                                    templateActionType: TEMPLATE_ACTION_TYPE.Delete,
                                }),
                            },
                        ]
                        : actions
                }
            />
        </div>
    );
}
