/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import ReactJoyride, { CallBackProps, STATUS, TooltipRenderProps } from 'react-joyride';
import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';

import { Modal, Button } from '@cimpress/react-components';
import IconArrowRightAlt from '@cimpress-technology/react-streamline-icons/lib/IconArrowRightAlt';
import IconArrowLeftAlt from '@cimpress-technology/react-streamline-icons/lib/IconArrowLeftAlt';
import IconRoadSignBanned from '@cimpress-technology/react-streamline-icons/lib/IconRoadSignBanned';
import IconCheckCircleAlt from '@cimpress-technology/react-streamline-icons/lib/IconCheckCircleAlt';
import { useTranslation } from 'react-i18next';
import { BS_STYLE } from 'utility/enums';
import { demoList } from './steps';
import { AllProps, getAllProps, mapStateToProps, mapDispatchToProps, DispatchProps, OwnProps, StateProps } from './props.overlay';

function Overlay(allProps: AllProps) {
    const { t } = useTranslation();
    const { ownProps, stateProps, dispatchProps } = getAllProps(allProps);
    const [autoDisplay, setAutoDisplay] = useState(false);

    const { userPreference } = stateProps;

    const getActiveDemo = () => demoList.find((demo) => demo.isActive === true);

    const getActiveDemoVersion = () => {
        const activeDemo = getActiveDemo();
        return activeDemo && activeDemo.version;
    };

    const getCurrentActiveRoute = () => {
        const activeDemo = getActiveDemo();
        return activeDemo && activeDemo.routes.find((route) => route.routeName === ownProps.routeName && route.isActive);
    };

    const shouldAutoDisplay = (demo: {[key: string]: string[]} | undefined, activeDemoVersion: string) => {
        let showDemo = false;
        if (!demo) {
            showDemo = true;
        } else if (!demo[activeDemoVersion]) {
            showDemo = true;
        } else if (demo[activeDemoVersion] && !demo[activeDemoVersion].includes(ownProps.routeName)) {
            showDemo = true;
        }
        return showDemo;
    };

    const generateDisplayedRoutes = (demo: {[key: string]: string[]} | undefined, activeDemoVersion: string) => {
        let routes: string[];
        if (!demo) {
            routes = [ownProps.routeName];
        } else {
            routes = [...demo[activeDemoVersion]];
            if (routes && routes.length > 0) {
                if (!routes.includes(ownProps.routeName)) {
                    routes.push(ownProps.routeName);
                }
            } else {
                routes = [ownProps.routeName];
            }
        }
        return routes;
    };

    // useEffect(() => {
    //     dispatchProps.resetDisplayOverlay();
    // }, []);

    const existInDOM = (step: any) => (document.getElementById(step.target.replace('#', '')));

    const getDemoSteps = () => {
        if (autoDisplay || stateProps.displayOverlay) {
            const activeDemoRoute = getCurrentActiveRoute();
            if (activeDemoRoute) {
                return activeDemoRoute.steps.filter(existInDOM).map((step: any) => ({ ...step, title: t(step.title), content: t(step.content) }));
            }
            dispatchProps.resetDisplayOverlay();
        }
        return [];
    };

    useEffect(() => {
        const activeDemoVersion = getActiveDemoVersion();
        let triggerAutoDemo = false;
        if (userPreference && Object.keys(userPreference).length !== 0 && activeDemoVersion) {
            if (userPreference.demo) {
                const { demo } = userPreference;
                triggerAutoDemo = shouldAutoDisplay(demo, activeDemoVersion);
            } else {
                triggerAutoDemo = shouldAutoDisplay(undefined, activeDemoVersion);
            }
            triggerAutoDemo && setAutoDisplay(true);
        }
    }, [userPreference]);

    const handleCallback = (props: CallBackProps) => {
        const { status } = props;
        if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
            stateProps.displayOverlay && dispatchProps.resetDisplayOverlay();
            const activeDemoVersion = getActiveDemoVersion();
            if (autoDisplay && activeDemoVersion) {
                setAutoDisplay(false);
                if (userPreference && userPreference.demo) {
                    const displayedRoutes = generateDisplayedRoutes(userPreference.demo, activeDemoVersion);
                    dispatchProps.setUserPreference({ demo: { ...userPreference.demo, [activeDemoVersion]: displayedRoutes } });
                } else {
                    const displayedRoutes = generateDisplayedRoutes(undefined, activeDemoVersion);
                    dispatchProps.setUserPreference({ demo: { [activeDemoVersion]: displayedRoutes } });
                }
            }
        }
    };

    const footer = (props: any) => {
        const { index, isLastStep, backProps, primaryProps, skipProps } = props;
        return (
            <>
                {!isLastStep && (
                    <Button {...skipProps} type='link' className='overlay-actions__skip-btn'>
                        {skipProps.title}
                        <IconRoadSignBanned size='lg' className='overlay-actions__skip-icon' />
                    </Button>
                )}
                {index !== 0 && (
                    <Button {...backProps} type='link'>
                        <IconArrowLeftAlt size='lg' className='overlay-actions__prev-icon' />
                        {backProps.title}
                    </Button>
                )}
                <Button {...primaryProps} type='primary'>
                    {isLastStep ? t('common.finish') : `${primaryProps.title}`}
                    {isLastStep ? <IconCheckCircleAlt size='lg' className='overlay-actions__next-icon' /> : <IconArrowRightAlt size='lg' className='overlay-actions__next-icon' />}
                </Button>
            </>
        );
    };

    const overlayModal = ({
        index,
        isLastStep,
        step,
        backProps,
        primaryProps,
        skipProps,
        tooltipProps,
    }: TooltipRenderProps) => (
        <div {...tooltipProps}>
            <Modal
                className='overlay-modal'
                bsStyle={BS_STYLE.info}
                show={autoDisplay || stateProps.displayOverlay}
                title='Template Maker Info'
                footer={footer({ index, isLastStep, backProps, primaryProps, skipProps })}
            >
                {step.title && <div className='overlay-title'>{step.title}:</div>}
                {step.content && <div className='overlay-content'>{step.content}</div>}
            </Modal>
        </div>
    );
    const buttonLocales = {
        back: t('overlayDemo.actions.back'),
        close: t('overlayDemo.actions.close'),
        last: t('overlayDemo.actions.last'),
        next: t('overlayDemo.actions.next'),
        open: t('overlayDemo.actions.open'),
        skip: t('overlayDemo.actions.skip'),
    };
    return (
        <ReactJoyride
            callback={handleCallback}
            steps={getDemoSteps()}
            run={autoDisplay || stateProps.displayOverlay}
            continuous={true}
            showProgress={false}
            showSkipButton={true}
            scrollOffset={300}
            styles={{
                options: {
                    primaryColor: '#0088a9',
                    arrowColor: 'transparent',
                },
            }}
            locale={buttonLocales}
            tooltipComponent={overlayModal}
        />
    );
}

export default connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)(Overlay);
