import React, { useState } from 'react';
import { Card, Button, Label } from '@cimpress/react-components';
import { useTranslation } from 'react-i18next';

import PreviewThumbnail from 'components/preview/Thumbnail';
import PreviewModal from 'components/preview/PreviewModal';
import { templateTypes } from 'store/template';

import './compareTemplateVersions.scss';

interface Props {
    versionsToCompare: templateTypes.TemplateVersion[];
    cancelComparing(): void;
}

export default function CompareTemplateVersions({ versionsToCompare, cancelComparing }: Props) {
    const { t } = useTranslation();
    const [templateToPreview, setTemplateToPreview] = useState<templateTypes.PreviewTemplateModal | undefined>();
    const setPreviewModalOpen = (version: templateTypes.TemplateVersion,
        previewNumber: number) => () => setTemplateToPreview({ template: version, title: `${t('common.preview')} ${previewNumber}` });

    const onModalClose = () => {
        setTemplateToPreview(undefined);
    };

    return (
        <>
            { templateToPreview
                    && (
                        <PreviewModal
                            title={templateToPreview.title}
                            src={templateToPreview.template.original}
                            onModalClose={onModalClose}
                        />
                    )}
            <h2 className='version-compare__title'>
                {t('version.compareVersion.compareVersion')}
                <Button className='version-compare__actions' onClick={cancelComparing}>{t('version.compareVersion.btnCancelCompare')}</Button>
            </h2>
            {versionsToCompare.map((version, index) => (
                <Card
                    className='version-compare-preview'
                    header={<Label text={`V${version.versionNumber}`} type='warning' />}
                    key={version.id}
                >
                    <PreviewThumbnail src={version.original} setOpen={setPreviewModalOpen(version, index + 1)} />
                </Card>
            ))}
        </>
    );
}
