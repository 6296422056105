import { uiStateTypes, uiStateSelector, uiStateActions } from 'store/uiState';
import { RootState } from 'store/rootReducer';
import { templateActions } from 'store/template';
import { tenantTypes, tenantSelector } from 'store/tenant';

export interface StateProps {
    filterCriteria: uiStateTypes.FilterCriteria;
    currentTenant: tenantTypes.Tenant | undefined;
}
export interface DispatchProps {
    setShowOwnTemplates(payload: boolean): void;
    setSearch(payload: uiStateTypes.Search): void;
    resetSearch(): void;
    loadTemplates(): void;
    setUserPreference(payload: uiStateTypes.UserPreference): void;
}
export type AllProps = StateProps & DispatchProps;

export const getAllProps = (props: AllProps) => {
    const { filterCriteria, currentTenant, setShowOwnTemplates, setSearch, resetSearch, loadTemplates, setUserPreference } = props;
    const stateProps: StateProps = { filterCriteria, currentTenant };
    const dispatchProps: DispatchProps = { setShowOwnTemplates, setSearch, resetSearch, loadTemplates, setUserPreference };
    return { stateProps, dispatchProps };
};

export const mapStateToProps = (state: RootState): StateProps => ({
    filterCriteria: uiStateSelector.selectFilterCriteria(state),
    currentTenant: tenantSelector.selectCurrentTenant(state),
});
export const mapDispatchToProps: DispatchProps = {
    setSearch: uiStateActions.setSearch,
    resetSearch: uiStateActions.resetSearch,
    setShowOwnTemplates: uiStateActions.setShowOwnTemplates,
    loadTemplates: templateActions.loadTemplates,
    setUserPreference: uiStateActions.setUserPreference,
};
