import { collectionTypes, collectionActions } from 'store/collection';
import { ReactNode } from 'react';

export interface OwnProps {
    title: string;
    footer: ReactNode;
    collections: collectionTypes.Collection[];
    isOpen: boolean;
    disabledDirectoryIds?: string[];
    selectedCollectionArea: {
        title: string;
        description: string;
        emptyMessage: string;
    };
    onChangeSelectedCollection(collections: collectionTypes.Collection[]): void;
    onRequestHide(): void;
}
export interface DispatchProps {
    onGetChildren(folder: collectionTypes.Collection): void;
}

export type AllProps = DispatchProps & OwnProps;

export const getAllProps = (props: AllProps) => {
    const { title, footer, collections, disabledDirectoryIds, selectedCollectionArea, isOpen, onChangeSelectedCollection, onGetChildren, onRequestHide } = props;
    const ownProps: OwnProps = { title, footer, collections, disabledDirectoryIds, selectedCollectionArea, isOpen, onChangeSelectedCollection, onRequestHide };
    const dispatchProps: DispatchProps = { onGetChildren };

    return { ownProps, dispatchProps };
};

export const mapDispatchToProps: DispatchProps = {
    onGetChildren: collectionActions.onCollectionChildrenLoad,
};
