import { RootState } from 'store/rootReducer';
import { uiStateSelector, uiStateActions, uiStateTypes } from 'store/uiState';

export interface OwnProps {
    routeName: string;
}
export interface StateProps {
    displayOverlay: boolean;
    userPreference: uiStateTypes.UserPreference;
}

export interface DispatchProps {
    resetDisplayOverlay(): void;
    setUserPreference(payload: uiStateTypes.UserPreference): void;
}

export type AllProps = StateProps & DispatchProps & OwnProps;

export const getAllProps = (props: AllProps) => {
    const { routeName, displayOverlay, userPreference, resetDisplayOverlay, setUserPreference } = props;
    const ownProps: OwnProps = { routeName };
    const stateProps: StateProps = { displayOverlay, userPreference };
    const dispatchProps: DispatchProps = { resetDisplayOverlay, setUserPreference };

    return { ownProps, stateProps, dispatchProps };
};

export const mapStateToProps = (state: RootState): StateProps => ({
    displayOverlay: uiStateSelector.selectDisplayOverlay(state),
    userPreference: uiStateSelector.selectUserPreference(state),
});

export const mapDispatchToProps: DispatchProps = {
    resetDisplayOverlay: uiStateActions.resetDisplayOverlay,
    setUserPreference: uiStateActions.setUserPreference,
};
