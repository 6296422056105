import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    hidden: boolean;
}

/**
 * This is a tab for adding, removing, and editing textfields. Two widgets get injected here.
 */
export default function TextTab({ hidden }: Props) {
    const { t } = useTranslation();

    return (
        <div className={`content-panel${hidden ? ' content-panel--hidden' : ''}`}>
            <div className='content-panel__title'>{t('editor.tabs.text.textTabTitle')}</div>
            <div className='content-panel__add-button'>
                <div className='dcl-widget-add-text' />
            </div>
            <div className='dcl-widget-edit-text' />
        </div>
    );
}
