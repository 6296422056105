import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { colors } from '@cimpress/react-components';
import IconImageFileSettings from '@cimpress-technology/react-streamline-icons/lib/IconImageFileSettings';
import { RootState } from 'store/rootReducer';
import { DESIGNER_MODE } from 'utility/enums';
import { useTranslation } from 'react-i18next';

import Images from 'components/Images';
import Text from 'components/Text';
import TemplateInformation from 'components/templateInformation/TemplateInformation';
import ShapesTab from 'components/smartShapes/Shapes';

import { ClipArtModal } from '../clipartTab/clipArtModal/ClipArtModal';
import { getAllProps, AllProps, StateProps, DispatchProps, OwnProps, mapStateToProps, mapDispatchToProps } from './props.tabs';

import './tabs.scss';
// eslint-disable-next-line import/order
import useFullScreen from 'customHooks/useFullScreen';

enum TabsEnum {
    IMAGE = 'image',
    TEXT = 'text',
    SHAPE = 'shape',
    INFORMATION = 'information',
    CLIPART = 'clipart'
}

function GenerateInfoSvgIcon(active: boolean) {
    const { info } = colors;
    return (
        <div className='tab-item__info-icon'>
            <IconImageFileSettings size='3x' color={(active && info.base) || ''} />
        </div>
    );
}

function GenerateSvgIcon(tabType: string) {
    return (
        <svg className='tab-item__icon'>
            <use className='tab-item__use' xlinkHref={`#dcl-icon-${tabType}`} />
        </svg>
    );
}

function GenerateTabItem(onClick: () => void, tabType: string, title: string, active: boolean) {
    const isInfoTab = tabType === TabsEnum.INFORMATION;

    return (
        <button type='button' className={`tab-item tab-item-${tabType} ${active ? ' tab-item--active' : ''}`} onClick={onClick} data-dcl-prevent-canvas-items-deselection={true}>
            <div className={`tab-item__icon-wrapper ${isInfoTab ? `tab-item__info-icon-wrapper${active ? '--active' : ''}` : ''}`}>
                {!isInfoTab ? GenerateSvgIcon(tabType) : GenerateInfoSvgIcon(active)}
            </div>
            <div className='tab-item__title'>
                {title}
            </div>
        </button>
    );
}

/**
 * This is the component wrapper for the a lot of widgets that add/edit things on the canvas.
 */
function Tabs(allProps: AllProps) {
    const { ownProps, stateProps, dispatchProps } = getAllProps(allProps);
    const { isFullscreen } = useFullScreen();

    const isCustomerDesignerMode = ownProps.designerMode === DESIGNER_MODE.CustomerDesigner;
    const [openTab, setOpenTab] = useState(!isCustomerDesignerMode ? TabsEnum.INFORMATION : TabsEnum.IMAGE);
    const [openClipArtTab, setOpenClipArtTab] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        dispatchProps.toggleHide(isFullscreen);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFullscreen]);

    const openImages = () => setOpenTab(TabsEnum.IMAGE);
    const openText = () => setOpenTab(TabsEnum.TEXT);
    const openShape = () => setOpenTab(TabsEnum.SHAPE);
    const openInformation = () => setOpenTab(TabsEnum.INFORMATION);
    const openClipArt = () => setOpenClipArtTab(true);
    const onClick = () => dispatchProps.toggleHide(!stateProps.hidden);

    const shapesEnabled = ownProps.designer && !ownProps.designer.featureManager.featureEnabled('addShape') ? null : true;
    const clipArtEnabled = (stateProps.tenant && stateProps.tenant.tenantId === 'build-a-sign');

    return (
        <>
            <div className={`tabs${stateProps.hidden ? ' tabs--hidden' : ''}`}>
                <div className='tabs__items'>
                    {!isCustomerDesignerMode && GenerateTabItem(openInformation, TabsEnum.INFORMATION, t('editor.tabs.templateInfo.shortTitle'), openTab === TabsEnum.INFORMATION)}
                    {GenerateTabItem(openImages, TabsEnum.IMAGE, t('editor.tabs.images.shortTitle'), openTab === TabsEnum.IMAGE)}
                    {GenerateTabItem(openText, TabsEnum.TEXT, t('editor.tabs.text.shortTitle'), openTab === TabsEnum.TEXT)}
                    {shapesEnabled && GenerateTabItem(openShape, TabsEnum.SHAPE, t('editor.tabs.shapes.shortTitle'), openTab === TabsEnum.SHAPE)}
                    {(!isCustomerDesignerMode && clipArtEnabled && ownProps.designer)
                        && GenerateTabItem(openClipArt, TabsEnum.CLIPART, t('editor.tabs.clipArt.shortTitle'), openTab === TabsEnum.CLIPART)}
                </div>
                <div className='tabs__content'>
                    <Images hidden={openTab !== TabsEnum.IMAGE} />
                    <Text hidden={openTab !== TabsEnum.TEXT} />
                    {shapesEnabled && ownProps.designer && stateProps.tenant && (
                        <ShapesTab
                            designer={ownProps.designer}
                            hidden={openTab !== TabsEnum.SHAPE}
                            tenantId={stateProps.tenant.tenantId}
                            locale={stateProps.currentLocale}
                        />
                    )}
                    {!isCustomerDesignerMode
                        && (
                            <TemplateInformation
                                hidden={openTab !== TabsEnum.INFORMATION}
                                inFlightTemplate={stateProps.inFlightTemplate}
                                setInFlightTemplateName={dispatchProps.setInFlightTemplateName}
                                setInFlightTemplateDescription={dispatchProps.setInFlightTemplateDescription}
                                setInFlightTemplateTags={dispatchProps.setInFlightTemplateTags}
                                removeInFlightTemplateTags={dispatchProps.removeInFlightTemplateTags}
                                structuredTags={stateProps.structuredTags}
                            />
                        )}
                    {(!isCustomerDesignerMode && clipArtEnabled && ownProps.designer)
                        && <ClipArtModal designer={ownProps.designer} onRequestHide={() => setOpenClipArtTab(false)} hidden={!openClipArtTab} />}
                </div>
            </div>
            <button type='button' className={`tabs-hide-button${stateProps.hidden ? ' tabs-hide-button--active' : ''}`} onClick={onClick} data-dcl-prevent-canvas-items-deselection={true}>
                <svg>
                    <use xlinkHref='#dcl-icon-downcaret' />
                </svg>
            </button>
        </>
    );
}

export default connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)(Tabs);
