import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TenantState, Tenant } from './types.tenant';

export const INITIAL_STATE: TenantState = {
    currentTenant: undefined,
    availableTenants: [],
};

const slice = createSlice({
    name: 'tenant',
    initialState: INITIAL_STATE,
    reducers: {
        setCurrentTenant: (state, { payload }: PayloadAction<Tenant>) => {
            state.currentTenant = payload;
        },

        fetchTenant: (state) => {},
        fetchTenantSuccess: (state, { payload }: PayloadAction<{ availableTenants: Tenant[]; lastTenant: Tenant | undefined }>) => {
            state.availableTenants = payload.availableTenants;
            state.currentTenant = payload.lastTenant;
        },
        fetchTenantFailure: (state) => {},
    },
});

export default slice;
