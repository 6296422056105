import React from 'react';

import { Modal } from '@cimpress/react-components';
import { clipArtTypes } from 'store/clipart';
import { getUploadUrl } from 'utility/url.utility';
import { UPLOAD_URL_VARIANTS } from 'utility/enums';
import { editorTypes } from 'store/editor';
import { useTranslation } from 'react-i18next';

import ClipArtWrapper from '../clipartWrapper/ClipartWrapper';
import './clipartModal.scss';

interface Props {
    hidden: boolean;
    onRequestHide(): void;
    designer: Designer;
}

const CanvasToClipArtPercentage = 10; // Could be a value between 0 to 100

const getUploadObj = (uploadId: string) => ({
    pages: [{
        pageNumber: 1,
        previewUrl: getUploadUrl(uploadId, 'build-a-sign', UPLOAD_URL_VARIANTS.Preview),
        printUrl: getUploadUrl(uploadId, 'build-a-sign', UPLOAD_URL_VARIANTS.Print),
    }],
    fileUrl: getUploadUrl(uploadId, 'build-a-sign', UPLOAD_URL_VARIANTS.Preview),
});

const addImageToDesigner = (uploadId: string, designer: Designer) => {
    if (designer) {
        const clipArtThumbnail = new Image();
        clipArtThumbnail.onload = () => {
            const aspectRatio = clipArtThumbnail.width / clipArtThumbnail.height;
            const activeCanvasIndex = designer.documentRepository.getActiveCanvas().get('ordinal') - 1;
            designer.api.design.update((mutableCanvases: { addImage(payload: editorTypes.ImageOptions): void; mmDimensions: editorTypes.MmDimensions}[]) => {
                const activeMutableCanvas = mutableCanvases[activeCanvasIndex];
                const mmDimensions = {
                    width: 0,
                    height: 0,
                };
                if (activeMutableCanvas.mmDimensions.width > activeMutableCanvas.mmDimensions.height) {
                    mmDimensions.width = (CanvasToClipArtPercentage / 100) * activeMutableCanvas.mmDimensions.width;
                    mmDimensions.height = mmDimensions.width / aspectRatio;
                } else {
                    mmDimensions.height = (CanvasToClipArtPercentage / 100) * activeMutableCanvas.mmDimensions.height;
                    mmDimensions.width = mmDimensions.height * aspectRatio;
                }
                activeMutableCanvas.addImage({
                    upload: getUploadObj(uploadId),
                    pageNumber: 1,
                    mmDimensions,
                });
            });
        };
        clipArtThumbnail.src = getUploadUrl(uploadId, 'build-a-sign', UPLOAD_URL_VARIANTS.Thumbnail);
    }
};

export function ClipArtModal({ hidden, onRequestHide, designer }: Props) {
    const onSelectClipArt = (clipArt: clipArtTypes.ClipArt) => {
        addImageToDesigner(clipArt.uploadId, designer);
        onRequestHide();
    };
    const { t } = useTranslation();

    return (
        <Modal
            className='clipart-modal'
            show={!hidden}
            closeButton={true}
            title={t('editor.tabs.clipArt.clipArtTabTitle')}
            onRequestHide={onRequestHide}
        >
            <ClipArtWrapper onSelectClipArt={onSelectClipArt} />
        </Modal>
    );
}
