import { RootState } from 'store/rootReducer';
import { clipArtTypes } from './index';

const selectClipArts = (state: RootState): clipArtTypes.ClipArt[] => state.clipArtState.clipArts;
const selectClipArtCategories = (state: RootState): clipArtTypes.ClipArtCategory[] => state.clipArtState.clipArtCategories;

export default {
    selectClipArts,
    selectClipArtCategories,
};
