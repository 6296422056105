/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Tooltip } from '@cimpress/react-components';

import { directoryTypes } from 'shared/directory/index';
import './directoryActions.scss';
import { DIRECTORY_ALLOWED_ACTIONS } from 'utility/enums';

interface Props {
    actions?: directoryTypes.DirectoryActions[];
    directory: directoryTypes.Directory;
    onAddOrEditLabel?(type: DIRECTORY_ALLOWED_ACTIONS): void;
}

export function DirectoryActions({ actions, directory, onAddOrEditLabel }: Props) {
    return (
        <div className='directory__actions'>
            {actions && actions.map((action) => ((action.isDisabled && action.isDisabled(directory)) ? (
                <Tooltip key={`${directory.id}-${action.label}`} direction='right' contents={action.disabledContent}>
                    <div className='directory-header__button disabled'>{action.disabledIcon}</div>
                </Tooltip>
            ) : (
                <div
                    key={`${directory.id}-${action.label}`}
                    className='directory__action-btn text-primary'
                    onClick={() => {
                        if (action.label === DIRECTORY_ALLOWED_ACTIONS.Edit && onAddOrEditLabel) {
                            onAddOrEditLabel(DIRECTORY_ALLOWED_ACTIONS.Edit);
                            return;
                        } if (action.label === DIRECTORY_ALLOWED_ACTIONS.Add && onAddOrEditLabel) {
                            onAddOrEditLabel(DIRECTORY_ALLOWED_ACTIONS.Add);
                            return;
                        }
                        action.onClick && action.onClick(directory);
                    }}
                >{action.icon}
                </div>
            )))}
        </div>
    );
}
