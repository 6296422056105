import { CalculatedSurface, Mask } from 'store/product/types.product';
import { drawSvg } from './drawSvg';
import { convertSurfaceData } from './surfaceSpecificationsConverter';

const convertToSVGs = (surface: CalculatedSurface) => drawSvg(surface.widthCm, surface.heightCm, surface.masks);

const convertSurface = (surface: CalculatedSurface) => {
    const convertedSurface = { ...surface };

    const { masks } = surface.docAdditionalData;
    convertedSurface.masks = masks.map((mask: Mask) => convertSurfaceData(mask, true));

    return convertedSurface;
};

export const convertSurfaceToSVG = (surface: CalculatedSurface) => Promise.resolve(convertSurface(surface))
    .then(convertToSVGs);
