import React from 'react';
import cx from 'classnames';

import CarouselPreviewWrapper from 'components/CarouselPreviewWrapper/CarouselPreviewWrapper';
import SuccessDetails from 'connectedComponents/successDetails/SuccessDetails';
import SuccessHeaderWrapper from 'connectedComponents/successHeaderWrapper/SuccessHeaderWrapper';
import { useResponsiveDesign } from 'providers/ResponsiveDesign';
import './success.scss';

export default function Success() {
    const { isMobile } = useResponsiveDesign();

    return (
        <div className='success'>
            <div className='success__header'>
                <SuccessHeaderWrapper />
            </div>
            <div className={cx('success__template-info')}>
                <div className={cx('success__template-info-card', { mobile: isMobile })}>
                    <div className='success__template-info-details'>
                        <SuccessDetails />
                    </div>
                    <div className={cx('success__template-info-card-preview')}>
                        <div className={cx('carousel-container')}>
                            <div className={cx('image-carousel')}>
                                <CarouselPreviewWrapper
                                    minimal={true}
                                    pxSize={isMobile ? 240 : 400}
                                    showImageHeading={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
