import React from 'react';
import { connect } from 'react-redux';
import { Header } from '@cimpress-technology/react-platform-header/lib/Header';
import { Link, withRouter } from 'react-router-dom';
import { Loudspeaker, LoudspeakerIcon } from '@cimpress-technology/react-loudspeaker';
import { Button } from '@cimpress/react-components';
import { URLs, SupportGroupEmail, SupportedLanguages } from 'utility/constants';
import OverlayButton from 'shared/overlay/OverlayButton';
import TenantDisplay from 'components/tenant/tenantDesplay/TenantDisplay';
import { useAuth } from 'providers/AuthContext';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import { RootState } from 'store/rootReducer';
import useFullScreen from 'customHooks/useFullScreen';
import { StateProps, DispatchProps, OwnProps, mapStateToProps, mapDispatchToProps, AllProps, getAllProps } from './props.header';

export function AppHeader(allProps: AllProps) {
    const { ownProps, stateProps, dispatchProps } = getAllProps(allProps);
    const { isFullscreen } = useFullScreen();

    const { t } = useTranslation();
    const { auth, isLoggedIn } = useAuth();
    function login() {
        auth.login();
    }
    function logout() {
        window.onbeforeunload = null;
        auth.logout('/');
    }
    function getProfile() {
        return isLoggedIn ? auth.getProfile() : {};
    }
    function hasAdminRole() {
        return stateProps.currentTenant && (stateProps.currentTenant.permissions.setting.canRead || stateProps.currentTenant.permissions.setting.canManage);
    }
    const changeLanguage = (languageCode: string) => {
        i18n.changeLanguage(languageCode);
        dispatchProps.setUserPreference({ currentLocale: languageCode });
    };
    const languageDropdownOptions = (
        SupportedLanguages.map((language) => <Button key={language.code} type='link' onClick={() => changeLanguage(language.code)}>{language.text}</Button>)
    );
    const languageDropdown = [{
        id: 'lang',
        type: 'dropdown',
        title: 'Language',
        content: <>{languageDropdownOptions}</>,
    }];
    const appLinks = [
        {
            id: 'tenant-selector',
            content: <TenantDisplay key='tenant' />,
        },
        {
            id: 'app-demo',
            content: !stateProps.isTenantLoading && hasAdminRole()
            && <OverlayButton history={ownProps.history} setDisplayOverlay={dispatchProps.setDisplayOverlay} />,
        },
        {
            id: 'contact-us',
            content: <Link key='contact' to='/contact'>{t('header.contact')}</Link>,
        },
        {
            id: 'config',
            content: !stateProps.isTenantLoading && hasAdminRole() && <Link key='configuration' to='/configuration' id='joy-ride__template-config-btn'>{t('header.configuration')}</Link>,
        },
    ];
    return (
        <>
            {!isFullscreen && (
                <>
                    <Header
                        appTitle={!stateProps.isCustomerDesignerMode && (
                            <Link className='header-title' to='/catalog'>Template Maker</Link>
                        )}
                        appLinks={SupportedLanguages.length > 1 && !(window.location.pathname.indexOf('/editor') >= 0) ? appLinks.concat(languageDropdown) : appLinks}
                        responsive={true}
                        profile={getProfile()}
                        notifications={<LoudspeakerIcon accessToken={auth.getAccessToken()} channelIds={['template-maker']} title='Announcements' />}
                        onLogInClicked={login}
                        onLogOutClicked={logout}
                        isLoggedIn={isLoggedIn}
                        accessToken={auth.getAccessToken()}
                        documentationUrl={URLs.TemplateMakerDocumentation}
                        mailToAddress={SupportGroupEmail}
                    />
                    <Loudspeaker
                        accessToken={auth.getAccessToken()}
                        channelIds={['template-maker']}
                        title='Announcements'
                    />
                </>
            )}
        </>
    );
}

const connectedHeader = connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)(AppHeader);

export default withRouter(connectedHeader);
