/* eslint-disable react/button-has-type */
import React from 'react';
import { Button, Tooltip } from '@cimpress/react-components';
import cx from 'classnames';

import IconRatingStarAlt from '@cimpress-technology/react-streamline-icons/lib/IconRatingStarAlt';
import './recentSkus.scss';
import { useTranslation } from 'react-i18next';

export interface RecentSku {
    sku: string;
    lastUsedOn: Date;
    isFavourite: boolean;
}

interface Props {
    skus: RecentSku[];
    onSkuSelect(sku: string): void;
    showFavouritesIcon?: boolean;
    onClickFavourites?(e: Event, recentSkuObj: RecentSku): void;
}

export default function RecentSkus({ onSkuSelect, skus, showFavouritesIcon, onClickFavourites }: Props) {
    const { t } = useTranslation();

    const getTooltipContent = (sku: RecentSku) => {
        if (Object.values(skus).filter((s) => s.isFavourite).length >= 3 && !sku.isFavourite) {
            return t('create.maxFavouriteSelected');
        }
        if (sku.isFavourite) {
            return t('create.removeFavourite');
        }
        return t('create.setAsFavourite');
    };

    return (
        <div className={cx('recent-sku-list')}>
            {skus.map((recentSku) => (
                <Button
                    key={recentSku.sku}
                    className={cx('recent-sku-list__sku')}
                    size='sm'
                    onClick={() => onSkuSelect(recentSku.sku)}
                >{recentSku.sku}
                    {showFavouritesIcon && (
                        <Tooltip direction='top' contents={getTooltipContent(recentSku)}>
                            <IconRatingStarAlt
                                weight={recentSku.isFavourite ? 'fill' : 'regular'}
                                onClick={(e: Event) => onClickFavourites && onClickFavourites(e, recentSku)}
                                size='lg'
                            />
                        </Tooltip>
                    )}
                </Button>
            ))}
        </div>
    );
}
