import { collectionTypes, collectionSelector } from 'store/collection';
import { uiStateSelector } from 'store/uiState';
import { RootState } from 'store/rootReducer';
import { templateActions, templateTypes, templateSelector } from 'store/template';

export interface StateProps {
    allTemplates: templateTypes.Template[];
    activeCollection: collectionTypes.Collection | undefined;
    areTemplatesLoading: boolean;
}

export type AllProps = StateProps;

export const getAllProps = (props: AllProps) => {
    const { allTemplates, activeCollection, areTemplatesLoading } = props;
    const stateProps: StateProps = { allTemplates, activeCollection, areTemplatesLoading };
    return { stateProps };
};

export const mapStateToProps = (state: RootState): StateProps => ({
    allTemplates: templateSelector.selectAllTemplates(state),
    areTemplatesLoading: uiStateSelector.selectIfLoading(state, templateActions.loadTemplates.toString()),
    activeCollection: collectionSelector.selectActiveCollection(state),
});
