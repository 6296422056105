import React, { ReactNode } from 'react';
import { Modal } from '@cimpress/react-components';

interface DialogConfig {
    isDialogOpen: boolean;
    children: {
        title: string;
        body: ReactNode;
        footer: ReactNode;
    };
}
const ConfirmDialog = ({ isDialogOpen, children }: DialogConfig) => {
    const { title, body, footer } = children;
    return (
        <Modal
            show={isDialogOpen}
            closeOnOutsideClick={false}
            title={title}
            footer={footer}
        >
            {body}
        </Modal>
    );
};

export default ConfirmDialog;
