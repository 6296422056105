import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { clipArtTypes } from './index';

const INITIAL_STATE: clipArtTypes.ClipArtState = {
    clipArts: [],
    clipArtCategories: [],
};

const slice = createSlice({
    name: 'clipart',
    initialState: INITIAL_STATE,
    reducers: {
        loadClipArts: (state, { payload }: PayloadAction<string>) => { },
        loadClipArtsSuccess: (state, { payload }: PayloadAction<clipArtTypes.ClipArt[]>) => { state.clipArts = payload; },
        loadClipArtsFailure: () => { },

        loadClipArtCategories: () => { },
        loadClipArtCategoriesSuccess: (state, { payload }: PayloadAction<clipArtTypes.ClipArtCategory[]>) => { state.clipArtCategories = payload; },
        loadClipArtCategoriesFailure: () => { },

    },
});

export default slice;
