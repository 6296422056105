import { all, takeEvery, put, call, select, StrictEffect } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import tenantSlice from 'store/tenant/slice.tenant';
import tenantSelector from 'store/tenant/selector.tenant';
import editorSlice from 'store/editor/slice.editor';
import templateSlice from 'store/template/slice.template';
import collectionSlice from 'store/collection/slice.collection';
import productSlice from 'store/product/slice.product';
import clipArtSlice from 'store/clipart/slice.clipart';
import { SNACKBAR_NOTIFICATION_TYPES } from 'utility/enums';
import { tenantTypes } from 'store/tenant';
import { uiStateTypes, formatNotification, uiStateSelector } from './index';
import slice, { hydrationStopActionTypes } from './slice.uiState';
import { loadUserPreference, setUserPreference, querySettings, validateApiKey, saveSettings } from './service.uiState';
import { StructuredTags, TenantSettingsValidationErrors } from './types.uiState';

const { actions: uiStateActions } = slice;

const blockingActionTypes = [
    tenantSlice.actions.fetchTenant,
    uiStateActions.setTenantSettings,
    templateSlice.actions.loadTemplates,
    uiStateActions.loadUserPreference,
];

const blockingStopActionTypes = [
    tenantSlice.actions.fetchTenantSuccess,
    tenantSlice.actions.fetchTenantFailure,
    uiStateActions.setTenantSettingsSuccess,
    uiStateActions.setTenantSettingsFailure,
    templateSlice.actions.loadTemplatesSuccess,
];

const nonBlockingActionTypes = [
    templateSlice.actions.publishTemplateVersion,
    templateSlice.actions.createPrintPdf,
    collectionSlice.actions.onCollectionLoad,
    collectionSlice.actions.onAddOrEditCollection,
    editorSlice.actions.saveDocument,
    clipArtSlice.actions.loadClipArts,
    templateSlice.actions.loadRecentTemplates,
    templateSlice.actions.loadSurfaces,
    templateSlice.actions.fetchTemplateVersions,
    templateSlice.actions.loadInFlightTemplate,
    productSlice.actions.loadCalculatedSurfaceSets,
];

const nonBlockingActionTypesWithMeta = [
    templateSlice.actions.onDeleteTemplate,
    templateSlice.actions.onRestoreTemplate,
    collectionSlice.actions.deleteCollection,
];

const nonBlockingStopActionTypesAll = [
    templateSlice.actions.publishTemplateVersionSuccess,
    templateSlice.actions.publishTemplateVersionFailure,
    templateSlice.actions.createPrintPdfSuccess,
    templateSlice.actions.createPrintPdfFailure,
    templateSlice.actions.onDeleteTemplateSuccess,
    templateSlice.actions.onDeleteTemplateFailure,
    templateSlice.actions.onRestoreTemplateSuccess,
    templateSlice.actions.onRestoreTemplateFailure,
    templateSlice.actions.loadInFlightTemplateSuccess,
    templateSlice.actions.loadInFlightTemplateFailure,
    productSlice.actions.loadCalculatedSurfaceSetsSuccess,
    productSlice.actions.loadCalculatedSurfaceSetsFailure,
    collectionSlice.actions.onCollectionLoadSuccess,
    collectionSlice.actions.onCollectionLoadFailure,
    collectionSlice.actions.onAddOrEditCollectionSuccess,
    collectionSlice.actions.onAddOrEditCollectionFailure,
    collectionSlice.actions.deleteCollectionSuccess,
    collectionSlice.actions.deleteCollectionFailure,
    editorSlice.actions.saveDocumentSuccess,
    editorSlice.actions.saveDocumentFailure,
    clipArtSlice.actions.loadClipArtsSuccess,
    clipArtSlice.actions.loadClipArtsFailure,
    templateSlice.actions.loadRecentTemplatesSuccess,
    templateSlice.actions.loadRecentTemplatesFailure,
    uiStateActions.loadUserPreferenceSuccess,
    uiStateActions.loadUserPreferenceFailure,
    templateSlice.actions.loadSurfacesSuccess,
    templateSlice.actions.loadSurfacesFailure,
    templateSlice.actions.fetchTemplateVersionsSuccess,
    templateSlice.actions.fetchTemplateVersionsFailure,
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* onHydrationActionStop(action: PayloadAction<any>) {
    const isHydrated: boolean = yield select(uiStateSelector.selectIsHydrated);
    if (!isHydrated) {
        const hydratorActionType = action.type.replace('Success', '').replace('Failure', '');
        yield put(uiStateActions.addHydrator(hydratorActionType));
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* onAsyncBlockingActionStart(action: PayloadAction<any>) {
    yield put(uiStateActions.addLoader([{
        id: action.type,
        isBlocking: true,
    }]));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* onAsyncBlockingActionStop(action: PayloadAction<any>) {
    const loaderId = action.type.replace('Success', '').replace('Failure', '');
    yield put(uiStateActions.removeLoader({ id: loaderId }));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* onAsyncNonBlockingActionStart(action: PayloadAction<any>) {
    const actionTypes = nonBlockingActionTypesWithMeta.map((i) => i.toString());
    yield put(uiStateActions.addLoader([{
        id: actionTypes.includes(action.type) && typeof action.payload === 'string' ? `${action.type}_${action.payload}` : action.type,
        isBlocking: false,
    }]));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* onAsyncNonBlockingActionStop(action: PayloadAction<any>) {
    const actionTypes = nonBlockingActionTypesWithMeta.map((i) => i.toString());
    const loaderId = action.type.replace('Success', '').replace('Failure', '');
    yield put(uiStateActions.removeLoader({ id: actionTypes.includes(loaderId) && typeof action.payload === 'string' ? `${loaderId}_${action.payload}` : loaderId }));
}

export function* onLoadUserPreference() {
    try {
        const preference: uiStateTypes.UserPreference = yield call(loadUserPreference);
        yield put(uiStateActions.loadUserPreferenceSuccess(preference));
    } catch (error) {
        yield put(uiStateActions.loadUserPreferenceFailure());
    }
}

export function* onSetUserPreference(action: PayloadAction<uiStateTypes.UserPreference>) {
    try {
        const userPreference: uiStateTypes.UserPreference = yield select(uiStateSelector.selectUserPreference);
        const { payload } = action;
        const preferenceObj = { ...userPreference, ...payload };
        const preference: uiStateTypes.UserPreference = yield call(setUserPreference, preferenceObj);
        yield put(uiStateActions.loadUserPreferenceSuccess(preference));
    } catch (error) {
        yield put(uiStateActions.loadUserPreferenceFailure());
    }
}

export function* onLoadTenantSettings() {
    try {
        const currentTenant: tenantTypes.Tenant | undefined = yield select(tenantSelector.selectCurrentTenant);
        if (currentTenant === undefined) {
            yield put(uiStateActions.loadTenantSettingsFailure());
            return;
        }

        const settings: uiStateTypes.DesignerApiSettings | undefined = yield call(querySettings, currentTenant.tenantId, currentTenant.tenantType);
        yield put(uiStateActions.loadTenantSettingsSuccess(settings));
    } catch (error) {
        yield put(uiStateActions.loadTenantSettingsFailure());
    }
}

function validateStructuredTags(structuredTags: StructuredTags[]): string {
    if (structuredTags.length === 0) {
        return '';
    }

    if (structuredTags.some((tag) => tag.category === undefined || tag.category === '' || tag.allowedValues === undefined || tag.allowedValues.length === 0)) {
        return 'Please enter valid value for structured tag(s).';
    }

    return '';
}

export function validateSettings(apiKeyStatus: number | '', uploadTenant: string, designerVersion: string, structuredTags: StructuredTags[]): TenantSettingsValidationErrors {
    let apiKeyValidationError = '';
    if (apiKeyStatus === 403) {
        apiKeyValidationError = 'Please add domain to whitelist against this api key, refer to documentation';
    } else if (apiKeyStatus !== 200) {
        apiKeyValidationError = 'Please enter a valid api key';
    }

    const uploadTenantValidationError = uploadTenant.toLowerCase() === 'default'
        ? 'Using default upload tenant is prohibited.'
        : '';

    const cimpressDesignerVersionValidationError = designerVersion.toLowerCase() === 'latest'
        ? 'Using latest cimpress designer version is prohibited.'
        : '';

    const structuredTagsValidationError = validateStructuredTags(structuredTags);

    return {
        apiKey: apiKeyValidationError,
        uploadTenant: uploadTenantValidationError,
        designerVersion: cimpressDesignerVersionValidationError,
        structuredTags: structuredTagsValidationError,
    };
}

export function* onSetTenantSettings(action: PayloadAction<uiStateTypes.DesignerApiSettings>) {
    try {
        const newSettings = action.payload;
        const validateApiKeyStatus = newSettings.apiKey && (yield call(validateApiKey, newSettings.apiKey)) as number;

        const validationResult: TenantSettingsValidationErrors = yield call(validateSettings, validateApiKeyStatus, newSettings.uploadTenant, newSettings.designerVersion, newSettings.structuredTags);
        if (validationResult.apiKey || validationResult.uploadTenant || validationResult.designerVersion || validationResult.structuredTags) {
            yield put(uiStateActions.setTenantSettingsFailure(validationResult));
            return;
        }

        const currentTenant: tenantTypes.Tenant | undefined = yield select(tenantSelector.selectCurrentTenant);
        if (currentTenant === undefined) {
            yield put(uiStateActions.setTenantSettingsFailure({}));
            yield put(uiStateActions.addNotification([formatNotification(SNACKBAR_NOTIFICATION_TYPES.Danger, { key: 'notifications.danger.saveSetting' }, 0)]));
            return;
        }

        yield call(saveSettings, newSettings, currentTenant.tenantId, currentTenant.tenantType);
        yield put(uiStateActions.setTenantSettingsSuccess(newSettings));
    } catch (error) {
        yield put(uiStateActions.setTenantSettingsFailure({}));
        yield put(uiStateActions.addNotification([formatNotification(SNACKBAR_NOTIFICATION_TYPES.Danger, { key: 'notifications.danger.saveSetting' }, 0)]));
    }
}

export default function* uiStateSaga(): Generator<StrictEffect, StrictEffect, never> {
    return yield all([
        takeEvery(blockingActionTypes, onAsyncBlockingActionStart),
        takeEvery(blockingStopActionTypes, onAsyncBlockingActionStop),
        takeEvery([...nonBlockingActionTypes, ...nonBlockingActionTypesWithMeta], onAsyncNonBlockingActionStart),
        takeEvery(nonBlockingStopActionTypesAll, onAsyncNonBlockingActionStop),
        takeEvery(hydrationStopActionTypes, onHydrationActionStop),
        takeEvery(uiStateActions.loadUserPreference, onLoadUserPreference),
        takeEvery(uiStateActions.setUserPreference, onSetUserPreference),
        takeEvery(uiStateActions.loadTenantSettings, onLoadTenantSettings),
        takeEvery(uiStateActions.setTenantSettings, onSetTenantSettings),
    ]);
}
