import React from 'react';
import { connect } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { RootState } from 'store/rootReducer';
import { getAllProps, AllProps, mapStateToProps, StateProps } from './props.emptyCatalogMessage';

import './emptyCatalogMessage.scss';

function EmptyCatalogMessage(allProps: AllProps) {
    const { t } = useTranslation();
    const { stateProps } = getAllProps(allProps);

    const getMessage = (): JSX.Element => {
        let message = (
            <Trans i18nKey='catalog.emptyCatalogMessage'>
                No templates to show, please <Link to='/create'>click here</Link> to create one.
            </Trans>
        );
        if (stateProps.activeCollection) {
            message = stateProps.activeCollection.hasChildren
                ? t('catalog.emptyCollectionMessageWithChildren')
                : t('catalog.emptyCollectionMessage');
        }
        return message;
    };
    return (
        <>
            {!stateProps.areTemplatesLoading && !stateProps.allTemplates.length
            && (
                <div className='empty-container'>
                    { getMessage() }
                </div>
            )}
        </>
    );
}

export default connect<StateProps, {}, {}, RootState>(mapStateToProps)(EmptyCatalogMessage);
