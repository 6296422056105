/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Label, Checkbox, LabelProps, Tooltip, colors } from '@cimpress/react-components';
import { formatDistance } from 'date-fns';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import IconNavigationMenuVertical from '@cimpress-technology/react-streamline-icons/lib/IconNavigationMenuVertical';

import PreviewThumbnail from 'components/preview/Thumbnail';
import { CollectionLabel } from 'store/template/types.template';
import { templateTypes } from 'store/template';
import { tenantTypes } from 'store/tenant';
import { MinLabelsToShowTableView } from 'utility/constants';
import { TEMPLATE_ACTIONS } from 'utility/enums';
import { useResponsiveDesign } from 'providers/ResponsiveDesign';
import ActionsDropdown from '../actionDropdown/ActionsDropdown';

import './templateTableRow.scss';

const { ocean } = colors;

const getCollectionLabels = (collectionLabels: CollectionLabel[]) => ({
    tooltipContent: collectionLabels.slice(MinLabelsToShowTableView).map((c) => <div className='tooltip-label'>{`${c.label}`}</div>),
    label: collectionLabels.length > MinLabelsToShowTableView ? collectionLabels.slice(0, MinLabelsToShowTableView).map((c) => c.label).join(', ') : collectionLabels.map((c) => c.label).join(', '),
});

interface Props {
    canEdit: boolean;
    template: templateTypes.Template;
    tenant?: tenantTypes.Tenant;
    isSelected: boolean;
    currentFolderId?: string;
    isUpdating: boolean;
    enableEditingAceTemplates: boolean;
    uploadsTenant: string | undefined;
    onCopyUrl(): void;
    onSelectionChanged(payload: templateTypes.Template): void;
    onRemoveTemplateFromCollection(payload: templateTypes.Template[]): void;
    setCurrentTemplate(payload: templateTypes.Template): void;
    setOpenTemplateInfoDrawer(payload: boolean): void;
    setOpenTemplatePreviewModal(payload: boolean): void;
    onDeleteTemplate?(payload: string): void;
    onRestoreTemplate?(payload: string): void;
}

export default function TemplateTableRow({
    canEdit,
    template,
    tenant,
    isSelected,
    currentFolderId,
    isUpdating,
    enableEditingAceTemplates,
    uploadsTenant,
    onCopyUrl,
    onSelectionChanged,
    onRemoveTemplateFromCollection,
    setCurrentTemplate,
    setOpenTemplateInfoDrawer,
    setOpenTemplatePreviewModal,
    onDeleteTemplate,
    onRestoreTemplate,
}: Props) {
    const { isTabletOrMobile, isTablet, isDesktopOrLaptop } = useResponsiveDesign();
    const { t } = useTranslation();
    const collectionLabels = template.collectionLabels || [];
    const { label, tooltipContent } = getCollectionLabels(collectionLabels);

    const handleSelectionChanged = () => {
        onSelectionChanged(template);
    };

    const isDraft = !!template.isDraft;

    const getStatusLabel = (): LabelProps => {
        let resultLabel: LabelProps = {
            text: t('catalog.templateStatus.published'),
            type: 'success',
        };
        if (isUpdating) {
            resultLabel = {
                text: t('catalog.templateStatus.updating'),
                type: 'warning',
            };
        } else if (template.deleted) {
            resultLabel = {
                text: t('catalog.templateStatus.deleted'),
                type: 'default',
            };
        } else if (isDraft) {
            resultLabel = {
                text: t('catalog.templateStatus.draft'),
                type: 'warning',
            };
        }
        return resultLabel;
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const detachEvent = (event: any) => event.stopPropagation();
    const onClickRow = () => {
        setCurrentTemplate(template);
        setOpenTemplateInfoDrawer(true);
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onClickPreviewThumbnail = (event: any) => {
        setCurrentTemplate(template);
        setOpenTemplatePreviewModal(true);
        setOpenTemplateInfoDrawer(false);
        detachEvent(event);
    };

    return (
        <tr onClick={onClickRow} className={cx('template-table-row')}>
            <td onClick={detachEvent} className={cx('select-template-checkbox')}>
                <Checkbox className='checkbox' disabled={isDraft} checked={isSelected} onChange={handleSelectionChanged} />
            </td>
            <td onClick={onClickPreviewThumbnail}>
                <PreviewThumbnail src={template.thumbnail} setOpen={setOpenTemplateInfoDrawer} />
            </td>
            <td className={cx('select-template-checkbox-container')}>
                <Tooltip direction='top' contents={template.name}>
                    <span className={cx('table-row-template-name')}>{template.name}</span>
                </Tooltip>
                <div className='template-collection-names'>
                    {collectionLabels && collectionLabels.length > 0 && (
                        <>
                            <span className='collection-text'>{t('catalog.lblCollections')}:</span>
                            <span className='collection-name'>{label}</span>
                            {collectionLabels.length > MinLabelsToShowTableView && (
                                <span className='extended-collection-names'>
                                    <Tooltip direction='right' contents={tooltipContent}>
                                        {`...+${collectionLabels.length - MinLabelsToShowTableView} ${t('catalog.lblMore')}`}
                                    </Tooltip>
                                </span>
                            )}
                        </>
                    )}
                </div>
            </td>
            {isDesktopOrLaptop && (
                <td className='template-info-row'>
                    <span
                        style={{ wordBreak: 'break-word' }}
                        className={cx({ 'row-length-exceeded': template.description.length > 30 })}
                        data-text={template.description}
                    >
                        {template.description}
                    </span>
                </td>
            )}
            {isDesktopOrLaptop && (
                <td className='template-info-row'>
                    {formatDistance(new Date(template.lastModified), new Date())} ago
                </td>
            )}
            {(isTablet || isDesktopOrLaptop) && (
                <td>
                    <Tooltip direction='top' contents={template.owner}>
                        <span className={cx('table-row-author')}>{template.owner}</span>
                    </Tooltip>
                </td>

            )}
            {(isTablet || isDesktopOrLaptop) && (
                <td>
                    <Label text={getStatusLabel().text} type={getStatusLabel().type} />
                </td>
            )}
            <td
                onClick={detachEvent}
                className={cx({ 'template-info-row__mobile-tablet': isTabletOrMobile, 'template-info-row__actions': (isDesktopOrLaptop) })}
            >
                {!isDraft && (
                    <ActionsDropdown
                        templateDetail={template}
                        tenant={tenant}
                        title={isTabletOrMobile ? <IconNavigationMenuVertical size='lg' color={ocean.base} weight='fill' /> : t('catalog.actionsDropdown.title')}
                        currentFolderId={currentFolderId}
                        canEdit={canEdit}
                        onCopyUrl={onCopyUrl}
                        onRemoveTemplateFromCollection={onRemoveTemplateFromCollection}
                        onRestoreTemplate={onRestoreTemplate}
                        onDeleteTemplate={onDeleteTemplate}
                        disabledActions={[TEMPLATE_ACTIONS.Create]}
                        enableEditingAceTemplates={enableEditingAceTemplates}
                        uploadsTenant={uploadsTenant}
                    />
                )}
            </td>
        </tr>
    );
}
