import { AxiosError } from 'axios';

import { URLs, AssetTypeTemplate } from 'utility/constants';
import { axiosWithAuth } from 'interceptors/auth.interceptor';
import { logError } from 'loggingManager';
import { productTypes } from 'store/product';
import { mapAsset, mapAssets, mapVersions, mapCimDoc } from './responseMapper.template';
import { AssetToBeAdded, AssetQueryParams, AssetToBeEdited, Template, TemplateVersion, VersionMetaData, QueryAssetsResponse } from './types.template';
import { templateTypes } from '.';

export async function addAsset(asset: AssetToBeAdded): Promise<Template> {
    return axiosWithAuth.post(URLs.AssetBase, { ...asset, assetType: AssetTypeTemplate })
        .then(mapAsset)
        .catch((error: AxiosError) => {
            logError(error.message || 'Could not save asset to API');
            throw error;
        });
}

export async function queryAssets(queryParams: AssetQueryParams): Promise<QueryAssetsResponse> {
    return axiosWithAuth.get(`${URLs.AssetBase}:query`, {
        params: { ...queryParams, assetType: AssetTypeTemplate, searchEngine: 'DynamoDb' },
    })
        .then(mapAssets)
        .catch((error) => {
            logError(`Could not fetch templates from the API ${error}`);
            throw error;
        });
}

export async function deleteAsset(assetId: string) {
    return axiosWithAuth.delete(`${URLs.AssetBase}/${assetId}`, {
        headers: { 'If-Match': '*' },
    })
        .catch((error: AxiosError) => {
            logError(error.message || 'Could not delete asset');
            throw error;
        });
}

export async function restoreAsset(assetId: string) {
    return axiosWithAuth.put(`${URLs.AssetBase}/${assetId}`, { deleted: false }, {
        headers: { 'If-Match': '*' },
    })
        .catch((error: AxiosError) => {
            logError(error.message || 'Could not restore asset');
            throw error;
        });
}

export async function addRevision(assetId: string, docRefUrl: string, surfaceSpecifications?: productTypes.SurfaceSpecification) {
    return axiosWithAuth.post(`${URLs.AssetBase}/${assetId}/revisions`,
        { uri: docRefUrl, ...surfaceSpecifications && { metadata: surfaceSpecifications } })
        .then(({ data }) => data)
        .catch((error: AxiosError) => {
            logError(error.message || 'Could not save a new revision to API');
            throw error;
        });
}

export async function editAsset(assetId: string, asset: AssetToBeEdited): Promise<templateTypes.Template> {
    try {
        const response = await axiosWithAuth.put<templateTypes.Template>(`${URLs.AssetBase}/${assetId}`, asset, {
            headers: { 'If-Match': '*' },
        });
        return response.data;
    } catch (error) {
        logError(error.message || 'Could not edit template');
        throw error;
    }
}

export async function publishRevision(assetId: string, revisionId: string) {
    return axiosWithAuth.put(`${URLs.AssetBase}/${assetId}`, { publishedRevisionId: revisionId }, {
        headers: { 'If-Match': '*' },
    })
        .catch((error: AxiosError) => {
            logError(error.message || 'Could not publish a new revision to API');
            throw error;
        });
}

export async function queryAsset(assetId: string): Promise<Template> {
    const url = `${URLs.AssetBase}/${assetId}`;
    return axiosWithAuth.get(url)
        .then(mapAsset)
        .catch((error) => {
            logError(`Could not fetch asset from the API ${error}`);
            throw error;
        });
}

export async function getRevision(assetId: string, revisionId: string): Promise<VersionMetaData> {
    return axiosWithAuth.get(`${URLs.AssetBase}/${assetId}/revisions/${revisionId}`)
        .then((response) => response.data)
        .catch((error: AxiosError) => {
            logError(error.message || `Could not fetch revisions metadata for assetId = ${assetId}`);
            throw error;
        });
}

export async function getRevisionsByAssetId(assetId: string): Promise<TemplateVersion[]> {
    return axiosWithAuth.get(`${URLs.AssetBase}/${assetId}/revisions`)
        .then(mapVersions)
        .catch((error: AxiosError) => {
            logError(error.message || `Could not fetch revisions for assetId = ${assetId}`);
            throw error;
        });
}

export async function fetchAllTemplates(queryParams: AssetQueryParams): Promise<templateTypes.Template[]> {
    return queryAssets(queryParams).then(async (data) => {
        const allTemplates = data.templates;
        if (data.offset) {
            const newQueryParams = { ...queryParams, offset: data.offset, pageSize: 100 };
            return [...allTemplates, ...(await fetchAllTemplates(newQueryParams))];
        }
        return allTemplates;
    });
}

export async function fetchCimDoc(url: string) {
    return axiosWithAuth.get(url)
        .then(mapCimDoc)
        .catch((error) => {
            logError(`Could not fetch cimDoc ${error}`);
            throw error;
        });
}

export async function fetchSku(url: string) {
    return axiosWithAuth.get(url)
        .then((response) => response.data.sku)
        .catch((error) => {
            logError(`Could not fetch sku ${error}`);
            throw error;
        });
}

async function getCimdoc(cimDocUrl: string) {
    return axiosWithAuth.get(cimDocUrl)
        .then((response) => response.data)
        .catch((error) => {
            logError(`Could not fetch cimDoc ${error}`);
            throw error;
        });
}

async function getDocref(contentUrl: string) {
    return axiosWithAuth.get(contentUrl)
        .then((response) => response.data)
        .catch((error) => {
            logError(`Could not fetch docRef ${error}`);
            throw error;
        });
}

export async function getCimDocData(contentUrl: string) {
    return getDocref(contentUrl)
        .then((response) => getCimdoc(response.cimDocUrl))
        .then((response) => response)
        .catch((error) => {
            throw error;
        });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function saveCimDocToUds(cimDoc: any) {
    return axiosWithAuth.post(URLs.UdsBase, cimDoc)
        .then((response) => ({ documentReferenceUrl: response.data._links.documentReference.href }))
        .catch((error) => {
            logError(`Could not save cimDoc ${error}`);
            throw error;
        });
}
