import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useLocation, useParams } from 'react-router-dom';
import { Location } from 'history';
import _omit from 'lodash.omit';
import { v4 as uuidv4 } from 'uuid';
import Toolbar from 'components/toolbar/Toolbar';
import Tabs from 'connectedComponents/tabs/Tabs';
import Canvases from 'components/canvases/Canvases';
import Footer from 'connectedComponents/footer/Footer';
import Switcher from 'connectedComponents/switcher/Switcher';
import { getQueryVariable, generateDocRefUrl } from 'utility/url.utility';
import { useTranslation } from 'react-i18next';
import { EDITOR_MODE, DESIGNER_MODE, SNACKBAR_NOTIFICATION_TYPES, BS_STYLE } from 'utility/enums';
import HeadTags from 'components/HeadTags';
import { shapes, Snackbar } from '@cimpress/react-components';
import { RootState } from 'store/rootReducer';
import { connect } from 'react-redux';
import { formatNotification } from 'store/uiState';
import { logError } from 'loggingManager';
import { getItemFromLocalStorage, getItemFromSessionStorage, saveItemInLocalStorage } from 'utility/storage.utility';
import { DefaultUploadTenant, SessionStorage, LocalStorage, URLs } from 'utility/constants';
import { resolveDesignerVersionToLoad } from 'utility/designer';
import { getCimDocData } from 'store/template/service.template';
import { tenantTypes } from 'store/tenant';
import { getDesignerConfiguration } from './designer.config';
import { triggerResize } from './designerApi';
import { getAllProps, AllProps, StateProps, DispatchProps, OwnProps, mapStateToProps, mapDispatchToProps } from './props.editor';
import './editor.scss';

const { Spinner } = shapes;
interface TemplateDesigner {
    id: string;
    designer: Designer;
}
const hiddenProps = {
    tabsHidden: false,
    switcherHidden: false,
};
function removeFromDom(selector: string) {
    const elem = document.querySelector(selector);
    elem && elem.parentNode && elem.parentNode.removeChild(elem);
}
const loadDesigner = (designerVersion: string, currentTenant?: tenantTypes.Tenant): Promise<Designer> => new Promise((resolve, reject) => {
    const script = document.createElement('script');

    if (currentTenant?.tenantId === 'bas-boxup') {
        // See https://cimpress.slack.com/archives/CH9RKD6JE/p1641473633005700
        // Pass empty parametersUrl to prepress when generating PDF
        script.src = 'https://templatemaker.contentauthoring.cimpress.io/custom-scripts/designer.4.4.2.pdf-prepress-boxup-fix.min.js';
    } else {
        script.src = `${URLs.DesignerBase}/${resolveDesignerVersionToLoad(designerVersion)}/designer.min.js`;
    }

    document.head.append(script);
    script.onload = () => {
        window.designer ? resolve(window.designer) : reject(new Error('designer could not load'));
    };
    script.onerror = (error) => {
        reject(error);
    };
});
/**
 * The editor screen. Will initialize and pass Designer object.
 */
function Editor(allProps: AllProps) {
    const { ownProps, stateProps, dispatchProps } = getAllProps(allProps);
    const { t } = useTranslation();
    const [templateDesigner, setTemplateDesigner] = useState<TemplateDesigner>();
    const [isDesignerLoaded, setDesignerLoaded] = useState(false);
    const [isFailedToLoad, setIsFailedToLoad] = useState(false);
    const [isLoadingStarted, setIsLoadingStarted] = useState(false);
    const [isAceTemplate, setIsAceTemplate] = useState(false);
    const [skuFromDocument, setSkuFromDocument] = useState<string>();
    const [isLoadingSkuFromDocument, setIsLoadingSkuFromDocument] = useState(false);
    const { designerMode, id, revisionId }: { designerMode: string; id: string; revisionId: string; location: string } = useParams();
    const location: Location = useLocation();
    const omissions = ['id', 'locked', 'placeholderUrl', 'placeholderReplaced', 'autoPlaced'];
    const onHideSnackbar = () => setIsFailedToLoad(false);
    const productUuid = getQueryVariable('productUuid', location.search);
    const product = productUuid && getItemFromSessionStorage(productUuid);
    const surfaceSpecificationsUuid = getQueryVariable('surfaceSpecificationsUuid', location.search);
    const surfaceSpecifications = surfaceSpecificationsUuid && getItemFromSessionStorage(surfaceSpecificationsUuid);
    const docRefQueryString = getQueryVariable('docRefUrl', location.search);
    const duplicateCollections = getQueryVariable('duplicateCollection', location.search) === 'true';
    const showNotification = (message: string, uploadTenant?: string | null) => {
        if (uploadTenant === DefaultUploadTenant) {
            dispatchProps.addNotification([formatNotification(SNACKBAR_NOTIFICATION_TYPES.Warning, { key: message }, 0)]);
        }
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onAddImage = (eventData: any) => {
        const imageUploadURL = eventData.image.url.split('?').pop();
        const imageUploadTenant = getQueryVariable('tenant', imageUploadURL);
        if (stateProps.settings?.uploadTenant !== DefaultUploadTenant) {
            showNotification('notifications.warning.imageWithDefaultUploadTenant', imageUploadTenant);
        } else {
            showNotification('notifications.warning.defaultUploadtenant', imageUploadTenant);
        }
    };
    const onUploadImage = () => {
        showNotification('notifications.warning.defaultUploadtenant', stateProps.settings?.uploadTenant);
    };
    const onCopyItems = () => {
        const selectedElementsOnCanvas = window.designer && window.designer.selectionManager.toArray();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const itemsToCopyOnCanvas = selectedElementsOnCanvas.map((element: any) => element.model.attributes);
        const itemsToCopyInLocalStorage = {
            designerId: templateDesigner?.id,
            items: itemsToCopyOnCanvas,
        };
        saveItemInLocalStorage(LocalStorage.SelectedItemsToCopy, itemsToCopyInLocalStorage);
    };
    const onPasteItems = () => {
        const { designerId, items } = getItemFromLocalStorage(LocalStorage.SelectedItemsToCopy);
        const sourceDesignerId = designerId;
        const targetDesignerId = templateDesigner?.id;
        if (sourceDesignerId !== targetDesignerId) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const modifiedItemsToCopy = items.map((item: any) => {
                if (item.module !== 'TextField' && !(item.placeholder && !item.placeholderReplaced)) {
                    omissions.push('placeholder');
                }
                return _omit(item, omissions);
            });
            const activeCanvas = templateDesigner?.designer.documentRepository.getActiveCanvas();
            if (modifiedItemsToCopy.length !== 0) {
                const options = {
                    model: activeCanvas,
                    items: modifiedItemsToCopy,
                    selectNewModels: true,
                };
                // eslint-disable-next-line no-unused-expressions
                templateDesigner?.designer.commandDispatcher.add(options);
            }
        }
    };
    useEffect(() => () => {
        dispatchProps.resetSurfaceSpecifications();
        dispatchProps.clearProduct();
        dispatchProps.resetCalculatedSurfaceSets();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => () => {
        removeFromDom('#dcl-upload-form');
        removeFromDom('.dcl-popper-overlay');
        removeFromDom('.dcl-message-overlay');
        removeFromDom('.dcl-contextual-toolbar');
    }, []);
    useEffect(() => {
        dispatchProps.setManualLoader();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatchProps.setManualLoader]);
    useEffect(() => {
        if (stateProps.isExistingTemplateLoading) {
            setIsLoadingStarted(true);
        }
    }, [stateProps.isExistingTemplateLoading]);

    useEffect(() => {
        const setData = async () => {
            const assetId = ownProps.match.params.id;
            dispatchProps.setInFlightTemplateId(assetId);
            dispatchProps.setEditorMode(((getQueryVariable('mode', ownProps.location.search) === 'duplicate') || !!docRefQueryString) ? EDITOR_MODE.SaveAsNew : EDITOR_MODE.AddRevision);
            if (assetId) {
                dispatchProps.loadTemplateInEditor({ duplicateCollections, revisionId });
            } else if (docRefQueryString || product) {
                if (!product.sku && docRefQueryString && !skuFromDocument && !isLoadingSkuFromDocument) {
                    setIsLoadingSkuFromDocument(true);
                    const documentResponse = (await getCimDocData(docRefQueryString)) as { 'sku': string };
                    setSkuFromDocument(documentResponse.sku);

                    dispatchProps.loadProduct(documentResponse.sku);
                    dispatchProps.setInFlightTemplateSku(documentResponse.sku);
                } else if (product.sku) {
                    dispatchProps.loadProduct(product.sku);
                    dispatchProps.setInFlightTemplateSku(product.sku);
                }
            } else if (surfaceSpecifications) {
                dispatchProps.loadCalculatedSurfaceSets(surfaceSpecifications);
            }
        };

        setData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        dispatchProps.loadTemplateInEditor,
        ownProps.match.params.id,
        dispatchProps.setEditorMode,
        dispatchProps.setInFlightTemplateId,
        ownProps.location.search,
        docRefQueryString,
        product,
        dispatchProps.setInFlightTemplateSku,
        skuFromDocument,
        isLoadingSkuFromDocument]);
    useEffect(() => {
        if ((product || (isLoadingStarted && !stateProps.isExistingTemplateLoading))
            && stateProps.hasMandatoryDesignerSettings
            && stateProps.settings) {
            const { settings, currentLocale } = stateProps;
            loadDesigner(stateProps.settings.designerVersion, stateProps.currentTenant).then((windowDesigner: Designer) => {
                let cimDoc;
                const documentReferenceUrl = generateDocRefUrl(id, revisionId, ownProps.location.search);
                if (designerMode === DESIGNER_MODE.CustomerDesigner) {
                    cimDoc = getItemFromSessionStorage(SessionStorage.CimDocKey);
                }
                windowDesigner.start(getDesignerConfiguration(settings,
                    product,
                    documentReferenceUrl,
                    cimDoc,
                    stateProps.calculatedSurfaceSet,
                    surfaceSpecifications,
                    designerMode,
                    hiddenProps.tabsHidden,
                    hiddenProps.switcherHidden,
                    currentLocale))
                    .then(() => {
                        setDesignerLoaded(true);
                        setTemplateDesigner({
                            id: uuidv4(),
                            designer: windowDesigner,
                        });
                        dispatchProps.resetManualLoader();
                    })
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    .catch((e: any) => {
                        setIsFailedToLoad(true);
                        dispatchProps.resetManualLoader();
                        logError(`Designer failed to start. ${JSON.stringify(e)} ${e}`);
                    });
            });
        }
        return () => {
            delete window.designer;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateProps.hasMandatoryDesignerSettings, stateProps.isExistingTemplateLoading]);
    useEffect(() => {
        if (product) {
            dispatchProps.loadProduct(product.sku);
            dispatchProps.setInFlightTemplateSku(product.sku);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    // This next effect is a little hacky, but it allows us to pass an object reference to designer in which we update the values here.
    useEffect(() => {
        hiddenProps.tabsHidden = stateProps.tabsHidden;
        hiddenProps.switcherHidden = stateProps.switcherHidden;
        triggerResize(templateDesigner?.designer);
    }, [stateProps.tabsHidden, stateProps.switcherHidden, templateDesigner]);
    useEffect(() => {
        const templateDesiner = templateDesigner?.designer;
        if (templateDesiner) {
            templateDesiner.eventBus.on(templateDesiner.eventBus.events.addImage, onAddImage);
            templateDesiner.eventBus.on(templateDesiner.eventBus.events.uploadComplete, onUploadImage);
            templateDesiner.eventBus.on(templateDesiner.eventBus.events.copyItems, onCopyItems);
            templateDesiner.eventBus.on(templateDesiner.eventBus.events.pasteItems, onPasteItems);
        }
        return () => {
            if (templateDesiner) {
                templateDesiner.eventBus.off(templateDesiner.eventBus.events.addImage, onAddImage);
                templateDesiner.eventBus.off(templateDesiner.eventBus.events.uploadComplete, onUploadImage);
                templateDesiner.eventBus.off(templateDesiner.eventBus.events.copyItems, onCopyItems);
                templateDesiner.eventBus.off(templateDesiner.eventBus.events.pasteItems, onPasteItems);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateDesigner?.designer]);
    useEffect(() => {
        if (stateProps.inFlightTemplate?.attributes?.camDesignId) {
            setIsAceTemplate(true);
        }
    }, [stateProps.inFlightTemplate]);
    const onClickClose = () => setDesignerLoaded(false);
    return (
        <>
            {ownProps.saving && <div className='modal-backdrop in'><Spinner className='catalog-spinner' /></div>}
            <Snackbar show={isFailedToLoad} bsStyle={BS_STYLE.danger} onHideSnackbar={onHideSnackbar}>
                {t('notifications.danger.generic')}
            </Snackbar>
            <Snackbar show={isDesignerLoaded} bsStyle={BS_STYLE.success} delay={3000} onHideSnackbar={onClickClose}>
                {t('notifications.success.templateLoadedMsg')}
            </Snackbar>
            <Snackbar show={isAceTemplate} bsStyle={BS_STYLE.warning} onHideSnackbar={onHideSnackbar}>
                {t('notifications.warning.editingAceTemplate')}
            </Snackbar>
            {stateProps.settings && stateProps.settings.designerVersion && <HeadTags designerVersion={stateProps.settings.designerVersion} />}
            <div className={cx('designer')}>
                <Toolbar />
                <Tabs designer={templateDesigner?.designer} designerMode={designerMode} />
                <Canvases />
                <div className='dcl-widget-canvas-actions' />
                <Switcher designer={templateDesigner?.designer} />
            </div>
            <Footer
                designer={templateDesigner?.designer}
                designerMode={designerMode}
                surfaceSpecifications={surfaceSpecifications || stateProps.templateSurfaceSpecifications}
            />
        </>
    );
}
export default connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)(Editor);
