import React, { ChangeEvent, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { TextField, Card } from '@cimpress/react-components';
import { useTranslation } from 'react-i18next';
import { fetchCimDoc, fetchSku } from 'store/template/service.template';
import { useDebounce } from 'customHooks/useDebounce';
import { BS_STYLE, DESIGNER_MODE } from 'utility/enums';
import { saveItemInSessionStorage } from 'utility/storage.utility';
import { getProduct } from 'store/product/service.product';

import './import.scss';
import { productTypes } from 'store/product';

export default function Import() {
    const { t } = useTranslation();
    const [docRefUrl, setDocRefUrl] = useState('');
    const [productUuid, setProductUuid] = useState('');
    const [enableConfig, setEnableConfig] = useState({ isCorrectUrl: true, shouldEnable: false });
    const onChangeUrl = (e: ChangeEvent<HTMLInputElement>) => setDocRefUrl(e.currentTarget.value);
    const buttonClass = enableConfig.shouldEnable ? '' : 'disabled';
    const debouncedDocRefUrl = useDebounce(docRefUrl, 500);

    useEffect(() => {
        if (debouncedDocRefUrl) {
            fetchCimDoc(debouncedDocRefUrl)
                .then((data) => {
                    const properties = ['cimDocUrl', 'prepressInstructionsUrl', 'renderingInstructionsUrl'];
                    if (properties.every((item) => item in data)) {
                        fetchSku(data.cimDocUrl).then((mcpSku) => {
                            getProduct(mcpSku).then((product: productTypes.Product) => {
                                const productUniqueId = uuidv4();
                                setProductUuid(productUniqueId);
                                saveItemInSessionStorage(productUniqueId, product);
                                setEnableConfig({ isCorrectUrl: true, shouldEnable: true });
                            });
                        });
                    } else {
                        setEnableConfig({ isCorrectUrl: false, shouldEnable: false });
                    }
                })
                .catch(() => setEnableConfig({ isCorrectUrl: false, shouldEnable: false }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedDocRefUrl]);

    return (
        <Card header={t('import.importTemplate')} className='import-template' isMinimal={true}>
            <TextField
                data-testid='import-input'
                className='import-template__input'
                name='normal'
                label={t('import.inputPlaceholder')}
                value={docRefUrl}
                onChange={onChangeUrl}
                bsStyle={enableConfig.isCorrectUrl ? BS_STYLE.none : BS_STYLE.error}
                helpText={enableConfig.isCorrectUrl ? '' : `${t('import.validationError')}`}
            />
            <div className='import-template__footer'>
                <Link to='/catalog' className='import-template__cancel-btn btn btn-default'>{t('common.cancel')}</Link>
                <Link
                    className={`btn btn-primary ${buttonClass}`}
                    to={{ pathname: `/editor/${DESIGNER_MODE.TemplateEditor}`, search: `docRefUrl=${encodeURIComponent(docRefUrl)}&productUuid=${encodeURIComponent(productUuid)}` }}
                >
                    {t('common.import')}
                </Link>
            </div>
        </Card>
    );
}
