import React, { ReactNode, useState, useContext, useEffect } from 'react';
import { centralizedAuth, Profile } from '@cimpress/simple-auth-wrapper';
import AuthExpirationModal from 'components/authExpiration/AuthExpirationModal';
import { AuthClientId } from 'utility/constants';

import { logError } from '../loggingManager';

interface Props {
    children: ReactNode;
}

interface AuthContext {
    auth: centralizedAuth;
    isLoggedIn: boolean;
}

export const auth = new centralizedAuth({ // eslint-disable-line new-cap
    clientID: AuthClientId,
    redirectRoute: '/',
    emitInitialTokenExpired: false,
});

export const getEmailIdentifier = (profile: Profile): string => profile['https://claims.cimpress.io/canonical_id'] ?? profile.email;

const DEFAULT_CONTEXT: AuthContext = {
    auth,
    isLoggedIn: false,
};

const authContext = React.createContext<AuthContext>(DEFAULT_CONTEXT);
const { Provider } = authContext;

function AuthProvider({ children }: Props) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const setCloseModal = async () => {
        setIsOpenModal(false);
        const loginSuccessFull = await auth.login();
        if (loginSuccessFull) {
            setIsLoggedIn(true);
        }
    };

    useEffect(() => {
        auth.ensureAuthentication({ nextUri: window.location.pathname + window.location.search })
            .then((isAuthenticated: boolean) => {
                if (isAuthenticated) {
                    setIsLoggedIn(true);
                    auth.on('tokenExpired', () => {
                        setIsLoggedIn(false);
                        setIsOpenModal(true);
                    });
                }
            })
            .catch((error: Error) => logError(`Failed to ensure authentication: ${error}`));
    }, []);

    return (
        <>
            <AuthExpirationModal
                isOpen={isOpenModal}
                setClose={setCloseModal}
            />
            <Provider value={{ auth, isLoggedIn }}>
                {children}
            </Provider>
        </>
    );
}

function useAuth() {
    return useContext(authContext);
}

export {
    useAuth,
    AuthProvider,
};
