import { RootState } from 'store/rootReducer';
import { tenantSelector } from 'store/tenant';
import { Tenant } from 'store/tenant/types.tenant';

export interface StateProps {
    currentTenant: Tenant | undefined;
}

export interface OwnProps {
    designer?: Designer;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DispatchProps {
}

export type AllProps = StateProps & DispatchProps & OwnProps;

export const getAllProps = (props: AllProps) => {
    const { currentTenant,
        designer,
    } = props;
    const stateProps: StateProps = { currentTenant };
    const ownProps: OwnProps = { designer };

    return { stateProps, ownProps };
};

export const mapStateToProps = (state: RootState): StateProps => ({
    currentTenant: tenantSelector.selectCurrentTenant(state),
});

export const mapDispatchToProps: DispatchProps = {
};
