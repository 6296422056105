import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Snackbar } from '@cimpress/react-components';
import { Trans } from 'react-i18next';

import { RootState } from 'store/rootReducer';
import { tenantTypes } from 'store/tenant';
import { useAuth } from 'providers/AuthContext';
import { PublicUrl, IsDevMode, SupportedLanguages } from 'utility/constants';
import i18n from 'i18n';
import { BS_STYLE } from 'utility/enums';
import { StateProps, mapStateToProps, AllProps, getAllProps, DispatchProps, mapDispatchToProps } from './props.hydrateApp';

const hasAdminRole = (tenant: tenantTypes.Tenant | undefined): boolean => !!tenant && tenant.permissions.setting.canManage;

function HydrateApp(allProps: AllProps) {
    const { stateProps, dispatchProps } = getAllProps(allProps);
    const { isLoggedIn } = useAuth();

    useEffect(() => {
        if (isLoggedIn && !stateProps.isHydrated) {
            dispatchProps.loadUserPreference();
            dispatchProps.fetchTenant();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, stateProps.isHydrated]);

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const onHideSnackbar = () => {};

    useEffect(() => {
        dispatchProps.loadTenantSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateProps.currentTenant, stateProps.isHydrated]);

    useEffect(() => {
        // init i18next
        // for all options read: https://www.i18next.com/overview/configuration-options
        i18n.init({
            lng: stateProps.userPreference.currentLocale,
            fallbackLng: SupportedLanguages.find((l) => l.default)?.code || 'en-US',
            debug: IsDevMode,
            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
            },
            backend: {
                loadPath: `${PublicUrl}/locales/{{lng}}/{{ns}}.json`,
            },
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateProps.isHydrated]);

    return (
        <>
            <Snackbar show={stateProps.settings && stateProps.isSettingsLoaded && !stateProps.hasMandatoryDesignerSettings} bsStyle={BS_STYLE.danger} onHideSnackbar={onHideSnackbar}>
                {hasAdminRole(stateProps.currentTenant) ? (
                    <Trans i18nKey='notifications.danger.adminMsg'>
                        Template Maker requires you to set up the <strong>API Key</strong>, <strong>Upload Tenant</strong> and <strong>Designer Version</strong> to load Cimpress Designer.
                        Please configure the same on the<Link key='configuration' to='/configuration'>Configuration Page</Link> before accessing Template Maker.
                    </Trans>
                )
                    : (
                        <Trans i18nKey='notifications.danger.userMsg'>
                            Template Maker requires settings for Cimpress Designer.
                            Please contact your group admin to apply these settings on the<Link key='configuration' to='/configuration'>Configuration Page</Link>.
                        </Trans>
                    )}
            </Snackbar>
        </>
    );
}

export default connect<StateProps, DispatchProps, {}, RootState>(mapStateToProps, mapDispatchToProps)(HydrateApp);
