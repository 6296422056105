import { tenantTypes, tenantSelector } from 'store/tenant';
import { uiStateTypes } from 'store/uiState';
import { collectionTypes } from 'store/collection';
import { SEARCH_OPTIONS } from 'utility/enums';
import { auth, getEmailIdentifier } from 'providers/AuthContext';
import { CatalogPageSize, CollectionTagPrefix, SkulessTag } from 'utility/constants';
import { call, select, put } from 'redux-saga/effects';
import { queryAssets, editAsset, deleteAsset, restoreAsset } from './service.template';
import { templateTypes, templateActions } from './index';
import { QueryAssetsResponse } from './types.template';

export const getEffectiveOwner = (search: uiStateTypes.Search | undefined, includeOwnTemplates: boolean | undefined): (string | undefined) => {
    let owner;
    if (search && search.searchBy === SEARCH_OPTIONS.Author && search.searchString) {
        owner = search.searchString;
    } else if (includeOwnTemplates) {
        owner = auth.getProfile() ? getEmailIdentifier(auth.getProfile()) : ''; // INFO: Getting the current user from auth context
    }
    return owner;
};

export const formatQueryParams = (
    tenant: tenantTypes.Tenant,
    filterCriteria: uiStateTypes.FilterCriteria,
    activeCollection: collectionTypes.Collection | undefined,
    offset?: string | undefined,
) => {
    const { tenantId, tenantType } = tenant;
    const { search, includeDeleted, includeOwnTemplates, includeSkulessTemplates } = filterCriteria;
    const effectiveOwnerForSearch = getEffectiveOwner(search, includeOwnTemplates);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result: {[key: string]: any} = {
        tenantId,
        tenantType,
        includeDeleted,
        pageSize: CatalogPageSize,
    };

    effectiveOwnerForSearch && (result.owner = effectiveOwnerForSearch);
    offset && (result.offset = offset);
    const collectionTag = activeCollection && `${CollectionTagPrefix}${activeCollection.id}`;
    collectionTag && (result.tag = collectionTag);
    if (search) {
        if (search.searchBy === SEARCH_OPTIONS.Tags) {
            const tags = search.searchString.split(',').map((tag) => tag.trim()).filter((tag) => !!tag);
            collectionTag && tags.push(collectionTag);
            result.tag = tags.join(',');
        } else {
            result[search.searchBy] = search.searchString;
        }
    }
    includeSkulessTemplates && (result.tag = result.tag ? `${result.tag},${SkulessTag}` : SkulessTag);

    return result;
};

export function* collectionHasTemplates(collectionId: string) {
    const { tenantId, tenantType } = yield select(tenantSelector.selectCurrentTenant);

    const assetQueryParams = {
        tenantId,
        tenantType,
        includeDeleted: true,
        pageSize: 1,
        tag: `${CollectionTagPrefix}${collectionId}`,
    };

    const response: QueryAssetsResponse = yield call(queryAssets, assetQueryParams);
    return response && response.templates && response.templates.length > 0;
}

export function* handleCollectionTag({ template, sourceCollectionId, targetCollections }: {
    template: templateTypes.Template;
    sourceCollectionId?: string;
    targetCollections?: collectionTypes.Collection[];}) {
    const existingNonCollectionTags = template.tags.filter((tag) => !tag.startsWith(CollectionTagPrefix));
    const newCollectionTags = targetCollections ? targetCollections.map((collection) => `${CollectionTagPrefix}${collection.id}`) : [];
    const existingCollectionTagsWithOutSourceCollectionTag = template.tags
        .filter((tag) => tag.startsWith(CollectionTagPrefix) && tag !== `${CollectionTagPrefix}${sourceCollectionId}`);
    const uniqueCollectionTags = [...newCollectionTags, ...existingCollectionTagsWithOutSourceCollectionTag].filter((x, i, a) => a.indexOf(x) === i);
    const newTags = [...existingNonCollectionTags, ...uniqueCollectionTags];
    yield call(editAsset, template.id, { tags: newTags });
    yield put(templateActions.updateBulkProcess(template.id));
}

export function* handleBulkDelete({ template }: {
    template: templateTypes.Template;}) {
    yield call(deleteAsset, template.id);
    yield put(templateActions.updateBulkProcess(template.id));
}

export function* handleBulkRestore({ template }: {
    template: templateTypes.Template;}) {
    yield call(restoreAsset, template.id);
    yield put(templateActions.updateBulkProcess(template.id));
}
