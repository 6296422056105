import React, { useEffect, useRef } from 'react';
import cx from 'classnames';
import { convertSurfaceSpecToVariables } from 'utility/helper';
import { getCalculatedSurfaceSets } from 'store/product/service.product';
import { productTypes } from 'store/product';
import { SKULessTemplateRetrivalId } from 'utility/constants';
import { useDebounce } from 'customHooks/useDebounce';
import { convertSurfaceToSVG } from './surfaceSpecsToSVGs/convertSurfaceToSVG';

function SKULessProductPreview({ surfaceSpecifications }: { surfaceSpecifications: productTypes.SurfaceSpecification }) {
    const divRef = useRef<HTMLDivElement>(null);
    const debounceSurfaceSpecs = useDebounce(surfaceSpecifications, 1000);

    useEffect(() => {
        if (debounceSurfaceSpecs) {
            if (divRef.current) {
                divRef.current.innerHTML = '';
            }
            const params = convertSurfaceSpecToVariables(debounceSurfaceSpecs);
            getCalculatedSurfaceSets(SKULessTemplateRetrivalId, params).then((response) => {
                const surfaceToConvert = response.surfaceGroups[0].surfaces[0];
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                convertSurfaceToSVG(surfaceToConvert).then((svgElement: any) => {
                    if (divRef && divRef.current) { divRef.current.appendChild(svgElement); }
                });
            });
        } else if (divRef.current) {
            divRef.current.innerHTML = '';
        }
    }, [debounceSurfaceSpecs]);

    return (
        <div
            className={cx('create__preview-custom-template')}
            ref={divRef}
        />

    );
}

export default SKULessProductPreview;
