import { RootState } from 'store/rootReducer';
import { productTypes, productActions } from 'store/product';
import { templateTypes, templateActions, templateSelector } from 'store/template';
import { CREATE_MODE } from 'utility/enums';
import { uiStateActions } from 'store/uiState';
import { StructuredTags } from 'store/uiState/types.uiState';

export interface OwnProps {
    isTemplateInformationOpen?: boolean;
    surfaceSpecifications?: productTypes.SurfaceSpecification;
    location?: {
        search: string;
    };
    createMode: CREATE_MODE;
    docRefUrl?: string;
    tags?: string[];
    onSkuInputChange?(sku: string | undefined): void;
    onTemplateInformationToggle?(isNowOpen: boolean): void;
    onValidProduct?(product: productTypes.Product, complete: boolean): void;
    onInValidProduct?(): void;
    onValidSurfaceSpecification?(surfaceSpecification: productTypes.SurfaceSpecification): void;
    onInValidSurfaceSpecification?(): void;
}

export interface StateProps {
    inFlightTemplate: templateTypes.InFlightTemplate;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    designTransferTransientDocument?: {cimDoc: any; documentReferenceUrl: string};
    isClearCurrentSkuForDesignTransfer?: boolean;
    structuredTags: StructuredTags[] | undefined;
    enableIncompleteSkuVariableSelection?: boolean;
}

export interface DispatchProps {
    setInFlightTemplateName(payload: string): void;
    setInFlightTemplateDescription(payload: string): void;
    setInFlightTemplateTags(payload: string[]): void;
    removeInFlightTemplateTags(payload: string[]): void;
    resetInFlightTemplate(): void;
    designTransfer(payload: {docRefUrl: string; product: productTypes.Product}): void;
    setCurrentSkuForDesignTransfer(): void;
    clearCurrentSkuForDesignTransfer(): void;
    setInFlightTemplateSku(payload: string): void;
    clearDesignTransfer(): void;
}

export type AllProps = StateProps & DispatchProps & OwnProps;

export const getAllProps = (props: AllProps) => {
    const { location,
        isTemplateInformationOpen,
        inFlightTemplate,
        surfaceSpecifications,
        createMode,
        docRefUrl,
        tags,
        designTransferTransientDocument,
        isClearCurrentSkuForDesignTransfer,
        structuredTags,
        enableIncompleteSkuVariableSelection,
        onSkuInputChange,
        onTemplateInformationToggle,
        onValidProduct,
        onInValidProduct,
        setInFlightTemplateName,
        setInFlightTemplateDescription,
        setInFlightTemplateTags,
        removeInFlightTemplateTags,
        resetInFlightTemplate,
        onValidSurfaceSpecification,
        onInValidSurfaceSpecification,
        designTransfer,
        setCurrentSkuForDesignTransfer,
        clearCurrentSkuForDesignTransfer,
        setInFlightTemplateSku,
        clearDesignTransfer } = props;
    const ownProps: OwnProps = { location,
        isTemplateInformationOpen,
        surfaceSpecifications,
        createMode,
        docRefUrl,
        tags,
        onSkuInputChange,
        onTemplateInformationToggle,
        onValidProduct,
        onInValidProduct,
        onValidSurfaceSpecification,
        onInValidSurfaceSpecification };
    const stateProps: StateProps = { inFlightTemplate, designTransferTransientDocument, isClearCurrentSkuForDesignTransfer, structuredTags, enableIncompleteSkuVariableSelection };
    const dispatchProps: DispatchProps = { setInFlightTemplateName,
        setInFlightTemplateDescription,
        setInFlightTemplateTags,
        removeInFlightTemplateTags,
        resetInFlightTemplate,
        designTransfer,
        setCurrentSkuForDesignTransfer,
        clearCurrentSkuForDesignTransfer,
        setInFlightTemplateSku,
        clearDesignTransfer,
    };

    return { ownProps, stateProps, dispatchProps };
};

export const mapStateToProps = (state: RootState): StateProps => ({
    inFlightTemplate: templateSelector.selectInflightTemplate(state),
    designTransferTransientDocument: state.productState.designTransferTransientDocument,
    isClearCurrentSkuForDesignTransfer: state.uiState.isClearCurrentSkuForDesignTransfer,
    structuredTags: state.uiState.settings?.structuredTags,
    enableIncompleteSkuVariableSelection: state.uiState.settings?.enableIncompleteSkuVariableSelection,
});

export const mapDispatchToProps: DispatchProps = {
    setInFlightTemplateName: templateActions.setInFlightTemplateName,
    setInFlightTemplateDescription: templateActions.setInFlightTemplateDescription,
    setInFlightTemplateTags: templateActions.setInFlightTemplateTags,
    removeInFlightTemplateTags: templateActions.removeInFlightTemplateTags,
    resetInFlightTemplate: templateActions.resetInFlightTemplate,
    designTransfer: productActions.designTransfer,
    setCurrentSkuForDesignTransfer: uiStateActions.setClearCurrentSkuForDesignTransfer,
    clearCurrentSkuForDesignTransfer: uiStateActions.clearCurrentSkuForDesignTransfer,
    setInFlightTemplateSku: templateActions.setInFlightTemplateSku,
    clearDesignTransfer: productActions.clearDesignTransfer,
};
