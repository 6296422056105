import { takeEvery, put, call, all } from 'redux-saga/effects';
import { designTransfer } from 'services/designTransfer.service';
import { PayloadAction } from '@reduxjs/toolkit';
import { uiStateActions, formatNotification } from 'store/uiState';
import { SKULESS_TEMPLATE_SHAPE_TYPE, SNACKBAR_NOTIFICATION_TYPES, SURFACE_VARIABLES } from 'utility/enums';
import { SKULessTemplateRetrivalId } from 'utility/constants';
import { productActions, productTypes } from './index';
import { getCalculatedSurfaceSets, getProduct } from './service.product';

export function* onDesignTransfer(action: PayloadAction<{docRefUrl: string; product: productTypes.Product}>) {
    const { docRefUrl, product } = action.payload;
    try {
        const designTransferTransientDocument = yield call(designTransfer, docRefUrl, product.sku, product.attributes);
        yield put(productActions.designTransferSuccess(designTransferTransientDocument));
    } catch {
        yield put(uiStateActions.addNotification([formatNotification(SNACKBAR_NOTIFICATION_TYPES.Info, { key: 'notifications.danger.transferDesignFailed' })]));
        yield put(productActions.designTransferFailure());
    }
}

export function* onLoadProduct(action: PayloadAction<string>) {
    try {
        const product = yield call(getProduct, action.payload);
        yield put(productActions.loadProductSuccess(product));
    } catch {
        yield put(productActions.loadProductFailure());
    }
}

export function* onLoadCalculatedSurfaceSets(action: PayloadAction<productTypes.SurfaceSpecification>) {
    const { heightInMm, widthInMm, bleed, safe, pageCount, radius, shapeType } = action.payload;

    let skuLessShapeType = shapeType;
    if (widthInMm && heightInMm && widthInMm === heightInMm && radius === widthInMm / 2) {
        skuLessShapeType = SKULESS_TEMPLATE_SHAPE_TYPE.Circle;
    }
    const variables = {
        [SURFACE_VARIABLES.TrimWidth]: widthInMm / 10,
        [SURFACE_VARIABLES.TrimHeight]: heightInMm / 10,
        [SURFACE_VARIABLES.SurfaceBleed]: bleed * 0.1 || 0,
        [SURFACE_VARIABLES.SurfaceSafety]: safe * 0.1 || 0,
        [SURFACE_VARIABLES.Radius]: radius * 0.1 || 0,
        [SURFACE_VARIABLES.ShapeType]: skuLessShapeType,
        [SURFACE_VARIABLES.PageCount]: pageCount,
    };
    try {
        const calculatedSurfaceSets = (yield call(getCalculatedSurfaceSets, SKULessTemplateRetrivalId, JSON.stringify(variables)));
        yield put(productActions.setSurfaceSpecifications(action.payload));
        yield put(productActions.loadCalculatedSurfaceSetsSuccess(calculatedSurfaceSets));
    } catch {
        yield put(productActions.loadCalculatedSurfaceSetsFailure());
    }
}

export default function* productSaga() {
    return yield all([
        yield takeEvery(productActions.designTransfer, onDesignTransfer),
        yield takeEvery(productActions.loadProduct, onLoadProduct),
        yield takeEvery(productActions.loadCalculatedSurfaceSets, onLoadCalculatedSurfaceSets),
    ]);
}
