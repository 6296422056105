import { Mask, Path, Pathpoints, Point } from 'store/product/types.product';

const svgDirections = {
    moveTo: 'M',
    lineTo: 'L',
    curve: 'C',
    closePath: 'Z',
};

const createCoordinateInstructions = ({ x, y }: Point) => `${x},${y}`;

const createInstructions = (direction: string, coordinates: Point[]) => {
    const coordStrings = coordinates.map(createCoordinateInstructions);
    return `${direction} ${coordStrings.join(' ')}`;
};

const mapCoordinates = (data: Pathpoints) => ({
    end: {
        x: data.endPointX,
        y: data.endPointY,
    },
    controlPoint1: {
        x: data.firstControlPointX,
        y: data.firstControlPointY,
    },
    controlPoint2: {
        x: data.secondControlPointX,
        y: data.secondControlPointY,
    },
});

const createBorder = () => {
    const topLeft = { x: 0, y: 0 };
    const borderAroundCanvas = [topLeft, { x: '{width}', y: 0 }, { x: '{width}', y: '{height}' }, { x: 0, y: '{height}' }];

    return `${createInstructions(svgDirections.moveTo, [topLeft])} ${createInstructions(svgDirections.lineTo, borderAroundCanvas)} ${svgDirections.closePath}`;
};

const isCurve = (coordinates: { end?: Point; controlPoint1: Point; controlPoint2: Point }) => coordinates.controlPoint1.x !== undefined
        && coordinates.controlPoint1.y !== undefined
        && coordinates.controlPoint2.x !== undefined
        && coordinates.controlPoint2.y !== undefined;

const createPaths = (data: Path, closePath: boolean) => {
    const start = createInstructions(svgDirections.moveTo, [{ x: data.anchorX, y: data.anchorY }]);

    const directions = data.pathPoints.map((p: Pathpoints) => {
        const coordinateObject = mapCoordinates(p);
        return isCurve(coordinateObject)
            ? createInstructions(svgDirections.curve, [coordinateObject.controlPoint1, coordinateObject.controlPoint2, coordinateObject.end])
            : createInstructions(svgDirections.lineTo, [coordinateObject.end]);
    });

    const path = closePath ? svgDirections.closePath : '';

    const border = createBorder();

    return {
        start, directions, closePath: path, border,
    };
};

export function convertSurfaceData(data: Mask, closePath: boolean) {
    const paths = data.paths.map((path: Path) => createPaths(path, closePath));
    const type = data.pathType.toLowerCase();

    return { paths, type };
}
