/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { colors, Button, Snackbar, Tooltip, Dropdown } from '@cimpress/react-components';
import IconDownloadBottom from '@cimpress-technology/react-streamline-icons/lib/IconDownloadBottom';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { fetchAllTemplates } from 'store/template/service.template';
import { templateTypes } from 'store/template';
import { RecurringExportTemplatePageSize } from 'utility/constants';
import { mapTemplateToExport } from 'store/template/responseMapper.template';
import CollectionModal from 'components/collectionModal/CollectionModal';
import { collectionTypes } from 'store/collection';
import { useResponsiveDesign } from 'providers/ResponsiveDesign';
import { BS_STYLE } from 'utility/enums';
import './exportTemplate.scss';
import { formatQueryParams } from 'store/template/saga.template.util';
import { defaultFilterCriteria } from 'store/uiState/slice.uiState';
import { StateProps, mapStateToProps, AllProps, getAllProps } from './props.exportTemplate';

const { ocean } = colors;

export function ExportTemplate(allProps: AllProps) {
    const { stateProps } = getAllProps(allProps);
    const { isDesktopOrLaptop, isTabletOrMobile } = useResponsiveDesign();
    const { t } = useTranslation();
    const [isExporting, setIsExporting] = useState(false);
    const [showCollectionModal, setShowCollectionModal] = useState(false);
    const [selectedCollections, setSelectedCollections] = useState<collectionTypes.Collection[]>([]);
    const queryParam = {
        pageSize: RecurringExportTemplatePageSize,
        tenantId: (stateProps.tenant && stateProps.tenant.tenantId) || '',
        tenantType: (stateProps.tenant && stateProps.tenant.tenantType) || '',
    };
    const getQueryParam = (collection?: collectionTypes.Collection) => {
        let parameters;
        if (stateProps.tenant) {
            parameters = { ...queryParam, ...formatQueryParams(stateProps.tenant, stateProps.filterCriteria, collection) };
        }
        return parameters;
    };

    const generateFile = (templates: templateTypes.Template[]) => {
        const templatesToBeExported = mapTemplateToExport(templates);
        const date = new Date();
        const formatedDate = `${date.getDate()}${date.getMonth() + 1}${date.getFullYear()}`;
        const filename = stateProps.tenant ? `${stateProps.tenant.tenantId}-${formatedDate}.json` : 'export.json';
        const contentType = 'application/json;charset=utf-8;';
        const a = generateAnchorTagElm(filename, contentType, templatesToBeExported);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setShowCollectionModal(false);
    };

    const generateAnchorTagElm = (filename: string, contentType: string, templates: templateTypes.TemplateToBeExported[]) => {
        const a = document.createElement('a');
        a.download = filename;
        a.href = `data: ${contentType},${encodeURIComponent(JSON.stringify(templates))}`;
        a.target = '_blank';
        return a;
    };

    const onExportTemplateClick = () => setShowCollectionModal(true);

    const exportTemplate = (params: { queryParameters?: templateTypes.AssetQueryParams; collection?: collectionTypes.Collection }) => {
        setIsExporting(true);
        const parameters = params.queryParameters || getQueryParam(params.collection);
        parameters && fetchAllTemplates(parameters).then((templates) => generateFile(templates));
    };

    const onHideSnackbar = () => {
        setIsExporting(false);
        setSelectedCollections([]);
    };
    const onCollectionModalCancel = () => {
        setSelectedCollections([]);
        setShowCollectionModal(false);
    };
    const onExportAll = () => {
        if (stateProps.tenant) {
            exportTemplate({ queryParameters: { ...queryParam, ...formatQueryParams(stateProps.tenant, defaultFilterCriteria, undefined) } });
        }
    };

    const onExportSelectedCollections = () => {
        setIsExporting(true);
        if (selectedCollections.length > 0) {
            const promises: Promise<templateTypes.Template[]>[] = [];
            selectedCollections.forEach((collection) => {
                const params = getQueryParam(collection);
                const response = params && fetchAllTemplates(params);
                response && promises.push(response);
            });
            Promise.all(promises).then((responses) => generateFile(responses.flat()));
        }
    };

    const onChangeSelectedCollection = (collections: collectionTypes.Collection[]) => setSelectedCollections(collections);

    const onExportTemplateWithCriteriaClick = () => {
        if (stateProps.tenant) {
            setIsExporting(true);
            exportTemplate({ collection: stateProps.activeCollection });
        }
    };

    const getTitle = () => {
        if (isDesktopOrLaptop) {
            return (
                <>
                    <IconDownloadBottom color={ocean.darker} size='14px' weight='fill' />
                    {t('catalog.exportTemplateDropdown.title')}
                </>
            );
        }
        return (
            <Tooltip direction='top' contents={t('catalog.exportTemplateDropdown.title')}>
                <IconDownloadBottom size='2x' color={ocean.darker} weight='fill' />
            </Tooltip>
        );
    };
    return (
        <>
            <Snackbar show={isExporting} bsStyle={BS_STYLE.success} delay={3000} onHideSnackbar={onHideSnackbar}>
                {t('notifications.info.exportTemplates')}
            </Snackbar>
            <div className={cx('export-template', { 'mobile-tablet': isTabletOrMobile })} id='joy-ride__template-export'>
                <Dropdown
                    title={getTitle()}
                    className='export-dropdown'
                >
                    <button onClick={onExportAll}>
                        {t('catalog.exportTemplateDropdown.exportAll')}
                    </button>
                    <button disabled={!stateProps.isFilterCriteraApplied || !stateProps.selectAllTemplates.length} onClick={onExportTemplateWithCriteriaClick}>
                        {t('catalog.exportTemplateDropdown.exportFilteredTemplates')}
                    </button>
                    <button onClick={onExportTemplateClick}>
                        {t('catalog.exportTemplateDropdown.exportFromCollection')}
                    </button>
                </Dropdown>
            </div>
            { showCollectionModal && (
                <CollectionModal
                    title={t('modals.exportTemplate.exportHeader')}
                    footer={(
                        <div className='collection-modal-btn'>
                            <Button
                                disabled={!(selectedCollections.length > 0)}
                                className='btn btn-default'
                                onClick={onExportSelectedCollections}
                            >{t('modals.exportTemplate.exportSelected')}
                            </Button>
                            <Button className='btn btn-default' onClick={onCollectionModalCancel}>{t('common.cancel')}</Button>
                        </div>
                    )}
                    onRequestHide={onCollectionModalCancel}
                    onChangeSelectedCollection={onChangeSelectedCollection}
                    collections={stateProps.collections}
                    isOpen={showCollectionModal}
                    selectedCollectionArea={{
                        title: t('modals.exportTemplate.exportSelectedTitle'),
                        description: t('modals.exportTemplate.exportSelectedDescription'),
                        emptyMessage: t('modals.exportTemplate.exportSelectedEmptyMessage'),
                    }}
                />
            ) }
        </>
    );
}

export default connect<StateProps, {}, {}, RootState>(mapStateToProps)(ExportTemplate);
