import { useState, useEffect } from 'react';
/* eslint-disable @typescript-eslint/no-explicit-any */
export default function useFullScreen() {
    const [isFullscreen, setIsFullscreen] = useState(false);
    useEffect(() => {
        function handleStatusChange() {
            setIsFullscreen(isFullscreenMode());
            if (!isFullscreenMode()) {
                exitFullscreen();
            }
        }
        const fullScreenEvents = ['webkitfullscreenchange', 'mozfullscreenchange', 'fullscreenchange', 'MSFullscreenChange'];
        fullScreenEvents.forEach((event) => document.addEventListener(event, handleStatusChange));
        return () => { fullScreenEvents.forEach((event) => document.removeEventListener(event, handleStatusChange)); };
    });
    const requestFullscreen = () => {
        const element = document.documentElement as any;
        const requestFull = element.requestFullscreen || element.webkitRequestFullscreen || element.mozRequestFullscreen || element.msRequestFullscreen;
        requestFull.call(element);
    };
    const isFullscreenMode = () => {
        const doc = document as any;
        const fullScreenElem = doc.fullscreenElement || doc.webkitFullscreenElement || doc.mozFullScreenElement;
        return !!fullScreenElem;
    };
    const exitFullscreen = () => {
        const doc = document as any;
        const exitFull = doc.exitFullscreen || doc.webkitExitFullscreen || doc.mozCancelFullScreen || doc.msExitFullscreen;
        if (isFullscreenMode()) {
            exitFull.call(doc);
        }
    };
    const fullscreenEnabled = () => {
        const doc = document as any;
        return doc.fullscreenEnabled || doc.webkitFullscreenEnabled || doc.mozFullScreenEnabled || doc.msFullscreenEnabled;
    };

    return { isFullscreen, fullscreenEnabled, requestFullscreen, exitFullscreen };
}
