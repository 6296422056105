import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import './switcher.scss';

import { RootState } from 'store/rootReducer';
import { useTranslation } from 'react-i18next';
import useFullScreen from 'customHooks/useFullScreen';
import { OwnProps, StateProps, DispatchProps, mapStateToProps, mapDispatchToProps, AllProps, getAllProps } from './props.switcher';

/**
 * This is the component wrapper for the canvas switcher.
 * The switcher will be injected into the .dcl-widget-canvas-switcher element.
 * After designer is initialized, we should check if it is actually being used, and if not hide all this.
 */
function Switcher(allProps: AllProps) {
    const { ownProps, stateProps, dispatchProps } = getAllProps(allProps);
    const { isFullscreen } = useFullScreen();

    const { t } = useTranslation();
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        if (ownProps.designer && !ownProps.designer.featureManager.featureEnabled('canvasSwitcher')) {
            dispatchProps.toggleHide(true);
            setDisabled(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ownProps.designer, dispatchProps.toggleHide]);

    useEffect(() => {
        dispatchProps.toggleHide(isFullscreen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFullscreen]);

    const onClick = () => dispatchProps.toggleHide();

    if (disabled) {
        return null;
    }

    return (
        <>
            <button type='button' className='canvas-switcher-hide-button' onClick={onClick} data-dcl-prevent-canvas-items-deselection={true}>
                <svg>
                    <use xlinkHref='#dcl-icon-downcaret' />
                </svg>
            </button>
            <div className={`canvas-switcher${stateProps.hidden ? ' canvas-switcher--hidden' : ''}`}>
                <div className='canvas-switcher__title'>{t('editor.switcher.designArea')}</div>
                <div className='dcl-widget-canvas-switcher' />
            </div>
        </>
    );
}

export default connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)(Switcher);
