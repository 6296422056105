/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';

import { clipArtTypes } from 'store/clipart';
import { getUploadUrl } from 'utility/url.utility';
import { UPLOAD_URL_VARIANTS } from 'utility/enums';
import './clipartItem.scss';

interface Props {
    clipArt: clipArtTypes.ClipArt;
    onSelectClipArt(clipArt: clipArtTypes.ClipArt): void;
}

export function ClipArtItem({ clipArt, onSelectClipArt }: Props) {
    const handleClick = () => onSelectClipArt(clipArt);

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div className='clipart-item' onClick={handleClick}>
            <img
                alt={clipArt.name}
                src={getUploadUrl(clipArt.uploadId, 'build-a-sign', UPLOAD_URL_VARIANTS.Thumbnail)}
            />
        </div>
    );
}
