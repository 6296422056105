import { RootState } from 'store/rootReducer';
import { editorActions } from 'store/editor';

export interface OwnProps {
    designer?: Designer;
}

export interface StateProps {
    hidden: boolean;
}

export interface DispatchProps {
    toggleHide(hide?: boolean): void;
}

export type AllProps = StateProps & DispatchProps & OwnProps;

export const getAllProps = (props: AllProps) => {
    const { designer, hidden, toggleHide } = props;
    const ownProps: OwnProps = { designer };
    const stateProps: StateProps = { hidden };
    const dispatchProps: DispatchProps = { toggleHide };

    return { ownProps, stateProps, dispatchProps };
};

export const mapStateToProps = (state: RootState): StateProps => ({
    hidden: state.editorState.editorLoadSettings.switcherHidden,
});

export const mapDispatchToProps: DispatchProps = {
    toggleHide: editorActions.toggleSwitcherHidden,
};
