import { RootState } from 'store/rootReducer';
import { uiStateTypes, uiStateActions, uiStateSelector } from 'store/uiState';
import { templateActions, templateTypes, templateSelector } from 'store/template';
import { tenantSelector, tenantTypes } from 'store/tenant';

export interface StateProps {
    recentTemplates: templateTypes.Template[];
    updatingTemplateIds: string[];
    hasMandatoryDesignerSettings: boolean;
    currentTenant?: tenantTypes.Tenant;
}
export interface DispatchProps {
    addNotification(payload: uiStateTypes.Notification[]): void;
    onDeleteTemplate(payload: string): void;
    onRestoreTemplate(payload: string): void;
}
export type AllProps = StateProps & DispatchProps;

export const getAllProps = (props: AllProps) => {
    const { recentTemplates, updatingTemplateIds, hasMandatoryDesignerSettings, currentTenant,
        addNotification, onDeleteTemplate, onRestoreTemplate } = props;
    const stateProps: StateProps = {
        recentTemplates,
        updatingTemplateIds,
        hasMandatoryDesignerSettings,
        currentTenant };
    const dispatchProps: DispatchProps = { addNotification,
        onDeleteTemplate,
        onRestoreTemplate };
    return { stateProps, dispatchProps };
};

export const mapStateToProps = (state: RootState): StateProps => ({
    recentTemplates: templateSelector.selectRecentTemplatesWithPreview(state),
    updatingTemplateIds: uiStateSelector.selectLoadingIdsByActions(state, templateActions.onDeleteTemplate.toString(),
        templateActions.onRestoreTemplate.toString()),
    hasMandatoryDesignerSettings: uiStateSelector.selectHasMandatoryDesignerSettings(state),
    currentTenant: tenantSelector.selectCurrentTenant(state),
});
export const mapDispatchToProps: DispatchProps = {
    addNotification: uiStateActions.addNotification,
    onDeleteTemplate: templateActions.onDeleteTemplate,
    onRestoreTemplate: templateActions.onRestoreTemplate,
};
