import { SurfaceSpecification } from 'store/product/types.product';
import { templateTypes } from 'store/template';
import { CollectionTagPrefix, MaskColor } from './constants';
import { SKULESS_TEMPLATE_SHAPE_TYPE, SURFACE_VARIABLES } from './enums';

const filterUniqueElements = (array: string[]): string[] => array.filter((x, i, a) => a.indexOf(x) === i);

const getCollectionIdsFromTemplates = (templates: templateTypes.Template[]): string[] => {
    const collectionIds: string[] = [];
    templates.length && templates.forEach((template: templateTypes.Template) => {
        template.tags && template.tags.length && template.tags.forEach((tag) => {
            if (tag.startsWith(CollectionTagPrefix)) {
                collectionIds.push(tag.replace(CollectionTagPrefix, ''));
            }
        });
    });
    return collectionIds;
};

const getCommonCollectionIdsFromTemplates = (templates: templateTypes.Template[]): string[] => {
    let collectionIds: string[] = [];
    if (templates.length > 0 && templates[0].tags) {
        collectionIds = templates[0].tags.filter((tag) => tag.startsWith(CollectionTagPrefix));
        templates.forEach((template: templateTypes.Template) => {
            collectionIds = collectionIds.filter((id) => template.tags && template.tags.length && template.tags.includes(id));
        });
    }
    return collectionIds.map((id) => id.replace(CollectionTagPrefix, ''));
};

const getDuplicateLabel = (label: string): string => {
    const labelArray = label.split(' ');
    const lastIndex = labelArray.length - 1;
    if (labelArray[lastIndex - 1] === 'copy') {
        const existingDuplicateValue = Number(labelArray[lastIndex]);
        labelArray[lastIndex] = !Number.isNaN(existingDuplicateValue) ? `${existingDuplicateValue + 1}` : `${labelArray[lastIndex]} copy 1`;
        return labelArray.join(' ');
    }
    return `${label} copy 1`;
};

const convertSurfaceSpecToVariables = (surfaceSpecifications: SurfaceSpecification, shouldStringyfy = false): string => {
    const { heightInMm, widthInMm, bleed, safe, pageCount, radius, shapeType } = surfaceSpecifications;

    let skuLessShapeType = shapeType;
    if (widthInMm && heightInMm && widthInMm === heightInMm && radius === widthInMm / 2) {
        skuLessShapeType = SKULESS_TEMPLATE_SHAPE_TYPE.Circle;
    }
    // TrimWidth is in 'cm' unit in SurfaceSpec API, so we are deviding user input by 10
    /** We have to multiply bleed, safe and all user input with '0.1' so that it will represented as 'mm' in SurfaceSpec API,
    so that calculation will be correct with respect to trimWidth and trimHeight * */
    const variables = {
        [SURFACE_VARIABLES.TrimWidth]: `${widthInMm / 10}`,
        [SURFACE_VARIABLES.TrimHeight]: `${heightInMm / 10}`,
        [SURFACE_VARIABLES.SurfaceBleed]: `${bleed * 0.1 || 0}`,
        [SURFACE_VARIABLES.SurfaceSafety]: `${safe * 0.1 || 0}`,
        [SURFACE_VARIABLES.Radius]: `${radius * 0.1 || 0}`,
        [SURFACE_VARIABLES.ShapeType]: skuLessShapeType,
        [SURFACE_VARIABLES.PageCount]: `${pageCount}`,
    };

    return JSON.stringify(variables);
};

const MM_TO_PT = 2.83465;

const round = (num: number, places: number) => {
    let roundedValue;
    if (places > 0) {
        roundedValue = num.toFixed(places);
    } else if (places === 0) {
        roundedValue = Math.round(num);
    } else {
        roundedValue = parseFloat(`${roundedValue}${Math.round(parseFloat(`${num}e-${-places}`))}e+${-places}`);
    }

    return roundedValue || 0;
};

const mm2pt = (mm: number) => mm * MM_TO_PT;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getStrokeWidthByCanvasSize = (activeCanvas: any, mm2ptRequired?: boolean) => {
    const { attributes } = activeCanvas;
    let minSize = Math.min(attributes.width, attributes.height);
    const maxSize = Math.max(attributes.width, attributes.height);
    // If the smaller dimension is much smaller than the larger, then the strokeWidth will still be too small
    minSize = Math.max(minSize, maxSize * 0.33);
    const minSizeInCorrectMeasurement = mm2ptRequired ? mm2pt(minSize) : minSize;
    // 0.5% of the canvas's smallest dimension yields a good line thickness
    return round(minSizeInCorrectMeasurement * 0.005, 3);
};

const getMaskColor = (mask: string) => MaskColor[mask];

const setElementAttributes = (element: SVGSVGElement | HTMLElement | SVGElement, attrs: {[key: string]: string}) => {
    Object.keys(attrs).forEach((attr) => {
        element.setAttribute(attr, attrs[attr]);
    });
};

export {
    filterUniqueElements,
    getCollectionIdsFromTemplates,
    getCommonCollectionIdsFromTemplates,
    getDuplicateLabel,
    convertSurfaceSpecToVariables,
    getStrokeWidthByCanvasSize,
    getMaskColor,
    setElementAttributes,
};
