import React, { useState, useEffect } from 'react';
import { RootState } from 'store/rootReducer';
import { connect } from 'react-redux';
import { Modal } from '@cimpress/react-components';
import CarouselPreviewWrapper, { maxSurfacesToShow } from 'components/CarouselPreviewWrapper/CarouselPreviewWrapper';
import { useResponsiveDesign } from 'providers/ResponsiveDesign';
import { templateTypes } from 'store/template';
import { useTranslation } from 'react-i18next';
import { AllProps, getAllProps, OwnProps, StateProps, DispatchProps, mapStateToProps, mapDispatchToProps } from './props.templatePreviewModal';

import './templatePreviewModal.scss';

enum SIZES {
    xs = 'xs',
    s = 's',
    m = 'm',
    l = 'l',
    xl = 'xl',
}

function TemplateInfoDrawer(allProps: AllProps) {
    const { ownProps, stateProps } = getAllProps(allProps);
    const [size, setSize] = useState(SIZES.l);
    const { isMobile, isTablet } = useResponsiveDesign();
    const [selectedVersion] = useState<templateTypes.TemplateVersion>();
    const { t } = useTranslation();
    const [surfaces, setSurfaces] = useState<string[] | undefined>();
    const [imageView, setImageView] = useState('');

    let imageViews: string[] = [t('success.imageViews.closeUp'), t('success.imageViews.full'), t('success.imageViews.fullBleed')];

    const setSurfaceDetails = (surfaceSet?: string[]) => {
        imageViews = surfaceSet && surfaceSet.length > maxSurfacesToShow ? [imageViews[0]] : imageViews;
        surfaceSet && setImageView(`${surfaceSet[0]}, ${imageViews[0]} ${t('success.imageViews.view')}`); // set initial view
        surfaceSet && setSurfaces(surfaceSet);
    };

    const onSlideChange = (slideNumber: number) => {
        if (surfaces) {
            let view = '';
            let surfaceIndex = Math.ceil(slideNumber / imageViews.length);
            if (surfaces && surfaces.length > maxSurfacesToShow) {
                // eslint-disable-next-line prefer-destructuring
                view = imageViews[0];
                surfaceIndex = slideNumber;
            } else {
                view = imageViews[(slideNumber - 1 < 0 ? 0 : slideNumber - 1) % imageViews.length];
            }
            setImageView(`${surfaces[surfaceIndex - 1]}, ${view} ${t('success.imageViews.view')}`);
        }
    };

    useEffect(() => {
        if (isMobile) {
            setSize(SIZES.xs);
        } else if (isTablet) {
            setSize(SIZES.s);
        } else {
            setSize(SIZES.l);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile, isTablet]);

    const onRequestHide = () => {
        ownProps.setOpen(false);
    };

    return (
        <>
            {stateProps.template && (
                <Modal
                    closeButton={true}
                    bsSize='lg'
                    show={ownProps.open}
                    title={stateProps.template.name}
                    onRequestHide={onRequestHide}
                    closeOnOutsideClick={true}
                    className='template-details-modal-container'
                >
                    <div className='template-details-modal-preview'>
                        <CarouselPreviewWrapper
                            minimal={false}
                            selectedVersionId={selectedVersion?.id}
                            size={size}
                            onSlideChanged={onSlideChange}
                            setSurfaceDetails={setSurfaceDetails}
                            imageHeading={imageView}
                            showImageHeading={true}
                        />
                    </div>
                </Modal>
            )}
        </>
    );
}
export default connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)(TemplateInfoDrawer);
