import { RootState } from 'store/rootReducer';
import { uiStateSelector } from 'store/uiState';

export interface StateProps {
    showLoader: boolean;
    currentLocale?: string;
    isHydrated: boolean;
}

export type AllProps = StateProps;

export const getAllProps = (props: AllProps) => {
    const { showLoader, currentLocale, isHydrated } = props;
    const stateProps: StateProps = { showLoader, currentLocale, isHydrated };

    return { stateProps };
};

export const mapStateToProps = (state: RootState): StateProps => ({
    showLoader: uiStateSelector.selectShowBlockingLoader(state),
    currentLocale: uiStateSelector.selectCurrentLocale(state),
    isHydrated: uiStateSelector.selectIsHydrated(state),
});
