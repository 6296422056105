import { URLs, AllCollection } from 'utility/constants';
import { directoryTypes } from 'shared/directory';
import { collectionTypes } from './index';

interface RootNode {
    id?: undefined;
    children: collectionTypes.Collection[];
}

export function updateById(current: collectionTypes.Collection | RootNode, nodeId: string, label: string, updateComplete: boolean): true {
    if (updateComplete) {
        return true;
    }

    if (current && current.id && nodeId === current.id) {
        // eslint-disable-next-line no-param-reassign
        current.label = label;
    }

    if (current && current.children) {
        for (let i = 0; i < current.children.length; i += 1) {
            const child = current.children[i];
            updateById(child, nodeId, label, false);
        }
    }
    return updateById(current, nodeId, label, true);
}

export function getPath(current: collectionTypes.Collection | RootNode, nodeId: string, path: collectionTypes.Collection[]): collectionTypes.Collection[] | undefined {
    if (current && current.id) {
        path.push(current);
        if (nodeId === current.id) {
            return path;
        }
    }

    if (current && current.children) {
        for (let i = 0; i < current.children.length; i += 1) {
            const child = current.children[i];
            const tempPath = getPath(child, nodeId, path);
            if (tempPath) {
                return tempPath;
            }
            path.pop();
        }
    }
    return undefined;
}

/**
 * @param  {Collection[]} collection
 * @param  {string|undefined} folderId
 * @returns FolderInfo[] | undefined
 */
export function getFolderPath(collection: collectionTypes.Collection[], folderId: string | undefined): collectionTypes.Collection[] | undefined {
    return folderId ? getPath({ children: collection }, folderId, []) : undefined;
}

/**
 * @param  {Collection[]} collection
 * @param  {string|undefined} folderId
 * @returns FolderInfo | undefined
 */
export function findCollectionById(collection: collectionTypes.Collection[], folderId: string | undefined): collectionTypes.Collection | undefined {
    if (folderId) {
        const path = getPath({ children: collection }, folderId, []);
        if (path && path.length > 0) {
            return path[path.length - 1];
        }
        return undefined;
    }
    return undefined;
}
/**
 * @param  {Collection[]} collection
 * @param  {Collection} newFolder
 * @param  {string} parentId
 */
export function addChildrenToParent(collection: collectionTypes.Collection[], children: collectionTypes.Collection[], parentId: string, tenantId: string) {
    const collectionClone = [...collection];
    const parent = findCollectionById(collectionClone, parentId);
    if (parent) {
        if (!parent.children) {
            parent.children = children;
        } else {
            parent.children.unshift(...children);
        }
        if (parent.children.length > 0) {
            parent._links.children = `${URLs.CollectionBase}?tenantId=${tenantId}&parentId=${parentId}`;
        }
    } else {
        collectionClone.unshift(...children);
    }
    return collectionClone;
}

export function updateFolderLabelById(collection: collectionTypes.Collection[], id: string, label: string) {
    const collectionClone = [...collection];
    updateById({ children: collection }, id, label, false);
    return collectionClone;
}

/**
 * @param  {Collection[]} collection
 * @param  {string} collectionId
 */
export function removeCollectionFromParent(collection: collectionTypes.Collection[], collectionId: string) {
    const collectionClone = [...collection];
    const folder = findCollectionById(collectionClone, collectionId);
    if (folder) {
        if (folder.parentId && folder.parentId !== 'ROOT') {
            const parent = findCollectionById(collectionClone, folder.parentId);
            if (parent) {
                parent.children = parent.children.filter((col) => col.id !== folder.id);
                if (parent.children.length === 0) {
                    parent._links.children = undefined;
                }
            }
        } else {
            return collectionClone.filter((col) => col.id !== folder.id);
        }
    }

    return collectionClone;
}

/**
 * @param  {Collection} collection
 */
export function hasParent(collection?: collectionTypes.Collection) {
    return collection && collection.parentId && collection.parentId !== 'ROOT';
}

/**
 * @param  {Collection[]} collections
 * @param  {string} collectionId
 */
export function getNearestSibling(collections: collectionTypes.Collection[], collectionId: string) {
    const collectionIndex = collections.findIndex((c) => c.id === collectionId);
    let newIndex = 0;
    newIndex = collectionIndex > 0 ? collectionIndex - 1 : 1;
    return collections[newIndex].id;
}

/**
 * @param  {Collection[]} collection
 * @param  {string} collectionId
 */
export function getActiveCollectionIdAfterDelete(collections: collectionTypes.Collection[], collectionId: string) {
    const collection = findCollectionById(collections, collectionId);
    let activeCollectionIdAfterDelete = AllCollection;
    if (collection && hasParent(collection)) {
        const parentCollection = findCollectionById(collections, collection.parentId);
        if (parentCollection && parentCollection.children.length > 1) {
            const { children } = parentCollection;
            activeCollectionIdAfterDelete = getNearestSibling(children, collectionId);
        } else {
            activeCollectionIdAfterDelete = collection.parentId;
        }
    }
    return activeCollectionIdAfterDelete;
}

export function mapCollectionToDirectory(collections: collectionTypes.Collection[]): directoryTypes.Directory[] {
    return collections.map((collection) => ({
        id: collection.id,
        parentId: collection.parentId,
        label: collection.label,
        subDirectories: collection.hasChildren && collection.children ? mapCollectionToDirectory(collection.children) : undefined,
        hasSubDirectories: collection.hasChildren,
        selected: false,
        disabled: false,
    }));
}

export function getNestedCollection(collections: collectionTypes.Collection[]): collectionTypes.Collection[] {
    return collections.map((collection) => ({
        ...collection,
        hasChildren: !!(collection._links && collection._links.children),
        children: collection.children ? getNestedCollection(collection.children) : collection.children }));
}
