import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { shapes } from '@cimpress/react-components';

import { RootState } from 'store/rootReducer';
import { connect } from 'react-redux';
import Notifications from 'shared/notification/notification';
import CoamError from 'shared/coamError/CoamError';
import { AllProps, getAllProps, StateProps, mapStateToProps } from 'props.app';
import { ResponsiveDesignProvider } from 'providers/ResponsiveDesign';
import Catalog from 'screens/catalog/Catalog';
import Header from './components/header/Header';

import Configuration from './screens/configuration/Configuration';
import Contact from './screens/contact/Contact';
import Create from './screens/create/Create';
import Import from './screens/import/Import';
import Editor from './screens/editor/Editor';
import Versions from './screens/versions/Versions';
import Landing from './screens/landing/Landing';
import Success from './screens/success/Success';
import TenantAssignedProtectedRoute from './TenantAssignedProtectedRoute';
import { history } from './store/rootStore';
import HydrateApp from './components/hydrateApp/HydrateApp';

import './app.scss';
import './brand.scss';

const { Spinner } = shapes;

/**
 * The top level application component. This routes to each individual page, as well as displaying headers, footers,
 * and other components that are common between pages
 */
function App(allProps: AllProps) {
    const { stateProps } = getAllProps(allProps);

    return (
        <ConnectedRouter history={history}>
            <div className='app'>
                <HydrateApp />
                {stateProps.showLoader && <div className='full-page-overlay'><Spinner /></div>}
                {stateProps.isHydrated && (
                    <Switch>
                        <Suspense fallback={<Spinner className='catalog-spinner' />}>
                            <ResponsiveDesignProvider>
                                <Notifications />
                                <Header />
                                <TenantAssignedProtectedRoute path='/' exact={true} component={Landing} />
                                <Route path='/coam-error' exact={true} component={CoamError} />
                                <TenantAssignedProtectedRoute path='/configuration' component={Configuration} />
                                <TenantAssignedProtectedRoute path='/catalog' component={Catalog} />
                                <Route path='/contact' component={Contact} />
                                <TenantAssignedProtectedRoute path='/success/:id' component={Success} />
                                <TenantAssignedProtectedRoute path='/create' component={Create} />
                                <TenantAssignedProtectedRoute path='/import' component={Import} />
                                <TenantAssignedProtectedRoute path='/editor/:designerMode?/:id?/:revisionId?' component={Editor} />
                                <TenantAssignedProtectedRoute path='/versions/:id' component={Versions} />
                            </ResponsiveDesignProvider>
                        </Suspense>
                        <Redirect to='/' />
                    </Switch>
                )}
            </div>
        </ConnectedRouter>
    );
}
export default connect<StateProps, {}, {}, RootState>(mapStateToProps)(App);
