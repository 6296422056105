import { AxiosError } from 'axios';

import { axiosWithAuth } from 'interceptors/auth.interceptor';
import { URLs } from 'utility/constants';
import { logError } from '../loggingManager';

interface CanonicalPrincipal {
    canonical_principal: string;
    profiles: [
        { name: string }
    ];
}

interface CanonicalPrincipals {
    canonical_principals: CanonicalPrincipal[];
}

const mapProfiles = (profiles: CanonicalPrincipals): {name: string;emailId: string}[] => (
    profiles.canonical_principals.map((owner: CanonicalPrincipal) => ({
        name: owner.profiles[0] && owner.profiles[0].name,
        emailId: owner.canonical_principal,
    }))
);

export async function fetchOwnerProfiles(name: string) {
    return axiosWithAuth.get(URLs.AccessManagementSearchBySubstring, {
        params: { q: name } })
        .then((response) => mapProfiles(response.data))
        .catch((error: AxiosError) => {
            logError(`Could not fetch owners ${error}`);
            throw error;
        });
}
