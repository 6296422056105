/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { CssLoader } from '@cimpress/react-components';

import './i18n';
import { AuthProvider } from 'providers/AuthContext';
import App from './App';
import createStore from './store/rootStore';

const store = createStore();

const renderWithProviders = (Component) => {
    ReactDOM.render(
        <Provider store={store}>
            <AuthProvider>
                <CssLoader>
                    <Component />
                </CssLoader>
            </AuthProvider>
        </Provider>,
        document.getElementById('root'),
    );
};

renderWithProviders(App);

// subscribe to any app updates when running with hot reloading
if (module.hot) {
    module.hot.accept('./App', () => {
        const updatedApp = App.default;
        renderWithProviders(updatedApp);
    });
}
