import { LocalStorage } from 'utility/constants';
import { saveItemInLocalStorage, getItemFromLocalStorage } from '../../utility/storage.utility';
import { RecentSku } from './RecentSkus';

const maxRecentSkus = 6;

export const updateRecentSkus = (sku: string) => {
    let recentSkus: RecentSku[] = getItemFromLocalStorage(LocalStorage.RecentSkusKey) || [];
    if (recentSkus.length >= maxRecentSkus && !recentSkus.some((s) => s.sku === sku)) {
        recentSkus = recentSkus.sort((a, b) => new Date(b.lastUsedOn).getTime() - new Date(a.lastUsedOn).getTime()).slice(0, maxRecentSkus - 1);
    }
    const index = recentSkus.findIndex((recentSku) => recentSku.sku === sku);

    if (index >= 0) {
        recentSkus[index] = { ...recentSkus[index], lastUsedOn: new Date() };
    } else {
        const newSku = {
            sku,
            lastUsedOn: new Date(),
            isFavourite: false,
        };
        recentSkus.push(newSku);
    }
    saveItemInLocalStorage(LocalStorage.RecentSkusKey, recentSkus);
};
