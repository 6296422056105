import React from 'react';
import { Checkbox } from '@cimpress/react-components';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { collectionTypes } from 'store/collection';
import { templateTypes } from 'store/template';
import { tenantTypes } from 'store/tenant';
import TemplateTableRow from 'components/templateTableRow/TemplateTableRow';
import cx from 'classnames';

import './templates.scss';
import { useResponsiveDesign } from 'providers/ResponsiveDesign';

interface Props {
    canEdit: boolean;
    templates: templateTypes.Template[];
    tenant?: tenantTypes.Tenant;
    selectedTemplates?: templateTypes.Template[];
    hasMoreTemplates: boolean;
    activeCollection: collectionTypes.Collection | undefined;
    updatingTemplateIds: string[];
    enableEditingAceTemplates: boolean;
    uploadsTenant: string | undefined;
    setSelectedTemplates?(payload: templateTypes.Template[]): void;
    onCopyUrl(): void;
    onRemoveTemplateFromCollection(payload: templateTypes.Template[]): void;
    loadNextTemplates(): void;
    setCurrentTemplate(payload: templateTypes.Template): void;
    setOpenTemplateInfoDrawer(payload: boolean): void;
    setOpenTemplatePreviewModal(payload: boolean): void;
    onDeleteTemplate?(payload: string): void;
    onRestoreTemplate?(payload: string): void;
}

export default function Templates({
    templates,
    tenant,
    selectedTemplates,
    hasMoreTemplates,
    activeCollection,
    updatingTemplateIds,
    enableEditingAceTemplates,
    uploadsTenant,
    setSelectedTemplates,
    onCopyUrl,
    onRemoveTemplateFromCollection,
    loadNextTemplates,
    setCurrentTemplate,
    setOpenTemplateInfoDrawer,
    setOpenTemplatePreviewModal,
    onDeleteTemplate,
    onRestoreTemplate,
    canEdit,
}: Props) {
    const { isMobile, isTablet, isDesktopOrLaptop } = useResponsiveDesign();
    const { t } = useTranslation();

    const isSelected = (id: string) => !!selectedTemplates && selectedTemplates.some((template) => template.id === id);

    const isAllSelected = () => templates.every((template) => isSelected(template.id));

    const onAddToSelections = (templatesToAdd: templateTypes.Template[]) => {
        const newEntries = templatesToAdd.filter((entry) => !selectedTemplates?.some((template) => template.id === entry.id));
        selectedTemplates && setSelectedTemplates && setSelectedTemplates([...selectedTemplates, ...newEntries]);
    };

    const onRemoveFromSelections = (templatesToRemove: templateTypes.Template[]) => {
        selectedTemplates && setSelectedTemplates && setSelectedTemplates(selectedTemplates.filter((selectedTemplate) => !templatesToRemove.some((template) => template.id === selectedTemplate.id)));
    };

    const onSelectionChanged = (templateDetail: templateTypes.Template) => {
        if (isSelected(templateDetail.id)) {
            onRemoveFromSelections([templateDetail]);
        } else {
            onAddToSelections([templateDetail]);
        }
    };

    const selectAll = () => {
        if (isAllSelected()) {
            onRemoveFromSelections(templates);
        } else {
            onAddToSelections(templates);
        }
    };

    return (
        <InfiniteScroll
            dataLength={templates.length}
            next={loadNextTemplates}
            hasMore={hasMoreTemplates}
            scrollableTarget='infite-scrollable-container'
            loader={<h3>{t('catalog.lblLoading')}</h3>}
            className={cx('templates__infinite-scroll', { mobile: isMobile, tablet: isTablet })}
        >
            {!!templates.length
                && (
                    <table className={cx('templates__table-view table table-hover', { mobile: isMobile, tablet: isTablet })}>
                        <thead>
                            <tr>
                                {
                                    // eslint-disable-next-line jsx-a11y/control-has-associated-label
                                    templates.length > 0 && <th className={cx('template-select-checkbox')}><Checkbox className='checkbox' checked={isAllSelected()} onChange={selectAll} /></th>
                                }
                                <th className={cx('template-preview')}>{t('catalog.tableHeader.preview')}</th>
                                <th className='template-name'>{t('catalog.tableHeader.name')}</th>
                                {isDesktopOrLaptop && <th className={cx('template-description')}>{t('catalog.tableHeader.description')}</th>}
                                {isDesktopOrLaptop && <th className={cx('template-last-modified')}>{t('catalog.tableHeader.lastModified')}</th>}
                                {(isTablet || isDesktopOrLaptop) && <th className={cx('template-author')}>{t('catalog.tableHeader.author')}</th>}
                                {(isTablet || isDesktopOrLaptop) && <th className={cx('template-status')}>{t('catalog.tableHeader.status')}</th>}
                                <th className={cx('template-actions')}>{t('catalog.tableHeader.actions')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {templates.map((template) => (
                                <TemplateTableRow
                                    key={template.id}
                                    template={template}
                                    tenant={tenant}
                                    enableEditingAceTemplates={enableEditingAceTemplates}
                                    uploadsTenant={uploadsTenant}
                                    onCopyUrl={onCopyUrl}
                                    isSelected={isSelected(template.id)}
                                    onSelectionChanged={onSelectionChanged}
                                    currentFolderId={activeCollection?.id}
                                    onRemoveTemplateFromCollection={onRemoveTemplateFromCollection}
                                    isUpdating={updatingTemplateIds.includes(template.id)}
                                    canEdit={canEdit}
                                    setCurrentTemplate={setCurrentTemplate}
                                    setOpenTemplateInfoDrawer={setOpenTemplateInfoDrawer}
                                    setOpenTemplatePreviewModal={setOpenTemplatePreviewModal}
                                    onDeleteTemplate={onDeleteTemplate}
                                    onRestoreTemplate={onRestoreTemplate}
                                />
                            ))}
                        </tbody>
                    </table>
                )}
        </InfiniteScroll>
    );
}
