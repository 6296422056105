/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import SmoothCollapse from 'react-smooth-collapse';
import cx from 'classnames';
import { checkExpand } from 'utility/directory.utility';
import { directoryTypes } from '../index';
import { Directory } from '../directory/Directory';

import './directoryNode.scss';

interface Props {
    directory: directoryTypes.Directory;
    selectedDirectoryIds?: string[];
    disabledDirectoryIds?: string[];
    actions?: directoryTypes.DirectoryActions[];
    expandOnLabelClick?: boolean;
    onSelect?(directory: directoryTypes.Directory): void;
    onExpand?(directory: directoryTypes.Directory): void;
    isCollectionSaving?: boolean;
    isCollectionAddedSuccess?: boolean;
    resetIsCollectionAddedSuccess?(): void;
    levelToAddChild?: number;
    level?: number;
}

export function DirectoryNode({
    directory,
    selectedDirectoryIds,
    disabledDirectoryIds,
    onSelect,
    onExpand,
    actions,
    expandOnLabelClick,
    isCollectionSaving,
    isCollectionAddedSuccess,
    resetIsCollectionAddedSuccess,
    levelToAddChild,
    level }: Props) {
    const [expanded, setExpanded] = useState(checkExpand(selectedDirectoryIds, directory));

    const onExpandClick = () => {
        onExpand && onExpand(directory);
        setExpanded(!expanded);
    };

    const isDisabled = disabledDirectoryIds && disabledDirectoryIds.some((id) => id === directory.id);

    const handleSelect = () => {
        !isDisabled && onSelect && onSelect(directory);
        if (expandOnLabelClick) {
            onExpandClick();
        }
    };
    return (
        <div className={cx('directory', {
            selected: selectedDirectoryIds && selectedDirectoryIds.some((id) => id === directory.id),
            disabled: isDisabled,
        })}
        >
            <Directory
                expand={expanded}
                directory={directory}
                onSelect={handleSelect}
                onExpand={onExpandClick}
                actions={actions}
                isCollectionSaving={isCollectionSaving}
                isCollectionAddedSuccess={isCollectionAddedSuccess}
                resetIsCollectionAddedSuccess={resetIsCollectionAddedSuccess}
                level={level}
                levelToAddChild={levelToAddChild}
            />

            <SmoothCollapse expanded={expanded}>
                {((directory.subDirectories && directory.subDirectories.length > 0) || directory.hasSubDirectories)
                    && (
                        <div className='directory__sub-directories'>{directory.subDirectories && directory.subDirectories.map((subDirectory) => (
                            <DirectoryNode
                                key={subDirectory.id}
                                selectedDirectoryIds={selectedDirectoryIds}
                                disabledDirectoryIds={disabledDirectoryIds}
                                directory={subDirectory}
                                onSelect={onSelect}
                                onExpand={onExpand}
                                actions={actions}
                                expandOnLabelClick={expandOnLabelClick}
                                isCollectionSaving={isCollectionSaving}
                                isCollectionAddedSuccess={isCollectionAddedSuccess}
                                resetIsCollectionAddedSuccess={resetIsCollectionAddedSuccess}
                                level={level && level + 1}
                                levelToAddChild={levelToAddChild}
                            />
                        ))}
                        </div>
                    )}
            </SmoothCollapse>
        </div>
    );
}
