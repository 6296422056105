import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from 'providers/AuthContext';
import { noop } from 'utility/function.utility';
import { useTranslation } from 'react-i18next';
import { productTypes } from 'store/product';
import { KeyValue } from 'store/product/types.product';
import SkuSelector from './SkuSelector';
import { getRequiredAttributesForSurfaceSpecifications } from './requiredAttributesClient';
import GenericSelectorWrapper from './GenericSelectorWrapper';

import './productSelector.scss';

export interface Attributes {
    [key: string]: string;
}

export interface Props {
    sku?: string;
    allowIncompleteAttributeSelection: boolean;
    onValid?(product: productTypes.Product, complete: boolean): void;
    onInvalid?(): void;
    onKeyPress(event: React.KeyboardEvent<HTMLInputElement>): void;
    onValidSku?(payload: { value: string; isValid: boolean; name: string }): void;
    onInvalidSku?(payload: { value: string; isValid: boolean; name: string }): void;
    onSkuInputChange?(sku: string): void;
}

export default function ProductSelector({
    sku: externalSku,
    onValid: externalOnValid = noop,
    onInvalid: externalOnInvalid = noop,
    onKeyPress,
    onValidSku: externalOnValidSku = noop,
    onInvalidSku: externalOnInvalidSku = noop,
    onSkuInputChange,
    allowIncompleteAttributeSelection }: Props) {
    const { t } = useTranslation();
    const [currentSku, setCurrentSku] = useState({ value: externalSku || '', isValid: false, name: '' });
    const [requiredAttributes, setRequiredAttributes] = useState<undefined | string[]>();
    const [attributeDefaults, setAttributeDefaults] = useState<KeyValue<string, string>[]>([]);
    const [isResolved, setIsResolved] = useState(false);

    const { auth } = useAuth();
    const accessToken = auth.getAccessToken();

    const onValidSku = useCallback((sku: string, name: string) => {
        setCurrentSku({ value: sku, isValid: true, name });
        externalOnValidSku({ value: sku, isValid: true, name });
    }, [externalOnValidSku]);

    const onInvalidSku = useCallback((sku: string) => {
        externalOnInvalidSku({ value: sku, isValid: false, name: '' });
    }, [externalOnInvalidSku]);

    const onValidProduct = useCallback((sku: string, attributes: Attributes | undefined, name: string, complete: boolean) => {
        setIsResolved(true);
        externalOnValid({ sku, attributes, name }, complete);
    }, [externalOnValid, setIsResolved]);

    const onInvalidProduct = useCallback(() => {
        setIsResolved(false);
        externalOnInvalid();
    }, [setIsResolved, externalOnInvalid]);

    const onSkuChange = useCallback((sku: string) => {
        setCurrentSku({ value: sku, isValid: false, name: '' });
        setRequiredAttributes(undefined);
        onSkuInputChange && onSkuInputChange(sku);
    }, [onSkuInputChange]);

    const onValidAttributes = (attributes: Attributes, complete: boolean) => {
        onValidProduct(currentSku.value, attributes, currentSku.name, complete);
    };

    useEffect(() => {
        const retrieveSurfaceInformation = async () => {
            if (currentSku.isValid) {
                const surfaceInfo = await getRequiredAttributesForSurfaceSpecifications(currentSku.value);
                if (surfaceInfo && surfaceInfo.variables.length > 0) {
                    setRequiredAttributes(surfaceInfo.variables);
                    setAttributeDefaults(surfaceInfo.variableMetadata.defaults);
                } else {
                    onValidProduct(currentSku.value, undefined, currentSku.name, true);
                }
            }
        };

        retrieveSurfaceInformation();
    }, [accessToken, currentSku, onValidProduct]);

    useEffect(() => setCurrentSku({ value: externalSku || '', isValid: false, name: '' }), [externalSku]);

    const requiresAttributeSelector = currentSku.isValid && requiredAttributes && requiredAttributes.length > 0;
    const attributesRequireResolution = requiresAttributeSelector && !isResolved;
    const shouldShowProductName = currentSku && currentSku.name.length > 0;

    return (
        <div className='mcp-product-selector'>
            <SkuSelector
                sku={currentSku.value}
                onValid={onValidSku}
                onInvalid={onInvalidSku}
                onChange={onSkuChange}
                onKeyPress={onKeyPress}
            />
            {
                shouldShowProductName && (
                    <div>
                        <b>{t('create.productSelector.productName')}:</b> {currentSku.name}
                    </div>
                )
            }
            {
                attributesRequireResolution && (
                    <div className='text-danger'>
                        <span>{t('create.productSelector.attributeInfo')}:</span>
                    </div>
                )
            }
            { requiresAttributeSelector
              && requiredAttributes
                && (
                    <GenericSelectorWrapper
                        accessToken={accessToken}
                        onValid={onValidAttributes}
                        onInvalid={onInvalidProduct}
                        sku={currentSku.value}
                        defaultAttributeValues={attributeDefaults}
                        allowIncompleteAttributeSelection={allowIncompleteAttributeSelection}
                    />
                )}
        </div>
    );
}
