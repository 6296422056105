import React, { useCallback, useState } from 'react';
import { Modal } from '@cimpress/react-components';
import CreateTemplate from 'connectedComponents/createTemplate/CreateTemplate';
import { productTypes } from 'store/product';
import { CREATE_MODE } from 'utility/enums';
import { useTranslation } from 'react-i18next';
import CopyDesignActions from 'connectedComponents/createTemplate/CopyDesignActions';
import cx from 'classnames';

import './copyDesignModal.scss';

interface Props {
    docRefUrl: string;
    tags?: string[];
    onCancel(): void;
}

export default function CopyDesignModal({ docRefUrl, tags, onCancel }: Props) {
    const [currentProduct, setCurrentProduct] = useState<productTypes.Product>();
    const [sku, setSku] = useState<string>();
    const { t } = useTranslation();

    const onValidProduct = useCallback((product: productTypes.Product) => {
        setCurrentProduct(product);
    }, []);

    const onInValidProduct = useCallback(() => {
        setCurrentProduct(undefined);
    }, []);

    return (
        <Modal
            show={true}
            onRequestHide={onCancel}
            title={t('catalog.actionsDropdown.transferDesignLabel')}
            footer={(
                <CopyDesignActions product={currentProduct} sku={sku} />
            )}
            closeButton={true}
            className={cx('design-transfer-modal')}
        >
            <CreateTemplate
                isTemplateInformationOpen={true}
                onValidProduct={onValidProduct}
                onInValidProduct={onInValidProduct}
                createMode={CREATE_MODE.designTransfer}
                docRefUrl={docRefUrl}
                tags={tags}
                onSkuInputChange={setSku}
            />
        </Modal>
    );
}
