import React, { useEffect, useState } from 'react';
import { colors, Button } from '@cimpress/react-components';

import './structuredTag.scss';
import { useTranslation } from 'react-i18next';
import { StructuredTags } from 'store/uiState/types.uiState';
import IconBin from '@cimpress-technology/react-streamline-icons/lib/IconBin';
import { StructuredTagItem } from './StructuredTagItem';

interface Props {
    onStructuredTagChange: (key: StructuredTags[]) => void;
    loadedStructuredTags: StructuredTags[];
    disabled: boolean;
  }

export const StructuredTag = ({ onStructuredTagChange, loadedStructuredTags, disabled }: Props) => {
    const { t } = useTranslation();
    const { ocean } = colors;

    const [structuredTags, setStructuredTags] = useState<StructuredTags[]>([]);

    const setInitialTags = () => {
        if (loadedStructuredTags !== undefined) {
            setStructuredTags(loadedStructuredTags);
        }
    };

    useEffect(setInitialTags, [loadedStructuredTags]);

    const onAddClick = () => {
        const updatedStructuredTags = [...structuredTags];
        updatedStructuredTags.push({ category: '', allowedValues: [] });
        setStructuredTags(updatedStructuredTags);
        onStructuredTagChange(updatedStructuredTags);
    };

    const onRemoveClick = (structuredTag: StructuredTags) => {
        const updatedStructuredTags = [...structuredTags];
        const index = updatedStructuredTags.indexOf(structuredTag, 0);
        if (index > -1) {
            updatedStructuredTags.splice(index, 1);
        }
        setStructuredTags(updatedStructuredTags);
        onStructuredTagChange(updatedStructuredTags);
    };

    const onStructuredTagChanged = (oldTag: StructuredTags, newTag: StructuredTags, index: number): void => {
        const updatedStructuredTags = [...structuredTags];
        updatedStructuredTags[index] = newTag;
        setStructuredTags(updatedStructuredTags);
        onStructuredTagChange(updatedStructuredTags);
    };

    if (structuredTags.length === 0 && disabled) {
        return null;
    }
    return (
        <>
            <div className='structuredTags-root'>
                <h3>Structured Tags</h3>
                <div className='structuredTags'>
                    {structuredTags.map((structuredTag, i) => (

                        <div className='individual-structured-tag' key={i.toString()}>
                            <StructuredTagItem
                                structuredTag={structuredTag}
                                disabled={disabled}
                                onStructuredTagChanged={(newStructuredTag) => onStructuredTagChanged(structuredTag, newStructuredTag, i)}
                            />
                            <div className='remove-button-container'>
                                <Button size='sm' className='remove-button' onClick={() => onRemoveClick(structuredTag)} style={disabled ? { display: 'none' } : undefined}>
                                    <IconBin color={ocean.darker} />
                                </Button>
                            </div>

                        </div>

                    ))}

                    {!disabled && (
                        <div className='structuredTagsAdd'>
                            <Button onClick={onAddClick}>{t('common.add')}</Button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
