import React, { ChangeEvent, useState, useEffect } from 'react';
import { TextField, Tooltip, colors } from '@cimpress/react-components';

import './structuredTagItem.scss';
import { useTranslation } from 'react-i18next';
import IconInformationCircle from '@cimpress-technology/react-streamline-icons/lib/IconInformationCircle';
import { StructuredTags } from 'store/uiState/types.uiState';
import { StructuredTagsSeparator } from 'utility/constants';

interface Props {
    disabled: boolean;
    structuredTag: StructuredTags;
    onStructuredTagChanged: (structuredTag: StructuredTags) => void;
}

export const StructuredTagItem = ({ structuredTag, disabled, onStructuredTagChanged }: Props) => {
    const { t } = useTranslation();
    const { ocean } = colors;

    const [category, setCategory] = useState(structuredTag.category);
    const [allowedValues, setAllowedValues] = useState(structuredTag.allowedValues);

    useEffect(() => {
        setCategory(structuredTag.category);
        setAllowedValues(structuredTag.allowedValues);
    }, [structuredTag]);

    const onTextChange = (inputType: string, e: ChangeEvent<HTMLInputElement>) => {
        if (inputType === 'category') {
            setCategory(e.target.value);
            onStructuredTagChanged({
                allowedValues,
                category: e.target.value,
            });
        } else {
            const newValue = e.target.value.split(StructuredTagsSeparator);
            setAllowedValues(newValue);
            onStructuredTagChanged({
                category,
                allowedValues: newValue,
            });
        }
    };

    const getCategoryErrorStyle = (input: string | undefined): 'success' | 'warning' | 'error' | '' => {
        if (input === undefined || input.length === 0) {
            return 'error';
        }

        return '';
    };

    const getAllowedValuesErrorStyle = (input: string[] | undefined): 'success' | 'warning' | 'error' | '' => {
        if (input === undefined || input.length === 0) {
            return 'error';
        }

        return '';
    };

    return (
        <div className='structuredTag'>
            <div className='category'>
                <TextField
                    label={t('configuration.structuredTag.category.label')}
                    value={category}
                    required={true}
                    bsStyle={getCategoryErrorStyle(category)}
                    disabled={disabled}
                    onChange={(e) => onTextChange('category', e)}
                />
                <div className='setting-item__info'>
                    <Tooltip direction='top' contents={t('configuration.structuredTag.category.description')}>
                        <IconInformationCircle color={ocean.darker} />
                    </Tooltip>
                </div>
            </div>

            <div className='allowedValues'>
                <TextField
                    label={t('configuration.structuredTag.allowedValues.label')}
                    value={structuredTag.allowedValues.join(StructuredTagsSeparator)}
                    required={true}
                    disabled={disabled}
                    bsStyle={getAllowedValuesErrorStyle(allowedValues)}
                    onChange={(e) => onTextChange('allowedValues', e)}
                />
                <div className='setting-item__info'>
                    <Tooltip direction='top' contents={t('configuration.structuredTag.allowedValues.description')}>
                        <IconInformationCircle
                            color={ocean.darker}
                        />
                    </Tooltip>
                </div>
            </div>
        </div>
    );
};
