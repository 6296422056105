import { combineReducers } from '@reduxjs/toolkit';
import { StateType } from 'typesafe-actions';
import { RouterAction, LocationChangeAction, connectRouter } from 'connected-react-router';
import { History } from 'history';

import { tenantState } from './tenant';
import { uiState } from './uiState';
import { collectionState } from './collection';
import { templateState } from './template';
import { editorState } from './editor';
import { productState } from './product';
import { clipArtState } from './clipart';

type ReactRouterAction = RouterAction | LocationChangeAction;

const rootReducer = (history: History) => combineReducers({
    router: connectRouter(history),
    tenantState,
    templateState,
    uiState,
    collectionState,
    editorState,
    productState,
    clipArtState,
});

export type RootState = StateType<ReturnType<typeof rootReducer>>;
export type RootAction = ReactRouterAction;

// If the reducers need a wrapper (for example, for routing/history), this is where it would be done.

export default rootReducer;
