import { RootState } from 'store/rootReducer';
import { collectionTypes, collectionSelector, collectionActions } from 'store/collection';

export interface StateProps {
    activeCollection: collectionTypes.Collection | undefined;
    activeCollectionPath: {label: string; id: string}[] | undefined;
}
export interface DispatchProps {
    updateActiveCollection(payload: string): void;
}
export type AllProps = StateProps & DispatchProps;

export const getAllProps = (props: AllProps) => {
    const { activeCollection, activeCollectionPath, updateActiveCollection } = props;
    const stateProps: StateProps = { activeCollection, activeCollectionPath };
    const dispatchProps: DispatchProps = { updateActiveCollection };
    return { stateProps, dispatchProps };
};

export const mapStateToProps = (state: RootState): StateProps => ({
    activeCollection: collectionSelector.selectActiveCollection(state),
    activeCollectionPath: collectionSelector.selectActiveCollectionPath(state),
});

export const mapDispatchToProps: DispatchProps = {
    updateActiveCollection: collectionActions.updateActiveCollection,
};
