import { tenantSelector, tenantTypes } from 'store/tenant';
import { collectionTypes, collectionSelector } from 'store/collection';
import { uiStateTypes, uiStateSelector } from 'store/uiState';
import { RootState } from 'store/rootReducer';
import { templateSelector, templateTypes } from 'store/template';

export interface StateProps {
    tenant: tenantTypes.Tenant | undefined;
    filterCriteria: uiStateTypes.FilterCriteria;
    collections: collectionTypes.Collection[];
    activeCollection: collectionTypes.Collection | undefined;
    isFilterCriteraApplied: boolean;
    selectAllTemplates: templateTypes.Template[];
}

export type AllProps = StateProps;

export const getAllProps = (props: AllProps) => {
    const { tenant, filterCriteria, collections, activeCollection, isFilterCriteraApplied, selectAllTemplates } = props;
    const stateProps: StateProps = { tenant, filterCriteria, collections, activeCollection, isFilterCriteraApplied, selectAllTemplates };
    return { stateProps };
};

export const mapStateToProps = (state: RootState): StateProps => ({
    tenant: tenantSelector.selectCurrentTenant(state),
    filterCriteria: uiStateSelector.selectFilterCriteria(state),
    collections: collectionSelector.selectAllCollections(state),
    activeCollection: collectionSelector.selectActiveCollection(state),
    isFilterCriteraApplied: uiStateSelector.selectIsFilterCriteriaApplied(state),
    selectAllTemplates: templateSelector.selectAllTemplates(state),
});
