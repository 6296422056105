import { TFunction } from 'i18next';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { SVGNameSpace } from 'utility/constants';
import { getMaskColor, setElementAttributes } from 'utility/helper';

const maskLegendType = ['bleed', 'trim', 'safe'];

const drawLegend = (divRef: React.RefObject<HTMLDivElement>, t: TFunction) => {
    // eslint-disable-next-line array-callback-return
    maskLegendType.forEach((mask) => {
        // Create each legend here
        const div = document.createElement('div');
        setElementAttributes(div, { class: 'mask-legend' });

        // Create svg for each mask
        const svg = document.createElementNS(SVGNameSpace, 'svg');
        setElementAttributes(svg, { width: '25', height: '20', class: `mask-legend__${mask}-svg` });
        const rect = document.createElementNS(SVGNameSpace, 'rect');
        const strokeColor = getMaskColor(mask);
        setElementAttributes(rect, { width: '20', height: '20', stroke: strokeColor, fill: '#FFFFFF' });
        svg.appendChild(rect);
        div.appendChild(svg);

        // Create text for each svg that will indicate the mask type
        const span = document.createElement('span');
        setElementAttributes(span, { class: `mask-legend__${mask}-text` });
        const textNode = document.createTextNode(t(`create.${mask}`));
        span.appendChild(textNode);
        div.appendChild(span);

        if (divRef && divRef.current) {
            divRef.current.appendChild(div);
        }
    });

    return null;
};

export function MaskLegend() {
    const { t } = useTranslation();
    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        drawLegend(divRef, t);
    }, [t]);

    return (
        <>
            <div
                className='create__mask-legend'
                ref={divRef}
            />
        </>

    );
}
