import { axiosWithAuth } from 'interceptors/auth.interceptor';

export const verifyNecessaryAttributes = async (sku: string, attributes: Record<string, string>): Promise<boolean> => {
    const attributesString = Object.keys(attributes)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(attributes[key])}`)
        .join('&');
    const url = `https://surface.products.cimpress.io/v3/calculatedSurfaceSets/${sku}?${attributesString}`;
    try {
        const response = await axiosWithAuth.get(url);
        if (response.status === 200) {
            return true;
        }
        return false;
    } catch {
        return false;
    }
};
