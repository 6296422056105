import React, { useState, useEffect } from 'react';
import { Button, Tooltip } from '@cimpress/react-components';
import IconArrowUpAlt from '@cimpress-technology/react-streamline-icons/lib/IconArrowUpAlt';
import { useTranslation } from 'react-i18next';

import './scrollToTop.scss';

let el: HTMLElement | null;

export default function ScrollToTop() {
    const { t } = useTranslation();
    const [showNavigateToTop, setShowNavigateToTop] = useState(false);

    const setScrollTo = () => {
        el && el.scrollTop > 1200 ? setShowNavigateToTop(true) : setShowNavigateToTop(false);
    };

    useEffect(() => {
        el = document.querySelector('#infite-scrollable-container');
        el && el.addEventListener('scroll', setScrollTo);
        return () => {
            setShowNavigateToTop(false);
            el && el.removeEventListener('scroll', setScrollTo);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const scrollToTop = () => {
        el && el.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    return (
        <>
            {showNavigateToTop && (
                <div className='scroll-to-top'>
                    <Tooltip direction='top' contents={t('catalog.ttScrollToTop')}>
                        <Button className='btn btn-primary' onClick={scrollToTop}>
                            <IconArrowUpAlt weight='fill' size='lg' />
                        </Button>
                    </Tooltip>
                </div>
            )}
        </>
    );
}
