import { RootState } from 'store/rootReducer';
import { uiStateActions, uiStateSelector, uiStateTypes } from 'store/uiState';
import { editorSelector, editorActions } from 'store/editor';
import { EDITOR_MODE } from 'utility/enums';
import { productTypes, productSelector } from 'store/product';
import { templateActions } from 'store/template';
import { tenantSelector, tenantTypes } from 'store/tenant';
import { SaveDocumentPayload } from 'store/editor/types.editor';

export interface OwnProps {
    designer?: Designer;
    designerMode: string;
    surfaceSpecifications: productTypes.SurfaceSpecification;
}

export interface StateProps {
    isSaveAndPublishInProgress: boolean;
    currentProduct?: productTypes.Product;
    editorMode: EDITOR_MODE;
    tenantAssetPermissions: tenantTypes.AssetPermissions | undefined;
}

export interface DispatchProps {
    saveAndPublishTemplate(payload: SaveDocumentPayload): void;
    addNotification(payload: uiStateTypes.Notification[]): void;
}

export type AllProps = StateProps & DispatchProps & OwnProps;

export const getAllProps = (props: AllProps) => {
    const { designer,
        designerMode,
        surfaceSpecifications,
        isSaveAndPublishInProgress,
        currentProduct,
        editorMode,
        saveAndPublishTemplate,
        addNotification,
        tenantAssetPermissions } = props;
    const ownProps: OwnProps = { designer, designerMode, surfaceSpecifications };
    const stateProps: StateProps = { isSaveAndPublishInProgress, currentProduct, editorMode, tenantAssetPermissions };
    const dispatchProps: DispatchProps = { saveAndPublishTemplate, addNotification };

    return { ownProps, stateProps, dispatchProps };
};

export const mapStateToProps = (state: RootState): StateProps => ({
    isSaveAndPublishInProgress: uiStateSelector.selectIfLoading(state, editorActions.saveDocument.toString(), templateActions.saveTemplate.toString()),
    currentProduct: productSelector.selectCurrentProduct(state),
    editorMode: editorSelector.selectEditorMode(state),
    tenantAssetPermissions: tenantSelector.selectCurrentTenantAssetPermissions(state),
});

export const mapDispatchToProps: DispatchProps = {
    saveAndPublishTemplate: editorActions.saveDocument,
    addNotification: uiStateActions.addNotification,
};
