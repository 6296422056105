import { CollectionTagPrefix, SkulessTag } from 'utility/constants';
import { getAssetContentUrl } from 'utility/url.utility';
import { Asset, Template, AssetListResponse, TemplateToBeExported, VersionListResponse, TemplateVersion } from './types.template';

interface CimDoc {
    cimDocUrl: string;
    prepressInstructionsUrl: string;
    renderingInstructionsUrl: string;
}

export const mapAsset = ({ data }: {data: Asset}): Template => {
    const { referenceId } = data;
    const mappedAsset = {
        sku: referenceId,
        ...data,
    };
    return mappedAsset;
};

export const mapAssets = ({ data: assetList }: {data: AssetListResponse}): {templates: Template[]; offset: string} => ({
    templates: assetList._embedded.item.map((data: Asset) => mapAsset({ data })),
    offset: assetList.offset,
});

export const mapAssetAttributes = (data: {
        camDesignId?: string;
        processType?: string;
        skuVariables?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } | undefined): any => {
    if (data?.skuVariables) {
        let parsed;

        try {
            parsed = JSON.parse(data.skuVariables);
        } catch {
            parsed = undefined;
        }

        return { ...data, skuVariables: parsed };
    }

    return data;
};

export const mapTemplateToExport = (templates: Template[]): TemplateToBeExported[] => (
    templates.map((template: Template) => ({
        id: template.id,
        name: template.name,
        docRef: getAssetContentUrl(template.id),
        author: template.owner,
        sku: template.sku,
        attributes: mapAssetAttributes(template.attributes),
        desription: template.description,
        deleted: template.deleted,
        tags: template.tags.filter((tag) => (!tag.startsWith(CollectionTagPrefix) && !tag.startsWith(SkulessTag))),
        collection: template.collectionLabels,
        lastModified: template.lastModified,
        created: template.created,
        publishedBy: template.publishedBy,
    })));

export const mapVersions = ({ data }: { data: VersionListResponse}): TemplateVersion[] => [...data._embedded.item];

export const mapCimDoc = ({ data }: {data: CimDoc}): CimDoc => data;
