import { RootState } from 'store/rootReducer';
import { templateActions, templateTypes, templateSelector } from 'store/template';
import { tenantSelector, tenantTypes } from 'store/tenant';
import { uiStateActions, uiStateSelector, uiStateTypes } from 'store/uiState';
import { StructuredTags } from 'store/uiState/types.uiState';

export interface OwnProps {
    open: boolean;
    setOpen(payload: boolean): void;
    setOpenTemplatePreviewModal(payload: boolean): void;
}
export interface StateProps {
    template?: templateTypes.Template;
    hasMandatoryDesignerSettings: boolean;
    tenant?: tenantTypes.Tenant;
    enableEditingAceTemplates: boolean;
    structuredTags: StructuredTags[] | undefined;
    uploadsTenant: string | undefined;
}
export interface DispatchProps {
    resetCurrentTemplate(): void;
    editTemplateInformation(payload: templateTypes.AssetToBeEdited): void;
    addNotification(payload: uiStateTypes.Notification[]): void;
    onDeleteTemplate(payload: string): void;
    onRestoreTemplate(payload: string): void;

}
export type AllProps = OwnProps & StateProps & DispatchProps;

export const getAllProps = (props: AllProps) => {
    const { open,
        setOpen,
        setOpenTemplatePreviewModal,
        template,
        hasMandatoryDesignerSettings,
        tenant,
        enableEditingAceTemplates,
        structuredTags,
        uploadsTenant,
        resetCurrentTemplate,
        editTemplateInformation,
        addNotification,
        onDeleteTemplate,
        onRestoreTemplate } = props;

    const ownProps: OwnProps = { open, setOpen, setOpenTemplatePreviewModal };
    const stateProps: StateProps = { template, hasMandatoryDesignerSettings, tenant, enableEditingAceTemplates, structuredTags, uploadsTenant };
    const dispatchProps: DispatchProps = { resetCurrentTemplate,
        editTemplateInformation,
        addNotification,
        onDeleteTemplate,
        onRestoreTemplate };
    return { ownProps, stateProps, dispatchProps };
};

export const mapStateToProps = (state: RootState): StateProps => ({
    template: templateSelector.selectCurrentTemplate(state),
    hasMandatoryDesignerSettings: uiStateSelector.selectHasMandatoryDesignerSettings(state),
    tenant: tenantSelector.selectCurrentTenant(state),
    enableEditingAceTemplates: uiStateSelector.selectAceTemplateEdit(state),
    structuredTags: state.uiState.settings?.structuredTags,
    uploadsTenant: state.uiState.settings?.uploadTenant,
});
export const mapDispatchToProps: DispatchProps = {
    resetCurrentTemplate: templateActions.resetCurrentTemplate,
    editTemplateInformation: templateActions.editTemplateInformation,
    addNotification: uiStateActions.addNotification,
    onDeleteTemplate: templateActions.onDeleteTemplate,
    onRestoreTemplate: templateActions.onRestoreTemplate,
};
