/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { Button } from '@cimpress/react-components';
import IconAdd from '@cimpress-technology/react-streamline-icons/lib/IconAdd';
import IconFolderImage from '@cimpress-technology/react-streamline-icons/lib/IconFolderImage';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { collectionTypes, collectionUtils } from 'store/collection';
import { DirectoryList } from 'shared/directory/directoryList/DirectoryList';
import { mapCollectionToDirectory } from 'store/collection/collectionUtils';
import { DIRECTORY_ALLOWED_ACTIONS } from 'utility/enums';
import IconBin from '@cimpress-technology/react-streamline-icons/lib/IconBin';
import IconPencilAlt from '@cimpress-technology/react-streamline-icons/lib/IconPencilAlt';
import { directoryTypes } from 'shared/directory';
import { tenantTypes } from 'store/tenant';
import { uiStateTypes } from 'store/uiState';
import CopyCollectionTree from 'components/copyCollectionTree/CopyCollectionTree';
import NewCollectionInput from './NewCollectionInput';
import './collectionList.scss';

interface Props {
    collections: collectionTypes.Collection[];
    currentCollectionId?: string;
    levelToAddChild?: number;
    resetButtonText?: string;
    addButtonText?: string;
    isCollectionSaving?: boolean;
    isCollectionAddedSuccess?: boolean;
    tenant: tenantTypes.Tenant | undefined;
    resetIsCollectionAddedSuccess?(): void;
    onChangeSelection?(newCollection: collectionTypes.Collection | undefined): void;
    onAddOrEditCollection?(label: string, parentId?: string, id?: string): void;
    onExpand(collectionExpanded: collectionTypes.Collection): void;
    resetCollectionSelection?(): void;
    onDeleteCollection?(Collection: collectionTypes.Collection): void;
    addNotification(payload: uiStateTypes.Notification[]): void;
}

const tenantsForCopyCollectionTree = ['pixart', 'SceneTestGroup', 'DesignAssetsTestMerchant'];

export default function CollectionList({
    collections,
    currentCollectionId,
    resetButtonText,
    addButtonText,
    isCollectionSaving,
    isCollectionAddedSuccess,
    levelToAddChild,
    tenant,
    onChangeSelection,
    onAddOrEditCollection,
    onExpand,
    resetCollectionSelection,
    onDeleteCollection,
    resetIsCollectionAddedSuccess,
    addNotification }: Props) {
    const { t } = useTranslation();
    const [selectedCollection, setselectedCollection] = useState<collectionTypes.Collection | undefined>();
    const [addNewCollection, setAddNewCollection] = useState(false);

    const addOrEdit = (label: string, id: string, actionType: DIRECTORY_ALLOWED_ACTIONS) => {
        const collection = collectionUtils.findCollectionById(collections, id);
        if (collection && onAddOrEditCollection) {
            actionType === DIRECTORY_ALLOWED_ACTIONS.Add ? onAddOrEditCollection(label, id) : onAddOrEditCollection(label, collection.parentId, id);
        }
    };
    const onCollectionClick = (collection: collectionTypes.Collection) => setselectedCollection(collection);

    useEffect(() => {
        onChangeSelection && onChangeSelection(selectedCollection);
    }, [onChangeSelection, selectedCollection]);

    useEffect(() => {
        const currentCollection = collectionUtils.findCollectionById(collections, currentCollectionId);
        if (!selectedCollection || (selectedCollection && currentCollectionId !== selectedCollection.id)) {
            setselectedCollection(currentCollection);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collections, currentCollectionId]);

    const addCollection = (newCollectionName: string) => onAddOrEditCollection && onAddOrEditCollection(newCollectionName);

    const handleAddCancel = () => setAddNewCollection(false);

    const isDuplicateName = (label: string, parentId?: string) => {
        const parent = parentId && collectionUtils.findCollectionById(collections, parentId);
        if (parent) {
            return !!parent.children && parent.children.some((c) => c.label === label);
        }
        return collections.some((c) => c.label === label);
    };
    const validateInput = (inputText: string, parentId?: string): string | null => {
        let errorMessage = null;
        if (isDuplicateName(inputText, parentId)) {
            errorMessage = t('collection.duplicateErrorMessage');
        } else if (inputText === '') {
            errorMessage = '';
        }
        return errorMessage;
    };
    const validateInputAdd = (inputText: string, directory?: directoryTypes.Directory): string | null => (
        directory ? validateInput(inputText, directory.id) : validateInput(inputText)
    );

    const validateInputEdit = (inputText: string, directory?: directoryTypes.Directory): string | null => (
        directory && directory.parentId ? validateInput(inputText, directory.parentId) : validateInput(inputText)
    );

    const collectionHasNoChildren = (directory: directoryTypes.Directory): boolean => !!directory.hasSubDirectories;

    const withCollection = (
        <>
            <div className='collections-list__actions'>
                <div className={cx('cursor-pointer', { B: !currentCollectionId })} onClick={() => resetCollectionSelection && resetCollectionSelection()}>
                    <span>{resetButtonText}</span>
                    {tenant && tenantsForCopyCollectionTree.includes(tenant.tenantId) && (
                        <span style={{
                            marginLeft: '10px',
                        }}
                        ><CopyCollectionTree tenant={tenant} addNotification={addNotification} />
                        </span>
                    )}
                </div>
                <div className='collections-list__actions-add text-primary cursor-pointer' onClick={() => setAddNewCollection(true)}>
                    <IconAdd size='md' />
                    {addButtonText}
                </div>
            </div>
            {addNewCollection && (
                <div className='root-new-collection'>
                    <NewCollectionInput
                        value=''
                        onAdd={addCollection}
                        onCancel={handleAddCancel}
                        validateInput={validateInputAdd}
                        isCollectionSaving={isCollectionSaving}
                        isCollectionAddedSuccess={isCollectionAddedSuccess}
                        resetIsCollectionAddedSuccess={resetIsCollectionAddedSuccess}
                    />
                </div>
            )}
            <div className='collections-list__tree'>
                <DirectoryList
                    directories={mapCollectionToDirectory(collections)}
                    selectedDirectoryIds={selectedCollection && [selectedCollection.id]}
                    onSelect={onCollectionClick}
                    onExpand={onExpand}
                    expandOnLabelClick={false}
                    isCollectionSaving={isCollectionSaving}
                    isCollectionAddedSuccess={isCollectionAddedSuccess}
                    resetIsCollectionAddedSuccess={resetIsCollectionAddedSuccess}
                    levelToAddChild={levelToAddChild}
                    actions={[
                        {
                            icon: <IconPencilAlt weight='fill' size='md' />,
                            label: DIRECTORY_ALLOWED_ACTIONS.Edit,
                            onSave: addOrEdit,
                            validateInput: validateInputEdit,
                        }, {
                            icon: <IconAdd weight='fill' size='md' />,
                            label: DIRECTORY_ALLOWED_ACTIONS.Add,
                            onSave: addOrEdit,
                            validateInput: validateInputAdd,
                        }, {
                            icon: <IconBin weight='fill' size='md' />,
                            onClick: onDeleteCollection,
                            disabledContent: t('collection.ttDeleteCollection'),
                            disabledIcon: <IconBin weight='fill' size='md' color='lightgrey' />,
                            isDisabled: collectionHasNoChildren,
                        },

                    ]}
                />
            </div>
        </>
    );

    const withoutCollection = (
        <>
            <div className='collections-list__actions'>
                <div className='B'>{resetButtonText}</div>
                <div className='collections-list__actions-add disabled-gray'>
                    <IconAdd size='md' />
                    {addButtonText}
                </div>
            </div>
            <div className='no-collection__body'>
                <IconFolderImage size='5x' />
                <p className='no-collection__text'>
                    Organize your templates in folders called 'Collections' to help you manage your work
                </p>
                <div id='joy-ride__new-collection-btn'>
                    <Button className='no-collection__btnAdd' type='primary' onClick={() => setAddNewCollection(true)}>
                        {addButtonText}
                    </Button>
                </div>
                {addNewCollection && (
                    <div>
                        <NewCollectionInput
                            value=''
                            onAdd={addCollection}
                            onCancel={handleAddCancel}
                            validateInput={validateInputAdd}
                            isCollectionSaving={isCollectionSaving}
                            isCollectionAddedSuccess={isCollectionAddedSuccess}
                            resetIsCollectionAddedSuccess={resetIsCollectionAddedSuccess}
                        />
                    </div>
                )}
            </div>

        </>
    );
    return (
        <div className={cx('collections-list', { 'no-collection': collections && collections.length === 0 })}>
            { collections && (collections.length > 0 ? withCollection : withoutCollection)}
        </div>
    );
}
