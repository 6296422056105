import { RootState } from 'store/rootReducer';
import { tenantSelector } from 'store/tenant';

export interface StateProps {
    hasCoamAccess: boolean;
}

export type AllProps = StateProps;

export const getAllProps = (props: AllProps) => {
    const { hasCoamAccess } = props;
    const stateProps: StateProps = { hasCoamAccess };

    return { stateProps };
};

export const mapStateToProps = (state: RootState): StateProps => ({
    hasCoamAccess: tenantSelector.selectHasCoamAccess(state),
});
