import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SupportedLanguages } from 'utility/constants';
import { uiStateTypes } from 'store/uiState/index';
import tenantSlice from 'store/tenant/slice.tenant';
import { TenantSettingsValidationErrors } from './types.uiState';

export const defaultFilterCriteria = {
    search: undefined,
    includeDeleted: true,
    includeOwnTemplates: false,
    includeSkulessTemplates: false,
};

export const INITIAL_STATE: uiStateTypes.UIState = {
    loaders: [],
    filterCriteria: defaultFilterCriteria,
    notifications: [],
    showManualLoader: false,
    displayOverlay: false,
    userPreference: {
        filter: {
            includeDeleted: true,
            includeOwnTemplates: false,
        },
        currentLocale: SupportedLanguages.find((l) => l.default)?.code || 'en-US',
    },
    isSettingsLoaded: false,
    settingsValidationError: {},
    hydratorActions: [],
};

const slice = createSlice({
    name: 'uiState',
    initialState: INITIAL_STATE,
    reducers: {
        addLoader: (state, { payload }: PayloadAction<uiStateTypes.Loader[]>) => {
            state.loaders = [...state.loaders, ...payload];
        },
        removeLoader: (state, { payload }: PayloadAction<{id: string}>) => {
            state.loaders = state.loaders.filter((loader) => loader.id !== payload.id);
        },
        addHydrator: (state, { payload }: PayloadAction<string>) => {
            state.hydratorActions = [...state.hydratorActions, payload];
        },
        setManualLoader: (state) => {
            state.showManualLoader = true;
        },
        resetManualLoader: (state) => {
            state.showManualLoader = false;
        },
        addNotification: (state, { payload }: PayloadAction<uiStateTypes.Notification[]>) => {
            state.notifications = [...state.notifications, ...payload];
        },
        removeNotification: (state, { payload }: PayloadAction<{id: string}>) => {
            state.notifications = state.notifications.filter((notification) => notification.id !== payload.id);
        },
        setShowDeletedTemplates: (state, { payload }: PayloadAction<boolean>) => {
            state.filterCriteria.includeDeleted = payload;
        },
        setShowOwnTemplates: (state, { payload }: PayloadAction<boolean>) => {
            state.filterCriteria.includeOwnTemplates = payload;
        },
        setShowSkulessTemplates: (state, { payload }: PayloadAction<boolean>) => {
            state.filterCriteria.includeSkulessTemplates = payload;
        },
        setSearch: (state, { payload }: PayloadAction<uiStateTypes.Search>) => {
            state.filterCriteria.search = payload;
        },
        resetSearch: (state) => {
            state.filterCriteria.search = undefined;
        },
        setDisplayOverlay: (state) => {
            state.displayOverlay = true;
        },
        resetDisplayOverlay: (state) => {
            state.displayOverlay = false;
        },

        loadUserPreference: (state) => {},
        loadUserPreferenceSuccess: (state, { payload }: PayloadAction<uiStateTypes.UserPreference>) => {
            state.userPreference = { ...state.userPreference, ...payload };
        },
        loadUserPreferenceFailure: (state) => {},

        setUserPreference: (state, { payload }: PayloadAction<uiStateTypes.UserPreference>) => {},
        setUserPreferenceSuccess: (state, { payload }: PayloadAction<uiStateTypes.UserPreference>) => {
            state.userPreference = { ...state.userPreference, ...payload };
        },
        setUserPreferenceFailure: (state) => {},

        loadTenantSettings: (state) => {
            state.isSettingsLoaded = false;
        },
        loadTenantSettingsSuccess: (state, { payload }: PayloadAction<uiStateTypes.DesignerApiSettings | undefined>) => {
            state.settings = payload;
            state.isSettingsLoaded = true;
        },
        loadTenantSettingsFailure: (state) => {
            state.isSettingsLoaded = true;
        },

        setTenantSettings: (state, { payload }: PayloadAction<uiStateTypes.DesignerApiSettings>) => {},
        setTenantSettingsSuccess: (state, { payload }: PayloadAction<uiStateTypes.DesignerApiSettings>) => {
            state.settings = payload;
            state.settingsValidationError = {};
        },
        setTenantSettingsFailure: (state, { payload }: PayloadAction<TenantSettingsValidationErrors>) => {
            state.settingsValidationError = payload;
        },

        setClearCurrentSkuForDesignTransfer: (state) => {
            state.isClearCurrentSkuForDesignTransfer = true;
        },
        clearCurrentSkuForDesignTransfer: (state) => {
            state.isClearCurrentSkuForDesignTransfer = false;
        },
    },
    extraReducers: {
        '@@router/LOCATION_CHANGE': (state) => {
            state.filterCriteria.search = undefined;
        },
    },
});

export const hydrationActionTypes = [
    slice.actions.loadUserPreference,
    slice.actions.loadTenantSettings,
    tenantSlice.actions.fetchTenant,
];

export const hydrationStopActionTypes = [
    slice.actions.loadUserPreferenceSuccess,
    slice.actions.loadUserPreferenceFailure,
    slice.actions.loadTenantSettingsSuccess,
    slice.actions.loadTenantSettingsFailure,
    tenantSlice.actions.fetchTenantSuccess,
    tenantSlice.actions.fetchTenantFailure,
];

export default slice;
