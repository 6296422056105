import { RootState } from 'store/rootReducer';
import { productTypes } from './index';

export const selectCurrentProduct = (state: RootState): productTypes.Product | undefined => state.productState.product;
export const selectCurrentSurfaceSpecifications = (state: RootState): productTypes.SurfaceSpecification | undefined => state.productState.surfaceSpecifications;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const selectCalculatedSurfaceSets = (state: RootState): any => state.productState.calculatedSurfaceSet;

export default {
    selectCurrentProduct,
    selectCurrentSurfaceSpecifications,
    selectCalculatedSurfaceSets,
};
