import { tenantTypes, tenantActions, tenantSelector } from 'store/tenant';
import { RootState } from 'store/rootReducer';
import { uiStateActions, uiStateTypes, uiStateSelector } from 'store/uiState';
import { History, Location } from 'history';

export interface OwnProps {
    history: History ;
    location: Location;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    match: any;
}

export interface StateProps {
    currentTenant?: tenantTypes.Tenant;
    isTenantLoading: boolean;
    isCustomerDesignerMode: boolean;
}

export interface DispatchProps {
    setDisplayOverlay(): void;
    setUserPreference(payload: uiStateTypes.UserPreference): void;
}

export type AllProps = OwnProps & StateProps & DispatchProps;

export const getAllProps = (props: AllProps) => {
    const { history, location, match, currentTenant, isTenantLoading, isCustomerDesignerMode, setDisplayOverlay, setUserPreference } = props;
    const ownProps: OwnProps = { history, location, match };
    const stateProps: StateProps = { currentTenant, isTenantLoading, isCustomerDesignerMode };
    const dispatchProps: DispatchProps = { setDisplayOverlay, setUserPreference };

    return { ownProps, stateProps, dispatchProps };
};

export const mapStateToProps = (state: RootState): StateProps => ({
    currentTenant: tenantSelector.selectCurrentTenant(state),
    isTenantLoading: uiStateSelector.selectIfLoading(state, tenantActions.fetchTenant.toString()),
    isCustomerDesignerMode: uiStateSelector.selectIsCustomerDesignerMode(state),
});

export const mapDispatchToProps: DispatchProps = {
    setDisplayOverlay: uiStateActions.setDisplayOverlay,
    setUserPreference: uiStateActions.setUserPreference,
};
