import React, { useState, ChangeEvent, useEffect } from 'react';
import { TextField, Tooltip } from '@cimpress/react-components';
import { useTranslation } from 'react-i18next';
import { SurfaceSpecification } from 'store/product/types.product';
import { BS_STYLE, SKULESS_TEMPLATE_SHAPE_TYPE } from 'utility/enums';

interface Props {
    onValid?(payload: SurfaceSpecification): void;
    onInValid?(): void;
}

const MAX_SURFACE_COUNT = 20;
const getMaxSafe = (width: number | undefined, height: number | undefined): number => (height && width ? parseFloat((width > height ? (height / 4) - 0.5 : (width / 4) - 0.5).toFixed(3)) : 0);
const getMaxRadius = (width: number | undefined, height: number | undefined): number => (height && width ? Math.min(width, height) / 2 : 0);

function SurfaceSelector({ onValid, onInValid }: Props) {
    const { t } = useTranslation();
    const [surfaceSpecifications, setSurfaceSpecifications] = useState<SurfaceSpecification>({
        widthInMm: 210,
        heightInMm: 297,
        bleed: 0,
        safe: 0,
        radius: 0,
        pageCount: 1,
        shapeType: SKULESS_TEMPLATE_SHAPE_TYPE.Rectangle,
    });

    const setSurface = (key?: string, value?: number | string) => {
        setSurfaceSpecifications({ ...surfaceSpecifications, ...(key && { [key]: value }) });
    };

    const onWidthChange = (e: ChangeEvent<HTMLInputElement>) => {
        const width = e.currentTarget.value && parseFloat(e.currentTarget.value);
        if (width > 0) {
            setSurface('widthInMm', width);
        } else {
            setSurface('widthInMm', undefined);
        }
    };

    const onHeightChange = (e: ChangeEvent<HTMLInputElement>) => {
        const height = e.currentTarget.value && parseFloat(e.currentTarget.value);
        if (height > 0) {
            setSurface('heightInMm', height);
        } else {
            setSurface('heightInMm', undefined);
        }
    };

    const onBleedChange = (e: ChangeEvent<HTMLInputElement>) => {
        const bleed = e.currentTarget.value && parseFloat(e.currentTarget.value);
        setSurface('bleed', bleed);
    };

    const onSafeChange = (e: ChangeEvent<HTMLInputElement>) => {
        const safe = e.currentTarget.value && parseFloat(e.currentTarget.value);
        setSurface('safe', safe);
    };

    const onRadiusChange = (e: ChangeEvent<HTMLInputElement>) => {
        const radius = e.currentTarget.value && parseFloat(e.currentTarget.value);
        setSurface('radius', radius);
    };

    const onCountChange = (e: ChangeEvent<HTMLInputElement>) => {
        const count = e.currentTarget.value && parseInt(e.currentTarget.value, 10);
        setSurface('pageCount', count);
    };

    useEffect(() => {
        const { widthInMm, heightInMm, safe, radius, pageCount } = surfaceSpecifications;
        if (widthInMm
            && heightInMm
            && (!safe || (safe && safe <= getMaxSafe(widthInMm, heightInMm)))
            && (!radius || (radius && radius <= getMaxRadius(widthInMm, heightInMm)))
            && (pageCount && (pageCount >= 1 && pageCount <= MAX_SURFACE_COUNT))) {
            onValid && onValid(surfaceSpecifications);
        } else {
            onInValid && onInValid();
        }
    }, [onInValid, onValid, surfaceSpecifications]);

    return (

        <>
            <div className='surface-selector-dimensions'>
                <Tooltip
                    style={{ display: 'block' }}
                    direction='right'
                    contents={t('create.surface.ttMinValue_Info', { min: 0 })}
                >
                    <TextField
                        type='number'
                        value={surfaceSpecifications.widthInMm}
                        label={`${t('create.surface.width')} (mm)`}
                        onChange={onWidthChange}
                        required={true}
                        step='0.01'
                        min='0'
                        bsStyle={surfaceSpecifications.widthInMm && surfaceSpecifications.widthInMm > 0 ? BS_STYLE.none : BS_STYLE.error}
                    />
                </Tooltip>
                <Tooltip
                    style={{ display: 'block' }}
                    direction='right'
                    contents={t('create.surface.ttMinValue_Info', { min: 0 })}
                >
                    <TextField
                        type='number'
                        value={surfaceSpecifications.heightInMm}
                        label={`${t('create.surface.height')} (mm)`}
                        onChange={onHeightChange}
                        required={true}
                        step='0.01'
                        min='0'
                        bsStyle={surfaceSpecifications.heightInMm && surfaceSpecifications.heightInMm > 0 ? BS_STYLE.none : BS_STYLE.error}
                    />
                </Tooltip>
            </div>
            <TextField
                type='number'
                value={surfaceSpecifications.bleed}
                label={`${t('create.surface.bleed')} (mm)`}
                onChange={onBleedChange}
                min='0'
                step='0.01'
            />
            <Tooltip
                style={{ display: 'block' }}
                direction='right'
                contents={t('create.surface.ttRange_Info', { min: 0, max: getMaxSafe(surfaceSpecifications.widthInMm, surfaceSpecifications.heightInMm) })}
            >
                <TextField
                    type='number'
                    value={surfaceSpecifications.safe}
                    label={`${t('create.surface.safe')} (mm)`}
                    onChange={onSafeChange}
                    min='0'
                    max={getMaxSafe(surfaceSpecifications.widthInMm, surfaceSpecifications.heightInMm)}
                    step='0.01'
                    bsStyle={surfaceSpecifications.safe
                        && surfaceSpecifications.safe
                        > getMaxSafe(surfaceSpecifications.widthInMm, surfaceSpecifications.heightInMm) ? BS_STYLE.error : BS_STYLE.none}
                />
            </Tooltip>
            <Tooltip
                style={{ display: 'block' }}
                direction='right'
                contents={t('create.surface.ttRange_Info', { min: 0, max: getMaxRadius(surfaceSpecifications.widthInMm, surfaceSpecifications.heightInMm) })}
            >
                <TextField
                    type='number'
                    value={surfaceSpecifications.radius}
                    label={t('create.surface.radius')}
                    onChange={onRadiusChange}
                    min='0'
                    max={getMaxRadius(surfaceSpecifications.widthInMm, surfaceSpecifications.heightInMm)}
                    step='1'
                    bsStyle={surfaceSpecifications.radius
                        && surfaceSpecifications.radius
                        > getMaxRadius(surfaceSpecifications.widthInMm, surfaceSpecifications.heightInMm) ? BS_STYLE.error : BS_STYLE.none}
                />
            </Tooltip>
            <Tooltip
                style={{ display: 'block' }}
                direction='right'
                contents={t('create.surface.ttRange_Info', { min: 1, max: MAX_SURFACE_COUNT })}
            >
                <TextField
                    type='number'
                    value={surfaceSpecifications.pageCount}
                    label={t('create.surface.surfaceCount')}
                    onChange={onCountChange}
                    min='1'
                    max={MAX_SURFACE_COUNT}
                    step='1'
                    bsStyle={(surfaceSpecifications.pageCount
                        && (surfaceSpecifications.pageCount < 1 || surfaceSpecifications.pageCount > MAX_SURFACE_COUNT)) ? BS_STYLE.error : BS_STYLE.none}
                />
            </Tooltip>
        </>
    );
}

export default SurfaceSelector;
