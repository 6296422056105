/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { Drawer } from '@cimpress/react-components';
import { FeedbackSticker } from '@cimpress-technology/react-feedback';
import IconAdd from '@cimpress-technology/react-streamline-icons/lib/IconAdd';

import { useResponsiveDesign } from 'providers/ResponsiveDesign';
import CollectionBreadCrumbs from 'connectedComponents/collectionBreadCrumbs/CollectionBreadCrumbs';
import CollectionWrapper from 'connectedComponents/collectionWrapper/CollectionWrapper';
import ScrolltoTop from 'components/scrollToTop/ScrollToTop';

import './catalog.scss';
import ExportTemplate from 'connectedComponents/exportTemplate/ExportTemplate';
import Filter from 'connectedComponents/filter/Filter';
import SearchTemplate from 'connectedComponents/searchTemplate/SearchTemplate';
import BulkTemplateActions from 'connectedComponents/bulkTemplateActions/BulkTemplateActions';
import { templateTypes } from 'store/template';
import TemplatesWrapper from 'connectedComponents/templatesWrapper/TemplatesWrapper';
import EmptyCatalogMessage from 'connectedComponents/emptyCatalogMessage/EmptyCatalogMessage';
import Overlay from 'shared/overlay/Overlay';
import TemplateInfoDrawer from 'connectedComponents/templateInfoDrawer/TemplateInfoDrawer';
import TemplatePreviewModal from 'connectedComponents/templatePreviewModal/TemplatePreviewModal';
import { useAuth } from 'providers/AuthContext';
import { FeedbackCampaignId, FeedbackQuestionId } from '../../utility/constants';

export default function CatalogNew() {
    const [showCollectionDrawer, setShowCollectionDrawer] = useState(false);
    const { isMobile, isTablet, isTabletOrMobile, isDesktopOrLaptop } = useResponsiveDesign();
    const [selectedTemplates, setSelectedTemplates] = useState<templateTypes.Template[]>([]);
    const [openTemplateInfoDrawer, setOpenTemplateInfoDrawer] = useState(false);
    const [openTemplatePreviewModal, setOpenTemplatePreviewModal] = useState(false);
    const { t } = useTranslation();
    const { auth } = useAuth();

    const onRemoveFromSelections = (templatesToRemove: templateTypes.Template[]) => {
        setSelectedTemplates(selectedTemplates.filter((selectedTemplate) => !templatesToRemove.some((template) => template.id === selectedTemplate.id)));
    };

    const removeAllSelectedTemplates = () => {
        onRemoveFromSelections(selectedTemplates);
    };

    const getHeaderActionsLabel = () => {
        let importTemplate;
        let createNewTemplate;
        if (isMobile) {
            importTemplate = t('catalog.actions.importTemplate_md');
            createNewTemplate = t('catalog.actions.createNewTemplate_sm');
        } else if (isTablet) {
            importTemplate = t('catalog.actions.importTemplate_md');
            createNewTemplate = t('catalog.actions.createNewTemplate_md');
        } else if (isDesktopOrLaptop) {
            importTemplate = t('catalog.actions.importTemplate_lg');
            createNewTemplate = t('catalog.actions.createNewTemplate_lg');
        }
        return { importTemplate, createNewTemplate };
    };

    useEffect(() => {
        if (isDesktopOrLaptop) {
            setShowCollectionDrawer(false);
        }
    }, [isDesktopOrLaptop]);

    const onClickBrowseCollections = () => setShowCollectionDrawer(true);

    return (
        <>
            <Overlay routeName='catalog-new' />
            <TemplateInfoDrawer
                open={openTemplateInfoDrawer && !openTemplatePreviewModal}
                setOpen={setOpenTemplateInfoDrawer}
                setOpenTemplatePreviewModal={setOpenTemplatePreviewModal}
            />
            <TemplatePreviewModal
                open={openTemplatePreviewModal}
                setOpen={setOpenTemplatePreviewModal}
            />

            <div className={cx('catalog-new', { 'catalog-new-mobile': isMobile })}>
                <FeedbackSticker
                    title={t('catalog.feedbackTitle')}
                    accessToken={auth.getAccessToken()}
                    campaignId={FeedbackCampaignId}
                    questionId={FeedbackQuestionId}
                />
                <div className={cx('side-panel', { hide: isTabletOrMobile })}>
                    <CollectionWrapper />
                </div>
                {isTabletOrMobile && (
                    <Drawer
                        position='left'
                        autoResize={isMobile}
                        size={isMobile ? 1 : 400}
                        show={showCollectionDrawer}
                        onRequestHide={() => setShowCollectionDrawer(false)}
                        header={t('catalog.browseCollections')}
                        closeOnClickOutside={true}
                        className='collection-drawer'
                    >
                        <CollectionWrapper />
                    </Drawer>
                )}
                <div className={cx('catalog__body', { 'catalog__body-mobile': isMobile, 'catalog__body-tablet': isTablet })}>
                    <div className={cx('catalog__header-container debug1')}>
                        {isTabletOrMobile
                            && (
                                <div className={cx('catalog__header-container-browse-collections text-primary cursor-pointer')} onClick={onClickBrowseCollections}>
                                    {t('catalog.browseCollections')}
                                </div>
                            )}
                        <div className={cx('catalog__header')}>
                            <CollectionBreadCrumbs />
                            <div className={cx('catalog__header-actions')}>
                                <Link className='btn btn-outline-secondary' to='/import'>
                                    <IconAdd size='sm' /> {getHeaderActionsLabel().importTemplate}
                                </Link>
                                <Link className='btn btn-primary' to='/create'>
                                    <IconAdd size='sm' /> {getHeaderActionsLabel().createNewTemplate}
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div id='infite-scrollable-container' className={cx('catalog__main-panel', { mobile: isMobile })}>
                        <SearchTemplate />
                        <div className={cx('catalog__main-panel--actions-container')}>
                            <div className='catalog__main-panel-actions'>
                                <Filter />
                                <ExportTemplate />
                            </div>
                            <BulkTemplateActions
                                selectedTemplates={selectedTemplates}
                                setSelectedTemplates={setSelectedTemplates}
                                removeAllSelectedTemplates={removeAllSelectedTemplates}
                            />
                        </div>
                        <TemplatesWrapper
                            selectedTemplates={selectedTemplates}
                            setSelectedTemplates={setSelectedTemplates}
                            setOpenTemplateInfoDrawer={setOpenTemplateInfoDrawer}
                            setOpenTemplatePreviewModal={setOpenTemplatePreviewModal}

                        />
                        <EmptyCatalogMessage />
                    </div>
                    <ScrolltoTop />
                </div>
            </div>
        </>
    );
}
