import { all, takeEvery, select, call, put, takeLatest, fork, StrictEffect } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { PayloadAction } from '@reduxjs/toolkit';
import { SNACKBAR_NOTIFICATION_TYPES, EDITOR_MODE } from 'utility/enums';
import { uiStateActions, formatNotification, uiStateSelector } from 'store/uiState';
import { tenantSelector, tenantTypes } from 'store/tenant';
import { createPrintPdf } from 'services/prePress.service';
import { downloadFile } from 'utility/downloadFile.utility';
import { collectionSelector, collectionActions, collectionTypes } from 'store/collection';
import { getCollectionIdsFromTemplates } from 'utility/helper';
import { RecurringExportTemplatePageSize, CollectionTagPrefix, RecentTemplatesPageSize, LocalStorage, SkulessTag } from 'utility/constants';
import { editorSelector } from 'store/editor';
import { logError } from 'loggingManager';
import { saveConfig } from 'utility/storage.utility';
import { auth, getEmailIdentifier } from 'providers/AuthContext';
import { productActions, productTypes } from 'store/product';
import { getAssetContentUrl } from 'utility/url.utility';
import { extractCimDocManufacturingProcessType, extractCimDocManufacturingSkuVariables } from 'utility/processType.utility';
import { Collection } from 'store/collection/types.collection';
import { FilterCriteria, StructuredTags } from 'store/uiState/types.uiState';
import { getDisallowedTags } from 'utility/tags.utility';
import { formatQueryParams, handleCollectionTag, getEffectiveOwner, handleBulkDelete, handleBulkRestore } from './saga.template.util';
import { templateActions, templateTypes, templateSelector } from './index';
import {
    queryAssets,
    getRevisionsByAssetId,
    queryAsset,
    publishRevision,
    deleteAsset,
    restoreAsset,
    fetchAllTemplates,
    addAsset,
    addRevision,
    editAsset,
    getCimDocData,
    saveCimDocToUds,
    getRevision,
} from './service.template';
import { QueryAssetsResponse, EditTemplatePayload, SaveTemplatePayload } from './types.template';

export function* onLoadTemplates() {
    const activeCollection: Collection | undefined = yield select(collectionSelector.selectActiveCollection);
    const filterCriteria: FilterCriteria = yield select(uiStateSelector.selectFilterCriteria);
    const tenant: tenantTypes.Tenant | undefined = yield select(tenantSelector.selectCurrentTenant);

    if (tenant === undefined) {
        yield put(uiStateActions.addNotification([formatNotification(SNACKBAR_NOTIFICATION_TYPES.Danger, { key: 'notifications.danger.generic' }, 0)]));
        yield put(templateActions.loadTemplatesFailure());
        return;
    }

    const assetQueryParams = formatQueryParams(tenant, filterCriteria, activeCollection) as templateTypes.AssetQueryParams;
    try {
        const response: QueryAssetsResponse = yield call(queryAssets, assetQueryParams);
        const collectionIds = getCollectionIdsFromTemplates(response.templates);
        yield put(collectionActions.loadCollectionByIds(collectionIds));
        yield put(templateActions.loadTemplatesSuccess(response));
    } catch (error) {
        yield put(uiStateActions.addNotification([formatNotification(SNACKBAR_NOTIFICATION_TYPES.Danger, { key: 'notifications.danger.generic' }, 0)]));
        yield put(templateActions.loadTemplatesFailure());
    }
}

export function* onLoadRecentTemplates() {
    const tenant: tenantTypes.Tenant = yield select(tenantSelector.selectCurrentTenant);
    const assetQueryParams: templateTypes.AssetQueryParams = {
        pageSize: RecentTemplatesPageSize,
        tenantId: tenant.tenantId,
        tenantType: tenant.tenantType,
        includeDeleted: false,
        owner: getEffectiveOwner(undefined, true),
    };

    try {
        const response: QueryAssetsResponse = yield call(queryAssets, assetQueryParams);
        const collectionIds = getCollectionIdsFromTemplates(response.templates);
        yield put(collectionActions.loadCollectionByIds(collectionIds));
        yield put(templateActions.loadRecentTemplatesSuccess(response));
    } catch (error) {
        yield put(templateActions.loadRecentTemplatesFailure());
        yield put(uiStateActions.addNotification([formatNotification(SNACKBAR_NOTIFICATION_TYPES.Danger, { key: 'notifications.danger.generic' }, 0)]));
    }
}

export function* onLoadNextTemplates() {
    const offset: string | undefined = yield select(templateSelector.selectCurrentOffset);
    const activeCollection: collectionTypes.Collection | undefined = yield select(collectionSelector.selectActiveCollection);
    const filterCriteria: FilterCriteria = yield select(uiStateSelector.selectFilterCriteria);
    const tenant: tenantTypes.Tenant | undefined = yield select(tenantSelector.selectCurrentTenant);

    if (tenant === undefined) {
        yield put(templateActions.loadNextTemplatesFailure());
        yield put(uiStateActions.addNotification([formatNotification(SNACKBAR_NOTIFICATION_TYPES.Danger, { key: 'notifications.danger.generic' }, 0)]));
        return;
    }

    const assetQueryParams = formatQueryParams(tenant, filterCriteria, activeCollection, offset) as templateTypes.AssetQueryParams;

    try {
        const response: QueryAssetsResponse = yield call(queryAssets, assetQueryParams);
        const collectionIds = getCollectionIdsFromTemplates(response.templates);
        yield put(collectionActions.loadCollectionByIds(collectionIds));
        yield put(templateActions.loadNextTemplatesSuccess(response));
    } catch (error) {
        yield put(templateActions.loadNextTemplatesFailure());
        yield put(uiStateActions.addNotification([formatNotification(SNACKBAR_NOTIFICATION_TYPES.Danger, { key: 'notifications.danger.generic' }, 0)]));
    }
}

export function* onDeleteTemplate(action: PayloadAction<string>) {
    const templateId = action.payload;
    try {
        yield call(deleteAsset, templateId);
        yield put(templateActions.onDeleteTemplateSuccess(templateId));
    } catch (error) {
        yield put(templateActions.onDeleteTemplateFailure(templateId));
    }
}

export function* onBulkDeleteTemplate(action: PayloadAction<{ templates: templateTypes.Template[] }>) {
    const { templates } = action.payload;
    yield put(templateActions.setBulkProcess(templates));

    try {
        yield all(templates.map(function* handleDelete(template) {
            yield fork(handleBulkDelete, { template });
        }));
        yield put(templateActions.onBulkDeleteTemplateSuccess({ templatesToBeDeletedInStore: templates }));
        yield put(uiStateActions.addNotification([
            formatNotification(SNACKBAR_NOTIFICATION_TYPES.Success, { key: 'notifications.success.bulkDelete', values: { total: templates.length.toString() } }),
        ]));
    } catch (error) {
        const bulkProcess: templateTypes.BulkProcess | undefined = yield select(templateSelector.selectBulkProcess);
        const processedTemplates = bulkProcess ? bulkProcess.afterProcessing : [];
        yield put(templateActions.onBulkDeleteTemplateFailure({ templatesToBeDeletedInStore: processedTemplates }));
        yield put(uiStateActions.addNotification([
            formatNotification(SNACKBAR_NOTIFICATION_TYPES.Danger, { key: 'notifications.danger.bulkDelete' }, 0),
        ]));
    }
    yield put(templateActions.resetBulkProcess());
}

export function* onBulkRestoreTemplate(action: PayloadAction<{ templates: templateTypes.Template[] }>) {
    const { templates } = action.payload;
    yield put(templateActions.setBulkProcess(templates));

    try {
        yield all(templates.map(function* handleRstore(template) {
            yield fork(handleBulkRestore, { template });
        }));
        yield put(templateActions.onBulkRestoreTemplateSuccess({ templatesToBeRestoredInStore: templates }));
        yield put(uiStateActions.addNotification([
            formatNotification(SNACKBAR_NOTIFICATION_TYPES.Success, { key: 'notifications.success.bulkRestore', values: { total: templates.length.toString() } }),
        ]));
    } catch (error) {
        const bulkProcess: templateTypes.BulkProcess | undefined = yield select(templateSelector.selectBulkProcess);
        const processedTemplates = bulkProcess ? bulkProcess.afterProcessing : [];
        yield put(templateActions.onBulkRestoreTemplateFailure({ templatesToBeRestoredInStore: processedTemplates }));
        yield put(uiStateActions.addNotification([
            formatNotification(SNACKBAR_NOTIFICATION_TYPES.Danger, { key: 'notifications.danger.bulkRestore' }, 0),
        ]));
    }
    yield put(templateActions.resetBulkProcess());
}

export function* onRestoreTemplate(action: PayloadAction<string>) {
    const templateId = action.payload;
    try {
        yield call(restoreAsset, templateId);
        yield put(templateActions.onRestoreTemplateSuccess(templateId));
    } catch (error) {
        yield put(templateActions.onRestoreTemplateFailure(templateId));
    }
}

export function* onLoadTemplateById(action: PayloadAction<string>) {
    const templateId = action.payload;
    try {
        const template = (yield call(queryAsset, templateId)) as templateTypes.Template;
        yield put(templateActions.loadTemplateByIdSuccess(template));
    } catch (error) {
        yield put(templateActions.loadTemplateByIdFailure());
    }
}

export function* onAddTemplatesToCollections(action: PayloadAction<{ templates: templateTypes.Template[]; targetCollections: collectionTypes.Collection[] }>) {
    const { templates, targetCollections } = action.payload;
    yield put(templateActions.setBulkProcess(templates));
    try {
        yield all(templates.map(function* handleAddtoCollection(template) {
            yield fork(handleCollectionTag, { template, targetCollections });
        }));
        yield put(templateActions.addTemplatesToCollectionsSuccess({ templatesToBeUpdatedInStore: templates, targetCollections }));
        yield put(uiStateActions.addNotification([
            formatNotification(SNACKBAR_NOTIFICATION_TYPES.Success, { key: 'notifications.success.templatesAddedToCollections' }),
        ]));
    } catch (error) {
        const bulkProcess: templateTypes.BulkProcess | undefined = yield select(templateSelector.selectBulkProcess);
        const processedTemplates = bulkProcess ? bulkProcess.afterProcessing : [];
        yield put(templateActions.addTemplatesToCollectionsFailure({ templatesToBeUpdatedInStore: processedTemplates, targetCollections }));
        yield put(uiStateActions.addNotification([
            formatNotification(SNACKBAR_NOTIFICATION_TYPES.Danger, { key: 'notifications.danger.templatesAddedToCollections' }, 0),
        ]));
    }
    yield put(templateActions.resetBulkProcess());
}

export function* onMoveTemplatesToCollections(action: PayloadAction<templateTypes.ActionMoveTemplates>) {
    const { templates, sourceCollectionId, targetCollections, proceedToDeleteCollection } = action.payload;
    yield put(templateActions.setBulkProcess(templates));
    try {
        yield all(templates.map(function* handleTags(template) {
            yield fork(handleCollectionTag, { template, sourceCollectionId, targetCollections });
        }));
        const successPayload: templateTypes.ActionMoveTemplatesSuccess = proceedToDeleteCollection && sourceCollectionId
            ? {
                templatesToBeRemovedFromStore: templates,
                deleteCollectionId: sourceCollectionId,
            } : { templatesToBeRemovedFromStore: templates };
        yield put(templateActions.moveTemplatesToCollectionsSuccess(successPayload));
        yield put(uiStateActions.addNotification([
            formatNotification(SNACKBAR_NOTIFICATION_TYPES.Success, { key: 'notifications.success.templatesMovedToCollections' }),
        ]));
    } catch (error) {
        const bulkProcess: templateTypes.BulkProcess | undefined = yield select(templateSelector.selectBulkProcess);
        const processedTemplates = bulkProcess ? bulkProcess.afterProcessing : [];
        yield put(templateActions.moveTemplatesToCollectionsFailure({ templatesToBeRemovedFromStore: processedTemplates }));
        yield put(uiStateActions.addNotification([
            formatNotification(SNACKBAR_NOTIFICATION_TYPES.Danger, { key: 'notifications.danger.templatesMovedToCollections' }, 0),
        ]));
    }
    yield put(templateActions.resetBulkProcess());
}

export function* onRemoveTemplatesFromCollection(action: PayloadAction<templateTypes.ActionRemoveTemplates>) {
    const { templates, sourceCollectionId, proceedToDeleteCollection } = action.payload;
    yield put(templateActions.setBulkProcess(templates));
    try {
        yield all(templates.map(function* handleTags(template) {
            yield fork(handleCollectionTag, { template, sourceCollectionId });
        }));
        const successPayload: templateTypes.ActionRemoveTemplatesSuccess = proceedToDeleteCollection && sourceCollectionId
            ? {
                templatesToBeRemovedFromStore: templates,
                deleteCollectionId: sourceCollectionId,
            } : { templatesToBeRemovedFromStore: templates };
        yield put(templateActions.moveTemplatesToCollectionsSuccess(successPayload));
        yield put(uiStateActions.addNotification([
            formatNotification(SNACKBAR_NOTIFICATION_TYPES.Success, { key: 'notifications.success.templatesRemovedFromCollection' }),
        ]));
    } catch (error) {
        const bulkProcess: templateTypes.BulkProcess | undefined = yield select(templateSelector.selectBulkProcess);
        const processedTemplates = bulkProcess ? bulkProcess.afterProcessing : [];
        yield put(templateActions.moveTemplatesToCollectionsFailure({ templatesToBeRemovedFromStore: processedTemplates }));
        yield put(uiStateActions.addNotification([
            formatNotification(SNACKBAR_NOTIFICATION_TYPES.Danger, { key: 'notifications.danger.templatesRemovedFromCollection' }, 0),
        ]));
    }
    yield put(templateActions.resetBulkProcess());
}

export function* onFetchTemplateVersions(action: PayloadAction<string>) {
    const templateId = action.payload;
    try {
        const versionResponse = (yield call(getRevisionsByAssetId, templateId)) as templateTypes.TemplateVersion[];
        const assetResponse = (yield call(queryAsset, templateId)) as templateTypes.Template;

        const uniquePublishedVersionIds = assetResponse.recentPublishedRevisions.map((v) => v.revisionId)
            .filter((value, index, self) => self.indexOf(value) === index);
        const recentPublishedRevisions = assetResponse.recentPublishedRevisions.reverse();
        const revisions = versionResponse.map((version: templateTypes.TemplateVersion) => {
            const publishedRevision = recentPublishedRevisions.find((pv) => pv.revisionId === version.id);
            const { id, createdBy, created } = version;
            return {
                id,
                assetId: templateId,
                createdBy,
                created,
                isPublished: !!publishedRevision,
                publishedBy: publishedRevision ? publishedRevision.publishedBy : undefined,
                published: publishedRevision ? publishedRevision.publishedAt : undefined,
                versionNumber: uniquePublishedVersionIds.indexOf(id) + 1,
            };
        });

        yield put(templateActions.fetchTemplateVersionsSuccess(revisions));
    } catch (error) {
        yield put(templateActions.fetchTemplateVersionsFailure('Error while loading the versions.'));
    }
}

export function* onPublishTemplateVersion(action: PayloadAction<templateTypes.TemplateVersion>) {
    const version = action.payload;
    try {
        const { data } = (yield call(publishRevision, version.assetId, version.id)) as { data: templateTypes.Template };
        yield put(templateActions.publishTemplateVersionSuccess(data));
    } catch (error) {
        yield put(templateActions.publishTemplateVersionFailure('Error while publishing the version'));
    }
}

export function* onCreatePrintPdf(action: PayloadAction<{ docRefUrl: string; fileName: string }>) {
    const { docRefUrl, fileName } = action.payload;
    try {
        const { data } = (yield call(createPrintPdf, docRefUrl)) as { data: { PdfUrl: string } };
        yield call(downloadFile, data.PdfUrl, fileName);
        yield put(templateActions.createPrintPdfSuccess());
    } catch (error) {
        yield put(templateActions.createPrintPdfFailure());
        yield put(uiStateActions.addNotification([formatNotification(SNACKBAR_NOTIFICATION_TYPES.Danger, { key: 'notifications.danger.pdfErrorMessage' }, 0)]));
    }
}

export function* onMoveAllTemplatesAndDeleteCollection(action: PayloadAction<{ sourceCollectionId: string; targetCollections: collectionTypes.Collection[] }>) {
    const { sourceCollectionId, targetCollections } = action.payload;
    try {
        // fetch all to move
        const tenant: tenantTypes.Tenant = yield select(tenantSelector.selectCurrentTenant);
        const queryParam = {
            includeDeleted: true,
            pageSize: RecurringExportTemplatePageSize,
            tenantId: (tenant && tenant.tenantId) || '',
            tenantType: (tenant && tenant.tenantType) || '',
            tag: `${CollectionTagPrefix}${sourceCollectionId}`,
        };
        const allTemplatesInCollection: templateTypes.Template[] = yield call(fetchAllTemplates, queryParam);
        if (targetCollections.length > 0) {
            yield put(templateActions.moveTemplatesToCollections({
                templates: allTemplatesInCollection,
                sourceCollectionId,
                targetCollections,
                proceedToDeleteCollection: true,
            }));
        } else {
            yield put(templateActions.removeTemplatesFromCollection({
                templates: allTemplatesInCollection,
                sourceCollectionId,
                proceedToDeleteCollection: true,
            }));
        }
    } catch (error) {
        yield put(uiStateActions.addNotification([formatNotification(SNACKBAR_NOTIFICATION_TYPES.Danger, { key: 'notifications.danger.generic' }, 0)]));
    }
    // TODO: change active collection
}

export function* templateToBeAdded(inflightTemplate: templateTypes.InFlightTemplate,
    documentReferenceUrl: string,
    processType: string,
    surfaceSpecifications?: productTypes.SurfaceSpecification,
    skuVariables?: string) {
    const currentTenant: tenantTypes.Tenant | undefined = yield select(tenantSelector.selectCurrentTenant);
    const { tags } = inflightTemplate;
    const template: templateTypes.AssetToBeAdded = {
        name: inflightTemplate.name,
        description: inflightTemplate.description,
        referenceId: inflightTemplate.sku,
        tags: !inflightTemplate.sku && !(tags.includes(SkulessTag)) ? [...tags, SkulessTag] : tags,
        initialRevision: {
            uri: documentReferenceUrl,
            ...surfaceSpecifications && { metadata: surfaceSpecifications },
        },
        tenant: {
            id: currentTenant?.tenantId || '',
            type: currentTenant?.tenantType || '',
        },
        attributes: {
            processType,
            skuVariables,
        },
    };
    return template;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* onSaveCimDocToUds(action: PayloadAction<{ cimDoc: any }>) {
    const { cimDoc } = action.payload;
    try {
        const { documentReferenceUrl } = (yield call(saveCimDocToUds, cimDoc)) as SaveDocumentResponse;
        yield put(templateActions.saveCimDocToUdsSuccess());
        yield put(templateActions.saveTemplate(
            { documentReferenceUrl, redirectToSuccess: false, processType: extractCimDocManufacturingProcessType(cimDoc), skuVariables: extractCimDocManufacturingSkuVariables(cimDoc) },
        ));
    } catch (error) {
        yield put(templateActions.saveCimDocToUdsFailure());
        yield logError('Failed to save template to Uds');
    }
}

export function* onSaveTemplate(action: PayloadAction<SaveTemplatePayload>) {
    const { documentReferenceUrl, surfaceSpecifications, redirectToSuccess, processType, skuVariables } = action.payload;
    const mode: EDITOR_MODE = yield select(editorSelector.selectEditorMode);
    const inflightTemplate: templateTypes.InFlightTemplate = yield select(templateSelector.selectInflightTemplate);
    const saveAsNew = mode === EDITOR_MODE.SaveAsNew || !inflightTemplate.id;

    const template: templateTypes.AssetToBeAdded = yield call(templateToBeAdded, inflightTemplate, documentReferenceUrl, processType, surfaceSpecifications, skuVariables);

    try {
        let savedTemplateId;
        if (saveAsNew) {
            const structuredTags: StructuredTags[] = yield select(uiStateSelector.selectStructuredTagSettings);
            const disallowedTags = getDisallowedTags(structuredTags, template.tags);
            if (disallowedTags.length > 0) {
                yield put(templateActions.saveTemplateFailure());
                return;
            }

            const savedTemplate: templateTypes.Template = yield call(addAsset, template);
            if (savedTemplate && savedTemplate.id) {
                yield put(templateActions.setInFlightTemplateId(savedTemplate.id));
                savedTemplateId = savedTemplate.id;
            }
        } else {
            if (inflightTemplate.id === undefined) {
                yield put(templateActions.saveTemplateFailure());
                return;
            }

            const structuredTags: StructuredTags[] = yield select(uiStateSelector.selectStructuredTagSettings);
            const disallowedTags = getDisallowedTags(structuredTags, inflightTemplate.tags);
            if (disallowedTags.length > 0) {
                yield put(templateActions.saveTemplateFailure());
                return;
            }

            const templateInformation: EditTemplatePayload = {
                name: inflightTemplate.name,
                description: inflightTemplate.description,
                tags: inflightTemplate.tags,
                attributes: {
                    ...inflightTemplate.attributes,
                    processType,
                    skuVariables,
                },
            };
            yield call(onEditTemplate, inflightTemplate.id, templateInformation, documentReferenceUrl, surfaceSpecifications);
            savedTemplateId = inflightTemplate.id;
        }
        if (redirectToSuccess) {
            yield put(push(`/success/${savedTemplateId}`));
        } else {
            yield put(uiStateActions.addNotification([formatNotification(SNACKBAR_NOTIFICATION_TYPES.Success, { key: 'notifications.success.designTransferred' }, 5000)]));
            yield put(uiStateActions.setClearCurrentSkuForDesignTransfer());
            yield put(templateActions.loadTemplates());
        }
        yield put(templateActions.saveTemplateSuccess());
    } catch (error) {
        logError('Failed to save template!');
        if (saveAsNew) {
            // TODO: Blocking this for EDIT scenario, should be fixed
            const authorId = auth.getProfile() ? getEmailIdentifier(auth.getProfile()) : '';
            saveConfig(LocalStorage.TemplateConfigKey, {
                ...template,
                author: authorId,
                lastModified: (new Date()).toISOString(),
            });
        }
        yield put(templateActions.saveTemplateFailure()); // 'Failed to save template!'
    }
}

export function* onEditTemplate(templateId: string,
    templateInformation: EditTemplatePayload | undefined,
    documentReferenceUrl: string,
    surfaceSpecifications?: productTypes.SurfaceSpecification) {
    if (documentReferenceUrl) {
        yield call(createAndPublishTemplateRevision, templateId, documentReferenceUrl, surfaceSpecifications);
    }

    if (templateInformation) {
        try {
            yield call(editAsset, templateId, templateInformation);
        } catch {
            yield put(uiStateActions.addNotification([formatNotification(SNACKBAR_NOTIFICATION_TYPES.Danger, { key: 'notifications.danger.generic' }, 0)]));
        }
    }
}

export function* onEditTemplateInformation(action: PayloadAction<templateTypes.AssetToBeEdited>) {
    try {
        const template: templateTypes.Template | undefined = yield select(templateSelector.selectCurrentTemplate);
        if (template === undefined) {
            yield put(uiStateActions.addNotification([formatNotification(SNACKBAR_NOTIFICATION_TYPES.Danger, { key: 'notifications.danger.generic' }, 0)]));
            return;
        }

        const updatedTemplate: templateTypes.Template = yield call(editAsset, template.id, action.payload);
        yield put(templateActions.setCurrentTemplate(
            { ...template, name: updatedTemplate.name, description: updatedTemplate.description, tags: updatedTemplate.tags },
        ));
        yield put(templateActions.editTemplateInformationSuccess({
            name: updatedTemplate.name,
            description: updatedTemplate.description,
            tags: updatedTemplate.tags,
        }));
    } catch {
        yield put(uiStateActions.addNotification([formatNotification(SNACKBAR_NOTIFICATION_TYPES.Danger, { key: 'notifications.danger.generic' }, 0)]));
    }
}

export function* createAndPublishTemplateRevision(templateId: string, documentReferenceUrl: string, surfaceSpecifications?: productTypes.SurfaceSpecification) {
    try {
        const latestRevision = (yield call(addRevision, templateId, documentReferenceUrl, surfaceSpecifications)) as { id: string };
        if (latestRevision && latestRevision.id) {
            yield call(publishRevision, templateId, latestRevision.id);
        }
    } catch {
        yield put(uiStateActions.addNotification([formatNotification(SNACKBAR_NOTIFICATION_TYPES.Danger, { key: 'notifications.danger.generic' }, 0)]));
    }
}

export function* onLoadInFlightTemplate(action: PayloadAction<{ duplicateCollections: boolean; revisionId?: string }>) {
    const { duplicateCollections, revisionId } = action.payload;
    const id: string | undefined = yield select(templateSelector.selectInflightTemplateId);

    if (id === undefined) {
        yield put(templateActions.loadInFlightTemplateFailure());
        return;
    }

    try {
        const template = (yield call(queryAsset, id)) as templateTypes.InFlightTemplate;
        const editorMode: EDITOR_MODE = yield select(editorSelector.selectEditorMode);
        if (template) {
            if (template.sku) {
                yield put(productActions.loadProduct(template.sku));
            } else {
                const revisionIdForMetadata = revisionId || template.publishedRevisionId || '';
                const revisionMetadata = (yield call(getRevision, id, revisionIdForMetadata)) as templateTypes.VersionMetaData;
                yield put(productActions.loadCalculatedSurfaceSets(revisionMetadata.metadata));
            }
            yield put(templateActions.loadInFlightTemplateSuccess({ inflightTemplate: template, duplicateCollection: duplicateCollections, editorMode }));
        }
    } catch (error) {
        logError('Failed to load template!');
        yield put(templateActions.loadInFlightTemplateFailure());
    }
}

export function* onloadSurfaces(action: PayloadAction<string>) {
    const id = action.payload;
    try {
        const cimDoc = yield call(getCimDocData, getAssetContentUrl(id));
        const { surfaces, panels } = cimDoc.document;
        let surfaceList;
        if (surfaces) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            surfaceList = surfaces.map((surface: any) => surface.name);
        } else if (panels) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            surfaceList = panels.map((surface: any) => surface.name);
        }
        yield put(templateActions.loadSurfacesSuccess(surfaceList));
    } catch (error) {
        logError('Failed to load surfaces!');
        yield put(templateActions.loadSurfacesFailure());
    }
}

export default function* templateSaga(): Generator<StrictEffect, StrictEffect, never> {
    return yield all([
        takeLatest(templateActions.loadTemplates, onLoadTemplates),
        takeLatest(templateActions.loadRecentTemplates, onLoadRecentTemplates),
        takeEvery(templateActions.editTemplateInformation, onEditTemplateInformation),
        takeEvery(templateActions.loadNextTemplates, onLoadNextTemplates),
        takeEvery(templateActions.loadTemplateById, onLoadTemplateById),
        takeEvery(templateActions.fetchTemplateVersions, onFetchTemplateVersions),
        takeEvery(templateActions.publishTemplateVersion, onPublishTemplateVersion),
        takeEvery(templateActions.createPrintPdf, onCreatePrintPdf),
        takeEvery(templateActions.onDeleteTemplate, onDeleteTemplate),
        takeEvery(templateActions.onRestoreTemplate, onRestoreTemplate),
        takeEvery(templateActions.addTemplatesToCollections, onAddTemplatesToCollections),
        takeEvery(templateActions.bulkDeleteTemplate, onBulkDeleteTemplate),
        takeEvery(templateActions.bulkRestoreTemplate, onBulkRestoreTemplate),
        takeEvery(templateActions.moveTemplatesToCollections, onMoveTemplatesToCollections),
        takeEvery(templateActions.removeTemplatesFromCollection, onRemoveTemplatesFromCollection),
        takeEvery(templateActions.moveAllTemplatesAndDeleteCollection, onMoveAllTemplatesAndDeleteCollection),
        takeEvery(templateActions.saveCimDocToUds, onSaveCimDocToUds),
        takeEvery(templateActions.saveTemplate, onSaveTemplate),
        takeEvery(templateActions.loadInFlightTemplate, onLoadInFlightTemplate),
        takeEvery(templateActions.loadSurfaces, onloadSurfaces),
    ]);
}
