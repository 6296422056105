import React from 'react';
import { SmartShapes, getCurrentShapeInRenderingFormat, Layout, getCurrentSvg } from '@cimpress-technology/smart-shapes';

import './shapes.scss';
import { useAuth } from 'providers/AuthContext';
import { useTranslation } from 'react-i18next';

interface Props {
    hidden: boolean;
    designer: Designer | undefined;
    tenantId: string;
    locale: string | undefined;
}

const dropTargetClassName = 'dcl-targets';

function round(num: number, places: number) {
    let roundedValue;
    if (places > 0) {
        roundedValue = num.toFixed(places);
    } else if (places === 0) {
        roundedValue = Math.round(num);
    } else {
        roundedValue = parseFloat(`${roundedValue}${Math.round(parseFloat(`${num}e-${-places}`))}e+${-places}`);
    }

    return roundedValue || 0;
}

const CanvasToShapePercentage = 0.5; // Could be a value between 0 to 100
const MM_TO_PT = 2.83465;

const mm2pt = (mm: number) => mm * MM_TO_PT;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getStrokeWidthByCanvasSize = (activeCanvas: any) => {
    const { attributes } = activeCanvas;
    let minSize = Math.min(attributes.width, attributes.height);
    const maxSize = Math.max(attributes.width, attributes.height);
    // If the smaller dimension is much smaller than the larger, then the strokeWidth will still be too small
    minSize = Math.max(minSize, maxSize * 0.33);
    const minSizeInCorrectMeasurement = mm2pt(minSize);
    // 0.5% of the canvas's smallest dimension yields a good line thickness
    return round(minSizeInCorrectMeasurement * 0.005, 3);
};

const createLockOverrides = (item: { lockOverrides: { fillColor: boolean; strokeColor: boolean; strokeWidth: boolean; sizeAndPosition: boolean } }) => ({
    fillColor: item.lockOverrides ? item.lockOverrides.fillColor : undefined,
    strokeColor: item.lockOverrides ? item.lockOverrides.strokeColor : undefined,
    strokeWidth: item.lockOverrides ? item.lockOverrides.strokeWidth : undefined,
    sizeAndPosition: item.lockOverrides ? item.lockOverrides.sizeAndPosition : undefined,
});

const modules: { [key: string]: string } = {
    curve: 'Curve',
    line: 'Line',
    rectangle: 'Rectangle',
    ellipse: 'Ellipse',
};

const addShapeToDesigner = (designer: Designer, positionAndSize: { top: number; left: number; width: number; height: number }) => {
    const { top, left, width, height } = positionAndSize;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const activeCanvas: any = designer.documentRepository.getActiveCanvas();
    const strokeWidth = getStrokeWidthByCanvasSize(activeCanvas);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return getCurrentShapeInRenderingFormat(width, height).then((scaledShapes: any) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const shapes = scaledShapes.map((scaledShape: any) => ({
            module: modules[scaledShape.type],
            curves: scaledShape.curves,
            viewBox: scaledShape.viewBox,
            width: parseFloat(scaledShape.position.width),
            height: parseFloat(scaledShape.position.height),
            strokeColor: scaledShape.stroke.color,
            strokeWidth,
            fillColor: scaledShape.color,
            top,
            left,
            transforms: {
                rotatable: true,
                scalable: true,
            },
            isAspectRatioLocked: true,
            lockOverrides: createLockOverrides(scaledShape),
            restricted: false,
            rotation: 0,
        }));

        const options = {
            model: activeCanvas,
            items: shapes,
            selectNewModels: true,
        };
        // eslint-disable-next-line no-unused-expressions
        designer.commandDispatcher.add(options);

        return shapes;
    });
};

export default function ShapesTab({ hidden, designer, tenantId, locale }: Props) {
    const { t } = useTranslation();

    const onDrop = ({ x, y, height, width }: { x: number; y: number; height: number; width: number }) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const activeCanvasViewModel: any = designer && designer.documentRepository.getActiveCanvasViewModel();
        const { zoomFactor } = activeCanvasViewModel.attributes;

        getCurrentSvg().then((shapeSvg) => {
            designer
                && addShapeToDesigner(designer, {
                    left: x / zoomFactor,
                    top: y / zoomFactor,
                    width: width / zoomFactor,
                    height: height / zoomFactor,
                });
        });
    };

    const dragAndDrop = {
        dropTargetClassName,
        canvasToShapePercentage: CanvasToShapePercentage,
        onDrop,
    };

    const { auth } = useAuth();
    const accessToken = auth.getAccessToken();

    return (

        <div className={`content-panel${hidden ? ' content-panel--hidden' : ' content-panel--shapes-container'}`}>
            <div className='content-panel__title'>{t('editor.tabs.shapes.shapesTabTitle')}</div>
            <SmartShapes
                tenantId={tenantId}
                hostApp='Template-Maker'
                locale={locale}
                layout={Layout.PreviewOnTop}
                dragAndDrop={dragAndDrop}
                authConfig={{ authType: 'Bearer', value: accessToken }}
            />
        </div>

    );
}
