import React, { memo } from 'react';
import { Card, colors } from '@cimpress/react-components';
import IconBookOpen from '@cimpress-technology/react-streamline-icons/lib/IconBookOpen';
import IconEmailSend from '@cimpress-technology/react-streamline-icons/lib/IconEmailSend';
import IconEmailActionUnread from '@cimpress-technology/react-streamline-icons/lib/IconEmailActionUnread';
import { Trans, useTranslation } from 'react-i18next';

import './contact.scss';
import { URLs, SupportGroupEmail } from 'utility/constants';

const { ocean } = colors;

/**
 * The contact screen. Contains links or details for our documentation, email, and slack.
 * There really shouldn't be anything dynamic about this screen.
 */
export default memo(() => {
    const { t } = useTranslation();
    return (
        <div className='contact-content'>
            <Card>
                <h2 className='contact-content__documentation'>
                    <IconBookOpen
                        size='2x'
                    />
                    {t('contact.documentation.documentation')}
                </h2>
                <Trans i18nKey='contact.documentation.userGuide'>
                    The user guide for Template Maker is available
                    <a href={`${URLs.TemplateMakerDocumentation}`} target='_blank' rel='noopener noreferrer'> here</a>.
                </Trans>
            </Card>
            <Card>
                <h2 className='contact-content__contact-us'>
                    <IconEmailSend
                        size='2x'
                    />
                    {t('contact.contactUs.contactUs')}
                </h2>
                <div>{t('contact.contactUs.feedback')}</div>
                <ul className='contact-content__list'>
                    <li className='contact-content__list-mail'>
                        <IconEmailActionUnread
                            size='lg'
                            color={ocean.darker}
                        />
                        <Trans i18nKey='contact.contactUs.mailAddress'>
                            Email the team at <a href={`mailto:${SupportGroupEmail}`}>{SupportGroupEmail}</a>
                        </Trans>
                        <p>{t('contact.contactUs.responseMsg')}</p>
                    </li>
                    <li>
                        <img className='slack-icon' src='https://ux.cimpress.io/images/Slack_Mark.svg' alt='' />
                        <Trans i18nKey='contact.contactUs.supportChannel'>
                            Are you on slack? Reach out to us at
                            <a href={URLs.TemplateMakerSupportSlack} target='_blank' rel='noopener noreferrer'> #mcp-support-content-management</a>
                        </Trans>
                    </li>
                </ul>
            </Card>
        </div>
    );
});
