import React from 'react';
import { useTranslation } from 'react-i18next';
import { shapes, Button, Tooltip, colors } from '@cimpress/react-components';
import cx from 'classnames';

import { templateTypes } from 'store/template';
import ActionsDropdown from 'components/actionDropdown/ActionsDropdown';
import IconCopy from '@cimpress-technology/react-streamline-icons/lib/IconCopy';
import { getAssetContentUrl } from 'utility/url.utility';
import copyToClipboard from 'clipboard-copy';
import { formatNotification, uiStateTypes } from 'store/uiState';
import { SNACKBAR_NOTIFICATION_TYPES, TEMPLATE_ACTIONS } from 'utility/enums';
import { useResponsiveDesign } from 'providers/ResponsiveDesign';

import './successHeader.scss';
import { productTypes } from 'store/product';
import { tenantTypes } from 'store/tenant';

const { Spinner } = shapes;
const { ocean } = colors;

interface Props {
    canEdit: boolean;
    template: templateTypes.Template;
    tenant?: tenantTypes.Tenant;
    isCreatingPdf: boolean;
    hasCollection: boolean;
    enableEditingAceTemplates: boolean;
    uploadsTenant: string | undefined;
    createPdf(): void;
    onClickAddToCollection(): void;
    addNotification(payload: uiStateTypes.Notification[]): void;
    onDesignTransfer?(docRefUrl: string, product: productTypes.Product): void;
}

export default function SuccessHeader({
    canEdit,
    template,
    tenant,
    isCreatingPdf,
    hasCollection,
    enableEditingAceTemplates,
    uploadsTenant,
    createPdf,
    onClickAddToCollection,
    addNotification,
    onDesignTransfer,
}: Props) {
    const { t } = useTranslation();
    const { isMobile } = useResponsiveDesign();
    const docRefUrl = getAssetContentUrl(template?.id);

    const onClickCopy = () => {
        template && copyToClipboard(getAssetContentUrl(template.id));
        addNotification([formatNotification(SNACKBAR_NOTIFICATION_TYPES.Success, { key: 'notifications.success.copyUrl' })]);
    };
    return (
        <>
            <h1 className='save-success__title'> {t('success.successActionsCard.title')}</h1>
            <div className={cx('asset-url', { mobile: isMobile })}>
                {t('success.successActionsCard.txtURL')}:
                <div className={cx('asset-url__link-wrapper')}>
                    <a
                        className='asset-url__link'
                        href={docRefUrl}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        {docRefUrl}
                    </a>
                    <span>
                        <Tooltip direction='right' contents={t('success.successActionsCard.ttCopyUrl')}>
                            <Button className='url-copy-icon' onClick={onClickCopy} type='link'>
                                <IconCopy
                                    size='lg'
                                    color={ocean.darker}
                                />
                            </Button>
                        </Tooltip>
                    </span>
                </div>
            </div>
            <h2>{t('success.successActionsCard.instructionHeader')}</h2>
            <p>{t('success.successActionsCard.instructionDetails')}</p>
            <div className={cx('save-success-actions-container', { mobile: isMobile })}>
                <Button className={`btn btn-link btn-create-pdf ${isCreatingPdf ? 'btn-create-pdf--disabled' : ''}`} onClick={createPdf} disabled={isCreatingPdf}>
                    <span>
                        {isCreatingPdf && <Spinner size='small' className='create-pdf-spinner' />}
                        {t('success.successActionsCard.btnCreatePrintPdf')}
                    </span>
                </Button>
                <Button className='btn btn-default btn-add-to-collection' onClick={onClickAddToCollection} disabled={!hasCollection}>
                    {t('success.successActionsCard.btnAddToCollection')}
                </Button>
                <ActionsDropdown
                    templateDetail={template}
                    title={t('success.successActionsCard.ddTemplateActions')}
                    tenant={tenant}
                    className='template-actions-dropdown'
                    canEdit={canEdit}
                    disabledActions={[TEMPLATE_ACTIONS.CopyUrl, TEMPLATE_ACTIONS.ManageVersion, TEMPLATE_ACTIONS.Delete]}
                    onDesignTransfer={onDesignTransfer}
                    enableEditingAceTemplates={enableEditingAceTemplates}
                    uploadsTenant={uploadsTenant}
                />
            </div>
        </>
    );
}
