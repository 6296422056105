import { tenantTypes, tenantSelector } from 'store/tenant';
import { collectionTypes, collectionSelector, collectionActions } from 'store/collection';
import { uiStateTypes, uiStateSelector, uiStateActions } from 'store/uiState';
import { RootState } from 'store/rootReducer';
import { templateActions } from 'store/template';

export interface StateProps {
    tenant?: tenantTypes.Tenant;
    collections: collectionTypes.Collection[];
    isCollectionLoading: boolean;
    isCollectionSaving?: boolean;
    isCollectionAddedSuccess?: boolean;
    activeCollection: collectionTypes.Collection | undefined;
}
export interface DispatchProps {
    onCollectionLoad(): void;
    onAddOrEditCollection(payload: {label: string; parentId: string; id?: string}): void;
    onGetChildren(collection: collectionTypes.Collection): void;
    deleteCollection(payload: string): void;
    addNotification(payload: uiStateTypes.Notification[]): void;
    moveAllTemplatesAndDeleteCollection(payload: {sourceCollectionId: string; targetCollections: collectionTypes.Collection[] | null}): void;
    setManualLoader(): void;
    resetManualLoader(): void;
    resetIsCollectionAddedSuccess?(): void;
    updateActiveCollection(payload: string): void;
}
export type AllProps = StateProps & DispatchProps;

export const getAllProps = (props: AllProps) => {
    const { tenant, collections, isCollectionLoading, activeCollection,
        onCollectionLoad, onAddOrEditCollection, onGetChildren,
        deleteCollection, addNotification, moveAllTemplatesAndDeleteCollection, setManualLoader, resetManualLoader, isCollectionSaving, isCollectionAddedSuccess,
        resetIsCollectionAddedSuccess, updateActiveCollection } = props;
    const stateProps: StateProps = { tenant, collections, isCollectionLoading, isCollectionSaving, isCollectionAddedSuccess, activeCollection };
    const dispatchProps: DispatchProps = { onCollectionLoad,
        onAddOrEditCollection,
        onGetChildren,
        deleteCollection,
        addNotification,
        moveAllTemplatesAndDeleteCollection,
        setManualLoader,
        resetManualLoader,
        resetIsCollectionAddedSuccess,
        updateActiveCollection };
    return { stateProps, dispatchProps };
};

export const mapStateToProps = (state: RootState): StateProps => ({
    tenant: tenantSelector.selectCurrentTenant(state),
    activeCollection: collectionSelector.selectActiveCollection(state),
    collections: collectionSelector.selectAllCollections(state),
    isCollectionLoading: uiStateSelector.selectIfLoading(state, collectionActions.onCollectionLoad.toString()),
    isCollectionSaving: uiStateSelector.selectIfLoading(state, collectionActions.onAddOrEditCollection.toString()),
    isCollectionAddedSuccess: collectionSelector.selectCollectionAddedSuccess(state),
});
export const mapDispatchToProps: DispatchProps = {
    updateActiveCollection: collectionActions.updateActiveCollection,
    onCollectionLoad: collectionActions.onCollectionLoad,
    onAddOrEditCollection: collectionActions.onAddOrEditCollection,
    onGetChildren: collectionActions.onCollectionChildrenLoad,
    deleteCollection: collectionActions.deleteCollection,
    addNotification: uiStateActions.addNotification,
    moveAllTemplatesAndDeleteCollection: templateActions.moveAllTemplatesAndDeleteCollection,
    setManualLoader: uiStateActions.setManualLoader,
    resetManualLoader: uiStateActions.resetManualLoader,
    resetIsCollectionAddedSuccess: collectionActions.resetIsCollectionAddedSuccess,
};
