import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@cimpress/react-components';
import { RootState } from 'store/rootReducer';
import { templateTypes } from 'store/template';
import { formatNotification } from 'store/uiState';
import ConfirmDialog from 'shared/confirmDialog/confirmDialog';
import { templateHasCollections } from 'utility/template.utility';
import { TEMPLATE_ACTION_TYPE, SNACKBAR_NOTIFICATION_TYPES, DESIGNER_MODE } from 'utility/enums';
import PreviewModal from 'components/preview/PreviewModal';
import TemplateCard from 'components/templateCard/TemplateCard';
import { StateProps, DispatchProps, mapStateToProps, mapDispatchToProps, AllProps, getAllProps } from './props.recentTemplates';

import './recentTemplates.scss';

function RecentTemplates(allProps: AllProps) {
    const { stateProps, dispatchProps } = getAllProps(allProps);

    const history = useHistory();
    const { t } = useTranslation();
    const [templateToPreview, setTemplateToPreview] = useState<templateTypes.Template>();
    const [confirmTemplateAction, setConfirmTemplateAction] = useState<templateTypes.TemplateActionConfirmation | undefined>();

    const redirectToEditor = (templateId: string, shouldDuplicateCollection = false) => {
        if (shouldDuplicateCollection) {
            history.push(`/editor/${DESIGNER_MODE.TemplateEditor}/${templateId}?mode=duplicate&duplicateCollection=true`);
        } else {
            history.push(`/editor/${DESIGNER_MODE.TemplateEditor}/${templateId}?mode=duplicate`);
        }
    };

    const canEdit = stateProps.hasMandatoryDesignerSettings && !!(stateProps.currentTenant && stateProps.currentTenant.permissions.asset.canCreate);

    const onCopyUrl = () => {
        dispatchProps.addNotification([formatNotification(SNACKBAR_NOTIFICATION_TYPES.Success, { key: 'notifications.success.copyUrl' })]);
    };

    const onConfirmModalClose = () => {
        setConfirmTemplateAction(undefined);
    };

    const onConfirmModalYes = () => {
        if (confirmTemplateAction) {
            if (confirmTemplateAction.templateActionType === TEMPLATE_ACTION_TYPE.Delete) {
                dispatchProps.onDeleteTemplate(confirmTemplateAction.template.id);
            }
            if (confirmTemplateAction.templateActionType === TEMPLATE_ACTION_TYPE.Restore) {
                dispatchProps.onRestoreTemplate(confirmTemplateAction.template.id);
            }
            if (confirmTemplateAction.templateActionType === TEMPLATE_ACTION_TYPE.Duplicate) {
                redirectToEditor(confirmTemplateAction.template.id, true);
            }
            onConfirmModalClose();
        }
    };

    const deleteModalConfig = {
        title: t('modals.deleteTemplate.title'),
        body: t('modals.deleteTemplate.confirmationMessage'),
        footer: (
            <div>
                <Button onClick={onConfirmModalClose}>{t('common.cancel')}</Button>
                <Button type='primary' onClick={onConfirmModalYes}>{t('common.delete')}</Button>
            </div>
        ),
    };

    const restoreModalConfig = {
        title: t('modals.restoreTemplate.title'),
        body: t('modals.restoreTemplate.confirmationMessage'),
        footer: (
            <div>
                <Button onClick={onConfirmModalClose}>{t('common.cancel')}</Button>
                <Button type='primary' onClick={onConfirmModalYes}>{t('common.restore')}</Button>
            </div>
        ),
    };

    const onDuplicateCollectionModalClose = () => (confirmTemplateAction && confirmTemplateAction.templateActionType === TEMPLATE_ACTION_TYPE.Duplicate)
    && redirectToEditor(confirmTemplateAction.template.id);

    const duplicateTemplateModalConfig = {
        title: t('modals.duplicateTemplate.title'),
        body: t('modals.duplicateTemplate.confirmationMessage'),
        footer: (
            <div>
                <Button onClick={onDuplicateCollectionModalClose}>{t('common.no')}</Button>
                <Button type='primary' onClick={onConfirmModalYes}>{t('common.yes')}</Button>
            </div>
        ),
    };

    const onModalClose = () => {
        setTemplateToPreview(undefined);
    };

    return (
        <>
            {templateToPreview && (
                <PreviewModal
                    title={templateToPreview.name}
                    src={templateToPreview.original}
                    onModalClose={onModalClose}
                />
            )}

            {confirmTemplateAction && (
                <ConfirmDialog isDialogOpen={true}>{
                    confirmTemplateAction.templateActionType === TEMPLATE_ACTION_TYPE.Delete ? deleteModalConfig : restoreModalConfig
                }
                </ConfirmDialog>
            )}

            {confirmTemplateAction && (confirmTemplateAction.templateActionType === TEMPLATE_ACTION_TYPE.Duplicate && (
                confirmTemplateAction.template && templateHasCollections(confirmTemplateAction.template) ? (
                    <ConfirmDialog isDialogOpen={true}>{
                        duplicateTemplateModalConfig
                    }
                    </ConfirmDialog>
                ) : redirectToEditor(confirmTemplateAction.template.id))) }

            <div className='grid-view-container'>
                {stateProps.recentTemplates.map((template) => (
                    <TemplateCard
                        key={template.id}
                        template={template}
                        onCopyUrl={onCopyUrl}
                        setConfirmTemplateAction={setConfirmTemplateAction}
                        setTemplateToPreview={setTemplateToPreview}
                        isUpdating={stateProps.updatingTemplateIds.includes(template.id)}
                        canEdit={canEdit}
                    />
                ))}
            </div>
        </>
    );
}

export default connect<StateProps, DispatchProps, {}, RootState>(mapStateToProps, mapDispatchToProps)(RecentTemplates);
