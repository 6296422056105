import { logError } from 'loggingManager';
import { URLs } from 'utility/constants';
import { axiosWithAuth } from 'interceptors/auth.interceptor';

export function getCollection(tenantId: string, parentId?: string) {
    return axiosWithAuth.get(`${URLs.CollectionBase}/directories`, { params: { tenantId, parentId } })
        .then((response) => response.data._embedded.item)
        .catch((error) => {
            logError(`Could not fetch collection from the API ${error}`);
            throw error;
        });
}

export function getCollectionByIds(tenantId: string, collectionIds: string[]) {
    return axiosWithAuth.post(`${URLs.CollectionBase}/directories/collectionNamesRequest`, { tenantId, ids: collectionIds })
        .then((response) => response.data._embedded.item)
        .catch((error) => {
            logError(`Could not fetch collection from the API ${error}`);
            throw error;
        });
}

export function addNewFolder(newFolder: {label: string; parentId?: string; tenantId: string}) {
    return axiosWithAuth.post(`${URLs.CollectionBase}/directories`, newFolder)
        .then((response) => response.data)
        .catch((error) => {
            logError(`Could not add folder from the API ${error}`);
            throw error;
        });
}

export function editFolder(folder: {label: string; id: string; tenantId: string}) {
    return axiosWithAuth.patch(`${URLs.CollectionBase}/directories/${folder.id}`, folder)
        .then((response) => response.data)
        .catch((error) => {
            logError(`Could not edit folder from the API ${error}`);
            throw error;
        });
}

export function deleteCollection(tenantId: string, collectionId: string) {
    const deleteURL = `${URLs.CollectionBase}/directories/${collectionId}`;
    return axiosWithAuth.delete(deleteURL, { data: { tenantId } })
        .then((response) => response.data)
        .catch((error) => {
            logError(`Could not delete folder from the API ${error}`);
            throw error;
        });
}
