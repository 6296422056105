/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { AllProps, getAllProps, StateProps, mapStateToProps } from 'props.tenantAssignedProtectedRoute';
import { RootState } from './store/rootReducer';

export function TenantAssignedProtectedRoute(allProps: AllProps) {
    const { routeProps, stateProps } = getAllProps(allProps);
    const { component: Component, ...rest } = routeProps;

    const render = (props: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
        if (!stateProps.hasCoamAccess) {
            return (<Redirect to={{ pathname: '/coam-error' }} />);
        }

        if (stateProps.currentTenant && Component) {
            return (<Component tenant={stateProps.currentTenant} {...props} />);
        }

        return (<Redirect to={{ pathname: '/' }} />);
    };

    return (
        <Route render={render} {...rest} />
    );
}

export default connect<StateProps, {}, {}, RootState>(mapStateToProps)(TenantAssignedProtectedRoute);
