/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import cx from 'classnames';
import IconArrowDownAlt from '@cimpress-technology/react-streamline-icons/lib/IconArrowDownAlt';

import './directory.scss';
import NewCollectionInput from 'components/collectionList/NewCollectionInput';
import { DIRECTORY_ALLOWED_ACTIONS } from 'utility/enums';
import { directoryTypes } from 'shared/directory';
import { DirectoryActions } from './directoryActions/DirectoryActions';

interface Props {
    expand: boolean;
    isCollectionSaving?: boolean;
    isCollectionAddedSuccess?: boolean;
    directory: directoryTypes.Directory;
    actions?: directoryTypes.DirectoryActions[];
    onSelect(directory: directoryTypes.Directory): void;
    onExpand(directory: directoryTypes.Directory): void;
    resetIsCollectionAddedSuccess?(): void;
    level?: number;
    levelToAddChild?: number;
}

export function Directory({
    expand,
    directory,
    onSelect,
    onExpand,
    actions,
    isCollectionSaving,
    isCollectionAddedSuccess,
    resetIsCollectionAddedSuccess,
    level,
    levelToAddChild }: Props) {
    const [editOrAddFolder, setEditorAddFolder] = useState(false);
    const [actionTypeSelected, setActionTypeSelected] = useState<DIRECTORY_ALLOWED_ACTIONS | undefined>();
    const [editOrAddValue, setEditOrAddValue] = useState('');

    const editAction = actions && actions.find((action) => action.label === DIRECTORY_ALLOWED_ACTIONS.Edit);
    const addAction = actions && actions.find((action) => action.label === DIRECTORY_ALLOWED_ACTIONS.Add);

    const handleExpand = () => onExpand && onExpand(directory);

    const handleSelect = () => onSelect(directory);

    const addOrEdit = (label: string) => {
        if (actionTypeSelected === DIRECTORY_ALLOWED_ACTIONS.Add) {
            editAction && editAction.onSave && editAction.onSave(label, directory.id, DIRECTORY_ALLOWED_ACTIONS.Add);
        } else {
            addAction && addAction.onSave && addAction.onSave(label, directory.id, DIRECTORY_ALLOWED_ACTIONS.Edit);
        }
    };
    const onCancelAddorEditDirectory = () => {
        setEditorAddFolder(false);
        setActionTypeSelected(undefined);
    };

    const onAddOrEditLabel = (type: DIRECTORY_ALLOWED_ACTIONS) => {
        if (type === DIRECTORY_ALLOWED_ACTIONS.Add) {
            if (!expand) {
                handleExpand();
            }
            setEditOrAddValue('');
        } else {
            setEditOrAddValue(directory.label);
        }
        setEditorAddFolder(true);
        setActionTypeSelected(type);
    };

    const validateInputCheck = () => {
        if (actionTypeSelected === DIRECTORY_ALLOWED_ACTIONS.Add) {
            return addAction && addAction.validateInput;
        }
        return editAction && editAction.validateInput;
    };

    const filterAction = (action: directoryTypes.DirectoryActions) => {
        if (action.label === DIRECTORY_ALLOWED_ACTIONS.Add) {
            if (levelToAddChild && level) {
                return level <= levelToAddChild;
            }
            return true;
        }
        return true;
    };

    const modifiedActions = (actionsToModify: directoryTypes.DirectoryActions[]) => actionsToModify.filter(filterAction);
    return (
        <>
            <div className='directory-info'>
                { (directory.hasSubDirectories || (directory.subDirectories && directory.subDirectories.length > 0))
                    && (
                        <div className={cx('directory__expander', { 'directory__expander--expanded': expand })} onClick={handleExpand}>
                            <IconArrowDownAlt weight='fill' name='arrow-down-12-l' />
                        </div>
                    )}
                <div className='directory-info__header'>
                    { !(actionTypeSelected === DIRECTORY_ALLOWED_ACTIONS.Edit) && <div className='directory__label' onClick={handleSelect}><span>{directory.label}</span></div>}
                    { !editOrAddFolder
                        && (
                            <DirectoryActions
                                directory={directory}
                                actions={actions && modifiedActions(actions)}
                                onAddOrEditLabel={onAddOrEditLabel}
                            />
                        )}
                </div>
            </div>
            {editOrAddFolder && (
                <div className={actionTypeSelected === DIRECTORY_ALLOWED_ACTIONS.Add ? 'root-new-directory' : 'inline-edit-directory'}>
                    <NewCollectionInput
                        onAdd={addOrEdit}
                        onCancel={onCancelAddorEditDirectory}
                        value={editOrAddValue}
                        directory={directory}
                        validateInput={validateInputCheck()}
                        actionSelected={actionTypeSelected}
                        isCollectionSaving={isCollectionSaving}
                        isCollectionAddedSuccess={isCollectionAddedSuccess}
                        resetIsCollectionAddedSuccess={resetIsCollectionAddedSuccess}
                    />
                </div>
            )}
        </>
    );
}
