import React, { useEffect, useState } from 'react';
import { shapes } from '@cimpress/react-components';
import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { getAssetPreview, getPreviewUrl } from 'utility/url.utility';
import { OriginalPreviewSize } from 'utility/constants';
import { PREVIEW_TYPE_VIEW } from 'utility/enums';
import { useTranslation } from 'react-i18next';
import { CarouselPreview } from 'components/CarouselPreviewWrapper/CarousalPreview/CarouselPreview';
import { StateProps, mapStateToProps, OwnProps, AllProps, getAllProps, DispatchProps, mapDispatchToProps } from 'components/CarouselPreviewWrapper/props.carouselPreviewWrapper';

import './carouselPreviewWrapper.scss';
import { templateTypes } from 'store/template';

const { Spinner } = shapes;

const getImages = (assetParams: { numberOfSurfaces: number; currentTemplate?: templateTypes.Template; docref?: string; revisionId?: string }) => {
    const { numberOfSurfaces, currentTemplate, docref, revisionId } = assetParams;
    const imgSources: string[] = [];
    let previewTypes = [PREVIEW_TYPE_VIEW.CloseUp, PREVIEW_TYPE_VIEW.Full, PREVIEW_TYPE_VIEW.FullBleed];
    previewTypes = numberOfSurfaces > maxSurfacesToShow ? [PREVIEW_TYPE_VIEW.CloseUp] : previewTypes;
    for (let i = 1; i <= numberOfSurfaces; i += 1) {
        if (docref) {
            previewTypes.forEach((type) => {
                const src = getPreviewUrl({
                    width: OriginalPreviewSize,
                    view: type,
                    panelNumber: i,
                    documentReferenceUri: encodeURIComponent(docref),
                });
                imgSources.push(src);
            });
        } else if (currentTemplate) {
            previewTypes.forEach((type) => {
                const src = getAssetPreview({
                    assetId: currentTemplate.id,
                    width: OriginalPreviewSize,
                    view: type,
                    panelNumber: i,
                    revisionId,
                });
                imgSources.push(src);
            });
        }
    }
    return imgSources;
};

export const maxSurfacesToShow = 4;

function CarouselPreviewWrapper(allProps: AllProps) {
    const { ownProps, stateProps, dispatchProps } = getAllProps(allProps);
    const [imageSources, setImageSources] = useState<string[]>([]);

    const [surfaces, setSurfaces] = useState<string[] | undefined>();
    const [imageView, setImageView] = useState('');

    const { t } = useTranslation();

    let imageViews: string[] = [t('success.imageViews.closeUp'), t('success.imageViews.full'), t('success.imageViews.fullBleed')];

    const setSurfaceDetails = (surfaceSet?: string[]) => {
        imageViews = surfaceSet && surfaceSet.length > maxSurfacesToShow ? [imageViews[0]] : imageViews;
        surfaceSet && setImageView(`${surfaceSet[0]}, ${imageViews[0]} ${t('success.imageViews.view')}`); // set initial view
        surfaceSet && setSurfaces(surfaceSet);
    };

    const onSlideChange = (slideNumber: number) => {
        if (surfaces) {
            let view = '';
            let surfaceIndex = Math.ceil(slideNumber / imageViews.length);
            if (surfaces && surfaces.length > maxSurfacesToShow) {
                // eslint-disable-next-line prefer-destructuring
                view = imageViews[0];
                surfaceIndex = slideNumber;
            } else {
                view = imageViews[(slideNumber - 1 < 0 ? 0 : slideNumber - 1) % imageViews.length];
            }
            setImageView(`${surfaces[surfaceIndex - 1]}, ${view} ${t('success.imageViews.view')}`);
        }
    };

    useEffect(() => {
        if (stateProps.currentTemplate && !(stateProps.currentTemplate.surfaces?.length)) {
            dispatchProps.loadSurfaces(stateProps.currentTemplate.id);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateProps.currentTemplate?.id]);

    useEffect(() => {
        const { currentTemplate } = stateProps;
        if (currentTemplate && !stateProps.isSurfaceSetsLoading) {
            const images = getImages({
                numberOfSurfaces: currentTemplate.surfaces?.length || 1,
                currentTemplate,
                revisionId: ownProps.selectedVersionId,
            });
            setImageSources(images);
            currentTemplate.surfaces && setSurfaceDetails(currentTemplate.surfaces);
        } else if (!currentTemplate && ownProps.imageSources) {
            setImageSources(ownProps.imageSources);
        } else if (ownProps.designTransferTransientDocument) {
            const { document } = ownProps.designTransferTransientDocument.cimDoc;
            const images = getImages({
                numberOfSurfaces: (document.surfaces || document.panels).length,
                docref: ownProps.designTransferTransientDocument.documentReferenceUrl,
            });
            setImageSources(images);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateProps.currentTemplate?.id, stateProps.isSurfaceSetsLoading, ownProps.selectedVersionId]);

    const getImageSources = () => {
        if (imageSources.length) {
            return imageSources;
        }
        return ownProps.imageSources || [];
    };

    const getImageLoadingState = () => {
        if (ownProps.designTransferTransientDocument) {
            return !ownProps.designTransferTransientDocument.documentReferenceUrl;
        }
        return stateProps.isSurfaceSetsLoading;
    };

    return (
        <>
            {getImageLoadingState() ? <Spinner className='carousel-wrapper-spinner' />
                : (
                    <>
                        <div className='carousel-preview-container'>
                            {ownProps.showImageHeading && <h2 className='carousel-header'>{imageView}</h2>}
                            <CarouselPreview
                                imageSources={getImageSources()}
                                alt={t('common.preview')}
                                landscape={ownProps.landscape}
                                minimal={ownProps.minimal}
                                pxSize={ownProps.pxSize}
                                size={ownProps.size}
                                onSlideChanged={onSlideChange}
                                setOpenTemplatePreviewModal={ownProps.setOpenTemplatePreviewModal}
                                showZoomOverlay={ownProps.showZoomOverlay}
                            />
                        </div>
                    </>
                )}
        </>
    );
}

export default connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)(CarouselPreviewWrapper);
