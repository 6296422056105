import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { templateTypes } from 'store/template';
import { formatNotification } from 'store/uiState';
import { SNACKBAR_NOTIFICATION_TYPES } from 'utility/enums';
import Templates from 'components/templates/Templates';
import { StateProps, DispatchProps, OwnProps, mapStateToProps, mapDispatchToProps, AllProps, getAllProps } from './props.templatesWrapper';

function TemplatesWrapper(allProps: AllProps) {
    const { ownProps, stateProps, dispatchProps } = getAllProps(allProps);

    useEffect(() => {
        dispatchProps.loadTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatchProps.loadTemplates, stateProps.tenant, stateProps.filterCriteria, stateProps.activeCollection?.id]);

    const canEdit = stateProps.hasMandatoryDesignerSettings && !!stateProps.tenant?.permissions.asset.canCreate;

    const onCopyUrl = () => {
        dispatchProps.addNotification([formatNotification(SNACKBAR_NOTIFICATION_TYPES.Success, { key: 'notifications.success.copyUrl' })]);
    };

    const onRemoveTemplateFromCollection = (templatesToRemoveFromCollection: templateTypes.Template[]) => {
        if (stateProps.activeCollection) {
            dispatchProps.removeTemplatesFromCollection({ templates: templatesToRemoveFromCollection, sourceCollectionId: stateProps.activeCollection.id });
            ownProps.setSelectedTemplates && ownProps.setSelectedTemplates([]);
        }
    };

    return (
        <>
            <Templates
                canEdit={canEdit}
                templates={stateProps.allTemplates}
                tenant={stateProps.tenant}
                enableEditingAceTemplates={stateProps.enableEditingAceTemplates}
                uploadsTenant={stateProps.uploadsTenant}
                selectedTemplates={ownProps.selectedTemplates}
                setSelectedTemplates={ownProps.setSelectedTemplates}
                onCopyUrl={onCopyUrl}
                onRemoveTemplateFromCollection={onRemoveTemplateFromCollection}
                loadNextTemplates={dispatchProps.loadNextTemplates}
                hasMoreTemplates={stateProps.hasMoreTemplates}
                activeCollection={stateProps.activeCollection}
                updatingTemplateIds={stateProps.updatingTemplateIds}
                setCurrentTemplate={dispatchProps.setCurrentTemplate}
                setOpenTemplateInfoDrawer={ownProps.setOpenTemplateInfoDrawer}
                setOpenTemplatePreviewModal={ownProps.setOpenTemplatePreviewModal}
                onDeleteTemplate={dispatchProps.onDeleteTemplate}
                onRestoreTemplate={dispatchProps.onRestoreTemplate}
            />
        </>
    );
}

export default connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)(TemplatesWrapper);
