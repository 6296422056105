import { RootState } from 'store/rootReducer';
import { uiStateSelector } from 'store/uiState';
import { templateActions, templateTypes, templateSelector } from 'store/template';
import { tenantSelector, tenantTypes } from 'store/tenant';
import { StructuredTags } from 'store/uiState/types.uiState';

export interface OwnProps {
    selectedVersion?: templateTypes.TemplateVersion;
    showAdditionalVersionDetails: boolean;
}

export interface StateProps {
    currentTemplate: templateTypes.Template | undefined;
    publishing: boolean;
    hasMandatoryDesignerSettings: boolean;
    currentTenant?: tenantTypes.Tenant;
    structuredTags: StructuredTags[] | undefined;
}

export interface DispatchProps {
    loadTemplateById(templateId: string): void;
    publishTemplateVersion(version: templateTypes.TemplateVersion): void;
}

export type AllProps = StateProps & DispatchProps & OwnProps;

export const getAllProps = (props: AllProps) => {
    const { selectedVersion,
        currentTemplate, publishing, hasMandatoryDesignerSettings, currentTenant, structuredTags,
        loadTemplateById, publishTemplateVersion, showAdditionalVersionDetails } = props;
    const ownProps: OwnProps = { selectedVersion, showAdditionalVersionDetails };
    const stateProps: StateProps = { currentTemplate, publishing, hasMandatoryDesignerSettings, currentTenant, structuredTags };
    const dispatchProps: DispatchProps = { loadTemplateById, publishTemplateVersion };

    return { ownProps, stateProps, dispatchProps };
};

export const mapStateToProps = (state: RootState): StateProps => ({
    currentTemplate: templateSelector.selectCurrentTemplateWithPreview(state),
    publishing: uiStateSelector.selectIfLoading(state, templateActions.publishTemplateVersion.toString()),
    hasMandatoryDesignerSettings: uiStateSelector.selectHasMandatoryDesignerSettings(state),
    currentTenant: tenantSelector.selectCurrentTenant(state),
    structuredTags: state.uiState.settings?.structuredTags,
});

export const mapDispatchToProps: DispatchProps = {
    loadTemplateById: templateActions.loadTemplateById,
    publishTemplateVersion: templateActions.publishTemplateVersion,
};
