import Axios from 'axios';
import { logError } from 'loggingManager';
import { URLs } from 'utility/constants';
import { GetClipArtCategoriesResponse, GetClipArtsResponse } from './types.clipart';

export async function getClipArts(subCategoryId: string): Promise<GetClipArtsResponse> {
    try {
        const response = await Axios.get<GetClipArtsResponse>(`${URLs.ClipArtBase}/ClipArt/GetClipArtItems?subCatID=${subCategoryId}&pageSize=40&pageNumber=1`);
        return response.data;
    } catch (error) {
        logError(`Could not retrieve cliparts from the clipart API. ${error?.message}`);
        throw error;
    }
}

export async function getClipArtCategories(rootCategoryId?: string): Promise<GetClipArtCategoriesResponse> {
    try {
        const response = await Axios.get<GetClipArtCategoriesResponse>(`${URLs.ClipArtBase}/ClipArt/GetCategories?rootCategoryId=${rootCategoryId || ''}`);
        return response.data;
    } catch (error) {
        logError(`Could not retrieve category from the clipart API. ${error?.message}`);
        throw error;
    }
}
