import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EDITOR_MODE } from 'utility/enums';
import { EditorState } from './types.editor';

const INITIAL_STATE: EditorState = {
    editorLoadSettings: {
        tabsHidden: false,
        switcherHidden: false,
        mode: EDITOR_MODE.AddRevision,
    },
};

const slice = createSlice({
    name: 'editor',
    initialState: INITIAL_STATE,
    reducers: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        saveDocument: (state, { payload }: PayloadAction<any>) => { },
        saveDocumentSuccess: (state) => {},
        saveDocumentFailure: (state) => { },

        toggleSwitcherHidden: (state, { payload }: PayloadAction<boolean>) => {
            state.editorLoadSettings.switcherHidden = payload !== undefined ? payload : !state.editorLoadSettings.switcherHidden;
        },
        toggleTabsHidden: (state, { payload }: PayloadAction<boolean>) => { state.editorLoadSettings.tabsHidden = payload; },

        // publishTemplate: (state) => { }, // TODO: Move it to template slice
        // publishTemplateSuccess: (state) => { }, // TODO: Move it to template slice
        // publishTemplateFailure: (state) => { }, // TODO: Move it to template slice

        setEditorMode: (state, { payload }: PayloadAction<EDITOR_MODE>) => { state.editorLoadSettings.mode = payload; },
    },
});

export default slice;
