import { RootState } from 'store/rootReducer';
import { collectionTypes, collectionSelector } from 'store/collection';
import { uiStateTypes, uiStateActions, uiStateSelector } from 'store/uiState';
import { templateActions, templateTypes, templateSelector } from 'store/template';
import { tenantSelector, tenantTypes } from 'store/tenant';

export interface OwnProps {
    selectedTemplates?: templateTypes.Template[];
    setSelectedTemplates?(payload: templateTypes.Template[]): void;
    setOpenTemplateInfoDrawer(payload: boolean): void;
    setOpenTemplatePreviewModal(payload: boolean): void;
}
export interface StateProps {
    tenant?: tenantTypes.Tenant;
    allTemplates: templateTypes.Template[];
    hasMoreTemplates: boolean;
    activeCollection: collectionTypes.Collection | undefined;
    enableEditingAceTemplates: boolean;
    uploadsTenant: string | undefined;
    updatingTemplateIds: string[];
    hasMandatoryDesignerSettings: boolean;
    filterCriteria: uiStateTypes.FilterCriteria;
}
export interface DispatchProps {
    addNotification(payload: uiStateTypes.Notification[]): void;
    onDeleteTemplate(payload: string): void;
    onRestoreTemplate(payload: string): void;
    loadNextTemplates(): void;
    removeTemplatesFromCollection(payload: templateTypes.ActionRemoveTemplates): void;
    startLoadingCatalog(): void;
    loadTemplates(): void;
    setCurrentTemplate(playload: templateTypes.Template): void;
}
export type AllProps = StateProps & DispatchProps & OwnProps;

export const getAllProps = (props: AllProps) => {
    const { selectedTemplates, setSelectedTemplates, setOpenTemplateInfoDrawer, setOpenTemplatePreviewModal,
        tenant, allTemplates, hasMoreTemplates, activeCollection, enableEditingAceTemplates, uploadsTenant, updatingTemplateIds, hasMandatoryDesignerSettings, filterCriteria,
        addNotification, onDeleteTemplate, onRestoreTemplate, loadNextTemplates, removeTemplatesFromCollection,
        loadTemplates, startLoadingCatalog, setCurrentTemplate } = props;
    const ownProps: OwnProps = { selectedTemplates, setSelectedTemplates, setOpenTemplateInfoDrawer, setOpenTemplatePreviewModal };
    const stateProps: StateProps = { tenant,
        allTemplates,
        hasMoreTemplates,
        activeCollection,
        enableEditingAceTemplates,
        uploadsTenant,
        updatingTemplateIds,
        hasMandatoryDesignerSettings,
        filterCriteria };
    const dispatchProps: DispatchProps = { addNotification,
        onDeleteTemplate,
        onRestoreTemplate,
        loadNextTemplates,
        removeTemplatesFromCollection,
        loadTemplates,
        startLoadingCatalog,
        setCurrentTemplate,
    };
    return { ownProps, stateProps, dispatchProps };
};

export const mapStateToProps = (state: RootState): StateProps => ({
    tenant: tenantSelector.selectCurrentTenant(state),
    enableEditingAceTemplates: uiStateSelector.selectAceTemplateEdit(state),
    allTemplates: templateSelector.selectAllTemplatesWithPreview(state),
    hasMoreTemplates: templateSelector.selectHasMoreTemplates(state),
    activeCollection: collectionSelector.selectActiveCollection(state),
    updatingTemplateIds: uiStateSelector.selectLoadingIdsByActions(state, templateActions.onDeleteTemplate.toString(),
        templateActions.onRestoreTemplate.toString()),
    hasMandatoryDesignerSettings: uiStateSelector.selectHasMandatoryDesignerSettings(state),
    filterCriteria: uiStateSelector.selectFilterCriteria(state),
    uploadsTenant: uiStateSelector.selectUploadsTenantSettings(state),
});
export const mapDispatchToProps: DispatchProps = {
    addNotification: uiStateActions.addNotification,
    onDeleteTemplate: templateActions.onDeleteTemplate,
    onRestoreTemplate: templateActions.onRestoreTemplate,
    loadNextTemplates: templateActions.loadNextTemplates,
    removeTemplatesFromCollection: templateActions.removeTemplatesFromCollection,
    loadTemplates: templateActions.loadTemplates,
    startLoadingCatalog: templateActions.startLoadingCatalog,
    setCurrentTemplate: templateActions.setCurrentTemplate,
};
