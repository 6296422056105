import React, { useEffect, useState } from 'react';
import { RootState } from 'store/rootReducer';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Drawer, NavTab, NavTabItem } from '@cimpress/react-components';
import CarouselPreviewWrapper from 'components/CarouselPreviewWrapper/CarouselPreviewWrapper';
import TemplateDetails from 'components/templateDetails/TemplateDetails';
import { useResponsiveDesign } from 'providers/ResponsiveDesign';
import TemplateVersionsList from 'connectedComponents/templateVersionsList/TemplateVersionsList';
import { templateTypes } from 'store/template';
import ActionsDropdown from 'components/actionDropdown/ActionsDropdown';
import { formatNotification } from 'store/uiState';
import { SNACKBAR_NOTIFICATION_TYPES, TEMPLATE_ACTIONS } from 'utility/enums';
import { AllProps, getAllProps, OwnProps, StateProps, DispatchProps, mapStateToProps, mapDispatchToProps } from './props.templateInfoDrawer';

import './templateInfoDrawer.scss';

enum TABS {
    Information = 'information',
    Collections = 'collections',
    Versions = 'versions',
}

function TemplateInfoDrawer(allProps: AllProps) {
    const { ownProps, stateProps, dispatchProps } = getAllProps(allProps);
    const [size, setSize] = useState<number>();
    const [selectedTab, setSelectedTab] = useState(TABS.Information);
    const [selectedVersion, setSelectedVersion] = useState<templateTypes.TemplateVersion>();
    const { isMobile, isTablet } = useResponsiveDesign();
    const { t } = useTranslation();

    const onCopyTemplateUrl = () => {
        dispatchProps.addNotification([formatNotification(SNACKBAR_NOTIFICATION_TYPES.Success, { key: 'notifications.success.copyUrl' })]);
    };

    const canEdit = stateProps.hasMandatoryDesignerSettings && !!stateProps.tenant?.permissions.asset.canCreate;

    useEffect(() => {
        if (isMobile) {
            setSize(1);
        } else if (isTablet) {
            setSize(0.5);
        } else {
            setSize(0.3);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile, isTablet]);

    const onRequestHide = () => {
        ownProps.setOpen(false);
        dispatchProps.resetCurrentTemplate();
        setSelectedTab(TABS.Information);
    };

    const getTabContent = (template: templateTypes.Template) => {
        let tabContent;
        switch (selectedTab) {
        case TABS.Collections:
            tabContent = (
                <div className='collection-list'>
                    {template.collectionLabels && template.collectionLabels?.length > 0 ? (
                        <>
                            <h3>{t('templateInfoDrawer.collectionHeader')}</h3>
                            <ul>
                                {template.collectionLabels.map((collection: { id: string; label: string }) => <li key={collection.id}>{collection.label}</li>)}
                            </ul>
                        </>
                    ) : <h3>{t('templateInfoDrawer.noCollectionHeader')}</h3>}
                </div>
            );
            break;
        case TABS.Versions:
            tabContent = (
                <div className='versions-list__items list-group'>
                    <TemplateVersionsList
                        id={template.id}
                        selectedVersion={selectedVersion}
                        onVersionChange={setSelectedVersion}
                    />
                </div>
            );
            break;
        default:
            tabContent = (
                <TemplateDetails
                    template={template}
                    editable={true}
                    editTemplateInformation={dispatchProps.editTemplateInformation}
                    showAdditionalVersionDetails={true}
                    structuredTags={stateProps.structuredTags}
                />
            );
            break;
        }
        return tabContent;
    };

    return (
        <>{stateProps.template && (
            <Drawer
                position='right'
                autoResize={true}
                size={size}
                show={ownProps.open}
                header={stateProps.template.name}
                onRequestHide={onRequestHide}
                closeOnClickOutside={true}
                className='template-details'
            >
                <div className='template-details__preview'>
                    <CarouselPreviewWrapper
                        minimal={true}
                        pxSize={200}
                        selectedVersionId={selectedVersion?.id}
                        setOpenTemplatePreviewModal={ownProps.setOpenTemplatePreviewModal}
                        showZoomOverlay={true}
                    />
                </div>

                <div className='template-details__actions'>
                    <ActionsDropdown
                        templateDetail={stateProps.template}
                        title='Template Actions'
                        canEdit={canEdit}
                        onCopyUrl={onCopyTemplateUrl}
                        onRestoreTemplate={dispatchProps.onRestoreTemplate}
                        onDeleteTemplate={dispatchProps.onDeleteTemplate}
                        disabledActions={[TEMPLATE_ACTIONS.Create]}
                        enableEditingAceTemplates={stateProps.enableEditingAceTemplates}
                        uploadsTenant={stateProps.uploadsTenant}
                    />
                </div>

                <hr className='template-details__divider' />
                <NavTab className='template-details__tabs'>
                    <NavTabItem className='template-details__tabs-item' active={selectedTab === TABS.Information}>
                        <button type='button' onClick={() => setSelectedTab(TABS.Information)}>{t('templateInfoDrawer.information')}</button>
                    </NavTabItem>
                    <NavTabItem className='template-details__tabs-item' active={selectedTab === TABS.Collections}>
                        <button type='button' onClick={() => setSelectedTab(TABS.Collections)}>{t('templateInfoDrawer.collections')}</button>
                    </NavTabItem>
                    <NavTabItem className='template-details__tabs-item' active={selectedTab === TABS.Versions}>
                        <button type='button' onClick={() => setSelectedTab(TABS.Versions)}>{t('templateInfoDrawer.versions')}</button>
                    </NavTabItem>
                </NavTab>
                <div className='template-details__content'>
                    {getTabContent(stateProps.template)}
                </div>
            </Drawer>
        )}
        </>
    );
}
export default connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)(TemplateInfoDrawer);
