import { takeEvery, call, put, select, all, StrictEffect } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { logError } from 'loggingManager';

import { tenantSelector, tenantTypes } from 'store/tenant';
import { uiStateActions, formatNotification } from 'store/uiState';
import { SNACKBAR_NOTIFICATION_TYPES } from 'utility/enums';
import { getCollectionIdsFromTemplates } from 'utility/helper';
import { templateSelector, templateActions, templateTypes } from 'store/template';
import { Collection } from './types.collection';
import { collectionActions, collectionTypes, collectionSelector } from './index';
import { getCollection, addNewFolder, editFolder, deleteCollection, getCollectionByIds } from './service.collection';
import { getActiveCollectionIdAfterDelete } from './collectionUtils';

export function* onLoadCollection() {
    try {
        const tenant: tenantTypes.Tenant = yield select(tenantSelector.selectCurrentTenant);
        const collection = (yield call(getCollection, tenant.tenantId)) as Collection[];
        yield put(collectionActions.onCollectionLoadSuccess(collection));
    } catch (error) {
        yield put(uiStateActions.addNotification([formatNotification(SNACKBAR_NOTIFICATION_TYPES.Danger, { key: 'notifications.danger.collectionLoad' }, 0)]));
        yield put(collectionActions.onCollectionLoadFailure());
    }
}

export function* onAddOrEditCollection(action: PayloadAction<{label: string; parentId: string; id?: string}>) {
    try {
        const { label, parentId, id } = action.payload;
        const tenant = (yield select(tenantSelector.selectCurrentTenant)) as tenantTypes.Tenant;
        const folderInfo = id
            ? (yield call(editFolder, { label, id, tenantId: tenant.tenantId })) as Collection
            : (yield call(addNewFolder, { label, parentId, tenantId: tenant.tenantId })) as Collection;
        if (id) {
            // only need to update collectionLabels on edit of collection
            const allTemplates: templateTypes.Template[] = yield select(templateSelector.selectAllTemplates);
            const collectionIds = getCollectionIdsFromTemplates(allTemplates);
            yield put(collectionActions.loadCollectionByIds(collectionIds));
        }
        yield put(collectionActions.onAddOrEditCollectionSuccess({ newCollection: folderInfo, tenantId: tenant.tenantId }));
    } catch (error) {
        yield put(uiStateActions.addNotification([formatNotification(SNACKBAR_NOTIFICATION_TYPES.Danger, { key: 'notifications.danger.collectionAddOrEdit' }, 0)]));
        yield put(collectionActions.onAddOrEditCollectionFailure());
    }
}

export function* onCollectionChildrenLoad(action: PayloadAction<collectionTypes.Collection>) {
    try {
        const tenant = (yield select(tenantSelector.selectCurrentTenant)) as tenantTypes.Tenant;
        const parentId = action.payload.id;
        const children = (yield call(getCollection, tenant.tenantId, parentId)) as collectionTypes.Collection[];
        yield put(collectionActions.onCollectionChildrenLoadSuccess({ parentId, children, tenantId: tenant.tenantId }));
    } catch (error) {
        yield put(uiStateActions.addNotification([formatNotification(SNACKBAR_NOTIFICATION_TYPES.Danger, { key: 'notifications.danger.collectionChildrenLoad' }, 0)]));
        yield put(collectionActions.onCollectionChildrenLoadFailure());
    }
}

export function* onDeleteCollection(action: PayloadAction<string>) {
    const tenant = (yield select(tenantSelector.selectCurrentTenant)) as tenantTypes.Tenant;
    const activeCollection: Collection | undefined = yield select(collectionSelector.selectActiveCollection);
    const allCollections: Collection[] = yield select(collectionSelector.selectAllCollections);
    const collectionId = action.payload;
    try {
        const deleted = (yield call(deleteCollection, tenant.tenantId, collectionId)) as collectionTypes.Collection;
        yield put(collectionActions.deleteCollectionSuccess(collectionId));
        if (activeCollection && activeCollection.id === collectionId) {
            const activeCollectionIdAfterDelete = getActiveCollectionIdAfterDelete(allCollections, collectionId);
            yield put(collectionActions.updateActiveCollection(activeCollectionIdAfterDelete));
        }
        yield put(uiStateActions.addNotification([formatNotification(SNACKBAR_NOTIFICATION_TYPES.Success, { key: 'notifications.success.deleteCollection', values: { name: deleted.label } })]));
    } catch (error) {
        logError('Failed to delete collection');
        yield put(uiStateActions.addNotification([formatNotification(SNACKBAR_NOTIFICATION_TYPES.Danger, { key: 'notifications.danger.deleteCollection' }, 0)]));
        yield put(collectionActions.deleteCollectionFailure(collectionId));
    }
}

export function* onLoadCollectionByIds(action: PayloadAction<string[]>) {
    const tenant: tenantTypes.Tenant | undefined = yield select(tenantSelector.selectCurrentTenant);
    if (tenant === undefined) {
        yield put(collectionActions.loadCollectionByIdsFailure());
        return;
    }
    const collectionIds = action.payload.filter((x, i, a) => x && a.indexOf(x) === i);
    try {
        const collections: Collection[] = yield call(getCollectionByIds, tenant.tenantId, collectionIds);
        yield put(collectionActions.loadCollectionByIdsSuccess(collections));
    } catch (error) {
        yield put(collectionActions.loadCollectionByIdsFailure());
    }
}

export function* handleProceedToDelete(action: PayloadAction<templateTypes.ActionMoveTemplatesSuccess | templateTypes.ActionRemoveTemplatesSuccess>) {
    const { deleteCollectionId } = action.payload;
    if (deleteCollectionId) {
        yield put(collectionActions.deleteCollection(deleteCollectionId));
    }
}

export default function* collectionSaga(): Generator<StrictEffect, StrictEffect, never> {
    return yield all([
        yield takeEvery(collectionActions.onCollectionLoad, onLoadCollection),
        yield takeEvery(collectionActions.onAddOrEditCollection, onAddOrEditCollection),
        yield takeEvery(collectionActions.onCollectionChildrenLoad, onCollectionChildrenLoad),
        yield takeEvery(collectionActions.loadCollectionByIds, onLoadCollectionByIds),
        yield takeEvery(collectionActions.deleteCollection, onDeleteCollection),
        yield takeEvery([
            templateActions.moveTemplatesToCollectionsSuccess,
            templateActions.removeTemplatesFromCollectionSuccess,
        ], handleProceedToDelete),
    ]);
}
