import { RootState } from 'store/rootReducer';
import { templateActions, templateTypes, templateSelector } from 'store/template';
import { uiStateSelector } from 'store/uiState';

export interface OwnProps {
    id: string;
    selectedVersion: templateTypes.TemplateVersion | undefined;
    versionsToCompare?: templateTypes.TemplateVersion[];
    onVersionChange(payload: templateTypes.TemplateVersion | undefined): void;
    addVersionToCompare?(payload: templateTypes.TemplateVersion): void;
}

export interface StateProps {
    templateVersions: templateTypes.TemplateVersion[];
    currentTemplate: templateTypes.Template | undefined;
    isVersionListLoading: boolean;
}

export interface DispatchProps {
    fetchTemplateVersions(templateId: string): void;
}

export type AllProps = StateProps & DispatchProps & OwnProps;

export const getAllProps = (props: AllProps) => {
    const { id, selectedVersion, versionsToCompare, onVersionChange, addVersionToCompare,
        templateVersions, currentTemplate, isVersionListLoading, fetchTemplateVersions } = props;
    const ownProps: OwnProps = { id, selectedVersion, versionsToCompare, onVersionChange, addVersionToCompare };
    const stateProps: StateProps = { templateVersions, currentTemplate, isVersionListLoading };
    const dispatchProps: DispatchProps = { fetchTemplateVersions };

    return { ownProps, stateProps, dispatchProps };
};

export const mapStateToProps = (state: RootState): StateProps => ({
    templateVersions: templateSelector.selectTemplateVersionsWithPreview(state),
    currentTemplate: templateSelector.selectCurrentTemplateWithPreview(state),
    isVersionListLoading: uiStateSelector.selectIfLoading(state, templateActions.fetchTemplateVersions.toString()),
});

export const mapDispatchToProps: DispatchProps = {
    fetchTemplateVersions: templateActions.fetchTemplateVersions,
};
