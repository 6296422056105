import { RootState } from 'store/rootReducer';
import { uiStateSelector, uiStateTypes, uiStateActions } from 'store/uiState';
import { templateActions, templateTypes, templateSelector } from 'store/template';
import { collectionSelector, collectionActions, collectionTypes } from 'store/collection';
import { CollectionTagPrefix } from 'utility/constants';
import { tenantSelector, tenantTypes } from 'store/tenant';

export interface StateProps {
    template?: templateTypes.Template;
    isCreatingPdf: boolean;
    templateCollection: collectionTypes.Collection | undefined;
    collections: collectionTypes.Collection[];
    hasCollection: boolean;
    hasMandatoryDesignerSettings: boolean;
    tenant: tenantTypes.Tenant | undefined;
    enableEditingAceTemplates: boolean;
    uploadsTenant: string | undefined;
}

export interface DispatchProps {
    onCollectionLoad(): void;
    addTemplatesToCollections(payload: { templates: templateTypes.Template[]; targetCollections: collectionTypes.Collection[] }): void;
    createPrintPdf: (payload: {docRefUrl: string; fileName: string}) => {};
    addNotification(payload: uiStateTypes.Notification[]): void;
}

export type AllProps = StateProps & DispatchProps;

export const getAllProps = (props: AllProps) => {
    const {
        template,
        isCreatingPdf,
        templateCollection,
        collections,
        hasCollection,
        enableEditingAceTemplates,
        uploadsTenant,
        onCollectionLoad,
        addTemplatesToCollections,
        createPrintPdf,
        addNotification,
        hasMandatoryDesignerSettings,
        tenant } = props;
    const stateProps: StateProps = { template, isCreatingPdf, templateCollection, collections, hasCollection, hasMandatoryDesignerSettings, tenant, enableEditingAceTemplates, uploadsTenant };
    const dispatchProps: DispatchProps = { onCollectionLoad, addTemplatesToCollections, createPrintPdf, addNotification };

    return { stateProps, dispatchProps };
};

const getAssetCollectionId = (template: templateTypes.Template | undefined): string | undefined => {
    const collectionTag = template && template.tags && template.tags.find((tag) => tag.includes(CollectionTagPrefix));
    if (collectionTag) {
        return collectionTag.replace(CollectionTagPrefix, '');
    }
    return undefined;
};

export const mapStateToProps = (state: RootState): StateProps => {
    const templateCollectionId = getAssetCollectionId(templateSelector.selectCurrentTemplate(state));
    return {
        template: templateSelector.selectCurrentTemplateWithPreview(state),
        isCreatingPdf: uiStateSelector.selectIfLoading(state, templateActions.createPrintPdf.toString()),
        templateCollection: templateCollectionId ? collectionSelector.selectCollectionById(state, templateCollectionId) : undefined,
        collections: collectionSelector.selectAllCollections(state),
        hasCollection: collectionSelector.selectHasCollection(state),
        hasMandatoryDesignerSettings: uiStateSelector.selectHasMandatoryDesignerSettings(state),
        tenant: tenantSelector.selectCurrentTenant(state),
        enableEditingAceTemplates: uiStateSelector.selectAceTemplateEdit(state),
        uploadsTenant: uiStateSelector.selectUploadsTenantSettings(state),
    };
};

export const mapDispatchToProps: DispatchProps = {
    onCollectionLoad: collectionActions.onCollectionLoad,
    addTemplatesToCollections: templateActions.addTemplatesToCollections,
    createPrintPdf: templateActions.createPrintPdf,
    addNotification: uiStateActions.addNotification };
