import { RootState } from 'store/rootReducer';
import { collectionTypes, collectionSelector } from 'store/collection';
import { templateActions, templateTypes, templateSelector } from 'store/template';

export interface OwnProps {
    selectedTemplates: templateTypes.Template[];
    setSelectedTemplates(payload: templateTypes.Template[]): void;
    removeAllSelectedTemplates(): void;
}
export interface StateProps {
    allTemplates: templateTypes.Template[];
    activeCollection: collectionTypes.Collection | undefined;
    hasCollection: boolean;
    collections: collectionTypes.Collection[];
    bulkProgress: templateTypes.BulkProgress;
}
export interface DispatchProps {
    removeTemplatesFromCollection(payload: templateTypes.ActionRemoveTemplates): void;
    addTemplatesToCollections(payload: { templates: templateTypes.Template[]; targetCollections: collectionTypes.Collection[] }): void;
    moveTemplatesToCollections(payload: templateTypes.ActionMoveTemplates): void;
    onBulkDeleteTemplate(payload: { templates: templateTypes.Template[]}): void;
    onBulkRestoreTemplate(payload: { templates: templateTypes.Template[]}): void;
}
export type AllProps = StateProps & DispatchProps & OwnProps;

export const getAllProps = (props: AllProps) => {
    const { selectedTemplates, setSelectedTemplates, removeAllSelectedTemplates,
        allTemplates, activeCollection, hasCollection, collections, bulkProgress,
        removeTemplatesFromCollection, addTemplatesToCollections, moveTemplatesToCollections, onBulkDeleteTemplate, onBulkRestoreTemplate } = props;
    const ownProps: OwnProps = { selectedTemplates, setSelectedTemplates, removeAllSelectedTemplates };
    const stateProps: StateProps = { allTemplates, activeCollection, hasCollection, collections, bulkProgress };
    const dispatchProps: DispatchProps = {
        removeTemplatesFromCollection, addTemplatesToCollections, moveTemplatesToCollections, onBulkDeleteTemplate, onBulkRestoreTemplate };
    return { ownProps, stateProps, dispatchProps };
};

export const mapStateToProps = (state: RootState): StateProps => ({
    allTemplates: templateSelector.selectAllTemplates(state),
    activeCollection: collectionSelector.selectActiveCollection(state),
    hasCollection: collectionSelector.selectHasCollection(state),
    collections: collectionSelector.selectAllCollections(state),
    bulkProgress: templateSelector.selectBulkProgress(state),
});
export const mapDispatchToProps: DispatchProps = {
    removeTemplatesFromCollection: templateActions.removeTemplatesFromCollection,
    addTemplatesToCollections: templateActions.addTemplatesToCollections,
    moveTemplatesToCollections: templateActions.moveTemplatesToCollections,
    onBulkDeleteTemplate: templateActions.bulkDeleteTemplate,
    onBulkRestoreTemplate: templateActions.bulkRestoreTemplate,
};
