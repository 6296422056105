import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Accordion, Button } from '@cimpress/react-components';
import cx from 'classnames';

import { RootState } from 'store/rootReducer';
import { templateTypes } from 'store/template';
import { DESIGNER_MODE } from 'utility/enums';
import { useResponsiveDesign } from 'providers/ResponsiveDesign';
import CarouselPreviewWrapper from 'components/CarouselPreviewWrapper/CarouselPreviewWrapper';
import PreviewModal from 'components/preview/PreviewModal';
import TemplateDetails from 'components/templateDetails/TemplateDetails';
import { AllProps, getAllProps, StateProps, DispatchProps, OwnProps, mapStateToProps, mapDispatchToProps } from './props.templateVersionDetailsWrapper';
import './templateVersionDetailsWrapper.scss';

function TemplateVersionDetailsWrapper(allProps: AllProps) {
    const { ownProps, stateProps, dispatchProps } = getAllProps(allProps);
    const { t } = useTranslation();
    const { isTablet, isDesktopOrLaptop, isMobile } = useResponsiveDesign();
    const [templateToPreview, setTemplateToPreview] = useState<templateTypes.TemplateVersion>();
    const params: {id: string} = useParams();

    const onModalClose = () => {
        setTemplateToPreview(undefined);
    };

    const canedit = stateProps.hasMandatoryDesignerSettings && !!(stateProps.currentTenant && stateProps.currentTenant.permissions.asset.canCreate);

    useEffect(() => {
        dispatchProps.loadTemplateById(params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatchProps.loadTemplateById, params.id]);

    const publishSelectedRevision = () => ownProps.selectedVersion && dispatchProps.publishTemplateVersion(ownProps.selectedVersion);
    return (
        <>{(ownProps.selectedVersion && stateProps.currentTemplate) && (
            <>
                { templateToPreview
                    && (
                        <PreviewModal
                            title={stateProps.currentTemplate.name}
                            src={templateToPreview.original}
                            onModalClose={onModalClose}
                        />
                    )}
                <div className={cx({ 'version-preview-details-tablet': isTablet })}>
                    <div className={cx('version-preview-details-wrapper')}>
                        <div className='template-data__title'>
                            <h2>{t('version.templateData.title')}</h2>
                            {
                                canedit && !stateProps.currentTemplate.attributes?.camDesignId
                                    && (
                                        <div className={cx('template-data__actions', { tablet: isTablet })}>
                                            <Link
                                                to={ownProps.selectedVersion
                                                    ? `/editor/${DESIGNER_MODE.TemplateEditor}/${stateProps.currentTemplate.id}/${ownProps.selectedVersion.id}`
                                                    : `/editor/${DESIGNER_MODE.TemplateEditor}/${stateProps.currentTemplate.id}`}
                                                className='btn btn-default'
                                            >{t('common.edit')}
                                            </Link>
                                            {stateProps.publishing
                                                ? (
                                                    <span
                                                        className='publishing'
                                                    >
                                                        {t('version.templateData.progressPublish')}
                                                    </span>
                                                )
                                                : (
                                                    <Button
                                                        onClick={publishSelectedRevision}
                                                        disabled={ownProps.selectedVersion.id === stateProps.currentTemplate.publishedRevisionId || stateProps.publishing}
                                                    >
                                                        {t('common.publish')}
                                                    </Button>
                                                )}
                                        </div>
                                    )
                            }
                        </div>
                        {ownProps.selectedVersion
                && (
                    <div className={cx('version-preview', { mobile: isMobile, tablet: isTablet })}>
                        <div className={cx('carousel-container')}>
                            <div className={cx('image-carousel')}>
                                <CarouselPreviewWrapper selectedVersionId={ownProps.selectedVersion.id} pxSize={400} size='m' />
                            </div>
                        </div>
                    </div>
                )}
                    </div>
                    {(isDesktopOrLaptop || isTablet) && (
                        <div className={cx('template-details-wrapper')}>
                            <TemplateDetails
                                template={stateProps.currentTemplate}
                                version={ownProps.selectedVersion}
                                showAdditionalVersionDetails={ownProps.showAdditionalVersionDetails}
                                structuredTags={stateProps.structuredTags}
                            />
                        </div>
                    )}
                    {isMobile && (
                        <Accordion
                            title={t('version.templateDetails_sm')}
                            defaultOpen={false}
                        >
                            <div className={cx('template-details-wrapper')}>
                                <TemplateDetails
                                    template={stateProps.currentTemplate}
                                    version={ownProps.selectedVersion}
                                    showAdditionalVersionDetails={ownProps.showAdditionalVersionDetails}
                                    structuredTags={stateProps.structuredTags}
                                />
                            </div>
                        </Accordion>
                    )}
                </div>
            </>
        )}
        </>
    );
}
export default connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)(TemplateVersionDetailsWrapper);
