import React, { ReactNode, useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ResponsiveDesignBreakpoints } from 'utility/constants';

interface Props {
    children: ReactNode;
}

const DEFAULT_CONTEXT = {
    isMobile: false,
    isTablet: false,
    isTabletOrMobile: false,
    isDesktopOrLaptop: true,
    isBigScreen: false,
};
const layoutContext = React.createContext(DEFAULT_CONTEXT);
const { Provider } = layoutContext;

function ResponsiveDesignProvider({ children }: Props) {
    const isMobile = useMediaQuery({ maxWidth: ResponsiveDesignBreakpoints.MobileMax, minWidth: ResponsiveDesignBreakpoints.Zero });
    const isTablet = useMediaQuery({ maxWidth: ResponsiveDesignBreakpoints.TabletMax, minWidth: ResponsiveDesignBreakpoints.TabletMin });
    const isTabletOrMobile = useMediaQuery({ maxWidth: ResponsiveDesignBreakpoints.TabletMax, minWidth: ResponsiveDesignBreakpoints.Zero });
    const isDesktopOrLaptop = useMediaQuery({ minWidth: ResponsiveDesignBreakpoints.DesktopMin });
    const isBigScreen = useMediaQuery({ minDeviceWidth: ResponsiveDesignBreakpoints.BigScreenMin });

    return (
        <Provider value={{
            isMobile,
            isTablet,
            isTabletOrMobile,
            isDesktopOrLaptop,
            isBigScreen,
        }}
        >
            {children}
        </Provider>
    );
}

function useResponsiveDesign() {
    return useContext(layoutContext);
}

export {
    useResponsiveDesign,
    ResponsiveDesignProvider,
};
