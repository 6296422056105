import React, { useState } from 'react';
import { Carousel } from '@cimpress/react-components';
import IconZoomIn from '@cimpress-technology/react-streamline-icons/lib/IconZoomIn';
import './carouselPreview.scss';

interface Props {
    imageSources: string[];
    alt: string;
    minimal?: boolean;
    landscape?: boolean;
    pxSize?: number;
    size?: 'xs' | 's' | 'm' | 'l' | 'xl';
    onSlideChanged?(slideNumber: number): void;
    setOpenTemplatePreviewModal?(payload: boolean): void;
    showZoomOverlay?: boolean;
}

export function CarouselPreview({ imageSources, alt, minimal, pxSize, size, onSlideChanged, setOpenTemplatePreviewModal, showZoomOverlay }: Props) {
    const [imageLoaded, setImageLoaded] = useState(false);
    const onImageLoad = () => setImageLoaded(true);

    const onSlideChange = (i: number) => onSlideChanged && onSlideChanged(i + 1);
    const onCarouselClick = (currentSlide: number) => setOpenTemplatePreviewModal && setOpenTemplatePreviewModal(true);

    return (
        <>
            <img className='hidden' src={imageSources[0]} onLoad={() => onImageLoad()} alt={alt} />
            <Carousel
                size={size || 's'}
                minimal={minimal || false}
                pxSize={pxSize}
                loading={!imageLoaded}
                onSlideChanged={onSlideChange}
                onCarouselClick={onCarouselClick}
                showZoomOverlay={showZoomOverlay}
                zoomOverlayContent={showZoomOverlay && <IconZoomIn size='lg' />}
            >
                {imageSources.map((src) => <img key={src} src={src} alt={alt} />)}
            </Carousel>
        </>
    );
}
