import { RootState } from 'store/rootReducer';
import { uiStateActions, uiStateTypes, uiStateSelector } from 'store/uiState';
import { EDITOR_MODE } from 'utility/enums';
import { tenantTypes, tenantSelector } from 'store/tenant';
import { templateTypes, templateActions } from 'store/template';
import { editorActions } from 'store/editor';
import { EditorRouteParams } from 'store/rootTypes';
import { productActions, productSelector, productTypes } from 'store/product';

import { SurfaceSpecification } from 'store/product/types.product';

export interface OwnProps {
    match: EditorRouteParams;
    location: {
        search: string;
    };
    saving: boolean;
}
export interface StateProps {
    mode: EDITOR_MODE;
    currentTenant?: tenantTypes.Tenant;
    tabsHidden: boolean;
    switcherHidden: boolean;
    currentLocale?: string;
    settings?: uiStateTypes.DesignerApiSettings;
    hasMandatoryDesignerSettings: boolean;
    templateSurfaceSpecifications?: productTypes.SurfaceSpecification;
    isExistingTemplateLoading: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    calculatedSurfaceSet: any;
    inFlightTemplate: templateTypes.InFlightTemplate;
}

export interface DispatchProps {
    setEditorMode(payload: EDITOR_MODE): void;
    setInFlightTemplateId(payload: string): void;
    loadTemplateInEditor(payload: { duplicateCollections: boolean; revisionId?: string }): void;
    setInFlightTemplateSku(payload: string): void;
    setManualLoader(): void;
    resetManualLoader(): void;
    loadProduct(payload: string): void;
    addNotification(payload: uiStateTypes.Notification[]): void;
    resetSurfaceSpecifications(): void;
    clearProduct(): void;
    loadCalculatedSurfaceSets(payload: SurfaceSpecification): void;
    resetCalculatedSurfaceSets(): void;
}

export type AllProps = StateProps & DispatchProps & OwnProps;

export const getAllProps = (props: AllProps) => {
    const { match,
        location,
        settings,
        saving,
        mode,
        currentTenant,
        tabsHidden,
        switcherHidden,
        currentLocale,
        hasMandatoryDesignerSettings,
        templateSurfaceSpecifications,
        isExistingTemplateLoading,
        calculatedSurfaceSet,
        inFlightTemplate,
        setEditorMode,
        setInFlightTemplateId,
        loadTemplateInEditor,
        setInFlightTemplateSku,
        setManualLoader,
        resetManualLoader,
        loadProduct,
        addNotification,
        resetSurfaceSpecifications,
        clearProduct,
        loadCalculatedSurfaceSets,
        resetCalculatedSurfaceSets,
    } = props;
    const ownProps: OwnProps = { match, location, saving };
    const stateProps: StateProps = {
        mode,
        currentTenant,
        settings,
        tabsHidden,
        switcherHidden,
        hasMandatoryDesignerSettings,
        currentLocale,
        templateSurfaceSpecifications,
        isExistingTemplateLoading,
        calculatedSurfaceSet,
        inFlightTemplate,
    };
    const dispatchProps: DispatchProps = {
        setEditorMode,
        setInFlightTemplateId,
        loadTemplateInEditor,
        setInFlightTemplateSku,
        setManualLoader,
        resetManualLoader,
        loadProduct,
        addNotification,
        resetSurfaceSpecifications,
        clearProduct,
        loadCalculatedSurfaceSets,
        resetCalculatedSurfaceSets,
    };

    return { ownProps, stateProps, dispatchProps };
};

export const mapStateToProps = (state: RootState): StateProps => ({
    tabsHidden: state.editorState.editorLoadSettings.tabsHidden,
    switcherHidden: state.editorState.editorLoadSettings.switcherHidden,
    mode: state.editorState.editorLoadSettings.mode,
    currentTenant: tenantSelector.selectCurrentTenant(state),
    currentLocale: uiStateSelector.selectCurrentLocale(state),
    settings: state.uiState.settings,
    hasMandatoryDesignerSettings: uiStateSelector.selectHasMandatoryDesignerSettings(state),
    templateSurfaceSpecifications: productSelector.selectCurrentSurfaceSpecifications(state),
    isExistingTemplateLoading: uiStateSelector.selectIfLoading(state, templateActions.loadInFlightTemplate.toString(), productActions.loadCalculatedSurfaceSets.toString()),
    calculatedSurfaceSet: productSelector.selectCalculatedSurfaceSets(state),
    inFlightTemplate: state.templateState.inFlightTemplate,
});

export const mapDispatchToProps: DispatchProps = {
    setInFlightTemplateId: templateActions.setInFlightTemplateId,
    setEditorMode: editorActions.setEditorMode,
    loadTemplateInEditor: templateActions.loadInFlightTemplate,
    setInFlightTemplateSku: templateActions.setInFlightTemplateSku,
    setManualLoader: uiStateActions.setManualLoader,
    resetManualLoader: uiStateActions.resetManualLoader,
    loadProduct: productActions.loadProduct,
    addNotification: uiStateActions.addNotification,
    resetSurfaceSpecifications: productActions.resetSurfaceSpecifications,
    clearProduct: productActions.clearProduct,
    loadCalculatedSurfaceSets: productActions.loadCalculatedSurfaceSets,
    resetCalculatedSurfaceSets: productActions.resetCalculatedSurfaceSets,
};
