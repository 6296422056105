import { logError } from 'loggingManager';
import { axiosWithAuth } from 'interceptors/auth.interceptor';
import { URLs } from 'utility/constants';
import { encode } from 'js-base64';
import { Product } from './types.product';

const purposes = ['Product', 'Design', 'Merchandising'];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapProduct = ({ data }: { data: any }): Product => {
    const { mcpSku, ...productAttrs } = data;
    const product = {
        sku: mcpSku,
        ...productAttrs,
    };
    return product;
};

export async function getProduct(sku: string): Promise<Product> {
    const url = `${URLs.GetProduct}/${sku}`;
    return axiosWithAuth.get(url)
        .then(mapProduct)
        .catch((error) => {
            logError(`Could not fetch product ${error}`);
            throw error;
        });
}

export async function getProductPreviews(sku: string) {
    const previewSrc = [];
    for (let index = 0; index < purposes.length; index += 1) {
        const purpose = purposes[index];
        const inputJson = encode(JSON.stringify({ ReferenceId: sku, Purpose: purpose }));
        // eslint-disable-next-line no-await-in-loop
        const sceneQueryResponse = await axiosWithAuth.get(`${URLs.AssetLinkQuery}?inputJson=${inputJson}&pageSize=25&strict=true&useLegacySceneFallback=true&searchEngine=ElasticSearch`);
        if (sceneQueryResponse && sceneQueryResponse.data
            && sceneQueryResponse.data._embedded && sceneQueryResponse.data._embedded && sceneQueryResponse.data._embedded.item
            && sceneQueryResponse.data._embedded.item.length) {
            const sceneUri = sceneQueryResponse.data._embedded.item[0]._links.content.href;
            previewSrc.push(`https://rendering.documents.cimpress.io/v1/dcl/preview?width=400&scene=${encodeURI(sceneUri)}&format=jpg`);
        }
    }

    return previewSrc;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function getCalculatedSurfaceSets(sku: string, variables: string): Promise<any> {
    const url = `${URLs.CalculatedSurfaceSetsBase}/${sku}`;
    return axiosWithAuth.get(url, {
        params: { variables },
    })
        .then((response) => response.data)
        .catch((error) => {
            logError(`Could not fetch surface sets ${error}`);
            throw error;
        });
}
