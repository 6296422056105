import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from '@cimpress/react-components';
import cx from 'classnames';

import { collectionTypes } from 'store/collection';
import { RootState } from 'store/rootReducer';
import { DirectoryList } from 'shared/directory/directoryList/DirectoryList';
import { mapCollectionToDirectory, findCollectionById } from 'store/collection/collectionUtils';
import { directoryTypes } from 'shared/directory';
import Tag from '@cimpress/react-components/lib/Tag';
import { useResponsiveDesign } from 'providers/ResponsiveDesign';
import { getAllProps, AllProps, DispatchProps, OwnProps, mapDispatchToProps } from './props.collectionModal';

import './collectionModal.scss';

function CollectionModal(allProps: AllProps) {
    const { ownProps, dispatchProps } = getAllProps(allProps);
    const { isTablet, isMobile, isDesktopOrLaptop } = useResponsiveDesign();

    const [selectedCollections, setSelectedCollections] = useState<collectionTypes.Collection[]>([]);

    const onExpand = (directory: directoryTypes.Directory) => {
        const collection = findCollectionById(ownProps.collections, directory.id);
        collection && !collection.children && dispatchProps.onGetChildren(collection);
    };

    const onSelect = (directory: directoryTypes.Directory) => {
        const collection = findCollectionById(ownProps.collections, directory.id);
        if (collection) {
            if (selectedCollections.some((targetCollection) => targetCollection.id === collection.id)) {
                const updatedSelections = selectedCollections.filter((c) => c.id !== collection.id);
                setSelectedCollections(updatedSelections);
                ownProps.onChangeSelectedCollection(updatedSelections);
            } else {
                const updatedSelections = [...selectedCollections, collection];
                setSelectedCollections(updatedSelections);
                ownProps.onChangeSelectedCollection(updatedSelections);
            }
        }
    };

    const removeSelected = (id: string) => {
        const updatedSelections = selectedCollections.filter((targetCollection) => targetCollection.id !== id);
        setSelectedCollections(updatedSelections);
        ownProps.onChangeSelectedCollection(updatedSelections);
    };

    const onRequestHide = () => {
        setSelectedCollections([]);
        ownProps.onRequestHide();
    };

    return (
        <Modal
            className={cx('collection-modal', { mobile: isMobile, tablet: isTablet })}
            show={ownProps.isOpen}
            title={ownProps.title}
            onRequestHide={onRequestHide}
            closeButton={true}
            footer={ownProps.footer}
        >
            <div className='directory-selector'>
                <div className='directory-list'>
                    <DirectoryList
                        directories={mapCollectionToDirectory(ownProps.collections)}
                        selectedDirectoryIds={selectedCollections.map((collection) => collection.id)}
                        disabledDirectoryIds={ownProps.disabledDirectoryIds}
                        onExpand={onExpand}
                        onSelect={onSelect}
                    />
                </div>
                {isDesktopOrLaptop && (
                    <div className='selected-directory-list'>
                        <div className='selected-directory-list_title'>
                            {ownProps.selectedCollectionArea.title}
                        </div>
                        <div className='selected-directory-list_description'>
                            {ownProps.selectedCollectionArea.description}
                        </div>
                        <div>
                            { selectedCollections.length > 0
                                ? selectedCollections.map((targetCollection) => (
                                    <Tag
                                        key={targetCollection.id}
                                        value={targetCollection.label}
                                        removable={true}
                                        onRemoveClick={() => removeSelected(targetCollection.id)}
                                    />
                                ))
                                : (
                                    <div className='selected-directory-list_empty'>
                                        {ownProps.selectedCollectionArea.emptyMessage}
                                    </div>
                                )}
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    );
}

export default connect<{}, DispatchProps, OwnProps, RootState>(null, mapDispatchToProps)(CollectionModal);
