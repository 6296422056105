/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Tag, Dropdown } from '@cimpress/react-components';
import { RootState } from 'store/rootReducer';
import { templateTypes } from 'store/template';
import { collectionTypes } from 'store/collection';
import CollectionModal from 'components/collectionModal/CollectionModal';
import { getCommonCollectionIdsFromTemplates } from 'utility/helper';
import { COLLECTION_MODAL_TYPE, TEMPLATE_ACTION_TYPE } from 'utility/enums';
import { useResponsiveDesign } from 'providers/ResponsiveDesign';
import ConfirmDialog from 'shared/confirmDialog/confirmDialog';
import { getAllProps, AllProps, StateProps, DispatchProps, OwnProps, mapStateToProps, mapDispatchToProps } from './props.bulkTemplateActions';

import './bulkTemplateActions.scss';

const updatedSelectedTemplatesFromStore = (selectedTemplates: templateTypes.Template[],
    allTemplates: templateTypes.Template[]): templateTypes.Template[] => allTemplates.filter((template) => selectedTemplates.some((selectedTemplate) => selectedTemplate.id === template.id));

function BulkActionsWrapper(allProps: AllProps) {
    const { ownProps, stateProps, dispatchProps } = getAllProps(allProps);
    const { isBigScreen } = useResponsiveDesign();

    const { t } = useTranslation();
    const [collectionModalType, setCollectionModalType] = useState<COLLECTION_MODAL_TYPE>(COLLECTION_MODAL_TYPE.AddToCollections);
    const [isCollectionModalOpen, setIsCollectionModalOpen] = useState(false);
    const [selectedCollections, setSelectedCollections] = useState<collectionTypes.Collection[]>([]);
    const [confirmTemplateAction, setConfirmTemplateAction] = useState<templateTypes.TemplateActionConfirmation | null>(null);

    const moveToCollections = collectionModalType === COLLECTION_MODAL_TYPE.MoveToCollections;

    const onConfirmModalClose = () => {
        setConfirmTemplateAction(null);
    };

    const getModalConig = () => {
        if (confirmTemplateAction?.templateActionType === TEMPLATE_ACTION_TYPE.Delete) {
            const templatesToBeDeletedCount = ownProps.selectedTemplates.length - getDeletedTemplatesCountFromSelection();
            return {
                title: t('modals.bulkDeleteTemplate.title'),
                body: templatesToBeDeletedCount < ownProps.selectedTemplates.length
                    ? t('modals.bulkDeleteTemplate.confirmationMessageWhenTemplatesToBeIgnored', { total: templatesToBeDeletedCount })
                    : t('modals.bulkDeleteTemplate.confirmationMessage', { total: templatesToBeDeletedCount }),
                footer: (
                    <div>
                        <Button onClick={onConfirmModalClose}>{t('common.cancel')}</Button>
                        <Button type='primary' onClick={onBulkDelete}>{t('common.delete')}</Button>
                    </div>
                ),
            };
        }
        const templatesToBeRestoredCount = ownProps.selectedTemplates.length - getRestoredTemplatesCountFromSelection();
        return {
            title: t('modals.bulkRestoreTemplate.title'),
            body: templatesToBeRestoredCount < ownProps.selectedTemplates.length
                ? t('modals.bulkRestoreTemplate.confirmationMessageWhenTemplatesToBeIgnored', { total: templatesToBeRestoredCount })
                : t('modals.bulkRestoreTemplate.confirmationMessage', { total: templatesToBeRestoredCount }),
            footer: (
                <div>
                    <Button onClick={onConfirmModalClose}>{t('common.cancel')}</Button>
                    <Button type='primary' onClick={onBulkRestore}>{t('common.restore')}</Button>
                </div>
            ),
        };
    };

    const onRemoveTemplateFromCollection = (templatesToRemoveFromCollection: templateTypes.Template[]) => {
        if (stateProps.activeCollection) {
            dispatchProps.removeTemplatesFromCollection({ templates: templatesToRemoveFromCollection, sourceCollectionId: stateProps.activeCollection.id });
            ownProps.setSelectedTemplates([]);
        }
    };

    const onClickAddToCollections = () => {
        setCollectionModalType(COLLECTION_MODAL_TYPE.AddToCollections);
        setIsCollectionModalOpen(true);
    };
    const onClickMoveToCollections = () => {
        setCollectionModalType(COLLECTION_MODAL_TYPE.MoveToCollections);
        setIsCollectionModalOpen(true);
    };
    const onClickRemoveFromCollection = () => onRemoveTemplateFromCollection(ownProps.selectedTemplates);

    const onCancelAddToCollection = () => {
        setIsCollectionModalOpen(false);
        setSelectedCollections([]);
    };

    const onClickAddOrMove = () => {
        if (selectedCollections && selectedCollections.length > 0) {
            if (COLLECTION_MODAL_TYPE.AddToCollections === collectionModalType) {
                dispatchProps.addTemplatesToCollections({ templates: ownProps.selectedTemplates, targetCollections: selectedCollections });
            } else if (COLLECTION_MODAL_TYPE.MoveToCollections === collectionModalType && stateProps.activeCollection && stateProps.activeCollection.id) {
                dispatchProps.moveTemplatesToCollections({ templates: ownProps.selectedTemplates, sourceCollectionId: stateProps.activeCollection.id, targetCollections: selectedCollections });
            }
            ownProps.setSelectedTemplates([]);
            setIsCollectionModalOpen(false);
        }
    };

    const onChangeSelectedCollection = (collections: collectionTypes.Collection[]) => setSelectedCollections(collections);

    const onBulkDelete = () => {
        dispatchProps.onBulkDeleteTemplate({ templates: updatedSelectedTemplatesFromStore(ownProps.selectedTemplates, stateProps.allTemplates)
            .filter((template) => !template.deleted) });
        ownProps.setSelectedTemplates([]);
        onConfirmModalClose();
    };

    const onBulkRestore = () => {
        dispatchProps.onBulkRestoreTemplate({ templates: updatedSelectedTemplatesFromStore(ownProps.selectedTemplates, stateProps.allTemplates)
            .filter((template) => template.deleted) });
        ownProps.setSelectedTemplates([]);
        onConfirmModalClose();
    };

    const getDeletedTemplatesCountFromSelection = () => updatedSelectedTemplatesFromStore(ownProps.selectedTemplates, stateProps.allTemplates)
        .reduce((total, template) => (template.deleted ? total + 1 : total), 0);

    const getRestoredTemplatesCountFromSelection = () => updatedSelectedTemplatesFromStore(ownProps.selectedTemplates, stateProps.allTemplates)
        .reduce((total, template) => (!template.deleted ? total + 1 : total), 0);

    return (
        <>

            {confirmTemplateAction && (
                <ConfirmDialog isDialogOpen={true}>{
                    getModalConig()
                }
                </ConfirmDialog>
            )}
            {isCollectionModalOpen && (
                <CollectionModal
                    title={moveToCollections ? t('modals.collection.moveTitle') : t('modals.collection.addTitle')}
                    footer={(
                        <div className='collection-modal-btn'>
                            <Button className='btn btn-default' onClick={onCancelAddToCollection}>{t('common.cancel')}</Button>
                            <Button
                                disabled={!selectedCollections.length}
                                className='btn btn-primary'
                                onClick={onClickAddOrMove}
                            >{moveToCollections ? t('common.move') : t('common.add')}
                            </Button>
                        </div>
                    )}
                    onRequestHide={onCancelAddToCollection}
                    collections={stateProps.collections}
                    disabledDirectoryIds={getCommonCollectionIdsFromTemplates(ownProps.selectedTemplates)}
                    isOpen={isCollectionModalOpen}
                    onChangeSelectedCollection={onChangeSelectedCollection}
                    selectedCollectionArea={{
                        title: moveToCollections ? t('modals.collection.moveToSelected') : t('modals.collection.addToSelected'),
                        description: moveToCollections ? t('modals.collection.moveToSelectedDescription') : t('modals.collection.addToSelectedDescription'),
                        emptyMessage: moveToCollections ? t('modals.collection.moveToSelectedEmptyMessage') : t('modals.collection.addToSelectedEmptyMessage'),
                    }}
                />
            )}
            <Modal show={stateProps.bulkProgress.total > 0} title={t('modals.bulkProcessing.title')}>
                {stateProps.bulkProgress && t('modals.bulkProcessing.body', { processed: stateProps.bulkProgress.processed, total: stateProps.bulkProgress.total })}
            </Modal>
            {stateProps.allTemplates.length > 0 && (
                <div className='bulk-actions'>
                    {isBigScreen && (
                        <span id='joy-ride__add-to-collection-btn'>
                            <Button disabled={ownProps.selectedTemplates.length === 0 && stateProps.hasCollection} onClick={onClickAddToCollections} size='sm'>
                                {t('catalog.actions.addToCollection')}
                            </Button>
                        </span>
                    )}

                    {isBigScreen && stateProps.activeCollection && (
                        <>
                            <Button disabled={ownProps.selectedTemplates.length === 0} onClick={onClickMoveToCollections} size='sm'>
                                {t('catalog.actions.moveToCollection')}
                            </Button>
                            <Button disabled={ownProps.selectedTemplates.length === 0} onClick={onClickRemoveFromCollection} size='sm'>
                                {t('catalog.actions.removeFromCollection')}
                            </Button>
                        </>
                    )}

                    { isBigScreen && (
                        <>
                            <Button
                                onClick={() => setConfirmTemplateAction({
                                    template: ownProps.selectedTemplates[0],
                                    templateActionType: TEMPLATE_ACTION_TYPE.Delete,
                                })}
                                size='sm'
                                disabled={ownProps.selectedTemplates.length - getDeletedTemplatesCountFromSelection() === 0}
                            >
                                {t('catalog.actions.deleteTemplates')}
                            </Button>

                            <Button
                                onClick={() => setConfirmTemplateAction({
                                    template: ownProps.selectedTemplates[0],
                                    templateActionType: TEMPLATE_ACTION_TYPE.Restore,
                                })}
                                size='sm'
                                disabled={ownProps.selectedTemplates.length - getRestoredTemplatesCountFromSelection() === 0}
                            >
                                {t('catalog.actions.restoreTemplates')}
                            </Button>
                        </>
                    )}

                    {!isBigScreen && (
                        <Dropdown
                            disabled={ownProps.selectedTemplates.length === 0}
                            title={t('catalog.actions.organizeTemplates')}
                        >
                            <button disabled={!stateProps.hasCollection} onClick={onClickAddToCollections}>
                                {t('catalog.actions.addToCollection')}
                            </button>
                            <button disabled={!stateProps.activeCollection} onClick={onClickMoveToCollections}>
                                {t('catalog.actions.moveToCollection')}
                            </button>
                            <button disabled={!stateProps.activeCollection} onClick={onClickRemoveFromCollection}>
                                {t('catalog.actions.removeFromCollection')}
                            </button>
                            <button
                                onClick={() => setConfirmTemplateAction({
                                    template: ownProps.selectedTemplates[0],
                                    templateActionType: TEMPLATE_ACTION_TYPE.Delete,
                                })}
                                disabled={ownProps.selectedTemplates.length - getDeletedTemplatesCountFromSelection() === 0}
                            >
                                {t('catalog.actions.deleteTemplates')}
                            </button>

                            <button
                                onClick={() => setConfirmTemplateAction({
                                    template: ownProps.selectedTemplates[0],
                                    templateActionType: TEMPLATE_ACTION_TYPE.Restore,
                                })}
                                disabled={ownProps.selectedTemplates.length - getRestoredTemplatesCountFromSelection() === 0}
                            >
                                {t('catalog.actions.restoreTemplates')}
                            </button>

                        </Dropdown>
                    )}
                    {ownProps.selectedTemplates.length > 0
                    && <Tag value={t('catalog.templateSelected', { count: ownProps.selectedTemplates.length })} removable={true} onRemoveClick={ownProps.removeAllSelectedTemplates} />}
                </div>
            )}
        </>
    );
}
export default connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)(BulkActionsWrapper);
