import { logError } from 'loggingManager';

export const parseSkuVariables = (input?: string): object|undefined => {
    if (!input) {
        return undefined;
    }
    try {
        const skuVariable = JSON.parse(input);
        return skuVariable;
    } catch (e) {
        logError('Unable to parse skuVariable');
        return undefined;
    }
};
