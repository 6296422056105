import { directoryTypes } from 'shared/directory';

const checkExpand = (selectedDirectoryId: string[] | undefined, directory: directoryTypes.Directory) => {
    if (directory && directory.subDirectories && selectedDirectoryId) {
        return !!directory.subDirectories.find((node) => selectedDirectoryId.includes(node.id));
    }
    return false;
};

export {
    checkExpand,
};
