import { RootState } from 'store/rootReducer';
import { tenantSelector, tenantTypes } from 'store/tenant';
import { RouteProps } from 'react-router-dom';

export interface StateProps {
    hasCoamAccess: boolean;
    currentTenant?: tenantTypes.Tenant;
}

export type AllProps = StateProps & RouteProps;

export const getAllProps = (props: AllProps) => {
    const { hasCoamAccess, currentTenant, ...rest } = props;
    const routeProps: RouteProps = { ...rest };
    const stateProps: StateProps = { hasCoamAccess, currentTenant };

    return { routeProps, stateProps };
};

export const mapStateToProps = (state: RootState): StateProps => ({
    currentTenant: tenantSelector.selectCurrentTenant(state),
    hasCoamAccess: tenantSelector.selectHasCoamAccess(state),
});
