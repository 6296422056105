import React from 'react';

import './toolbar.scss';

export default function Toolbar() {
    return (
        <div className='contextual-toolbar'>
            <div className='toolbar' />
            <div className='itemtoolbar' />
        </div>
    );
}
