import { useEffect, useRef } from 'react';

export default function useInterval(callback: () => void, delay: number, shouldStartTicking: boolean) {
    const savedCallback = useRef<() => void>();
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => { // eslint-disable-line consistent-return
        function tick() {
            savedCallback.current && savedCallback.current();
        }
        if (shouldStartTicking) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [shouldStartTicking]); // eslint-disable-line react-hooks/exhaustive-deps
}
