/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { PureComponent } from 'react';
import { Attributes, GenericSelector, ResourceType, IStateDescriptor, IChangeMetadata } from '@cimpress-technology/generic-selector';
import { verifyNecessaryAttributes } from 'services/surface.service';
import { KeyValue } from 'store/product/types.product';

interface Props {
    accessToken: string;
    sku: string;
    onInvalid?(): void;
    onValid?(attributes: Attributes, complete: boolean): void;
    defaultAttributeValues: KeyValue<string, string>[];
    allowIncompleteAttributeSelection: boolean;
}

/**
 * Component for filling out details about an MCP product to load designer with
 *
 * As of 2/27/19, we attempted to write the parent component of AttributeSelector
 * as a functional component. It suffered from an infinite loop due to prop updates
 * for some reason. We attempted to use React.memo with no resolve.
 */
export default class GenericSelectorWrapper extends PureComponent<Props> {
    onNotResolved = () => {
        const { onInvalid } = this.props;
        if (onInvalid) {
            onInvalid();
        }
    }

    onResolve = (displayedAttributes: Attributes, complete: boolean) => {
        const { onValid } = this.props;
        if (onValid) {
            onValid(displayedAttributes, complete);
        }
    }

    handleGenericAttributeChange = async (state: IStateDescriptor, metadata: IChangeMetadata) => {
        const displayedAttributes = state.attributes.filter((a) => a.isDisplayed);
        const filledAttributes = state.attributes.filter((a) => a.isDisplayed && a.resolvedValue);
        const allAttributesHaveValues = displayedAttributes.length === filledAttributes.length;

        const resolvedAttributes: Attributes = {};
        filledAttributes.forEach((a: any) => {
            resolvedAttributes[a.key] = a.resolvedValue;
        });

        if (allAttributesHaveValues) {
            this.onResolve(resolvedAttributes, allAttributesHaveValues);
        } else if (this.props.allowIncompleteAttributeSelection) {
            const canCreateSurface = await verifyNecessaryAttributes(this.props.sku, resolvedAttributes);
            if (canCreateSurface) {
                this.onResolve(resolvedAttributes, allAttributesHaveValues);
            }
        } else {
            this.onNotResolved();
        }
    };

    render() {
        const { accessToken, sku } = this.props;

        return (
            <GenericSelector
                productId={sku}
                authToken={accessToken}
                onChange={this.handleGenericAttributeChange}
                onLoad={this.handleGenericAttributeChange}
                selectionResource={ResourceType.SURFACE}
                selectWithProductAttributes={false}
            />
        );
    }
}
