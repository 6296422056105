import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { collectionTypes } from 'store/collection';
import collectionSlice from 'store/collection/slice.collection';
import { CollectionTagPrefix, AssetTypeTemplate } from 'utility/constants';
import { EDITOR_MODE } from 'utility/enums';
import { getDuplicateLabel } from 'utility/helper';
import { templateTypes } from '.';
import { SaveTemplatePayload } from './types.template';

export const INITIAL_STATE: templateTypes.TemplateState = {
    templates: [],
    recentTemplates: [],
    templateVersions: [],
    inFlightTemplate: {
        assetType: AssetTypeTemplate,
        name: 'My Template',
        description: 'My Description',
        tags: [],
        initialRevision: {
            uri: '',
        },
        tenant: {
            id: '',
            type: '',
        },
    },
};

export const updateCollectionLabelsInTemplate = (template: templateTypes.Template, collections: collectionTypes.Collection[]): templateTypes.Template => {
    const collectionLabels: templateTypes.CollectionLabel[] = template.collectionLabels ? [...template.collectionLabels] : [];
    template.tags && template.tags.filter((tag) => tag.startsWith(CollectionTagPrefix))
        .forEach((tempTag) => {
            const collection = collections.find((c) => c.id === tempTag.replace(CollectionTagPrefix, ''));
            if (collection) {
                const labelIndex = collectionLabels.findIndex((c) => c.id === collection.id);
                const collectionLabel = {
                    id: collection.id,
                    label: collection.label,
                };
                if (labelIndex >= 0) {
                    collectionLabels[labelIndex] = collectionLabel;
                } else {
                    collectionLabels.push(collectionLabel);
                }
            }
        });
    return { ...template, collectionLabels };
};

const slice = createSlice({
    name: 'template',
    initialState: INITIAL_STATE,
    reducers: {
        loadTemplates: (state) => {},
        loadTemplatesSuccess: (state, { payload }: PayloadAction<{
            templates: templateTypes.Template[];
            offset: string;
        }>) => {
            if (payload) {
                state.templates = payload.templates;
                state.offset = payload.offset;
            }
        },
        loadTemplatesFailure: (state) => {},

        loadRecentTemplates: (state) => {},
        loadRecentTemplatesSuccess: (state, { payload }: PayloadAction<{ templates: templateTypes.Template[] }>) => {
            payload && payload.templates && (state.recentTemplates = payload.templates);
        },
        loadRecentTemplatesFailure: (state) => {},

        loadNextTemplates: (state) => {},
        loadNextTemplatesSuccess: (state, { payload }: PayloadAction<{
            templates: templateTypes.Template[];
            offset: string;
        }>) => {
            if (payload) {
                state.templates = [...state.templates, ...payload.templates];
                state.offset = payload.offset;
            }
        },
        loadNextTemplatesFailure: (state) => {},

        loadTemplateById: (state, { payload }: PayloadAction<string>) => {},
        loadTemplateByIdSuccess: (state, { payload }: PayloadAction<templateTypes.Template>) => {
            state.currentTemplate = payload;
        },
        loadTemplateByIdFailure: (state) => {},
        setCurrentTemplate: (state, { payload }: PayloadAction<templateTypes.Template>) => {
            state.currentTemplate = payload;
        },
        resetCurrentTemplate: (state) => {
            state.currentTemplate = undefined;
        },
        editTemplateInformation: (state, { payload }: PayloadAction<templateTypes.AssetToBeEdited>) => {},
        editTemplateInformationSuccess: (state, { payload }: PayloadAction<{ name: string; description: string; tags: string[] }>) => {
            state.templates = state.templates.map((template: templateTypes.Template) => {
                if (template.id === state.currentTemplate?.id) {
                    template.name = payload.name;
                    template.description = payload.description;
                    template.tags = payload.tags;
                }
                return template;
            });
        },
        createPrintPdf: (state, { payload }: PayloadAction<{docRefUrl: string; fileName: string}>) => {},
        createPrintPdfSuccess: (state) => {},
        createPrintPdfFailure: (state) => {},

        addTemplatesToCollections: (state, { payload }: PayloadAction<{ templates: templateTypes.Template[]; targetCollections: collectionTypes.Collection[] }>) => {},
        addTemplatesToCollectionsSuccess: (state, { payload }: PayloadAction<{ templatesToBeUpdatedInStore: templateTypes.Template[]; targetCollections: collectionTypes.Collection[]}>) => {
            const { templatesToBeUpdatedInStore, targetCollections } = payload;
            const updatedTemplates = templatesToBeUpdatedInStore.map((template) => {
                const existingNonCollectionTags = template.tags.filter((tag) => !tag.startsWith(CollectionTagPrefix));
                const newCollectionTags = targetCollections ? targetCollections.map((collection) => `${CollectionTagPrefix}${collection.id}`) : [];
                const existingCollectionTags = template.tags.filter((tag) => tag.startsWith(CollectionTagPrefix));
                const uniqueCollectionTags = [...newCollectionTags, ...existingCollectionTags].filter((x, i, a) => a.indexOf(x) === i);
                const newTags = [...existingNonCollectionTags, ...uniqueCollectionTags];
                let updatedTemplate: templateTypes.Template = { ...template, tags: newTags };
                updatedTemplate = updateCollectionLabelsInTemplate(updatedTemplate, targetCollections);
                return updatedTemplate;
            });
            state.templates = state.templates.map((template) => {
                const updatedTemplate = updatedTemplates.find((t) => template.id === t.id);
                return updatedTemplate || template;
            });
        },
        addTemplatesToCollectionsFailure: (state, { payload }: PayloadAction<{ templatesToBeUpdatedInStore: templateTypes.Template[]; targetCollections: collectionTypes.Collection[]}>) => {},

        moveTemplatesToCollections: (state, { payload }: PayloadAction<templateTypes.ActionMoveTemplates>) => {},
        moveTemplatesToCollectionsSuccess: (state, { payload }: PayloadAction<templateTypes.ActionMoveTemplatesSuccess>) => {
            state.templates = state.templates.filter((template) => !payload.templatesToBeRemovedFromStore.some((t) => t.id === template.id));
        },
        moveTemplatesToCollectionsFailure: (state, { payload }: PayloadAction<{ templatesToBeRemovedFromStore: templateTypes.Template[]}>) => {
            state.templates = state.templates.filter((template) => !payload.templatesToBeRemovedFromStore.some((t) => t.id === template.id));
        },

        removeTemplatesFromCollection: (state, { payload }: PayloadAction<templateTypes.ActionRemoveTemplates>) => {},
        removeTemplatesFromCollectionSuccess: (state, { payload }: PayloadAction<templateTypes.ActionRemoveTemplatesSuccess>) => {
            state.templates = state.templates.filter((template) => !payload.templatesToBeRemovedFromStore.some((t) => t.id === template.id));
        },
        removeTemplatesFromCollectionFailure: (state, { payload }: PayloadAction<{ templatesToBeRemovedFromStore: templateTypes.Template[]}>) => {
            state.templates = state.templates.filter((template) => !payload.templatesToBeRemovedFromStore.some((t) => t.id === template.id));
        },

        syncTemplatesAfterCollectionActions: (state, { payload }: PayloadAction<templateTypes.Template>) => {
            state.templates = state.templates.map((template) => (template.id === payload.id ? payload : template));
        },

        setBulkProcess: (state, { payload }: PayloadAction<templateTypes.Template[]>) => {
            state.bulkProcess = {
                beforeProcessing: payload,
                afterProcessing: [],
            };
        },
        resetBulkProcess: (state) => {
            state.bulkProcess = undefined;
        },
        updateBulkProcess: (state, { payload }: PayloadAction<string>) => {
            if (state.bulkProcess) {
                const processedTemplate = state.bulkProcess.beforeProcessing.find((template) => template.id === payload);
                if (processedTemplate) {
                    state.bulkProcess = {
                        beforeProcessing: state.bulkProcess.beforeProcessing.filter((template) => template.id !== processedTemplate.id),
                        afterProcessing: [...state.bulkProcess.afterProcessing, processedTemplate],
                    };
                }
            }
        },

        fetchTemplateVersions: (state, { payload }: PayloadAction<string>) => { state.templateVersions = []; },
        fetchTemplateVersionsSuccess: (state, { payload }: PayloadAction<templateTypes.TemplateVersion[]>) => {
            state.templateVersions = payload;
        },
        fetchTemplateVersionsFailure: (state, { payload }: PayloadAction<string>) => {},

        publishTemplateVersion: (state, { payload }: PayloadAction<templateTypes.TemplateVersion>) => {},
        publishTemplateVersionSuccess: (state, { payload }: PayloadAction<templateTypes.Template>) => {
            if (state.currentTemplate) {
                state.currentTemplate = { ...state.currentTemplate, ...payload };
            }
            state.templateVersions = state.templateVersions.map((version) => (version.id === payload.publishedRevisionId
                ? { ...version, publishedBy: payload.publishedBy, published: payload.lastPublished } : version));
        },
        publishTemplateVersionFailure: (state, { payload }: PayloadAction<string>) => {},

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        saveCimDocToUds: (state, { payload }: PayloadAction<{cimDoc: any}>) => {},
        saveCimDocToUdsSuccess: (state) => {},
        saveCimDocToUdsFailure: (state) => {},

        saveTemplate: (state, { payload }: PayloadAction<SaveTemplatePayload>) => {},
        saveTemplateSuccess: (state) => {
            state.inFlightTemplate = INITIAL_STATE.inFlightTemplate;
        },
        saveTemplateFailure: () => {},

        loadInFlightTemplate: (state, { payload }: PayloadAction<{ duplicateCollections: boolean; revisionId?: string }>) => { },

        loadInFlightTemplateSuccess: (state, { payload }: PayloadAction<{inflightTemplate: templateTypes.InFlightTemplate; duplicateCollection: boolean; editorMode: EDITOR_MODE}>) => {
            const { inflightTemplate, duplicateCollection, editorMode } = payload;
            if (editorMode === EDITOR_MODE.SaveAsNew) {
                state.inFlightTemplate = {
                    assetType: inflightTemplate.assetType,
                    name: getDuplicateLabel(inflightTemplate.name),
                    description: inflightTemplate.description,
                    sku: inflightTemplate.sku,
                    tenant: inflightTemplate.tenant,
                    tags: duplicateCollection ? inflightTemplate.tags
                        : (inflightTemplate.tags && inflightTemplate.tags.filter((tempTag) => !tempTag.startsWith(CollectionTagPrefix))),
                    initialRevision: { uri: '' },
                };
            } else {
                state.inFlightTemplate = inflightTemplate;
            }
        },
        loadInFlightTemplateFailure: (state) => { },

        setInFlightTemplateId: (state, { payload }: PayloadAction<string>) => {
            state.inFlightTemplate.id = payload;
        },
        setInFlightTemplateDescription: (state, { payload }: PayloadAction<string>) => {
            state.inFlightTemplate.description = payload;
        },
        setInFlightTemplateName: (state, { payload }: PayloadAction<string>) => {
            state.inFlightTemplate.name = payload;
        },
        setInFlightTemplateTags: (state, { payload }: PayloadAction<string[]>) => {
            state.inFlightTemplate.tags = payload;
        },
        removeInFlightTemplateTags: (state, { payload }: PayloadAction<string[]>) => {
            state.inFlightTemplate.tags = state.inFlightTemplate.tags.filter((tag) => !payload.includes(tag));
        },
        setInFlightTemplateSku: (state, { payload }: PayloadAction<string>) => {
            state.inFlightTemplate.sku = payload;
        },
        resetInFlightTemplate: (state) => {
            state.inFlightTemplate = INITIAL_STATE.inFlightTemplate;
        },

        bulkDeleteTemplate: (state, { payload }: PayloadAction<{ templates: templateTypes.Template[] }>) => {},
        onBulkDeleteTemplateSuccess: (state, { payload }: PayloadAction<{ templatesToBeDeletedInStore: templateTypes.Template[] }>) => {
            const { templatesToBeDeletedInStore } = payload;
            const toBeDeletedTemplatesId: string[] = templatesToBeDeletedInStore.map((template) => template.id);
            if (toBeDeletedTemplatesId.length > 0) {
                state.templates = state.templates.map((template) => (toBeDeletedTemplatesId.indexOf(template.id) !== -1
                    ? { ...template, deleted: true } : template));
                state.recentTemplates = state.recentTemplates.filter((template) => toBeDeletedTemplatesId.indexOf(template.id) !== -1);
                state.currentTemplate = (state.currentTemplate && toBeDeletedTemplatesId.indexOf(state.currentTemplate.id) !== -1)
                    ? { ...state.currentTemplate, deleted: true } : state.currentTemplate;
            }
        },
        onBulkDeleteTemplateFailure: (state, { payload }: PayloadAction<{ templatesToBeDeletedInStore: templateTypes.Template[] }>) => {
            const { templatesToBeDeletedInStore } = payload;
            const toBeDeletedTemplatesId: string[] = templatesToBeDeletedInStore.map((template) => template.id);
            if (toBeDeletedTemplatesId.length > 0) {
                state.templates = state.templates.map((template) => (toBeDeletedTemplatesId.indexOf(template.id) !== -1
                    ? { ...template, deleted: true } : template));
                state.recentTemplates = state.recentTemplates.filter((template) => toBeDeletedTemplatesId.indexOf(template.id) !== -1);
                state.currentTemplate = (state.currentTemplate && toBeDeletedTemplatesId.indexOf(state.currentTemplate.id) !== -1)
                    ? { ...state.currentTemplate, deleted: true } : state.currentTemplate;
            }
        },

        onDeleteTemplate: (state, { payload }: PayloadAction<string>) => {},
        onDeleteTemplateSuccess: (state, { payload }: PayloadAction<string>) => {
            state.templates = state.templates.map((template) => (template.id === payload ? { ...template, deleted: true } : template));
            state.recentTemplates = state.recentTemplates.filter((template) => template.id !== payload);
            state.currentTemplate = state.currentTemplate?.id === payload ? { ...state.currentTemplate, deleted: true } : state.currentTemplate;
        },
        onDeleteTemplateFailure: (state, { payload }: PayloadAction<string>) => {},

        onRestoreTemplate: (state, { payload }: PayloadAction<string>) => {},
        onRestoreTemplateSuccess: (state, { payload }: PayloadAction<string>) => {
            state.templates = state.templates.map((template) => (template.id === payload ? { ...template, deleted: false } : template));
            state.currentTemplate = state.currentTemplate?.id === payload ? { ...state.currentTemplate, deleted: false } : state.currentTemplate;
        },
        onRestoreTemplateFailure: (state, { payload }: PayloadAction<string>) => {},

        bulkRestoreTemplate: (state, { payload }: PayloadAction<{ templates: templateTypes.Template[] }>) => {},
        onBulkRestoreTemplateSuccess: (state, { payload }: PayloadAction<{ templatesToBeRestoredInStore: templateTypes.Template[] }>) => {
            const { templatesToBeRestoredInStore } = payload;
            const toBeRestoredTemplatesId: string[] = templatesToBeRestoredInStore.map((template) => template.id);
            if (toBeRestoredTemplatesId.length > 0) {
                state.templates = state.templates.map((template) => (toBeRestoredTemplatesId.indexOf(template.id) !== -1
                    ? { ...template, deleted: false } : template));
                state.recentTemplates = state.recentTemplates.filter((template) => toBeRestoredTemplatesId.indexOf(template.id) !== -1);
                state.currentTemplate = (state.currentTemplate && toBeRestoredTemplatesId.indexOf(state.currentTemplate.id) !== -1)
                    ? { ...state.currentTemplate, deleted: false } : state.currentTemplate;
            }
        },
        onBulkRestoreTemplateFailure: (state, { payload }: PayloadAction<{ templatesToBeRestoredInStore: templateTypes.Template[] }>) => {
            const { templatesToBeRestoredInStore } = payload;
            const toBeRestoredTemplatesId: string[] = templatesToBeRestoredInStore.map((template) => template.id);
            if (toBeRestoredTemplatesId.length > 0) {
                state.templates = state.templates.map((template) => (toBeRestoredTemplatesId.indexOf(template.id) !== -1
                    ? { ...template, deleted: false } : template));
                state.recentTemplates = state.recentTemplates.filter((template) => toBeRestoredTemplatesId.indexOf(template.id) !== -1);
                state.currentTemplate = (state.currentTemplate && toBeRestoredTemplatesId.indexOf(state.currentTemplate.id) !== -1)
                    ? { ...state.currentTemplate, deleted: false } : state.currentTemplate;
            }
        },

        moveAllTemplatesAndDeleteCollection: (state, { payload }: PayloadAction<{sourceCollectionId: string; targetCollections: collectionTypes.Collection[]}>) => {},

        startLoadingCatalog: () => INITIAL_STATE, // TODO: Rethink about it

        loadSurfaces: (state, { payload }: PayloadAction<string>) => {},
        loadSurfacesSuccess: (state, { payload }: PayloadAction<string[]>) => {
            const currentTemplate = state.currentTemplate;
            if (currentTemplate) {
                state.currentTemplate = { ...currentTemplate, surfaces: payload };
                state.templates = state.templates.map((template) => (template.id === currentTemplate.id ? { ...template, surfaces: payload } : template));
            }
        },
        loadSurfacesFailure: (state) => {},
    },
    extraReducers: {
        [collectionSlice.actions.loadCollectionByIdsSuccess.toString()]: (state, { payload }: PayloadAction<collectionTypes.Collection[]>) => {
            const collections = payload;
            state.templates = state.templates.map((template: templateTypes.Template) => updateCollectionLabelsInTemplate(template, collections));
            state.recentTemplates = state.recentTemplates.map((template: templateTypes.Template) => updateCollectionLabelsInTemplate(template, collections));
        },
    },
});

export default slice;
