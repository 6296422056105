import { RootState } from 'store/rootReducer';
import { uiStateSelector } from 'store/uiState';
import { tenantActions, tenantTypes, tenantSelector } from 'store/tenant';
import { templateSelector, templateTypes, templateActions } from 'store/template';

export interface StateProps {
    recentTemplates: templateTypes.Template[];
    isRecentTemplatesLoading: boolean;
    currentTenant?: tenantTypes.Tenant;
    isTenantLoading: boolean;
    availableTenants: tenantTypes.Tenant[];
}
export interface DispatchProps {
    loadRecentTemplates(): void;
}

export type AllProps = StateProps & DispatchProps;

export const getAllProps = (props: AllProps) => {
    const { recentTemplates, isRecentTemplatesLoading, currentTenant, isTenantLoading, availableTenants, loadRecentTemplates } = props;
    const stateProps: StateProps = {
        recentTemplates,
        isRecentTemplatesLoading,
        currentTenant,
        isTenantLoading,
        availableTenants,
    };
    const dispatchProps: DispatchProps = { loadRecentTemplates };

    return { stateProps, dispatchProps };
};

export const mapStateToProps = (state: RootState): StateProps => ({
    recentTemplates: templateSelector.selectRecentTemplatesWithPreview(state),
    isRecentTemplatesLoading: uiStateSelector.selectIfLoading(state, templateActions.loadRecentTemplates.toString()),
    currentTenant: tenantSelector.selectCurrentTenant(state),
    isTenantLoading: uiStateSelector.selectIfLoading(state, tenantActions.fetchTenant.toString()),
    availableTenants: tenantSelector.selectAllTenants(state),
});
export const mapDispatchToProps: DispatchProps = {
    loadRecentTemplates: templateActions.loadRecentTemplates,
};
