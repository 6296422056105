import React, { useState } from 'react';
import { Tooltip, colors } from '@cimpress/react-components';
import IconCopy from '@cimpress-technology/react-streamline-icons/lib/IconCopy';
import copyToClipboard from 'clipboard-copy';
import { tenantTypes } from 'store/tenant';
import { uiStateTypes, formatNotification } from 'store/uiState';
import { SNACKBAR_NOTIFICATION_TYPES } from 'utility/enums';
import { getCollection } from 'store/collection/service.collection';

const { ocean } = colors;

interface Props {
    tenant: tenantTypes.Tenant;
    addNotification(payload: uiStateTypes.Notification[]): void;
}

interface CollectionJson {
    id: string;
    label: string;
    children?: CollectionJson[];
}

function getCollectionTree(tenantName: string) {
    let collections: CollectionJson[] = [];
    function getCollections(tenantId: string, parentId?: string) {
        return getCollection(tenantId, parentId)
            .then((data) => {
                let response;
                if (parentId) {
                    const collection = collections.find((c) => c.id === parentId);
                    if (collection) {
                        collection.children = data.map((item: CollectionJson) => ({ id: item.id, label: item.label }));
                    }
                } else {
                    collections = data.map((item: CollectionJson) => ({ id: item.id, label: item.label }));
                    response = Promise.all(data.map((item: CollectionJson) => getCollections(tenantId, item.id)));
                }
                return response;
            });
    }
    return getCollections(tenantName).then((r) => collections);
}

export default function CopyCollectionTree({ tenant, addNotification }: Props) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const onClickCopy = () => {
        if (isLoading) {
            return;
        }
        setIsLoading(true);
        getCollectionTree(tenant.tenantId).then((response) => {
            copyToClipboard(JSON.stringify(response));
            addNotification([formatNotification(SNACKBAR_NOTIFICATION_TYPES.Success, 'Collection Json data copied to clipboard', 5000)]);
        }).catch(() => {
            addNotification([formatNotification(SNACKBAR_NOTIFICATION_TYPES.Danger, 'Something went wrong. Please try again')]);
        }).finally(() => {
            setIsLoading(false);
        });
    };
    return (
        <Tooltip direction='top' contents='Copy collection json'>
            <IconCopy
                onClick={onClickCopy}
                color={ocean.darker}
            />
        </Tooltip>
    );
}
