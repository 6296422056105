import Axios from 'axios';
import { URLs } from 'utility/constants';
import { axiosWithAuth } from 'interceptors/auth.interceptor';
import { fetchCimDoc } from 'store/template/service.template';
import { logError } from '../loggingManager';

enum Status {
    Completed = 'Completed',
    Failed = 'Failed',
    Starting = 'Starting',
}

interface Response {
    ResultUrl: string;
    Output: string;
    Status: string;
    Exception: {
        Message: string;
    };
}

const prePressPollingDelay = 1000;
const maxRetryCount = 50;
let retryCount = 0;
let errorMessage: string;

const getJSON = (url: string) => Promise.resolve(Axios.get(url));

const delay = (milliseconds: number) => new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fetchPdfUrl = (url: string): any => getJSON(url)
    .then(({ data }: { data: Response }) => {
        if (data.Status === Status.Starting) {
            retryCount += 1;
        }
        if (data.Status === Status.Completed) {
            return getJSON(data.Output);
        }
        if (data.Status === Status.Failed || retryCount === maxRetryCount) {
            if (retryCount === maxRetryCount) {
                errorMessage = 'Fail to create PDF due to timeout';
            } else if (data.Exception && data.Exception.Message.includes('NotFound')) {
                errorMessage = 'FilePrep could not retrieve the file (404).';
            } else {
                errorMessage = `Prepress failure: ${data.Exception && data.Exception.Message}`;
            }
            logError(errorMessage);
            throw Error;
        }
        return delay(prePressPollingDelay).then(() => fetchPdfUrl(url));
    });

export async function createPrintPdf(assetContentUrl: string) {
    try {
        const docRefUrl = new URL(assetContentUrl);
        docRefUrl.searchParams.append('cacheBuster', Date.now().toString());
        const cimDoc = await fetchCimDoc(docRefUrl.toString());
        const prePressResponse = await axiosWithAuth.post<Response>(URLs.Prepress, { documentInstructionsUrl: `${URLs.DocumentInstructionDrawing}?documentUri=${cimDoc.cimDocUrl}` });
        return await fetchPdfUrl(prePressResponse.data.ResultUrl);
    } catch (error) {
        logError(error.message);
        throw error;
    }
}
