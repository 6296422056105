import React, { useState, useEffect } from 'react';

import './overlay.scss';
import { useTranslation } from 'react-i18next';

interface Props {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    history: any;
    setDisplayOverlay(): void;
}

const demoDisableRoutes = ['editor', 'import', 'contact', 'configuration'];

const isDisabled = () => demoDisableRoutes.some((route) => window.location.pathname.indexOf(`/${route}`) >= 0);

export default function OverlayButton({ history, setDisplayOverlay }: Props) {
    const { t } = useTranslation();
    const [isEnabled, setIsEnabled] = useState<boolean>();
    useEffect(() => {
        setIsEnabled(!isDisabled());
    }, []);

    history.listen(() => {
        setIsEnabled(!isDisabled());
    });

    return (
        <>
            {isEnabled
        && (
            <div id='joy-ride__demo-btn'>
                <button
                    type='button'
                    className='overlay-button'
                    onClick={setDisplayOverlay}
                >
                    {t('header.appDemo')}
                </button>
            </div>
        )}
        </>
    );
}
