import React, { useState, useEffect } from 'react';
import IconPictureSun from '@cimpress-technology/react-streamline-icons/lib/IconPictureSun';
import { filterUniqueElements } from 'utility/helper';
import { getProductPreviews } from 'store/product/service.product';
import { productTypes } from 'store/product';
import { DesignTransferTransientDocument } from 'store/product/types.product';
import CarouselPreviewWrapper from 'components/CarouselPreviewWrapper/CarouselPreviewWrapper';
import './productPreview.scss';
import { useResponsiveDesign } from 'providers/ResponsiveDesign';
import SKULessProductPreview from './SKULessProductPreview';

const getPreview = (sku: string | undefined,
    sources: string[],
    designTransferTransientDocument: DesignTransferTransientDocument | undefined,
    isMobile: boolean) => {
    if (sku) {
        return (
            <div className='create__preview-carousel'>
                <CarouselPreviewWrapper pxSize={isMobile ? 240 : 400} minimal={true} imageSources={sources} />
            </div>
        );
    }

    if (designTransferTransientDocument && designTransferTransientDocument.documentReferenceUrl) {
        return (
            <div className='product-preview-carousel'>
                <CarouselPreviewWrapper
                    minimal={true}
                    pxSize={isMobile ? 240 : 400}
                    showImageHeading={true}
                    designTransferTransientDocument={designTransferTransientDocument}
                />
            </div>
        );
    }
    return <div className='create__preview-placeholder'> <IconPictureSun size='3x' /></div>;
};

function ProductPreview({ sku, surfaceSpecifications, designTransferTransientDocument }:
    { sku?: string; surfaceSpecifications?: productTypes.SurfaceSpecification; designTransferTransientDocument: DesignTransferTransientDocument | undefined }) {
    const [sources, setSources] = useState<string[]>([]);
    const { isMobile } = useResponsiveDesign();

    useEffect(() => {
        if (sku) {
            getProductPreviews(sku).then((previewSrc) => {
                setSources(filterUniqueElements(previewSrc));
            });
        }
    }, [sku]);

    return (
        <>
            {!surfaceSpecifications && getPreview(sku, sources, designTransferTransientDocument, isMobile)}
            {surfaceSpecifications && <SKULessProductPreview surfaceSpecifications={surfaceSpecifications} />}
        </>

    );
}

export default ProductPreview;
