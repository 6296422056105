/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useCallback } from 'react';
import { colors, Accordion } from '@cimpress/react-components';
import { handleEnterKeyPress } from 'utility/keyEvent.utility';
import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { useTranslation } from 'react-i18next';
import { useResponsiveDesign } from 'providers/ResponsiveDesign';
import TemplateInformationMinimal from 'components/templateInformation/TemplateInformationMinimal';
import ProductPreview from 'components/preview/ProductPreview';
import IconView from '@cimpress-technology/react-streamline-icons/lib/IconView';
import { CREATE_MODE } from 'utility/enums';
import { LocalStorage } from 'utility/constants';
import cx from 'classnames';
import RecentSkus, { RecentSku } from 'components/recentSkus/RecentSkus';
import { getItemFromLocalStorage, saveItemInLocalStorage } from 'utility/storage.utility';
import { productTypes } from 'store/product';
import { MaskLegend } from 'components/maskLegend/MaskLegend';

import ProductSelector from '../../screens/create/ProductSelector';
import { getAllProps, AllProps, StateProps, DispatchProps, OwnProps, mapStateToProps, mapDispatchToProps } from './props.createTemplate';
import SurfaceSelector from './SurfaceSelector';

import './createTemplate.scss';

const { ocean } = colors;

const maxFavouriteSku = 3;

const getSortedRecentSkus = () => {
    const skus = getItemFromLocalStorage(LocalStorage.RecentSkusKey);
    if (skus) {
        const favouriteSkus = skus.filter((sku: RecentSku) => sku.isFavourite)
            .sort((a: RecentSku, b: RecentSku) => new Date(b.lastUsedOn).getTime() - new Date(a.lastUsedOn).getTime());
        const nonFavouriteSkus = skus.filter((sku: RecentSku) => !sku.isFavourite)
            .sort((a: RecentSku, b: RecentSku) => new Date(b.lastUsedOn).getTime() - new Date(a.lastUsedOn).getTime());

        return favouriteSkus.concat(nonFavouriteSkus);
    }
    return undefined;
};

function CreateTemplate(allProps: AllProps) {
    const { ownProps, stateProps, dispatchProps } = getAllProps(allProps);
    const [currentSku, setCurrentSku] = useState({ value: '', isValid: false, name: '' });
    const [selectedSkuId, setSelectedSkuId] = useState<string | undefined>();
    const [showPreview, setShowPreview] = useState(false);
    const { t } = useTranslation();
    const [recentSkus, setRecentSkus] = useState<RecentSku[] | undefined>(getSortedRecentSkus());

    const { isMobile, isDesktopOrLaptop, isTablet } = useResponsiveDesign();
    const togglePreview = () => setShowPreview(!showPreview);
    const onHeaderClick = (e: DocumentEvent, isNowOpen: boolean) => {
        ownProps.onTemplateInformationToggle && ownProps.onTemplateInformationToggle(isNowOpen);
    };

    useEffect(() => {
        dispatchProps.resetInFlightTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setSelectedSkuId(undefined);
        if (ownProps.createMode === CREATE_MODE.custom) {
            setCurrentSku({ value: '', isValid: false, name: '' });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ownProps.createMode]);

    const onClickFavourites = (e: Event, recentSkuObj: RecentSku) => {
        e.stopPropagation();
        if (recentSkus) {
            if (Object.values(recentSkus).filter((sku) => sku.isFavourite).length === maxFavouriteSku && !recentSkuObj.isFavourite) {
                return;
            }
            const index = recentSkus.findIndex((recentSku: RecentSku) => recentSku.sku === recentSkuObj.sku);
            const skus = [...recentSkus];
            if (index >= 0) {
                skus[index] = { ...skus[index], isFavourite: !(skus[index].isFavourite) };
                setRecentSkus(skus);
                saveItemInLocalStorage(LocalStorage.RecentSkusKey, skus);
            }
        }
    };

    const handleSkuChange = (sku: string | undefined) => {
        dispatchProps.clearDesignTransfer();
        ownProps.onSkuInputChange && ownProps.onSkuInputChange(sku);
        if (!sku) {
            setCurrentSku({ value: '', isValid: false, name: '' });
            setSelectedSkuId(undefined);
        } else {
            setSelectedSkuId(sku);
        }
    };
    const onValidProduct = useCallback((product: productTypes.Product, complete: boolean) => {
        ownProps.onValidProduct && ownProps.onValidProduct(product, complete);
        if (ownProps.createMode === CREATE_MODE.designTransfer && ownProps.docRefUrl && product) {
            dispatchProps.designTransfer({ docRefUrl: ownProps.docRefUrl, product });
            dispatchProps.setInFlightTemplateSku(product.sku);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onInValidProduct = useCallback(() => {
        ownProps.onInValidProduct && ownProps.onInValidProduct();
        dispatchProps.clearDesignTransfer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (stateProps.isClearCurrentSkuForDesignTransfer) {
            handleSkuChange(undefined);
            dispatchProps.clearDesignTransfer();
            dispatchProps.clearCurrentSkuForDesignTransfer();
        }
        return () => {
            dispatchProps.clearDesignTransfer();
            dispatchProps.clearCurrentSkuForDesignTransfer();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateProps.isClearCurrentSkuForDesignTransfer]);

    const getCurrentSku = () => ((currentSku.isValid && ownProps.createMode === CREATE_MODE.skuBased) ? currentSku.value : undefined);

    return (
        <div className='create-template'>
            {isMobile && (
                <span className='create__preview-toggle' onClick={togglePreview}><IconView
                    size='md'
                    color={ocean.darker}
                /> {t('create.preview')}
                </span>
            )}

            <div className={cx('create__inputs', { 'create__inputs-show': ((!showPreview && isMobile) || isDesktopOrLaptop || isTablet) })}>
                <Accordion
                    customOpen={ownProps.isTemplateInformationOpen}
                    title={t('editor.tabs.templateInfo.templateInfoTabTitle')}
                    variant='minimal'
                    onHeaderClick={onHeaderClick}
                >
                    <TemplateInformationMinimal
                        tags={ownProps.tags}
                        inFlightTemplate={stateProps.inFlightTemplate}
                        setInFlightTemplateName={dispatchProps.setInFlightTemplateName}
                        setInFlightTemplateDescription={dispatchProps.setInFlightTemplateDescription}
                        setInFlightTemplateTags={dispatchProps.setInFlightTemplateTags}
                        removeInFlightTemplateTags={dispatchProps.removeInFlightTemplateTags}
                        structuredTags={stateProps.structuredTags}
                    />
                </Accordion>
                {(ownProps.createMode === CREATE_MODE.skuBased || ownProps.createMode === CREATE_MODE.designTransfer)
                    && (
                        <>
                            <div className='create__product-selector' id='joy-ride__sku-input'>
                                <ProductSelector
                                    onValid={onValidProduct}
                                    onInvalid={onInValidProduct}
                                    onKeyPress={handleEnterKeyPress(() => 'goToEditor')}
                                    onValidSku={setCurrentSku}
                                    onInvalidSku={setCurrentSku}
                                    sku={selectedSkuId}
                                    onSkuInputChange={handleSkuChange}
                                    allowIncompleteAttributeSelection={stateProps.enableIncompleteSkuVariableSelection === true}
                                />
                            </div>
                            {recentSkus && recentSkus.length > 0 && (
                                <Accordion
                                    title={t('create.useRecentSku')}
                                    variant='minimal'
                                    defaultOpen={true}
                                >
                                    <RecentSkus
                                        onClickFavourites={onClickFavourites}
                                        onSkuSelect={(skuId) => handleSkuChange(skuId)}
                                        skus={recentSkus}
                                        showFavouritesIcon={true}
                                    />
                                </Accordion>
                            )}
                        </>

                    )}

                {ownProps.createMode === CREATE_MODE.custom
                    && (
                        <div className='create__surfaces'>
                            <SurfaceSelector
                                onValid={ownProps.onValidSurfaceSpecification}
                                onInValid={ownProps.onInValidSurfaceSpecification}
                            />
                        </div>
                    )}
            </div>

            {(isDesktopOrLaptop || isTablet || showPreview) && (
                <div className='create__preview'>
                    <h2>{currentSku.name ? currentSku.name : t('create.preview')}</h2>
                    <div className={cx('create__preview-image', { 'create__preview-image-skuless': ownProps.surfaceSpecifications })}>
                        <ProductPreview
                            sku={getCurrentSku()}
                            surfaceSpecifications={ownProps.surfaceSpecifications}
                            designTransferTransientDocument={stateProps.designTransferTransientDocument}
                        />
                    </div>
                    {ownProps.surfaceSpecifications && (
                        <MaskLegend />
                    )}
                </div>
            )}
        </div>

    );
}

export default connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)(CreateTemplate);
