import React from 'react';
import { Modal } from '@cimpress/react-components';

import './previewThumbnail.scss';
import { useTranslation } from 'react-i18next';

interface Props {
    title: string;
    src?: string;
    onModalClose(): void;
}

export function PreviewModal({ title, src, onModalClose }: Props) {
    const { t } = useTranslation();
    const closePreviewModal = () => onModalClose();
    return (
        <Modal
            className='preview__thumbnail-modal'
            closeButton={true}
            show={true}
            closeOnOutsideClick={true}
            onRequestHide={closePreviewModal}
            title={title}
        >
            <div className='preview__thumbnail-image'>
                <img
                    src={src}
                    alt={t('common.preview')}
                />
            </div>
        </Modal>
    );
}

export default PreviewModal;
