const createLink = (blob: Blob, filename: string) => {
    const newBlob = new Blob([blob], { type: 'application/pdf' });
    const objectUrl = window.URL.createObjectURL(newBlob);
    const a = document.createElement('a');
    a.href = objectUrl;
    a.download = filename;
    a.click();
};

export async function downloadFile(url: string, filename: string) {
    return fetch(url)
        .then((r) => r.blob())
        .then((blob) => createLink(blob, filename));
}
