/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '@cimpress/react-components/lib/Breadcrumbs';
import BreadcrumbItem from '@cimpress/react-components/lib/BreadcrumbItem';
import Tooltip from '@cimpress/react-components/lib/Tooltip';
import cx from 'classnames';
import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { useResponsiveDesign } from 'providers/ResponsiveDesign';
import { AllProps, getAllProps, StateProps, DispatchProps, mapStateToProps, mapDispatchToProps } from './props.collectionBreadCrumbs';
import './collectionBreadCrumbs.scss';

function CollectionBreadCrumbs(allProps: AllProps) {
    const { stateProps, dispatchProps } = getAllProps(allProps);
    const { isTabletOrMobile, isDesktopOrLaptop } = useResponsiveDesign();
    const { t } = useTranslation();
    const [breadCrumbs, setBreadCrumbs] = useState([{ label: t('catalog.actions.allTemplates'), id: t('catalog.actions.allTemplates') }]);

    useEffect(() => {
        const allTemplates = t('catalog.actions.allTemplates');
        setBreadCrumbs(stateProps.activeCollectionPath || [{ label: allTemplates, id: allTemplates }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateProps.activeCollectionPath, t]);

    return (
        <>
            {isDesktopOrLaptop && (
                <Breadcrumbs>
                    {breadCrumbs.map((breadCrumb: {label: string; id: string}) => (
                        <BreadcrumbItem
                            key={breadCrumb.id}
                            active={(stateProps.activeCollection && breadCrumb.id === stateProps.activeCollection.id) || breadCrumb.id === t('catalog.actions.allTemplates')}
                        >
                            <Tooltip direction='top' contents={breadCrumb.label}>
                                <div
                                    className={cx('breadcrumb-label', { 'text-primary cursor-pointer': stateProps.activeCollection && breadCrumb.id !== stateProps.activeCollection.id })}
                                    onClick={() => stateProps.activeCollection && breadCrumb.id !== stateProps.activeCollection.id && dispatchProps.updateActiveCollection(breadCrumb.id)}
                                >
                                    {breadCrumb.label}
                                </div>
                            </Tooltip>
                        </BreadcrumbItem>
                    ))}
                </Breadcrumbs>
            )}
            {isTabletOrMobile
                && (
                    <Tooltip direction='bottom' contents={stateProps.activeCollection ? stateProps.activeCollection.label : t('catalog.actions.allTemplates')}>
                        <div className={cx('breadcrumb-mobile-laptop--active')}>
                            {stateProps.activeCollection ? stateProps.activeCollection.label : t('catalog.actions.allTemplates')}
                        </div>
                    </Tooltip>
                )}
        </>
    );
}
export default connect<StateProps, DispatchProps, {}, RootState>(mapStateToProps, mapDispatchToProps)(CollectionBreadCrumbs);
