/* eslint-disable no-plusplus */
import React from 'react';

import { directoryTypes } from '../index';
import { DirectoryNode } from '../directoryNode/DirectoryNode';

interface Props {
    directories: directoryTypes.Directory[];
    actions?: directoryTypes.DirectoryActions[];
    selectedDirectoryIds?: string[];
    disabledDirectoryIds?: string[];
    expandOnLabelClick?: boolean;
    onSelect?(directory: directoryTypes.Directory): void;
    onExpand?(directory: directoryTypes.Directory): void;
    validations?: string[];
    isCollectionSaving?: boolean;
    isCollectionAddedSuccess?: boolean;
    resetIsCollectionAddedSuccess?(): void;
    levelToAddChild?: number;
}

const ROOT_LEVEL = 1;

export function DirectoryList({
    directories,
    selectedDirectoryIds,
    disabledDirectoryIds,
    onSelect,
    onExpand,
    actions,
    expandOnLabelClick,
    isCollectionSaving,
    isCollectionAddedSuccess,
    resetIsCollectionAddedSuccess,
    levelToAddChild }: Props) {
    return (
        <div>
            {directories && directories.map((directory) => (
                <DirectoryNode
                    key={directory.id}
                    selectedDirectoryIds={selectedDirectoryIds}
                    disabledDirectoryIds={disabledDirectoryIds}
                    directory={directory}
                    onSelect={onSelect}
                    onExpand={onExpand}
                    actions={actions}
                    expandOnLabelClick={expandOnLabelClick}
                    isCollectionSaving={isCollectionSaving}
                    isCollectionAddedSuccess={isCollectionAddedSuccess}
                    resetIsCollectionAddedSuccess={resetIsCollectionAddedSuccess}
                    levelToAddChild={levelToAddChild}
                    level={ROOT_LEVEL}
                />
            ))}
        </div>
    );
}
