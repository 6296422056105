import { takeEvery, call, all, put, StrictEffect } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import _isEqual from 'lodash.isequal';

import { logError } from 'loggingManager';
import { uiStateActions, formatNotification } from 'store/uiState';
import { SNACKBAR_NOTIFICATION_TYPES } from 'utility/enums';
import slice from './slice.tenant';
import { Tenant } from './types.tenant';
import { getAvailableTenants, getLastTenant, setLastTenant } from './service.tenant';

export function* onStartLoadingTenant() {
    let availableTenants: Tenant[];
    try {
        availableTenants = yield call(getAvailableTenants);
    } catch (error) {
        logError('Failed to retrieve available tenants');
        yield put(slice.actions.fetchTenantFailure());
        return;
    }

    const lastTenant = (yield call(getLastTenant)) as Tenant | undefined;
    // if tenant doesn't exist in availableTenants it will mean the tenant doesn't have any permissions anymore, so tenant will be set to undefined
    const currentTenant = lastTenant && availableTenants.find((tenant) => tenant.tenantId === lastTenant.tenantId);

    if (lastTenant && currentTenant && !_isEqual(lastTenant.permissions, currentTenant.permissions)) {
        yield call(setLastTenant, currentTenant);
    }
    yield put(slice.actions.fetchTenantSuccess({ availableTenants, lastTenant: currentTenant }));

    if (!currentTenant && availableTenants) {
        yield put(uiStateActions.addNotification([formatNotification(SNACKBAR_NOTIFICATION_TYPES.Info, { key: 'notifications.danger.selectTenantAlertMsg' })]));
    }
}

export function* onSaveTenant(action: PayloadAction<Tenant>) {
    yield call(setLastTenant, action.payload);
}

export default function* tenantSaga(): Generator<StrictEffect, StrictEffect, never> {
    return yield all([
        yield takeEvery(slice.actions.fetchTenant, onStartLoadingTenant),
        yield takeEvery(slice.actions.setCurrentTenant, onSaveTenant),
    ]);
}
