import { RootState } from 'store/rootReducer';
import { createSelector } from '@reduxjs/toolkit';
import { collectionTypes } from './index';
import { findCollectionById, getFolderPath, getNestedCollection } from './collectionUtils';

const selectCollectionState = (state: RootState) => state.collectionState;

const selectActiveCollection = (state: RootState): collectionTypes.Collection | undefined => {
    const collection = state.collectionState.activeCollection;
    return collection ? { ...collection, hasChildren: !!(collection._links && collection._links.children) } : undefined;
};

const selectAllCollections = (state: RootState): collectionTypes.Collection[] => getNestedCollection(state.collectionState.collections);

const selectCollectionById = (state: RootState, collectionId: string): collectionTypes.Collection | undefined => {
    const collection = findCollectionById(state.collectionState.collections, collectionId);
    return collection ? { ...collection, hasChildren: !!(collection._links && collection._links.children) } : undefined;
};

const selectCollectionAddedSuccess = (state: RootState) => state.collectionState.isCollectionAddedSuccess;

const selectActiveCollectionPath = createSelector(
    [selectCollectionState],
    (templateState) => {
        const { activeCollection, collections: allCollections } = templateState;
        const collectionHierarchy = activeCollection && getFolderPath(allCollections, activeCollection.id);
        let response;
        if (collectionHierarchy && collectionHierarchy.length > 0) {
            response = collectionHierarchy.map((ch) => ({ label: ch.label, id: ch.id }));
        }
        return response;
    },
);

const selectHasCollection = (state: RootState): boolean => state.collectionState.collections.length > 0;

export default {
    selectCollectionState,
    selectActiveCollection,
    selectAllCollections,
    selectCollectionById,
    selectHasCollection,
    selectActiveCollectionPath,
    selectCollectionAddedSuccess,
};
