import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Button, Tooltip, colors } from '@cimpress/react-components';
import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { DESIGNER_MODE } from 'utility/enums';
import IconAlertTriangle from '@cimpress-technology/react-streamline-icons/lib/IconAlertTriangle';
import { URLs } from 'utility/constants';
import { saveItemInSessionStorage } from 'utility/storage.utility';
import { v4 as uuidv4 } from 'uuid';
import { updateRecentSkus } from 'components/recentSkus/recentSku.utility';
import { getAllProps, AllProps, StateProps, DispatchProps, OwnProps, mapStateToProps, mapDispatchToProps } from './props.create';

const { warning } = colors;

function TabFooter(allProps: AllProps) {
    const { ownProps, stateProps, dispatchProps } = getAllProps(allProps);

    const buttonDisabled = (!ownProps.surfaceSpecifications && !ownProps.currentProduct) || !stateProps.hasMandatoryDesignerSettings || !stateProps.inFlightTemplate.name;

    const { t } = useTranslation();

    const goToEditor = () => {
        if (ownProps.currentProduct) {
            const productUuid = uuidv4();
            saveItemInSessionStorage(productUuid, ownProps.currentProduct);
            updateRecentSkus(ownProps.currentProduct.sku);
            dispatchProps.connectedPush(`/editor/${DESIGNER_MODE.TemplateEditor}?productUuid=${productUuid}`);
        } else if (ownProps.surfaceSpecifications) {
            const surfaceSpecificationsUuid = uuidv4();
            saveItemInSessionStorage(surfaceSpecificationsUuid, ownProps.surfaceSpecifications);
            dispatchProps.connectedPush(`/editor/${DESIGNER_MODE.TemplateEditor}?surfaceSpecificationsUuid=${surfaceSpecificationsUuid}`);
        }
    };
    return (
        <>
            {!stateProps.hasMandatoryDesignerSettings && (
                <Tooltip
                    direction='top'
                    contents={(
                        <Trans i18nKey='create.ttDesignerSetupWarning'>
                            This button is disabled as the tenant that you are using is setup incorrectly. Please reach out to your tenant admin to set it up before creating templates.
                            See <a target='_blank' rel='noopener noreferrer' href={URLs.TemplateMakerDocumentation}>documentation</a> if you are the tenant admin.
                        </Trans>
                    )}
                >
                    <div className='create__footer-info'><IconAlertTriangle color={warning.darker} /></div>
                </Tooltip>
            )}
            {!ownProps.completelyConfiguredProduct && (

                <div className='create__footer-info incomplete-product-options'>
                    <IconAlertTriangle color={warning.darker} />
                    <span>
                        <Trans i18nKey='create.ttIncompleteProductConfigurationWarning'>
                            Not all product options have been specified. Template Maker may not work correctly.
                        </Trans>
                    </span>
                </div>
            )}
            <Button type='primary' disabled={buttonDisabled} onClick={goToEditor}>{t('create.btnStart')}</Button>
        </>

    );
}

export default connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)(TabFooter);
