import { RootState } from 'store/rootReducer';
import { tenantTypes, tenantActions, tenantSelector } from 'store/tenant';
import { uiStateSelector } from 'store/uiState';

export interface StateProps {
    currentTenant?: tenantTypes.Tenant;
    availableTenants: tenantTypes.Tenant[];
    disabled: boolean;
    isLoading: boolean;
}
export interface DispatchProps {
    saveTenant(tenant: tenantTypes.Tenant): void;
    fetchTenant(): void;
}
export type AllProps = StateProps & DispatchProps;

export const getAllProps = (props: AllProps) => {
    const { currentTenant, availableTenants, disabled, isLoading, saveTenant, fetchTenant } = props;
    const stateProps: StateProps = { currentTenant, availableTenants, disabled, isLoading };
    const dispatchProps: DispatchProps = { saveTenant, fetchTenant };
    return { stateProps, dispatchProps };
};

export const mapStateToProps = (state: RootState): StateProps => ({
    currentTenant: tenantSelector.selectCurrentTenant(state),
    availableTenants: tenantSelector.selectAllTenants(state),
    disabled: false,
    isLoading: uiStateSelector.selectIfLoading(state, tenantActions.fetchTenant.toString()),
});
export const mapDispatchToProps: DispatchProps = {
    saveTenant: tenantActions.setCurrentTenant,
    fetchTenant: tenantActions.fetchTenant,
};
