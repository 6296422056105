import React from 'react';

import './canvases.scss';

/**
 * This is the component wrapper for the canvases. The canvases will be injected into the #canvases element.
 */
export default function Canvases() {
    return (
        <div id='canvases' />
    );
}
