import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { Button } from '@cimpress/react-components';
import { saveItemInSessionStorage } from 'utility/storage.utility';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { DESIGNER_MODE } from 'utility/enums';
import { useTranslation } from 'react-i18next';
import { updateRecentSkus } from 'components/recentSkus/recentSku.utility';
import { INITIAL_STATE as INITIAL_TEMPLATE_STATE } from 'store/template/slice.template';
import { templateTypes } from 'store/template';
import { getAllProps, AllProps, OwnProps, StateProps, DispatchProps, mapDispatchToProps, mapStateToProps } from './props.copyDesignActions';

const defaultInflightTemplate = INITIAL_TEMPLATE_STATE.inFlightTemplate;

const isTemplateInformationModified = (inFlightTemplate: templateTypes.InFlightTemplate) => (inFlightTemplate.name !== defaultInflightTemplate.name
   || inFlightTemplate.description !== defaultInflightTemplate.description
   || inFlightTemplate.tags.length > 0);

function CopyDesignActions(allProps: AllProps) {
    const { ownProps, stateProps, dispatchProps } = getAllProps(allProps);
    const history = useHistory();
    const { t } = useTranslation();

    const onEdit = () => {
        if (stateProps.designTransferTransientDocument && stateProps.designTransferTransientDocument.documentReferenceUrl && ownProps.product) {
            const productUuid = uuidv4();
            saveItemInSessionStorage(productUuid, ownProps.product);
            updateRecentSkus(ownProps.product.sku);
            history.push(`/editor/${DESIGNER_MODE.TemplateEditor}?docRefUrl=${encodeURIComponent(stateProps.designTransferTransientDocument.documentReferenceUrl)}&productUuid=${productUuid}`);
        }
    };

    const onApprove = () => {
        if (stateProps.designTransferTransientDocument && stateProps.designTransferTransientDocument.cimDoc) {
            dispatchProps.saveCimDocToUds({ cimDoc: stateProps.designTransferTransientDocument.cimDoc });
            ownProps.product && updateRecentSkus(ownProps.product.sku);
        }
    };

    const onClear = () => {
        dispatchProps.resetInFlightTemplate();
        dispatchProps.clearDesignTransfer();
        dispatchProps.setCurrentSkuForDesignTransfer();
    };

    return (
        <>
            <Button onClick={onClear} disabled={!ownProps.sku && !isTemplateInformationModified(stateProps.inFlightTemplate)}>{t('modals.copyToSku.clear')}</Button>
            <Button onClick={onApprove} disabled={!stateProps.designTransferTransientDocument}>{t('modals.copyToSku.approve')}</Button>
            <Button disabled={!stateProps.designTransferTransientDocument} onClick={onEdit}>{t('modals.copyToSku.edit')}</Button>
        </>
    );
}

export default connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)(CopyDesignActions);
