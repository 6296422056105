import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'store/rootReducer';
import { tenantTypes } from 'store/tenant';
import { useAuth } from 'providers/AuthContext';
import TenantSelector from '../tenantSelector';

import './tenantDisplay.scss';
import { DispatchProps, mapDispatchToProps, StateProps, mapStateToProps, AllProps, getAllProps } from './props.tenantDisplay';

const tenantDisableRoutes = ['editor'];

const isTenantSelectionDisabled = () => tenantDisableRoutes.some((route) => window.location.pathname.indexOf(`/${route}/`) >= 0);

export function TenantDisplay(allProps: AllProps) {
    const { t } = useTranslation();
    const { stateProps, dispatchProps } = getAllProps(allProps);

    const [open, setOpen] = useState(false);
    const onOpen = () => !isTenantSelectionDisabled() && setOpen(true);
    const onClose = () => setOpen(false);
    const onSave = (tenant: tenantTypes.Tenant) => {
        dispatchProps.saveTenant(tenant);
        setOpen(false);
    };

    const { isLoggedIn } = useAuth();

    useEffect(() => {
        if (!stateProps.isLoading) {
            setOpen(!!stateProps.availableTenants.length && !stateProps.currentTenant);
        }
    }, [stateProps.isLoading, stateProps.availableTenants.length, stateProps.currentTenant]);

    const isOpen = () => open;

    return (
        <div className='tenant'>
            { (stateProps.availableTenants.length > 0) && (
                <div className='tenant__wrapper'>
                    <span className='tenant__label'>{t('header.tenant')}</span>
                    <button type='button' disabled={!isLoggedIn} className='tenant__button' onClick={onOpen}>
                        {stateProps.currentTenant ? stateProps.currentTenant.tenantDisplayName : 'None'}
                    </button>
                </div>
            )}
            { isLoggedIn && (stateProps.currentTenant || isOpen()) && (
                <TenantSelector
                    currentTenant={stateProps.currentTenant}
                    availableTenants={stateProps.availableTenants}
                    open={open}
                    onClose={onClose}
                    onSave={onSave}
                    disabled={isTenantSelectionDisabled()}
                />
            )}
        </div>
    );
}

export default connect<StateProps, DispatchProps, {}, RootState>(mapStateToProps, mapDispatchToProps)(TenantDisplay);
