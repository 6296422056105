import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from '@cimpress/react-components';
import { RootState } from 'store/rootReducer';
import CollectionModal from 'components/collectionModal/CollectionModal';
import { collectionTypes } from 'store/collection';
import { getAssetContentUrl } from 'utility/url.utility';
import { getCommonCollectionIdsFromTemplates } from 'utility/helper';
import TemplateSaveSuccessHeader from 'components/successHeader/SuccessHeader';
import { StateProps, DispatchProps, mapStateToProps, mapDispatchToProps, AllProps, getAllProps } from './props.successHeaderWrapper';

function SuccessHeaderWrapper(allProps: AllProps) {
    const { stateProps, dispatchProps } = getAllProps(allProps);
    const { t } = useTranslation();
    const [isCollectionModalOpen, setIsCollectionModalOpen] = useState(false);
    const [selectedCollections, setSelectedCollections] = useState<collectionTypes.Collection[]>([]);

    const onClickAddToCollection = () => {
        setIsCollectionModalOpen(true);
    };
    const onCancelAddToCollection = () => {
        setIsCollectionModalOpen(false);
        setSelectedCollections([]);
    };

    const createPdf = () => {
        if (stateProps.template) {
            dispatchProps.createPrintPdf({
                docRefUrl: getAssetContentUrl(stateProps.template.id),
                fileName: `${stateProps.template.name}.pdf`,
            });
        }
    };

    useEffect(() => {
        stateProps.collections.length === 0 && dispatchProps.onCollectionLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateProps.collections.length, dispatchProps.onCollectionLoad]);

    const onClickAdd = () => {
        if (selectedCollections && selectedCollections.length) {
            if (stateProps.template) {
                dispatchProps.addTemplatesToCollections({ templates: [stateProps.template], targetCollections: selectedCollections });
            }
            setIsCollectionModalOpen(false);
        }
    };
    const onChangeSelectedCollection = (collections: collectionTypes.Collection[]) => setSelectedCollections(collections);

    return (
        <>
            {stateProps.template && (
                <>
                    <CollectionModal
                        title={t('modals.collection.addTitle')}
                        footer={(
                            <div className='collection-modal-btn'>
                                <Button className='btn btn-default' onClick={onCancelAddToCollection}>{t('common.cancel')}</Button>
                                <Button
                                    disabled={!selectedCollections.length}
                                    className='btn btn-primary'
                                    onClick={onClickAdd}
                                >{t('common.add')}
                                </Button>
                            </div>
                        )}
                        onRequestHide={onCancelAddToCollection}
                        onChangeSelectedCollection={onChangeSelectedCollection}
                        collections={stateProps.collections}
                        disabledDirectoryIds={getCommonCollectionIdsFromTemplates([stateProps.template])}
                        isOpen={isCollectionModalOpen}
                        selectedCollectionArea={{
                            title: t('modals.collection.addToSelected'),
                            description: t('modals.collection.addToSelectedDescription'),
                            emptyMessage: t('modals.collection.addToSelectedEmptyMessage'),
                        }}
                    />
                    <TemplateSaveSuccessHeader
                        template={stateProps.template}
                        isCreatingPdf={stateProps.isCreatingPdf}
                        hasCollection={stateProps.hasCollection}
                        enableEditingAceTemplates={stateProps.enableEditingAceTemplates}
                        createPdf={createPdf}
                        canEdit={stateProps.hasMandatoryDesignerSettings && !!stateProps.tenant?.permissions.asset.canCreate}
                        onClickAddToCollection={onClickAddToCollection}
                        addNotification={dispatchProps.addNotification}
                        uploadsTenant={stateProps.uploadsTenant}
                    />
                </>
            )}
        </>
    );
}

export default connect<StateProps, DispatchProps, {}, RootState>(mapStateToProps, mapDispatchToProps)(SuccessHeaderWrapper);
