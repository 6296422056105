import { all } from 'redux-saga/effects';

import { tenantSaga } from 'store/tenant';
import { uiStateSaga } from 'store/uiState';
import { editorSaga } from 'store/editor';
import { templateSaga } from 'store/template';
import { collectionSaga } from 'store/collection';
import { productSaga } from 'store/product';
import { clipArtSaga } from 'store/clipart';

export default function* rootSaga() {
    return yield all([
        tenantSaga(),
        uiStateSaga(),
        collectionSaga(),
        editorSaga(),
        templateSaga(),
        productSaga(),
        clipArtSaga(),
    ]);
}
