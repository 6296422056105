import React from 'react';
import { connect } from 'react-redux';
import { uiStateTypes } from 'store/uiState';
import { RootState } from 'store/rootReducer';
import { Snackbar } from '@cimpress/react-components';
import { useTranslation } from 'react-i18next';

import './notifications.scss';
import { StateProps, DispatchProps, mapStateToProps, mapDispatchToProps, AllProps, getAllProps } from './props.notification';

function Notifications(allProps: AllProps) {
    const { stateProps, dispatchProps } = getAllProps(allProps);

    const { t } = useTranslation();

    const getNotificationMessage = (notificationMessage: string | uiStateTypes.TranslationMessage): string => {
        if (typeof notificationMessage === 'string') {
            return notificationMessage;
        }
        return t(notificationMessage.key, notificationMessage.values);
    };

    return (
        <div className='notifications'>
            {
                stateProps.notifications.map((notification) => (
                    <Snackbar
                        key={notification.id}
                        show={true}
                        bsStyle={notification.type}
                        delay={notification.delay === 0 ? undefined : notification.delay}
                        onHideSnackbar={() => dispatchProps.removeNotification({ id: notification.id })}
                    >{getNotificationMessage(notification.message)}
                    </Snackbar>
                ))
            }
        </div>
    );
}

export default connect<StateProps, DispatchProps, {}, RootState>(mapStateToProps, mapDispatchToProps)(Notifications);
