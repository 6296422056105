import { Modal } from '@cimpress/react-components';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';

import useInterval from 'customHooks/useInterval';
import { BS_STYLE } from 'utility/enums';

interface Props {
    isOpen: boolean;
    setClose(): void;
}

export default function AuthExpirationModal({ isOpen, setClose }: Props) {
    const [count, setCount] = useState(5);

    useInterval(() => {
        if (count === 1) {
            setCount(5);
            setClose();
        } else {
            setCount(count - 1);
        }
    }, 1000, isOpen);

    return (
        <Modal
            bsStyle={BS_STYLE.danger}
            show={isOpen}
            title='Session Expired'
        >
            <Trans i18nKey='authorization.sessionExpiration' values={{ count }}>
                Your session has expired. We are triggering a refresh in <b>{count}</b> seconds...
            </Trans>
        </Modal>
    );
}
