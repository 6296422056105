import { AxiosResponse } from 'axios';
import { axiosWithAuth } from 'interceptors/auth.interceptor';
import { productTypes } from 'store/product';
import { URLs } from '../../../utility/constants';

interface SurfaceSetV2 {
    relatedResources: {
        calculatedSurfaceSet: { definitionHref: string }[];
    };
}

export async function getRequiredAttributesForSurfaceSpecifications(sku: string): Promise<productTypes.SurfaceSet | undefined> {
    try {
        const productV1response = await getSurfaceSets(sku);
        const surfaceSetsV1 = productV1response.data;
        const data = (surfaceSetsV1 && surfaceSetsV1[0]) || undefined;

        if (data !== undefined) {
            return data;
        }

        // assume V2 product
        const productV2response = await axiosWithAuth.get<SurfaceSetV2>(`${URLs.ProductApiService}/v1/products/${sku}:current`);
        const surfaceSetsV2 = productV2response.data;
        const location = surfaceSetsV2.relatedResources.calculatedSurfaceSet[0].definitionHref;
        const calculatedSurfaceSetDefinition = await axiosWithAuth.get<productTypes.SurfaceSet>(location);
        return calculatedSurfaceSetDefinition.data;
    } catch {
        return undefined;
    }
}

export function getSurfaceSets(sku: string): Promise<AxiosResponse<productTypes.SurfaceSet[]>> {
    return axiosWithAuth.get(`${URLs.ProductSurfacesService}/v3/surfaceSets?mcpSku=${sku}`).then((response) => response);
}
