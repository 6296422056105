import { takeEvery, put, call, all, StrictEffect } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { logError } from 'loggingManager';
import { templateActions } from 'store/template';
import { extractManufacturingProcessType, extractCimDocManufacturingSkuVariables } from 'utility/processType.utility';
import { editorActions } from '.';
import { SaveDocumentPayload } from './types.editor';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* onSaveDocument(action: PayloadAction<SaveDocumentPayload>) {
    const saveDocumentToUds = action.payload.saveToUds;
    try {
        const udsResponse: SaveDocumentResponse = yield call(saveDocumentToUds, { newId: true });
        yield put(editorActions.saveDocumentSuccess());
        yield put(templateActions.saveTemplate({
            documentReferenceUrl: udsResponse.documentReferenceUrl,
            surfaceSpecifications: action.payload.surfaceSpecifications,
            redirectToSuccess: true,
            processType: extractManufacturingProcessType(action.payload.document),
            skuVariables: extractCimDocManufacturingSkuVariables(action.payload.document),
        }));
    } catch (error) {
        logError('Failed to save template to Uds');
        yield put(editorActions.saveDocumentFailure());
    }
}

// TODO: Don't Delete, Will be covered in darft save
// export function* onPublishTemplate(action: PayloadAction) {
//     const template = action.payload;
//     try {
//         const asset = yield call(addAsset, template);
//         if (asset && asset.id) {
//             yield put(editorActions.setInFlightTemplateId(asset.id));
//         }

//         yield put(editorActions.publishTemplateSuccess());
//         const savedAssetId = yield select(editorSelector.selectInflightTemplateId);
//         yield put(push(`/success/${savedAssetId}`)); // TODO: Handle Redirection
//         deleteConfig(LocalStorage.TemplateConfigKey);
//     } catch (error) {
//         logError('Failed to publish template!');
//         yield put(editorActions.publishTemplateFailure());
//         yield put(push('/catalog?draft=true')); // TODO: Handle Redirection
//     }
// }

export default function* editorSaga(): Generator<StrictEffect, StrictEffect, never> {
    return yield all([
        yield takeEvery(editorActions.saveDocument, onSaveDocument),
        // yield takeEvery(editorActions.publishTemplate, onPublishTemplate),
    ]);
}
