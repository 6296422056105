export enum API_CALL_STATUS {
    InProgress = 'InProgress',
    IsComplete = 'IsComplete',
}

export enum SEARCH_OPTIONS {
    Name = 'Name',
    Description = 'Description',
    ReferenceId = 'ReferenceId',
    Tags = 'Tags',
    Author = 'Author'
}

export enum SNACKBAR_NOTIFICATION_TYPES {
    Warning = 'warning',
    Danger = 'danger',
    Info = 'info',
    Success = 'success'
}

export enum EDITOR_MODE {
    AddRevision = 'AddRevision',
    SaveAsNew = 'SaveAsNew',
}

export enum DESIGNER_MODE {
    CustomerDesigner = 'customerDesigner',
    TemplateEditor = 'templateEditor',
    Developer = 'developer',
}

export enum CATALOG_VIEW {
    TableView = 'TableView',
    GridView = 'GridView'
}

export enum TEMPLATE_ACTION_TYPE {
    Delete = 'Delete',
    Restore = 'Restore',
    Duplicate = 'Duplicate',
}

export enum COLLECTION_MODAL_TYPE {
    AddToCollections = 'AddToCollections',
    MoveToCollections = 'MoveToCollections',
}

export enum UPLOAD_URL_VARIANTS {
    Thumbnail = 'thumb',
    Preview = 'preview',
    Original = 'original',
    Print = 'print',
}

export enum TEMPLATE_DISPLAY_PAGE {
    Landing = 'Landing',
    Catalog = 'Catalog'
}

export enum DIRECTORY_ALLOWED_ACTIONS {
    Edit = 'Edit',
    Add = 'Add',
}

export enum PREVIEW_TYPE_VIEW {
    CloseUp = 'CloseUp',
    Full = 'Full',
    FullBleed = 'FullBleed',
}

export enum TEMPLATE_ACTIONS {
    Create = 'create',
    Edit = 'edit',
    Duplicate = 'duplicate',
    CopyUrl = 'copyUrl',
    ManageVersion = 'manageVersion',
    DesignTransfer = 'designTransfer',
    Delete = 'delete',
    StitchX = 'stitchX',
    OpenInCam = 'openInCam'
}

export enum CREATE_MODE {
    skuBased = 'skuBased',
    custom = 'custom',
    import = 'import',
    designTransfer = 'designTransfer',
}

export enum BS_STYLE {
    success = 'success',
    error = 'error',
    danger = 'danger',
    warning = 'warning',
    info = 'info',
    none = ''
}

export enum SKULESS_TEMPLATE_SHAPE_TYPE {
    Rectangle = 'Rectangle',
    Circle = 'Circle',
}

export enum SURFACE_VARIABLES {
    TrimWidth = 'Trim Width',
    TrimHeight = 'Trim Height',
    SurfaceBleed = 'Surface Bleed',
    SurfaceSafety = 'Surface Safety',
    Radius = 'Radius',
    ShapeType = 'Shape Type',
    PageCount = 'Page Count',
}
