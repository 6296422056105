/**
 * Store the config under the provided key in local storage
 * @param {string} key - key want to extract
 * @param {string} configObj - TemplateConfig object as string
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const saveConfig = (key: string, configObj: any) => {
    localStorage.setItem(key, JSON.stringify(configObj));
};

export const deleteConfig = (key: string) => {
    localStorage.removeItem(key);
};

export const getConfig = (key: string) => JSON.parse(localStorage.getItem(key) || '{}');

export const hasConfig = (key: string): boolean => !!localStorage.getItem(key);

/**
 * Store the data under the provided key in session storage
 * @param {string} key - key want to extract
 * @param {string} item - data to store
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const saveItemInSessionStorage = (key: string, item: any) => {
    sessionStorage.setItem(key, JSON.stringify(item));
};

/**
 * get the data under the provided key in session storage
 * @param {string} key - key want to get
 */
export const getItemFromSessionStorage = (key: string) => JSON.parse(sessionStorage.getItem(key) || '{}');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const saveItemInLocalStorage = (key: string, item: any) => {
    localStorage.setItem(key, JSON.stringify(item));
};

export const getItemFromLocalStorage = (key: string) => {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : undefined;
};
