import { Attributes } from 'screens/create/ProductSelector';
import { axiosWithAuth } from 'interceptors/auth.interceptor';
import { logError } from 'loggingManager';
import { URLs } from 'utility/constants';

export async function designTransfer(docRefUrl: string, sku: string, skuVariables: Attributes | undefined) {
    let url = '';
    if (skuVariables) {
        url = `${URLs.DesignTransfer}?docRef=${docRefUrl}&sku=${sku}&SkuVariables=${encodeURIComponent(JSON.stringify(skuVariables))}`;
    } else {
        url = `${URLs.DesignTransfer}?docRef=${docRefUrl}&sku=${sku}`;
    }
    return axiosWithAuth.get(url)
        .then((response) => response.data)
        .catch((error) => {
            logError(`Design transfer api failed ${error}`);
            throw error;
        });
}
