import React from 'react';

import { clipArtTypes } from 'store/clipart';
import { ClipArtItem } from 'shared/clipart/clipartItem/ClipartItem';
import './clipartList.scss';

interface Props {
    clipArts: clipArtTypes.ClipArt[];
    onSelectClipArt(clipArt: clipArtTypes.ClipArt): void;
}

export default function ClipArtList({ clipArts, onSelectClipArt }: Props) {
    return (
        <div className='clipart-list'>
            {clipArts.map((clipArt) => (
                <ClipArtItem
                    key={clipArt.uploadId}
                    clipArt={clipArt}
                    onSelectClipArt={onSelectClipArt}
                />
            ))}
        </div>
    );
}
