import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import useMousetrap from 'react-hook-mousetrap';

import { RootState } from 'store/rootReducer';
import { useTranslation } from 'react-i18next';
import { Tooltip, Button, Dropdown } from '@cimpress/react-components';

import './footer.scss';
import { saveItemInSessionStorage } from 'utility/storage.utility';
import { SessionStorage } from 'utility/constants';
import { DESIGNER_MODE, SNACKBAR_NOTIFICATION_TYPES } from 'utility/enums';
import { Link, useLocation } from 'react-router-dom';
import useFullScreen from 'customHooks/useFullScreen';
import { formatNotification } from 'store/uiState';
import { SaveDocumentPayload } from 'store/editor/types.editor';
import VortexPreview from 'connectedComponents/vortexPreview/VortexPreview';
import { AllProps, getAllProps, StateProps, DispatchProps, OwnProps, mapStateToProps, mapDispatchToProps } from './props.footer';

/**
 * The footer holds the preview, zoom, history, and save widgets.
 */

function Footer(allProps: AllProps) {
    const { ownProps, stateProps, dispatchProps } = getAllProps(allProps);
    const { requestFullscreen, exitFullscreen, fullscreenEnabled, isFullscreen } = useFullScreen();
    const { t } = useTranslation();
    const location = useLocation();

    const isDisabled = !ownProps.designer || stateProps.isSaveAndPublishInProgress || (stateProps.currentProduct && !stateProps.currentProduct.name)
        || !(stateProps.tenantAssetPermissions?.canCreate);
    const isCustomerDesignerMode = ownProps.designerMode === DESIGNER_MODE.CustomerDesigner;

    function saveTemplate() {
        if (ownProps.designer) {
            const payload: SaveDocumentPayload = {
                saveToUds: ownProps.designer.saveDocumentToUds,
                document: ownProps.designer.getDocumentJson(),
                surfaceSpecifications: ownProps.surfaceSpecifications,
            };
            dispatchProps.saveAndPublishTemplate(payload);
        }
    }

    function onClickViewAsUser() {
        const cimDoc = ownProps.designer && ownProps.designer.api.design.getAsCimDoc();
        saveItemInSessionStorage(SessionStorage.CimDocKey, cimDoc);
    }

    const productName = stateProps.currentProduct && stateProps.currentProduct.name;

    const toggleFullScreen = () => {
        if (!fullscreenEnabled()) {
            dispatchProps.addNotification([formatNotification(SNACKBAR_NOTIFICATION_TYPES.Info, `${t('notifications.info.fullScreenRestricted')}`, 5000)]);
            return;
        }
        isFullscreen ? exitFullscreen() : requestFullscreen();
    };

    useEffect(() => () => {
        exitFullscreen();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useMousetrap(['f', 'F'], () => toggleFullScreen());

    const getLocation = () => `${(location.pathname.replace(DESIGNER_MODE.TemplateEditor, DESIGNER_MODE.CustomerDesigner))}${location.search}`;

    return (
        <>
            <div className={cx('footer')}>
                {!isCustomerDesignerMode && (
                    <div className={cx('footer__product-name')}>
                        {productName && (
                            <>
                                <span className={cx('product-label')}>{t('editor.footer.lblProductName')}</span>
                                <span className={cx('product-name')}>{productName}</span>
                            </>
                        )}
                    </div>
                )}
                <div className={cx('footer__section')}>
                    <VortexPreview designer={ownProps.designer} />
                    <div className={cx('dcl-widget-preview-document')} />
                    <div className={cx('dcl-widget-canvas-zoom')} />
                    <div className={cx('dcl-widget-canvas-history')} />
                </div>
                {!isCustomerDesignerMode && (
                    <div className={cx('footer__actions')}>
                        <Dropdown title='Actions' className={cx('footer__action-dropdown')}>
                            <Tooltip contents={t('editor.footer.ttEnterExtFullscreen')} direction='left'>
                                <Button onClick={toggleFullScreen} type='link' className={cx('footer__expand-window')}>
                                    {!isFullscreen ? t('editor.footer.btnFullScreenWindow') : t('editor.footer.btnExitFullScreenWindow')}
                                </Button>
                            </Tooltip>
                            <div className={cx('dcl-widget-print-pdf')} />
                            <Tooltip contents={t('editor.footer.ttViewAsUser')} direction='left'>
                                <Link
                                    className='btn btn-link footer__view-as-user'
                                    to={getLocation()}
                                    target='_blank'
                                    onClick={onClickViewAsUser}
                                    rel='opener'
                                >
                                    {t('editor.footer.btnViewAsUser')}
                                </Link>
                            </Tooltip>
                        </Dropdown>
                        <div className={cx('footer__save-template')}>
                            <Button disabled={isDisabled} type='primary' onClick={saveTemplate}>{t('editor.footer.btnSaveTemplate')}</Button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)(Footer);
