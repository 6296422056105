import React from 'react';
import { shapes } from '@cimpress/react-components';
import { useTranslation, Trans } from 'react-i18next';

import './coamError.scss';
import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { Redirect } from 'react-router-dom';
import { URLs } from 'utility/constants';
import { BS_STYLE } from 'utility/enums';
import { StateProps, mapStateToProps, AllProps, getAllProps } from './props.coamError';

const { Robot } = shapes;

function CoamError(allProps: AllProps) {
    const { stateProps } = getAllProps(allProps);

    const { t } = useTranslation();

    return (
        stateProps.hasCoamAccess ? <Redirect to={{ pathname: '/' }} />
            : (
                <div className='error-container'>
                    <div>
                        <Robot bsStyle={BS_STYLE.warning} size='lg' />
                    </div>
                    <h1>{t('coamError.accessDenied')}</h1>
                    <div>
                        <Trans i18nKey='coamError.COAMPermissionAlert'>
                            You have no COAM permission. Please contact your tenant admin or refer to the
                            <a
                                target='_blank'
                                rel='noopener noreferrer'
                                href={URLs.TemplateMakerDocumentation}
                            > documentation
                            </a>.
                        </Trans>
                    </div>
                </div>
            )

    );
}

export default connect<StateProps, {}, {}, RootState>(mapStateToProps)(CoamError);
