import { uiStateSelector, uiStateActions, uiStateTypes } from 'store/uiState';
import { RootState } from 'store/rootReducer';
import { templateSelector, templateTypes } from 'store/template';

export interface StateProps {
    templates: templateTypes.Template[];
    includeDeleted: boolean;
    includeOwnTemplates: boolean;
    includeSkulessTemplates: boolean;
    userPreference: uiStateTypes.UserPreference;
}
export interface DispatchProps {
    setShowDeletedTemplates(payload: boolean): void;
    setShowOwnTemplates(payload: boolean): void;
    setShowSkulessTemplates(payload: boolean): void;
    setUserPreference(payload: uiStateTypes.UserPreference): void;
}
export type AllProps = StateProps & DispatchProps;

export const getAllProps = (props: AllProps) => {
    const { templates, includeDeleted, includeOwnTemplates, userPreference, setShowDeletedTemplates, setShowOwnTemplates, setUserPreference, includeSkulessTemplates, setShowSkulessTemplates } = props;
    const stateProps: StateProps = { templates, includeDeleted, includeOwnTemplates, userPreference, includeSkulessTemplates };
    const dispatchProps: DispatchProps = { setShowDeletedTemplates, setShowOwnTemplates, setUserPreference, setShowSkulessTemplates };
    return { stateProps, dispatchProps };
};

export const mapStateToProps = (state: RootState): StateProps => ({
    templates: templateSelector.selectAllTemplatesWithPreview(state),
    includeDeleted: uiStateSelector.selectFilterCriteriaIncludeDeleted(state),
    includeOwnTemplates: uiStateSelector.selectFilterCriteriaIncludeOwnTemplates(state),
    includeSkulessTemplates: uiStateSelector.selectFilterCriteriaIncludeSkulessTemplates(state),
    userPreference: uiStateSelector.selectUserPreference(state),
});
export const mapDispatchToProps: DispatchProps = {
    setShowDeletedTemplates: uiStateActions.setShowDeletedTemplates,
    setShowOwnTemplates: uiStateActions.setShowOwnTemplates,
    setShowSkulessTemplates: uiStateActions.setShowSkulessTemplates,
    setUserPreference: uiStateActions.setUserPreference,
};
