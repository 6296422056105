import { RootState } from 'store/rootReducer';
import { createSelector } from '@reduxjs/toolkit';
import { tenantTypes } from './index';

const selectCurrentTenant = (state: RootState): tenantTypes.Tenant | undefined => state.tenantState.currentTenant;

const selectAllTenants = (state: RootState): tenantTypes.Tenant[] => state.tenantState.availableTenants;

const selectCurrentTenantAssetPermissions = (state: RootState): tenantTypes.AssetPermissions | undefined => state.tenantState.currentTenant?.permissions.asset;

const selectHasCoamAccess = createSelector(
    [selectAllTenants],
    (availableTenants) => availableTenants.length > 0,
);
export default {
    selectCurrentTenant,
    selectAllTenants,
    selectHasCoamAccess,
    selectCurrentTenantAssetPermissions,
};
