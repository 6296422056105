import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card } from '@cimpress/react-components';
import { useTranslation } from 'react-i18next';

import './landing.scss';
import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import Overlay from 'shared/overlay/Overlay';
import RecentTemplates from 'connectedComponents/recentTemplates/RecentTemplates';
import { AllProps, getAllProps, StateProps, mapStateToProps, DispatchProps, mapDispatchToProps } from './props.landing';

// const hasUnsavedTemplate = (): boolean => hasConfig(LocalStorage.TemplateConfigKey);

/**
 * The landing screen. Will be the first screen that anyone who visits the site will see.
 * Should have links and actions for viewing templates, creating templates, etc.
 */
export function Landing(allProps: AllProps) {
    const { stateProps, dispatchProps } = getAllProps(allProps);
    const { t } = useTranslation();
    const hasTenantAssigned = !!stateProps.currentTenant;
    const buttonDisabledClass = hasTenantAssigned ? '' : 'disabled';

    // const [openDraftModal, setOpenDraftModal] = useState(hasUnsavedTemplate());

    useEffect(() => {
        if (!stateProps.isTenantLoading && stateProps.currentTenant) {
            dispatchProps.loadRecentTemplates();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateProps.isTenantLoading, stateProps.currentTenant, stateProps.availableTenants, dispatchProps.loadRecentTemplates]);

    return (
        <>
            <Overlay routeName='landing' />
            <div className='landing'>
                {(hasTenantAssigned && !stateProps.isTenantLoading) && (
                    <div>
                        <div className='landing__banner'>
                            <Card className='landing__intro'>
                                <h1>Template Maker</h1>
                                <p>
                                    {t('landing.introCard.txtIntroduction')}
                                </p>
                                <div className='landing__actions-buttons'>
                                    <Link className={`btn btn-primary ${buttonDisabledClass}`} to='/create' id='joy-ride__template-create-btn'>
                                        {t('landing.introCard.btnCreateTemplate')}
                                    </Link>
                                    <Link className={`btn ${buttonDisabledClass}`} to='/catalog'>
                                        {t('landing.introCard.btnManageTemplate')}
                                    </Link>
                                </div>
                            </Card>
                        </div>
                        <div className='landing__features'>
                            <h2>{t('landing.features.features')}</h2>
                            <ul>
                                <li>{t('landing.features.featureDesignerSupport')}</li>
                                <li>{t('landing.features.featureExportTemplates')}</li>
                                <li>{t('landing.features.featureImportTemplates')}</li>
                                <li>{t('landing.features.featureVersioning')}</li>
                                <li>{t('landing.features.featurePlaceholder')}</li>
                                <li>{t('landing.features.featureTextLabels')}</li>
                                <li>{t('landing.features.featureUserPermissions')}</li>
                                <li>{t('landing.features.featureModificationTools')}</li>
                            </ul>
                        </div>
                        {stateProps.recentTemplates.length > 0 && (
                            <div className='landing__recent-templates'>
                                <h2>{t('landing.recentTemplates')}</h2>
                                <RecentTemplates />
                            </div>
                        )}
                    </div>
                )}
                {/* <UnsavedTemplateModal isOpen={openDraftModal} onRequestHide={() => setOpenDraftModal(false)} /> */}
            </div>
        </>
    );
}

export default connect<StateProps, DispatchProps, {}, RootState>(mapStateToProps, mapDispatchToProps)(Landing);
