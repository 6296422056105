import React, { useState } from 'react';
import { Dropdown, Button } from '@cimpress/react-components';
import { Link, useHistory } from 'react-router-dom';
import copyToClipboard from 'clipboard-copy';
import { useTranslation } from 'react-i18next';
import { templateTypes } from 'store/template';
import { tenantTypes } from 'store/tenant';
import { TEMPLATE_ACTION_TYPE, DESIGNER_MODE, TEMPLATE_ACTIONS } from 'utility/enums';
import { getAssetContentUrl, getStitchXEditorUrl } from 'utility/url.utility';
import { templateHasCollections } from 'utility/template.utility';
import ConfirmDialog from 'shared/confirmDialog/confirmDialog';
import CopyDesignModal from 'components/copyDesignModal/CopyDesignModal';
import { productTypes } from 'store/product';
import { EmbroideryProcessType } from 'utility/constants';

interface Props {
    templateDetail: templateTypes.Template;
    tenant?: tenantTypes.Tenant;
    title: string | JSX.Element;
    className?: string;
    canEdit: boolean;
    currentFolderId?: string;
    disabledActions?: string[];
    enableEditingAceTemplates: boolean;
    uploadsTenant: string | undefined;
    onCopyUrl?(): void;
    onRemoveTemplateFromCollection?(payload: templateTypes.Template[]): void;
    onDeleteTemplate?(payload: string): void;
    onRestoreTemplate?(payload: string): void;
    onDesignTransfer?(docRefUrl: string, product: productTypes.Product): void;
}

export default function ActionsDropdown({
    templateDetail,
    tenant,
    title,
    className,
    currentFolderId,
    disabledActions,
    canEdit,
    enableEditingAceTemplates,
    uploadsTenant,
    onCopyUrl,
    onRemoveTemplateFromCollection,
    onDeleteTemplate,
    onRestoreTemplate,
    onDesignTransfer }: Props) {
    const { t } = useTranslation();
    const history = useHistory();
    const [confirmTemplateAction, setConfirmTemplateAction] = useState<templateTypes.TemplateActionConfirmation | undefined>(undefined);
    const [docRefUrlToCopy, setDocRefUrlToCopy] = useState<string>();

    const redirectToEditor = (templateId: string, shouldDuplicateCollection = false) => {
        if (shouldDuplicateCollection) {
            history.push(`/editor/${DESIGNER_MODE.TemplateEditor}/${templateId}?mode=duplicate&duplicateCollection=true`);
        } else {
            history.push(`/editor/${DESIGNER_MODE.TemplateEditor}/${templateId}?mode=duplicate`);
        }
    };

    const onConfirmModalClose = () => {
        setConfirmTemplateAction(undefined);
    };

    const onConfirmModalYes = () => {
        if (confirmTemplateAction) {
            if (confirmTemplateAction.templateActionType === TEMPLATE_ACTION_TYPE.Delete) {
                onDeleteTemplate && onDeleteTemplate(confirmTemplateAction.template.id);
            }
            if (confirmTemplateAction.templateActionType === TEMPLATE_ACTION_TYPE.Restore) {
                onRestoreTemplate && onRestoreTemplate(confirmTemplateAction.template.id);
            }
            if (confirmTemplateAction.templateActionType === TEMPLATE_ACTION_TYPE.Duplicate) {
                redirectToEditor(confirmTemplateAction.template.id, true);
            }
            onConfirmModalClose();
        }
    };

    const onDuplicateCollectionModalClose = () => (confirmTemplateAction && confirmTemplateAction.templateActionType === TEMPLATE_ACTION_TYPE.Duplicate)
        && redirectToEditor(confirmTemplateAction.template.id);

    const deleteModalConfig = {
        title: t('modals.deleteTemplate.title'),
        body: t('modals.deleteTemplate.confirmationMessage'),
        footer: (
            <div>
                <Button onClick={onConfirmModalClose}>{t('common.cancel')}</Button>
                <Button type='primary' onClick={onConfirmModalYes}>{t('common.delete')}</Button>
            </div>
        ),
    };

    const restoreModalConfig = {
        title: t('modals.restoreTemplate.title'),
        body: t('modals.restoreTemplate.confirmationMessage'),
        footer: (
            <div>
                <Button onClick={onConfirmModalClose}>{t('common.cancel')}</Button>
                <Button type='primary' onClick={onConfirmModalYes}>{t('common.restore')}</Button>
            </div>
        ),
    };

    const duplicateTemplateModalConfig = {
        title: t('modals.duplicateTemplate.title'),
        body: t('modals.duplicateTemplate.confirmationMessage'),
        footer: (
            <div>
                <Button onClick={onDuplicateCollectionModalClose}>{t('common.no')}</Button>
                <Button type='primary' onClick={onConfirmModalYes}>{t('common.yes')}</Button>
            </div>
        ),
    };

    const onClickCopy = () => {
        copyToClipboard(getAssetContentUrl(templateDetail.id));
        onCopyUrl && onCopyUrl();
    };

    const onClickDuplicate = () => setConfirmTemplateAction({
        template: templateDetail,
        templateActionType: TEMPLATE_ACTION_TYPE.Duplicate,
    });

    const isActionEnabled = (actionName: TEMPLATE_ACTIONS) => !disabledActions?.some((disabledAction) => disabledAction === actionName);

    return (
        <>
            {confirmTemplateAction && (
                <ConfirmDialog isDialogOpen={true}>{
                    confirmTemplateAction.templateActionType === TEMPLATE_ACTION_TYPE.Delete ? deleteModalConfig : restoreModalConfig
                }
                </ConfirmDialog>
            )}

            {confirmTemplateAction && (confirmTemplateAction.templateActionType === TEMPLATE_ACTION_TYPE.Duplicate && (
                confirmTemplateAction.template && templateHasCollections(confirmTemplateAction.template) ? (
                    <ConfirmDialog isDialogOpen={true}>{
                        duplicateTemplateModalConfig
                    }
                    </ConfirmDialog>
                ) : redirectToEditor(confirmTemplateAction.template.id)))}

            {docRefUrlToCopy && (
                <CopyDesignModal
                    onCancel={() => setDocRefUrlToCopy(undefined)}
                    docRefUrl={docRefUrlToCopy}
                    tags={templateDetail.tags}
                />
            )}

            <Dropdown
                className={className}
                title={title}
            >
                {!templateDetail.deleted && (
                    <>
                        {canEdit && isActionEnabled(TEMPLATE_ACTIONS.Create)
                            && <Link className='btn btn-link' to={{ pathname: '/create' }}>{t('success.successActionsCard.btnCreateNewTemplate')}</Link>}
                        {canEdit && isActionEnabled(TEMPLATE_ACTIONS.Edit)
                            && (
                                <Button
                                    disabled={templateDetail.attributes?.camDesignId !== undefined && !enableEditingAceTemplates}
                                    type='link'
                                    href={templateDetail.attributes?.camDesignId !== undefined && !enableEditingAceTemplates ? '#' : `/editor/${DESIGNER_MODE.TemplateEditor}/${templateDetail.id}`}
                                >{t('catalog.actionsDropdown.editLabel')}
                                </Button>
                            )}
                        {canEdit && isActionEnabled(TEMPLATE_ACTIONS.StitchX)
                            && (templateDetail.attributes?.processType === undefined || templateDetail.attributes?.processType === EmbroideryProcessType)
                            && (
                                <Button
                                    type='link'
                                    href={getStitchXEditorUrl(templateDetail.id, uploadsTenant || '', tenant)}
                                    disabled={!uploadsTenant}
                                    target='_blank'
                                >{t('catalog.actionsDropdown.editInStitchXLabel')}
                                </Button>
                            )}
                        {canEdit && isActionEnabled(TEMPLATE_ACTIONS.Duplicate)
                            && <Button type='link' onClick={onClickDuplicate}>{t('catalog.actionsDropdown.duplicateLabel')}</Button>}
                        {onCopyUrl && isActionEnabled(TEMPLATE_ACTIONS.CopyUrl)
                            && <Button type='link' onClick={onClickCopy}>{t('catalog.actionsDropdown.copyLabel')}</Button>}
                        {isActionEnabled(TEMPLATE_ACTIONS.ManageVersion)
                            && <Link className='btn btn-link' to={{ pathname: `/versions/${templateDetail.id}` }}>{t('catalog.actionsDropdown.versionsLabel')}</Link>}
                        {canEdit && isActionEnabled(TEMPLATE_ACTIONS.DesignTransfer) && (
                            <Button
                                type='link'
                                onClick={() => setDocRefUrlToCopy(getAssetContentUrl(templateDetail.id))}
                            >{t('catalog.actionsDropdown.transferDesignLabel')}
                            </Button>
                        )}
                        {canEdit && isActionEnabled(TEMPLATE_ACTIONS.Delete) && (
                            <Button
                                type='link'
                                onClick={() => setConfirmTemplateAction({
                                    template: templateDetail,
                                    templateActionType: TEMPLATE_ACTION_TYPE.Delete,
                                })}
                            >{t('catalog.actionsDropdown.deleteLabel')}
                            </Button>
                        )}
                    </>
                )}
                {templateDetail.deleted && (
                    <Button
                        type='link'
                        onClick={() => setConfirmTemplateAction({
                            template: templateDetail,
                            templateActionType: TEMPLATE_ACTION_TYPE.Restore,
                        })}
                    >
                        {t('catalog.actionsDropdown.restoreLabel')}
                    </Button>
                )}
                {currentFolderId && onRemoveTemplateFromCollection
                    && <Button type='link' onClick={() => onRemoveTemplateFromCollection([templateDetail])}>{t('catalog.actionsDropdown.removeLabel')}</Button>}
            </Dropdown>
        </>
    );
}
