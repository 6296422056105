/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Label, Tag, colors } from '@cimpress/react-components';
import { templateTypes } from 'store/template';
import IconPencilAlt from '@cimpress-technology/react-streamline-icons/lib/IconPencilAlt';
import IconAdd from '@cimpress-technology/react-streamline-icons/lib/IconAdd';
import { getDisallowedTags, removeServiceTags, tagStringToArray } from 'utility/tags.utility';
import { StructuredTags } from 'store/uiState/types.uiState';
import InlineTextEdit from './InlineTextEdit';
import { parseSkuVariables } from '../../utility/skuVariables.utility';

import './templateDetails.scss';

const { ocean } = colors;

interface Props {
    template: templateTypes.Template;
    version?: templateTypes.TemplateVersion;
    editable?: boolean;
    editTemplateInformation?(payload: templateTypes.AssetToBeEdited): void;
    showAdditionalVersionDetails: boolean;
    structuredTags: StructuredTags[] | undefined;
}

const FormatString = 'MMMM do yyyy, h:mm:ss a';

function TemplateInfoRow({ label, className, children }: { label: string; className?: string; children: JSX.Element | string }) {
    return (
        <div className={`template__info-row ${className}`}>
            <div className='info-label'>{label}:</div>
            <div className='info-value'>
                {children}
            </div>
        </div>
    );
}

export default function TemplateDetails({ template, version, editable, editTemplateInformation, showAdditionalVersionDetails, structuredTags }: Props) {
    const [isEditingName, setIsEditingName] = useState(false);
    const [isEditingDescription, setIsEditingDescription] = useState(false);
    const [isEditingTags, setIsEditingTags] = useState(false);
    const [tagsErrorMessage, setTagsErrorMessage] = useState('');
    const { t } = useTranslation();

    const filteredTags = removeServiceTags(template.tags);
    const skuVariables = parseSkuVariables(template.attributes?.skuVariables);

    const onSaveInput = (name: string, value?: string) => {
        if (name === 'name' || name === 'description') {
            editTemplateInformation && editTemplateInformation({ [name]: value });
        } else {
            const tagSet = tagStringToArray(value);
            const uniqueTagSet = tagSet && Array.from(new Set([...tagSet, ...template.tags]));
            editTemplateInformation && editTemplateInformation({ [name]: uniqueTagSet });
        }
    };

    const removeTag = (tagToBeRemoved: React.ReactNode) => {
        editTemplateInformation && editTemplateInformation({ tags: template.tags.filter((eachTag) => eachTag !== tagToBeRemoved) });
    };

    const areTagsInvalid = (input: string) => {
        const tagSet = tagStringToArray(input);

        // no new tags are added, only duplicates
        if (tagSet && tagSet.length > 0 && tagSet.every((item) => filteredTags.indexOf(item) > -1)) {
            setTagsErrorMessage(t('editor.tabs.templateInfo.addTagError'));
            return true;
        }

        // some structured tags have values outside allowed ones
        const disallowedTags = getDisallowedTags(structuredTags, tagSet);
        if (disallowedTags.length > 0) {
            setTagsErrorMessage(t('editor.tabs.templateInfo.addTagStructuredTagError'));
            return true;
        }

        setTagsErrorMessage('');
        return false;
    };

    return (
        <>
            <TemplateInfoRow label={t('templateDetails.name')} className='no-bottom-margin'>
                <>
                    {!isEditingName && <span className='template-name'>{template.name}</span>}
                    {editable && !isEditingName && (
                        <span className='template-name__edit' onClick={() => setIsEditingName(true)}>
                            <IconPencilAlt weight='fill' color={ocean.darker} />
                        </span>
                    )}
                    {isEditingName && (
                        <InlineTextEdit
                            name='name'
                            value={template.name}
                            placeholder={t('templateDetails.namePlaceholder')}
                            isRequired={true}
                            onCancel={() => setIsEditingName(false)}
                            onSave={onSaveInput}
                        />
                    )}
                </>
            </TemplateInfoRow>
            {(editable || template.description) && (
                <TemplateInfoRow label={t('templateDetails.description')} className='no-bottom-margin'>
                    <>
                        {!isEditingDescription && (
                            <>
                                {template.description && <span className='template-description'>{template.description}</span>}
                                {!template.description && (
                                    <div className='template__actions-add text-primary' onClick={() => setIsEditingDescription(true)}>
                                        <IconAdd size='md' />
                                        {t('templateDetails.addDescription')}
                                    </div>
                                )}
                                {editable && template.description && (
                                    <span className='template-description__edit' onClick={() => setIsEditingDescription(true)}>
                                        <IconPencilAlt weight='fill' color={ocean.darker} />
                                    </span>
                                )}
                            </>
                        )}
                        {isEditingDescription && (
                            <InlineTextEdit
                                name='description'
                                value={template.description}
                                placeholder={t('templateDetails.descriptionPlaceholder')}
                                isRequired={false}
                                onCancel={() => setIsEditingDescription(false)}
                                onSave={onSaveInput}
                            />
                        )}
                    </>
                </TemplateInfoRow>
            )}
            {(editable || filteredTags.length !== 0) && (
                <TemplateInfoRow label={t('templateDetails.tags')} className='tag-list'>
                    <>
                        <div className='template-tags-input'>
                            {isEditingTags && (
                                <InlineTextEdit
                                    name='tags'
                                    value=''
                                    placeholder={t('templateDetails.tagPlaceholder')}
                                    isRequired={true}
                                    onCancel={() => setIsEditingTags(false)}
                                    onSave={onSaveInput}
                                    errorMessage={tagsErrorMessage}
                                    isError={areTagsInvalid}
                                />
                            )}
                            {!isEditingTags && filteredTags.length === 0 && (
                                <div className='template__actions-add text-primary' onClick={() => setIsEditingTags(true)}>
                                    <IconAdd size='md' />
                                    {t('templateDetails.addTag')}
                                </div>
                            )}
                        </div>
                        <div className='template-tags'>
                            <div className='template-tags__list'>
                                {filteredTags.map((eachTag) => (
                                    <Tag
                                        removable={isEditingTags}
                                        value={eachTag}
                                        onRemoveClick={removeTag}
                                    />
                                ))}
                            </div>
                            {editable && !isEditingTags && filteredTags.length !== 0 && (
                                <span className='template-tags__edit' onClick={() => setIsEditingTags(true)}>
                                    <IconPencilAlt weight='fill' color={ocean.darker} />
                                </span>
                            )}
                        </div>
                    </>
                </TemplateInfoRow>
            )}
            {template.sku && (
                <TemplateInfoRow label={t('templateDetails.SKU')}>
                    {template.sku}
                </TemplateInfoRow>
            )}
            <TemplateInfoRow label={t('templateDetails.dateCreated')}>
                {format(new Date(template.created), FormatString)}
            </TemplateInfoRow>
            <TemplateInfoRow label={t('templateDetails.status')}>
                <Label
                    text={template.deleted ? `${t('templateDetails.deleted')}` : `${t('templateDetails.published')}`}
                    type={template.deleted ? 'default' : 'success'}
                />
            </TemplateInfoRow>
            <TemplateInfoRow label={t('templateDetails.author')}>{template.owner}</TemplateInfoRow>
            <TemplateInfoRow label={t('templateDetails.tenant')}>{template.tenant.id}</TemplateInfoRow>
            {showAdditionalVersionDetails
            && (
                <>
                    <TemplateInfoRow label={t('templateDetails.modifiedOn')}>{format(new Date(template.lastModified), FormatString)}</TemplateInfoRow>
                    <TemplateInfoRow label={t('templateDetails.modifiedBy')}>{template.modifiedBy}</TemplateInfoRow>
                    <TemplateInfoRow label={t('templateDetails.publishedOn')}>{format(new Date(template.lastPublished), FormatString)}</TemplateInfoRow>
                    <TemplateInfoRow label={t('templateDetails.publishedBy')}>{template.publishedBy}</TemplateInfoRow>
                    {version && (
                        <>
                            <TemplateInfoRow label={t('templateDetails.createdOn')}>{format(new Date(version.created), FormatString)}</TemplateInfoRow>
                            <TemplateInfoRow label={t('templateDetails.createdBy')}>{version.createdBy}</TemplateInfoRow>
                        </>
                    )}
                </>
            )}
            {skuVariables && <legend className='legend-margin'>Sku Variables</legend>}
            {skuVariables && Object.entries(skuVariables).map(
                ([key, value]) => (
                    <TemplateInfoRow label={key}>{value}</TemplateInfoRow>
                ),
            )}
        </>
    );
}
