/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
import { StructuredTags } from 'store/uiState/types.uiState';
import { CollectionTagPrefix, SkulessTag, TagStringSeparator } from './constants';

export interface TagValidationResult {
    resultTags: string[];
    duplicateTags: string[];
    disallowedTags: string[];
}

export const tagArrayToString = (tags: string[] | undefined): string => {
    if (tags === undefined || tags.length === 0) {
        return '';
    }

    return tags.join(TagStringSeparator);
};

export const tagStringToArray = (tagString: string | undefined): string[] => {
    if (tagString === undefined) {
        return [];
    }

    const resultTags = tagString
        .trim()
        .replace(/;+/g, ';') // replace multiples of ';' with a single ';'
        .replace(/  +/g, ' ') // replace multiples of ' ' with a single ' '
        .split(TagStringSeparator);
    return deduplicateTrimTags(resultTags);
};

export const removeServiceTags = (tags: string[] | undefined): string[] => {
    if (tags === undefined || tags.length === 0) {
        return [];
    }

    return tags.filter((eachTag) => !eachTag.includes(CollectionTagPrefix) && !eachTag.includes(SkulessTag));
};

export const trimValidateTags = (tags: string[] | undefined, structuredTags: StructuredTags[] | undefined): TagValidationResult => {
    if (tags === undefined || tags.length === 0) {
        return {
            duplicateTags: [],
            resultTags: [],
            disallowedTags: [],
        };
    }

    const trimmedTags = tags.map((tag) => tag.trim());
    const uniqueTags = trimmedTags.filter((tag, index) => trimmedTags.indexOf(tag) === index);

    const duplicateTags = getDuplicateTags(trimmedTags);
    const disallowedTags = getDisallowedTags(structuredTags, uniqueTags);

    const resultTags = uniqueTags.filter((tag) => disallowedTags.indexOf(tag) === -1);

    return {
        duplicateTags,
        disallowedTags,
        resultTags,
    };
};

export const deduplicateTrimTags = (tags: string[] | undefined): string[] => {
    if (tags === undefined || tags.length === 0) {
        return [];
    }

    return tags
        .filter((w) => w) // filter out empty entries
        .map((tag) => tag.trim())
        .filter((tag, index, tagArray) => tagArray.indexOf(tag) === index);
};

export const getDuplicateTags = (tags: string[] | undefined): string[] => {
    if (tags === undefined || tags.length === 0) {
        return [];
    }

    const duplicateTags = tags.filter((tag, index, tagArray) => tagArray.indexOf(tag) !== index);
    return duplicateTags;
};

export const getDisallowedTags = (structuredTags: StructuredTags[] | undefined, tags: string[] | undefined): string[] => {
    if (tags === undefined || tags.length === 0 || structuredTags === undefined || structuredTags.length === 0) {
        return [];
    }

    const disallowedTags = tags.filter((tag) => isDisallowed(tag, structuredTags));
    return disallowedTags;
};

const isDisallowed = (tag: string, structuredTags: StructuredTags[]): boolean => {
    if (tag.indexOf('::') === -1) {
        return false;
    }

    const applicableStructuredTag = structuredTags.find((structuredTag) => tag.indexOf(`${structuredTag.category}::`) !== -1);
    if (applicableStructuredTag === undefined) {
        return false;
    }

    const tagValue = tag.substring(`${applicableStructuredTag.category}::`.length);
    return applicableStructuredTag.allowedValues.indexOf(tagValue) === -1;
};
