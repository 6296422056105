import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Card } from '@cimpress/react-components';
import cx from 'classnames';

import { useResponsiveDesign } from 'providers/ResponsiveDesign';
import { templateTypes } from 'store/template';
import CompareTemplateVersions from 'components/compareTemplateVersions/CompareTemplateVersions';
import TemplateVersionDetailsWrapper from 'connectedComponents/templateVersionDetailsWrapper/TemplateVersionDetailsWrapper';
import TemplateVersionsList from 'connectedComponents/templateVersionsList/TemplateVersionsList';

import './versions.scss';

export default function Versions() {
    const { t } = useTranslation();
    const { isMobile, isTabletOrMobile } = useResponsiveDesign();

    const [selectedVersion, setSelectedVersion] = useState<templateTypes.TemplateVersion>();
    const [versionsToCompare, setVersionsToCompare] = useState<templateTypes.TemplateVersion[]>([]);
    const params: {id: string} = useParams();

    const addVersionToCompare = (version: templateTypes.TemplateVersion) => {
        const compareVersions = [...versionsToCompare];
        // limiting it to 2 items
        if (compareVersions.length === 2) {
            compareVersions[1] = version;
        } else {
            compareVersions.push(version);
        }
        setVersionsToCompare(compareVersions);
    };

    const cancelComparing = () => {
        setVersionsToCompare([]);
    };

    return (
        <div className={cx('versions', { mobile: isMobile })}>
            <Card>
                <h1 className='versions__title'>{t('version.manageVersion')}</h1>
                <div className='versions__description'>
                    {t('version.introduction')}
                </div>
                <div className={cx('versions__body', { 'mobile-tablet': isTabletOrMobile, mobile: isMobile })}>
                    <div className={cx('versions-list', { mobile: isMobile })}>
                        <div className='versions-list__items list-group'>
                            <TemplateVersionsList
                                id={params.id}
                                selectedVersion={selectedVersion}
                                versionsToCompare={versionsToCompare}
                                onVersionChange={setSelectedVersion}
                                addVersionToCompare={addVersionToCompare}
                            />
                        </div>
                    </div>
                    { versionsToCompare.length
                        ? (
                            <div className='version-compare'>
                                <CompareTemplateVersions
                                    versionsToCompare={versionsToCompare}
                                    cancelComparing={cancelComparing}
                                />
                            </div>
                        )

                        : (
                            <div className='template-data'>
                                <TemplateVersionDetailsWrapper
                                    selectedVersion={selectedVersion}
                                    showAdditionalVersionDetails={false}
                                />
                            </div>
                        )}
                </div>
            </Card>
        </div>
    );
}
