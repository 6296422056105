import { DocumentReference, Environment, RenderingOptions } from '@rendering/vortex-core/config';
import { CameraOptions } from '@rendering/vortex-core/cameras';
import { Preview } from '@rendering/vortex-core/preview';
import { Tenant } from 'store/tenant/types.tenant';

export const initializeVortexPreview = (canvas: HTMLCanvasElement, documentReference: DocumentReference, tenant: Tenant): Preview => {
    // Camera options that allow hovering, no zooming, and padding to keep the product centered
    const cameraOptions: CameraOptions = {
        panning: false,
        hover: true,
        minZoom: 0.5,
        maxZoom: 2,
        containerPadding: 50,
    };

    // Environment options for showing a wood background and reflection
    const environment: Environment = {
        reflectionUrl: 'https://vortex.documents.cimpress.io/environments/default/reflection.jpg',
    };

    // Options for keeping track of rendering tenant
    const renderingOptions: RenderingOptions = {
        tenant: tenant.tenantId,
        category: 'template-maker',
        minimumRenderSize: 2048,
        maximumRenderSize: 8192,
    };

    return new Preview({
        canvas,
        cameraOptions,
        environment,
        renderingOptions,
        documentReference,
    });
};
