import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { productTypes } from './index';

const INITIAL_STATE: productTypes.ProductState = {};

const slice = createSlice({
    name: 'product',
    initialState: INITIAL_STATE,
    reducers: {
        loadProduct: (state, { payload }: PayloadAction<string>) => { state.product = undefined; },
        loadProductSuccess: (state, { payload }: PayloadAction<productTypes.Product>) => { state.product = payload; },
        loadProductFailure: (state) => { state.product = undefined; },
        setProduct: (state, { payload }: PayloadAction<productTypes.Product>) => { state.product = payload; },
        clearProduct: (state) => { state.product = undefined; },
        setSurfaceSpecifications: (state, { payload }: PayloadAction<productTypes.SurfaceSpecification>) => {
            state.surfaceSpecifications = payload;
        },
        resetSurfaceSpecifications: (state) => {
            state.surfaceSpecifications = undefined;
        },
        loadCalculatedSurfaceSets: (state, { payload }: PayloadAction<productTypes.SurfaceSpecification>) => { state.calculatedSurfaceSet = undefined; },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        loadCalculatedSurfaceSetsSuccess: (state, { payload }: PayloadAction<any>) => {
            state.calculatedSurfaceSet = payload;
        },
        loadCalculatedSurfaceSetsFailure: (state) => {
            state.calculatedSurfaceSet = undefined;
        },
        resetCalculatedSurfaceSets: (state) => {
            state.calculatedSurfaceSet = undefined;
        },
        designTransfer: (state, { payload }: PayloadAction<{docRefUrl: string; product: productTypes.Product}>) => {},
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        designTransferSuccess: (state, { payload }: PayloadAction<{cimDoc: any; documentReferenceUrl: string}>) => {
            state.designTransferTransientDocument = payload;
        },
        designTransferFailure: (state) => {
            state.designTransferTransientDocument = undefined;
        },
        clearDesignTransfer: (state) => {
            state.designTransferTransientDocument = undefined;
        },
    },
});

export default slice;
