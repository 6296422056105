import { RootState } from 'store/rootReducer';
import { uiStateSelector, uiStateActions, uiStateTypes } from 'store/uiState';
import { tenantActions, tenantTypes } from 'store/tenant';
import { TenantSettingsValidationErrors } from 'store/uiState/types.uiState';

export interface StateProps {
    currentTenant?: tenantTypes.Tenant;
    isTenantLoading: boolean;
    isSaving: boolean;
    settingsValidationError: TenantSettingsValidationErrors;
    settings?: uiStateTypes.DesignerApiSettings;
    isSettingsLoaded: boolean;
}

export interface DispatchProps {
    setTenantSettings(payload: uiStateTypes.DesignerApiSettings): void;
    addNotification(payload: uiStateTypes.Notification[]): void;
}

export type AllProps = StateProps & DispatchProps;

export const getAllProps = (props: AllProps) => {
    const { currentTenant, isTenantLoading, isSaving, settingsValidationError, settings, isSettingsLoaded, setTenantSettings, addNotification } = props;
    const stateProps: StateProps = { currentTenant, isTenantLoading, isSaving, settingsValidationError, settings, isSettingsLoaded };
    const dispatchProps: DispatchProps = { setTenantSettings, addNotification };

    return { stateProps, dispatchProps };
};

export const mapStateToProps = (state: RootState): StateProps => ({
    currentTenant: state.tenantState.currentTenant,
    isTenantLoading: uiStateSelector.selectIfLoading(state, tenantActions.fetchTenant.toString()),
    isSaving: uiStateSelector.selectIfLoading(state, uiStateActions.setTenantSettings.toString()),
    settingsValidationError: state.uiState.settingsValidationError,
    settings: state.uiState.settings,
    isSettingsLoaded: state.uiState.isSettingsLoaded,
});

export const mapDispatchToProps: DispatchProps = {
    setTenantSettings: uiStateActions.setTenantSettings,
    addNotification: uiStateActions.addNotification,
};
