import { URLs, SettingName } from 'utility/constants';
import { axiosWithAuth } from 'interceptors/auth.interceptor';
import { logError } from 'loggingManager';
import axios, { AxiosError } from 'axios';
import { uiStateTypes } from 'store/uiState';
import { resolveDesignerVersionToLoad } from 'utility/designer';

export async function loadUserPreference(): Promise<uiStateTypes.UserPreference> {
    return axiosWithAuth.get(`${URLs.CustomizrBase}/templateMaker/settings`)
        .then((response) => response.data)
        .catch((error) => {
            logError(`Could not fetch settings context from the customizr API ${error}`);
            throw error;
        });
}

export async function setUserPreference(preferenceObj: uiStateTypes.UserPreference): Promise<uiStateTypes.UserPreference> {
    try {
        const response = await axiosWithAuth.put<uiStateTypes.UserPreference>(`${URLs.CustomizrBase}/templateMaker/settings`, preferenceObj);
        return response.data;
    } catch (error) {
        logError(error.message || 'Could not save preference');
        throw error;
    }
}

export async function querySettings(resourceId: string, resourceType: string) {
    return axiosWithAuth.get<uiStateTypes.SettingsResult>(`${URLs.SettingsBase}/v1/settings?resourceType=${resourceType}&resourceId=${resourceId}&settingName=${SettingName}`)
        .then(({ data }): uiStateTypes.DesignerApiSettings | undefined => {
            const settingsData = data._embedded.item[0];
            const settings = settingsData && settingsData.settings ? settingsData.settings.settings : undefined;
            return settings ? { ...settings, id: settingsData && settingsData.id } : undefined;
        })
        .catch((error: AxiosError) => {
            logError('Could not retrieve settings from the Settings API.');
            throw error;
        });
}

export async function saveSettings(settings: uiStateTypes.DesignerApiSettings, resourceId: string, resourceType: string) {
    const postSettingsObject = {
        settings,
        name: SettingName,
        resource: {
            type: resourceType,
            id: resourceId,
        },
    };

    if (!settings.id) {
        return axiosWithAuth.post(`${URLs.SettingsBase}/v1/settings`, postSettingsObject)
            .catch((error: AxiosError) => {
                logError('Could not save settings to the Setting API.');
                throw error;
            });
    }

    return axiosWithAuth.put(`${URLs.SettingsBase}/v1/settings/${settings.id}`, postSettingsObject)
        .catch((error: AxiosError) => {
            logError('Could not update your settings to the Setting API.');
            throw error;
        });
}

export async function validateApiKey(key: string) {
    return axios.get(`${URLs.ValidateApiKey}/?api_key=${key}`)
        .then(({ status }) => status)
        .catch(({ response }) => response.status);
}

export async function validateDesignerVersion(version: string) {
    return axios.head(`${URLs.DesignerBase}/${resolveDesignerVersionToLoad(version)}/designer.min.js`)
        .then(({ status }) => (status === 200))
        .catch(() => false);
}
