import React, { useState, useRef, useCallback, useEffect } from 'react';

import { colors, Checkbox, Tooltip, Dropdown } from '@cimpress/react-components';
import IconFilterAlt from '@cimpress-technology/react-streamline-icons/lib/IconFilterAlt';
import { useOnClickOutside } from 'customHooks/useOnClickOutside';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import './filter.scss';
import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { uiStateTypes } from 'store/uiState';
import { useResponsiveDesign } from 'providers/ResponsiveDesign';
import { mapDispatchToProps, StateProps, DispatchProps, mapStateToProps, AllProps, getAllProps } from './props.filter';

const { ocean } = colors;

export const getUserFilterPreference = (userPreference?: uiStateTypes.UserPreference) => userPreference && userPreference.filter;

function Filter(allProps: AllProps) {
    const { isDesktopOrLaptop, isTabletOrMobile } = useResponsiveDesign();
    const { stateProps, dispatchProps } = getAllProps(allProps);
    const { userPreference } = stateProps;
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const node = useRef<HTMLDivElement>(null);
    const resetOpen = useCallback(() => setOpen(false), []);

    useOnClickOutside(node, resetOpen);

    function toggleDropdown() {
        setOpen(!open);
    }

    useEffect(() => {
        // TODO: Handle this at app hydration time
        const filter = getUserFilterPreference(userPreference);
        if (filter) {
            dispatchProps.setShowDeletedTemplates(!!filter.includeDeleted);
            dispatchProps.setShowOwnTemplates(!!filter.includeOwnTemplates);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hasTemplate = () => {
        const filter = getUserFilterPreference(stateProps.userPreference);
        const includeDeleted = filter && filter.includeDeleted;
        const includeOwnTemplates = filter && filter.includeOwnTemplates;
        return !!stateProps.templates.length || !includeDeleted || includeOwnTemplates;
    };

    const toggleShowDeletedTemplates = () => {
        dispatchProps.setUserPreference({ filter: { includeDeleted: !stateProps.includeDeleted, includeOwnTemplates: stateProps.includeOwnTemplates } });
        dispatchProps.setShowDeletedTemplates(!stateProps.includeDeleted);
    };
    const toggleShowOwnTemplates = () => {
        dispatchProps.setUserPreference({ filter: { includeDeleted: stateProps.includeDeleted, includeOwnTemplates: !stateProps.includeOwnTemplates } });
        dispatchProps.setShowOwnTemplates(!stateProps.includeOwnTemplates);
    };
    const toggleShowSkulessTemplates = () => {
        dispatchProps.setShowSkulessTemplates(!stateProps.includeSkulessTemplates);
    };
    const getTitle = () => {
        if (isDesktopOrLaptop) {
            return (
                <>
                    <IconFilterAlt weight='fill' color={ocean.darker} />
                    {t('catalog.filters.ddFilters')}
                </>
            );
        }
        return (
            <Tooltip direction='top' contents={t('catalog.filters.ddFilters')}>
                <IconFilterAlt size='2x' weight='fill' color={ocean.darker} onClick={toggleDropdown} />
            </Tooltip>
        );
    };

    return (
        <>
            {hasTemplate && (
                <div className={cx('filter', { open, 'filter-mobile-tablet': isTabletOrMobile })} ref={node} id='joy-ride__template-filter'>
                    <Dropdown
                        title={getTitle()}
                        className='filter-dropdown'
                    >
                        <Checkbox checked={stateProps.includeDeleted} label={t('catalog.filters.chbShowDeleted')} onChange={toggleShowDeletedTemplates} />
                        <Checkbox label={t('catalog.filters.chbShowOwn')} checked={stateProps.includeOwnTemplates} onChange={toggleShowOwnTemplates} />
                        <Checkbox label={t('catalog.filters.chbShowSkuless')} checked={stateProps.includeSkulessTemplates} onChange={toggleShowSkulessTemplates} />
                    </Dropdown>
                </div>
            )}
        </>
    );
}

export default connect<StateProps, DispatchProps, {}, RootState>(mapStateToProps, mapDispatchToProps)(Filter);
