import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'store/rootReducer';
import { useTranslation } from 'react-i18next';
import TemplateDetails from 'components/templateDetails/TemplateDetails';
import { StateProps, mapStateToProps, AllProps, getAllProps, DispatchProps, mapDispatchToProps } from './props.successDetails';

function SuccessDetails(allProps: AllProps) {
    const { stateProps, dispatchProps } = getAllProps(allProps);
    const { t } = useTranslation();
    const params: {id: string} = useParams();

    useEffect(() => {
        dispatchProps.loadTemplateById(params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id, dispatchProps.loadTemplateById]);

    return (
        <>
            {stateProps.template && (
                <>
                    <h2>{t('success.successInfoCard.title')}</h2>
                    <TemplateDetails
                        template={stateProps.template}
                        structuredTags={stateProps.structuredTags}
                        showAdditionalVersionDetails={true}
                    />
                </>
            )}
        </>
    );
}

export default connect<StateProps, DispatchProps, {}, RootState>(mapStateToProps, mapDispatchToProps)(SuccessDetails);
