export const demoList = [
    {
        version: '1.0.0',
        isActive: true,
        routes: [
            {
                routeName: 'landing',
                isActive: true,
                steps: [
                    {
                        target: '#joy-ride__template-config-btn',
                        title: 'overlayDemo.steps.ver_1-0-0.landing.configuration.title',
                        content: 'overlayDemo.steps.ver_1-0-0.landing.configuration.content',
                        floaterProps: {
                            disableAnimation: true,
                        },
                        disableBeacon: true,
                    },
                    {
                        target: '#joy-ride__template-create-btn',
                        title: 'overlayDemo.steps.ver_1-0-0.landing.createTemplate.title',
                        content: 'overlayDemo.steps.ver_1-0-0.landing.createTemplate.content',
                        floaterProps: {
                            disableAnimation: true,
                        },
                        disableBeacon: true,
                    },
                    {
                        target: '#joy-ride__demo-btn',
                        title: 'overlayDemo.steps.ver_1-0-0.landing.appDemo.title',
                        content: 'overlayDemo.steps.ver_1-0-0.landing.appDemo.content',
                        floaterProps: {
                            disableAnimation: true,
                        },
                        disableBeacon: true,
                    },
                ],
            },
            {
                routeName: 'create',
                isActive: true,
                steps: [
                    {
                        target: '#joy-ride__sku-input',
                        title: 'overlayDemo.steps.ver_1-0-0.create.skuInput.title',
                        content: 'overlayDemo.steps.ver_1-0-0.create.skuInput.content',
                        floaterProps: {
                            disableAnimation: true,
                        },
                        disableBeacon: true,
                    },
                ],
            },
            {
                routeName: 'catalog',
                isActive: true,
                steps: [
                    {
                        target: '#joy-ride__template-search',
                        title: 'overlayDemo.steps.ver_1-0-0.catalog.search.title',
                        content: 'overlayDemo.steps.ver_1-0-0.catalog.search.content',
                        floaterProps: {
                            disableAnimation: true,
                        },
                        disableBeacon: true,
                    },
                    {
                        target: '#joy-ride__template-filter',
                        title: 'overlayDemo.steps.ver_1-0-0.catalog.filter.title',
                        content: 'overlayDemo.steps.ver_1-0-0.catalog.filter.content',
                        floaterProps: {
                            disableAnimation: true,
                        },
                        disableBeacon: true,
                    },
                    {
                        target: '#joy-ride__toggle-view',
                        title: 'overlayDemo.steps.ver_1-0-0.catalog.view.title',
                        content: 'overlayDemo.steps.ver_1-0-0.catalog.view.content',
                        floaterProps: {
                            disableAnimation: true,
                        },
                        disableBeacon: true,
                    },
                    {
                        target: '#joy-ride__template-export',
                        title: 'overlayDemo.steps.ver_1-0-0.catalog.export.title',
                        content: 'overlayDemo.steps.ver_1-0-0.catalog.export.content',
                        floaterProps: {
                            disableAnimation: true,
                        },
                        disableBeacon: true,
                    },
                    {
                        target: '#joy-ride__new-collection-btn',
                        title: 'overlayDemo.steps.ver_1-0-0.catalog.createCollection.title',
                        content: 'overlayDemo.steps.ver_1-0-0.catalog.createCollection.content',
                        floaterProps: {
                            disableAnimation: true,
                        },
                        disableBeacon: true,
                    },
                    {
                        target: '#joy-ride__add-to-collection-btn',
                        title: 'overlayDemo.steps.ver_1-0-0.catalog.addToCollection.title',
                        content: 'overlayDemo.steps.ver_1-0-0.catalog.addToCollection.content',
                        floaterProps: {
                            disableAnimation: true,
                        },
                        disableBeacon: true,
                    },
                ],
            },
            {
                routeName: 'catalog-new',
                isActive: true,
                steps: [
                    {
                        target: '#joy-ride__template-search',
                        title: 'overlayDemo.steps.ver_1-0-0.catalog.search.title',
                        content: 'overlayDemo.steps.ver_1-0-0.catalog.search.content',
                        floaterProps: {
                            disableAnimation: true,
                        },
                        disableBeacon: true,
                    },
                    {
                        target: '#joy-ride__template-filter',
                        title: 'overlayDemo.steps.ver_1-0-0.catalog.filter.title',
                        content: 'overlayDemo.steps.ver_1-0-0.catalog.filter.content',
                        floaterProps: {
                            disableAnimation: true,
                        },
                        disableBeacon: true,
                    },
                    {
                        target: '#joy-ride__toggle-view',
                        title: 'overlayDemo.steps.ver_1-0-0.catalog.view.title',
                        content: 'overlayDemo.steps.ver_1-0-0.catalog.view.content',
                        floaterProps: {
                            disableAnimation: true,
                        },
                        disableBeacon: true,
                    },
                    {
                        target: '#joy-ride__template-export',
                        title: 'overlayDemo.steps.ver_1-0-0.catalog.export.title',
                        content: 'overlayDemo.steps.ver_1-0-0.catalog.export.content',
                        floaterProps: {
                            disableAnimation: true,
                        },
                        disableBeacon: true,
                    },
                    {
                        target: '#joy-ride__new-collection-btn',
                        title: 'overlayDemo.steps.ver_1-0-0.catalog.createCollection.title',
                        content: 'overlayDemo.steps.ver_1-0-0.catalog.createCollection.content',
                        floaterProps: {
                            disableAnimation: true,
                        },
                        disableBeacon: true,
                    },
                    {
                        target: '#joy-ride__add-to-collection-btn',
                        title: 'overlayDemo.steps.ver_1-0-0.catalog.addToCollection.title',
                        content: 'overlayDemo.steps.ver_1-0-0.catalog.addToCollection.content',
                        floaterProps: {
                            disableAnimation: true,
                        },
                        disableBeacon: true,
                    },
                ],
            },
        ],

    },
];
