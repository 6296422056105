import { SNACKBAR_NOTIFICATION_TYPES } from 'utility/enums';
import { uiStateTypes } from './index';

export const formatNotification = (
    type: SNACKBAR_NOTIFICATION_TYPES,
    messageOrKey: string | uiStateTypes.TranslationMessage,
    delay = 3000,
): uiStateTypes.Notification => ({
    type,
    delay,
    id: Math.random().toString(),
    message: messageOrKey,
});
