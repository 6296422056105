import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { templateTypes } from 'store/template';
import { RootState } from 'store/rootReducer';
import VersionItem from 'components/versionItem/VersionItem';
import { shapes } from '@cimpress/react-components';
import { StateProps, AllProps, getAllProps, DispatchProps, OwnProps, mapStateToProps, mapDispatchToProps } from './props.templateVersionsList';

const { Spinner } = shapes;

function TemplateVersionsList(allProps: AllProps) {
    const { ownProps, stateProps, dispatchProps } = getAllProps(allProps);

    useEffect(() => {
        dispatchProps.fetchTemplateVersions(ownProps.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ownProps.id]);

    useEffect(() => {
        ownProps.onVersionChange(stateProps.templateVersions.find((version) => version.id === (stateProps.currentTemplate && stateProps.currentTemplate.publishedRevisionId)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateProps.currentTemplate, stateProps.templateVersions]);

    return (
        <>
            {stateProps.isVersionListLoading ? <Spinner /> : (
                <>
                    {stateProps.templateVersions.map(
                        (version: templateTypes.TemplateVersion) => (
                            <VersionItem
                                version={version}
                                isSelected={!!ownProps.selectedVersion && version.id === ownProps.selectedVersion.id}
                                onVersionChange={ownProps.onVersionChange}
                                key={version.id}
                                addVersionToCompare={ownProps.addVersionToCompare}
                                versionsToCompare={ownProps.versionsToCompare}
                                publishedVersionId={stateProps.currentTemplate && stateProps.currentTemplate.publishedRevisionId}
                            />
                        ),
                    )}
                </>
            )}
        </>
    );
}
export default connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)(TemplateVersionsList);
