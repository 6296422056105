import { DesignTransferTransientDocument } from 'store/product/types.product';
import { RootState } from 'store/rootReducer';
import { templateSelector, templateActions } from 'store/template';
import { Template } from 'store/template/types.template';
import { uiStateSelector } from 'store/uiState';

export interface OwnProps {
    minimal?: boolean;
    landscape?: boolean;
    pxSize?: number;
    size?: 'xs' | 's' | 'm' | 'l' | 'xl';
    selectedVersionId?: string;
    imageHeading?: string;
    showImageHeading?: boolean;
    onSlideChanged?(sliceNumber: number): void;
    setSurfaceDetails?(surfaces: string[]): void;
    setOpenTemplatePreviewModal?(payload: boolean): void;
    showZoomOverlay?: boolean;
    imageSources?: string[];
    imagesLoaded?: boolean;
    designTransferTransientDocument?: DesignTransferTransientDocument;
}

export interface StateProps {
    currentTemplate: Template | undefined;
    isSurfaceSetsLoading: boolean;
}

export interface DispatchProps {
    loadSurfaces(id: string): void;
}

export type AllProps = OwnProps & StateProps & DispatchProps;

export const getAllProps = (props: AllProps) => {
    const { minimal, pxSize, size, selectedVersionId, currentTemplate, loadSurfaces, isSurfaceSetsLoading,
        onSlideChanged, setSurfaceDetails, setOpenTemplatePreviewModal, showZoomOverlay, showImageHeading, imageSources, designTransferTransientDocument } = props;
    const ownProps: OwnProps = { minimal,
        pxSize,
        size,
        selectedVersionId,
        onSlideChanged,
        setSurfaceDetails,
        setOpenTemplatePreviewModal,
        showZoomOverlay,
        showImageHeading,
        imageSources,
        designTransferTransientDocument };
    const stateProps: StateProps = { currentTemplate, isSurfaceSetsLoading };
    const dispatchProps: DispatchProps = { loadSurfaces };

    return { ownProps, stateProps, dispatchProps };
};
export const mapStateToProps = (state: RootState): StateProps => ({
    isSurfaceSetsLoading: uiStateSelector.selectIfLoading(state, templateActions.loadSurfaces.toString()),
    currentTemplate: templateSelector.selectCurrentTemplate(state),
});

export const mapDispatchToProps: DispatchProps = {
    loadSurfaces: templateActions.loadSurfaces,
};
