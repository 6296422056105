import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AllCollection } from 'utility/constants';
import { addChildrenToParent, removeCollectionFromParent, findCollectionById, updateFolderLabelById } from './collectionUtils';
import { collectionTypes } from '.';

const INITIAL_STATE: collectionTypes.CollectionState = {
    collections: [],
    activeCollection: undefined,
    isCollectionAddedSuccess: false,
};

const slice = createSlice({
    name: 'collection',
    initialState: INITIAL_STATE,
    reducers: {
        onCollectionLoad: (state) => {},
        onCollectionLoadSuccess: (state, { payload }: PayloadAction<collectionTypes.Collection[]>) => {
            state.collections = [...payload];
        },
        onCollectionLoadFailure: () => {},

        onAddOrEditCollection: (state, { payload }: PayloadAction<{label: string; parentId: string; id?: string}>) => {},
        onAddOrEditCollectionSuccess: (state, { payload }: PayloadAction<{newCollection: collectionTypes.Collection; tenantId: string}>) => {
            const { newCollection, tenantId } = payload;
            const collection = findCollectionById(state.collections, newCollection.id);
            state.collections = collection
                ? updateFolderLabelById(state.collections, newCollection.id, newCollection.label) : addChildrenToParent(state.collections, [newCollection], newCollection.parentId, tenantId);
            state.isCollectionAddedSuccess = true;
        },
        onAddOrEditCollectionFailure: () => {},
        resetIsCollectionAddedSuccess: (state) => {
            state.isCollectionAddedSuccess = false;
        },

        onCollectionChildrenLoad: (state, { payload }: PayloadAction<collectionTypes.Collection>) => {},
        onCollectionChildrenLoadSuccess: (state, { payload }: PayloadAction<{parentId: string; children: collectionTypes.Collection[]; tenantId: string}>) => {
            const { parentId, children, tenantId } = payload;
            state.collections = addChildrenToParent(state.collections, children, parentId, tenantId);
        },
        onCollectionChildrenLoadFailure: () => {},

        deleteCollection: (state, { payload }: PayloadAction<string>) => {},
        deleteCollectionSuccess: (state, { payload }: PayloadAction<string>) => {
            state.collections = removeCollectionFromParent(state.collections, payload);
        },
        deleteCollectionFailure: (state, { payload }: PayloadAction<string>) => { },

        updateActiveCollection: (state, { payload }: PayloadAction<string>) => {
            state.activeCollection = payload === AllCollection ? undefined : findCollectionById(state.collections, payload);
        },

        loadCollectionByIds: (state, { payload }: PayloadAction<string[]>) => { },
        loadCollectionByIdsSuccess: (state, { payload }: PayloadAction<collectionTypes.Collection[]>) => {},
        loadCollectionByIdsFailure: (state) => {},
    },
});

export default slice;
