import React, { useState } from 'react';
import { Modal, Button, Select, Label } from '@cimpress/react-components';
import { tenantTypes } from 'store/tenant';
import { useTranslation } from 'react-i18next';
import './tenantSelector.scss';

interface Props {
    currentTenant?: tenantTypes.Tenant;
    availableTenants: tenantTypes.Tenant[];
    open: boolean;
    disabled: boolean;
    onClose(): void;
    onSave(tenant: tenantTypes.Tenant): void;
}

const generateTenantHash = (tenant: tenantTypes.Tenant) => `${tenant.tenantType}-${tenant.tenantId}`;

const generateTenantLabel = (tenant: tenantTypes.Tenant) => `${tenant.tenantType}: ${tenant.tenantDisplayName}`;

export default function TenantSelector({ currentTenant, availableTenants, open, disabled, onClose, onSave }: Props) {
    const { t } = useTranslation();
    const [selectedTenant, setSelectedTenant] = useState(currentTenant);
    const requiresSelection = currentTenant === undefined;

    const onSaveClick = () => {
        if (selectedTenant) {
            onSave(selectedTenant);
        }
    };

    const onCloseClick = () => {
        setSelectedTenant(currentTenant); // if they arent saving the currently selected one, we should reset it
        onClose();
    };

    const footer = (
        <div>
            {!requiresSelection && (<Button onClick={onCloseClick}>{t('common.cancel')}</Button>)}
            <Button type='primary' onClick={onSaveClick}>{t('tenant.btnSetAsCurrent')}</Button>
        </div>
    );

    const onChange = ({ value }: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
        const matchingTenant = availableTenants.find((tenant) => generateTenantHash(tenant) === value);
        if (matchingTenant) {
            setSelectedTenant(matchingTenant);
        }
    };

    return (
        <Modal
            className='tenant-selector'
            show={open}
            onRequestHide={onClose}
            closeButton={!requiresSelection}
            title={t('tenant.selectTenant')}
            footer={footer}
        >
            <div className='tenant-selector__text'>
                {t('tenant.introduction')}
            </div>
            <div>
                <Select
                    label={t('tenant.selectTenant')}
                    value={selectedTenant
                        ? { value: generateTenantHash(selectedTenant), label: generateTenantLabel(selectedTenant) }
                        : currentTenant && { value: generateTenantHash(currentTenant), label: generateTenantLabel(currentTenant) }}
                    options={availableTenants.map((tenant) => ({ value: generateTenantHash(tenant), label: generateTenantLabel(tenant) }))}
                    onChange={onChange}
                    menuPortalTarget={null}
                    menuPosition='fixed'
                    disabled={disabled}
                />
            </div>
            {selectedTenant && (
                <div>
                    <span>{t('tenant.permissions')}</span>
                    <Label text={t('tenant.lblRead')} type={selectedTenant.permissions.asset.canRead ? 'success' : 'default'} />
                    <Label text={t('tenant.lblCreate')} type={selectedTenant.permissions.asset.canCreate ? 'success' : 'default'} />
                </div>
            )}
        </Modal>
    );
}
