import { useTranslation } from 'react-i18next';
import React, { ChangeEvent, useState } from 'react';
import { TextField, Tag, Tooltip, colors } from '@cimpress/react-components';
import { templateTypes } from 'store/template';
import { handleEnterKeyPress } from 'utility/keyEvent.utility';
import { CollectionTagPrefix, SkulessTag } from 'utility/constants';

import './templateInformation.scss';
import IconInformationCircle from '@cimpress-technology/react-streamline-icons/lib/IconInformationCircle';
import { BS_STYLE } from 'utility/enums';
import { deduplicateTrimTags, getDisallowedTags, tagStringToArray, trimValidateTags } from 'utility/tags.utility';
import { StructuredTags } from 'store/uiState/types.uiState';
import { parseSkuVariables } from 'utility/skuVariables.utility';

const { ocean } = colors;

interface Props {
    hidden: boolean;
    structuredTags: StructuredTags[] | undefined;
    inFlightTemplate: templateTypes.InFlightTemplate;
    setInFlightTemplateName(payload: string): void;
    setInFlightTemplateDescription(payload: string): void;
    setInFlightTemplateTags(payload: string[]): void;
    removeInFlightTemplateTags(payload: string[]): void;
}

export default function TemplateInformation({
    hidden,
    structuredTags,
    inFlightTemplate,
    setInFlightTemplateName,
    setInFlightTemplateDescription,
    setInFlightTemplateTags,
    removeInFlightTemplateTags,
}: Props) {
    const { t } = useTranslation();
    const [tag, setTag] = useState('');
    const [isDuplicateTag, setDuplicateTag] = useState(false);
    const [hasDisallowedTags, setHasDisallowedTags] = useState(false);

    const hasName = !!inFlightTemplate.name;

    const filterTag = (templateTag: string) => !templateTag.includes(CollectionTagPrefix) && !templateTag.includes(SkulessTag);
    const setName = (e: ChangeEvent<HTMLInputElement>) => setInFlightTemplateName(e.currentTarget.value);
    const setDescription = (e: ChangeEvent<HTMLInputElement>) => setInFlightTemplateDescription(e.currentTarget.value);
    const setTagValue = (e: ChangeEvent<HTMLInputElement>) => {
        isDuplicateTag && setDuplicateTag(false);
        hasDisallowedTags && setHasDisallowedTags(false);
        setTag(e.currentTarget.value);
    };
    const skuVariables = parseSkuVariables(inFlightTemplate.attributes?.skuVariables);
    const addTag = () => {
        if (!tag) {
            return;
        }

        const tagSet = tagStringToArray(tag);

        setDuplicateTag(false);
        setHasDisallowedTags(false);

        if (!tagSet.length) {
            return;
        }

        const alreadySetTags = trimValidateTags(inFlightTemplate.tags, structuredTags).resultTags;

        // no new tags are added, only duplicates
        if (tagSet && tagSet.length > 0 && tagSet.every((item) => alreadySetTags.indexOf(item) > -1)) {
            setDuplicateTag(true);
            return;
        }

        // some structured tags have values outside allowed ones
        const disallowedTags = getDisallowedTags(structuredTags, tagSet);
        if (disallowedTags.length > 0) {
            setHasDisallowedTags(true);
            return;
        }

        setTag('');

        // everything is ok, combine existing and freshly added tags + deduplicate and trim
        const resultTags = deduplicateTrimTags([...alreadySetTags, ...tagSet]);
        setInFlightTemplateTags(resultTags);
    };
    const removeTag = (tagToBeRemoved: React.ReactNode) => {
        removeInFlightTemplateTags([tagToBeRemoved] as string[]);
    };

    const tagErrorMessage = (): string => {
        if (isDuplicateTag) {
            return t('editor.tabs.templateInfo.addTagError');
        }

        if (hasDisallowedTags) {
            return t('editor.tabs.templateInfo.addTagStructuredTagError');
        }

        return '';
    };

    return (
        <div className={`info-container content-panel${hidden ? ' content-panel--hidden' : ''}`}>
            <div className='content-panel__title'>{t('editor.tabs.templateInfo.templateInfoTabTitle')}</div>
            <fieldset>
                <legend>{t('editor.tabs.templateInfo.nameAndDescription')}</legend>
                <TextField
                    label={t('editor.tabs.templateInfo.name')}
                    value={inFlightTemplate.name}
                    onChange={setName}
                    required={hasName}
                    bsStyle={hasName ? BS_STYLE.none : BS_STYLE.error}
                    helpText={hasName ? '' : t('editor.tabs.templateInfo.nameError')}
                />
                <TextField
                    label={t('editor.tabs.templateInfo.description')}
                    value={inFlightTemplate.description}
                    onChange={setDescription}
                />
            </fieldset>
            <fieldset className='info-container__tags'>
                <legend>Tag</legend>
                <TextField
                    label={t('editor.tabs.templateInfo.addTag')}
                    value={tag}
                    onChange={setTagValue}
                    onKeyPress={handleEnterKeyPress(addTag)}
                    rightAddon={(
                        <>
                            <button type='button' onClick={addTag} className='btn btn-default' disabled={!tag}>
                                {t('common.add')}
                            </button>
                            <Tooltip direction='top' contents={t('editor.tabs.templateInfo.tagInfo')}>
                                <IconInformationCircle
                                    color={ocean.darker}
                                />
                            </Tooltip>
                        </>
                    )}
                    bsStyle={isDuplicateTag || hasDisallowedTags ? BS_STYLE.error : BS_STYLE.none}
                    helpText={isDuplicateTag || hasDisallowedTags ? tagErrorMessage() : ''}
                />
                {inFlightTemplate.tags.length !== 0 && (
                    <div className='info-container__tag-set'>
                        {inFlightTemplate.tags.map((eachTag) => filterTag(eachTag) && <Tag value={eachTag} key={eachTag} removable={true} onRemoveClick={removeTag} />)}
                    </div>
                )}
            </fieldset>
            {skuVariables && (
                <fieldset>
                    <legend>Sku Variables</legend>
                    {Object.entries(skuVariables).map(
                        ([key, value]) => (
                            <div className='info-row'>
                                <div className='info-label'>{key}:</div>
                                <div className='info-value'>
                                    {value}
                                </div>
                            </div>
                        ),
                    )}
                </fieldset>
            )}
        </div>
    );
}
