/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useRef } from 'react';
import { shapes, InlineEdit } from '@cimpress/react-components';
import { useOnClickOutside } from 'customHooks/useOnClickOutside';
import { handleEscKeyUp } from 'utility/keyEvent.utility';
import { DIRECTORY_ALLOWED_ACTIONS } from 'utility/enums';
import { directoryTypes } from 'shared/directory';
import { useTranslation } from 'react-i18next';

const MAX_LENGTH = 30;

const { Spinner } = shapes;

interface Props {
    value: string;
    onAdd(newCollectionName: string): void;
    onCancel(): void;
    directory?: directoryTypes.Directory;
    actionSelected?: DIRECTORY_ALLOWED_ACTIONS;
    validateInput?(inputText: string, directory?: directoryTypes.Directory): string | null;
    isCollectionSaving?: boolean;
    isCollectionAddedSuccess?: boolean;
    resetIsCollectionAddedSuccess?(): void;
}

export default function NewCollectionInput({ value,
    onAdd,
    onCancel,
    directory,
    actionSelected,
    validateInput,
    isCollectionAddedSuccess,
    isCollectionSaving,
    resetIsCollectionAddedSuccess }: Props) {
    const { t } = useTranslation();
    const [isDisabled, setIsDisabled] = useState(true);
    const [currentValue, setCurrentValue] = useState(value);
    const [showSuccessTick, setShowSuccessTick] = useState(false);
    const node = useRef<HTMLDivElement>(null);

    const validateInputText = (inputText: string) => {
        let errorMessage = null;
        if (validateInput && inputText !== currentValue) {
            errorMessage = validateInput(inputText, directory);
            errorMessage ? setIsDisabled(true) : setIsDisabled(false);
        }
        return errorMessage || null;
    };

    const handleAdd = (input: { name?: string; value?: string }) => {
        const inputValue = input.value;
        if (inputValue) {
            setShowSuccessTick(false);
            onAdd(inputValue);
            setCurrentValue(inputValue);
        }
    };

    const handleCancel = () => onCancel();

    const changeToEditingState = () => {
        if (node && node.current) {
            const editElement = node.current.getElementsByClassName('resting-text')[0];
            if (editElement) {
                const el = (editElement.getElementsByTagName('p')[0] as HTMLElement);
                el.click();
            }
        }
    };

    useEffect(() => {
        if (isCollectionAddedSuccess) {
            setShowSuccessTick(true);
            setTimeout(() => {
                setShowSuccessTick(false);
                resetIsCollectionAddedSuccess && resetIsCollectionAddedSuccess();
                onCancel();
            }, 2000);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCollectionAddedSuccess, onCancel, resetIsCollectionAddedSuccess]);

    useEffect(() => {
        changeToEditingState();
    }, []);

    useOnClickOutside(node, onCancel);

    return (
        <div ref={node} className={isDisabled ? 'new-directory save--disabled' : 'new-directory'} onClick={(event) => event.stopPropagation()}>
            {isCollectionSaving && <Spinner size='small' />}
            <InlineEdit
                placeholder={t('collection.newCollectionPlaceholder')}
                label='Name'
                onSave={handleAdd}
                onCancel={handleCancel}
                required={true}
                requiredWarningMessage=''
                maxLength={MAX_LENGTH}
                validateInput={validateInputText}
                disabled={isCollectionSaving || showSuccessTick}
                value={value}
                onKeyUp={handleEscKeyUp(handleCancel)}
            />
            {showSuccessTick && <div className='success-icon'><p /></div>}
        </div>
    );
}
