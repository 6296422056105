import { RootState } from 'store/rootReducer';
import { uiStateSelector } from 'store/uiState';
import { clipArtTypes, clipArtActions, clipArtSelector } from 'store/clipart';

export interface OwnProps {
    onSelectClipArt(clipArt: clipArtTypes.ClipArt): void;
}
export interface StateProps {
    clipArts: clipArtTypes.ClipArt[];
    clipArtCategories: clipArtTypes.ClipArtCategory[];
    isClipArtLoading: boolean;
}

export interface DispatchProps {
    loadClipArts(payload: string): void;
    loadClipArtCategories(): void;
}

export type AllProps = StateProps & DispatchProps & OwnProps;

export const getAllProps = (props: AllProps) => {
    const { onSelectClipArt, clipArts, clipArtCategories, isClipArtLoading, loadClipArts, loadClipArtCategories } = props;
    const ownProps: OwnProps = { onSelectClipArt };
    const stateProps: StateProps = { clipArts, clipArtCategories, isClipArtLoading };
    const dispatchProps: DispatchProps = { loadClipArts, loadClipArtCategories };

    return { ownProps, stateProps, dispatchProps };
};

export const mapStateToProps = (state: RootState): StateProps => ({
    clipArts: clipArtSelector.selectClipArts(state),
    clipArtCategories: clipArtSelector.selectClipArtCategories(state),
    isClipArtLoading: uiStateSelector.selectIfLoading(state, clipArtActions.loadClipArts.toString()),
});

export const mapDispatchToProps: DispatchProps = {
    loadClipArts: clipArtActions.loadClipArts,
    loadClipArtCategories: clipArtActions.loadClipArtCategories,
};
