import { RootState } from 'store/rootReducer';
import { createSelector } from '@reduxjs/toolkit';
import { DESIGNER_MODE } from 'utility/enums';
import { FilterCriteria, Search, Notification } from './types.uiState';
import { hydrationActionTypes } from './slice.uiState';
import { uiStateTypes } from '.';

const selectAceTemplateEdit = (state: RootState) => state.uiState.settings?.enableEditingAceTemplates || false;

const selectUIStateLoaders = (state: RootState) => state.uiState.loaders;

const selectShowManualLoader = (state: RootState) => state.uiState.showManualLoader;

const selectFilterCriteria = (state: RootState): FilterCriteria => state.uiState.filterCriteria;

const selectFilterCriteriaSearch = (state: RootState): Search | undefined => state.uiState.filterCriteria.search;

const selectFilterCriteriaIncludeDeleted = (state: RootState): boolean => state.uiState.filterCriteria.includeDeleted;

const selectFilterCriteriaIncludeOwnTemplates = (state: RootState): boolean => state.uiState.filterCriteria.includeOwnTemplates;

const selectFilterCriteriaIncludeSkulessTemplates = (state: RootState): boolean => state.uiState.filterCriteria.includeSkulessTemplates;

const selectIsFilterCriteriaApplied = (state: RootState) => !!state.uiState.filterCriteria.search?.searchString || Object.values(state.uiState.filterCriteria).some((f) => f);

const selectCurrentLocale = (state: RootState): string | undefined => state.uiState.userPreference.currentLocale;

const selectAllNotification = (state: RootState): Notification[] => state.uiState.notifications;

const selectShowBlockingLoader = createSelector(
    [selectUIStateLoaders, selectShowManualLoader],
    (loaders, showLoader) => (loaders.some((loader) => loader.isBlocking) || showLoader) as boolean,
);

const selectIfLoading = (state: RootState, ...actionsToCheck: string[]): boolean => state.uiState.loaders.some((loader) => actionsToCheck.includes(loader.id));

const selectIfLoadingById = (state: RootState, id: string, ...actionsToCheck: string[]): boolean => {
    const actionsToCheckWithId = actionsToCheck.map((action) => `${action}_${id}`);
    return state.uiState.loaders.some((loader) => actionsToCheckWithId.includes(loader.id));
};

const selectLoadingIdsByActions = (state: RootState, ...actionsToCheck: string[]): string[] => state.uiState.loaders
    .filter((loader) => actionsToCheck.includes(loader.id.substring(0, loader.id.indexOf('_'))))
    .map((loader) => loader.id.substring(loader.id.indexOf('_') + 1, loader.id.length))
    .filter((x, i, a) => a.indexOf(x) === i);

const selectIsHydrated = (state: RootState): boolean => hydrationActionTypes.every((action) => state.uiState.hydratorActions.some((type) => type === action.type));

const selectDisplayOverlay = (state: RootState): boolean => state.uiState.displayOverlay;

const selectIsCustomerDesignerMode = (state: RootState): boolean => state.router.location.pathname.includes(DESIGNER_MODE.CustomerDesigner);

const selectHasMandatoryDesignerSettings = (state: RootState) => !!(state.uiState.settings
    && state.uiState.settings.apiKey
    && state.uiState.settings.designerVersion
    && state.uiState.settings.uploadTenant);

const selectUserPreference = (state: RootState): uiStateTypes.UserPreference => state.uiState.userPreference;

const selectStructuredTagSettings = (state: RootState): uiStateTypes.StructuredTags[] => state.uiState.settings?.structuredTags ?? [];

const selectUploadsTenantSettings = (state: RootState): string | undefined => state.uiState.settings?.uploadTenant;

export default {
    selectUIStateLoaders,
    selectShowManualLoader,
    selectFilterCriteria,
    selectFilterCriteriaSearch,
    selectFilterCriteriaIncludeDeleted,
    selectFilterCriteriaIncludeOwnTemplates,
    selectFilterCriteriaIncludeSkulessTemplates,
    selectIsFilterCriteriaApplied,
    selectAllNotification,
    selectCurrentLocale,
    selectShowBlockingLoader,
    selectIfLoading,
    selectIfLoadingById,
    selectLoadingIdsByActions,
    selectIsHydrated,
    selectDisplayOverlay,
    selectIsCustomerDesignerMode,
    selectHasMandatoryDesignerSettings,
    selectUserPreference,
    selectAceTemplateEdit,
    selectStructuredTagSettings,
    selectUploadsTenantSettings,
};
