import { RootState } from 'store/rootReducer';
import { uiStateActions, uiStateTypes } from 'store/uiState';

export interface StateProps {
    notifications: uiStateTypes.Notification[];
}

export interface DispatchProps {
    removeNotification(payload: {id: string}): void;
}

export type AllProps = StateProps & DispatchProps;

export const getAllProps = (props: AllProps) => {
    const { notifications, removeNotification } = props;
    const stateProps: StateProps = { notifications };
    const dispatchProps: DispatchProps = { removeNotification };

    return { stateProps, dispatchProps };
};

export const mapStateToProps = (state: RootState): StateProps => ({
    notifications: state.uiState.notifications,
});

export const mapDispatchToProps: DispatchProps = {
    removeNotification: uiStateActions.removeNotification,
};
