import { templateActions, templateSelector, templateTypes } from 'store/template';
import { RootState } from 'store/rootReducer';
import { productTypes, productActions } from 'store/product';
import { uiStateActions } from 'store/uiState';
import { DesignTransferTransientDocument } from 'store/product/types.product';

export interface OwnProps {
    product: productTypes.Product | undefined;
    sku: string | undefined;
}

export interface StateProps {
    designTransferTransientDocument: DesignTransferTransientDocument | undefined;
    inFlightTemplate: templateTypes.InFlightTemplate;
}
export interface DispatchProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    saveCimDocToUds(payload: {cimDoc: any}): void;
    resetInFlightTemplate(): void;
    setCurrentSkuForDesignTransfer(): void;
    clearDesignTransfer(): void;
}

export type AllProps = OwnProps & StateProps & DispatchProps;

export const getAllProps = (props: AllProps) => {
    const { product, sku, designTransferTransientDocument, inFlightTemplate, saveCimDocToUds, resetInFlightTemplate, setCurrentSkuForDesignTransfer, clearDesignTransfer } = props;
    const dispatchProps: DispatchProps = { saveCimDocToUds, resetInFlightTemplate, setCurrentSkuForDesignTransfer, clearDesignTransfer };
    const stateProps: StateProps = { designTransferTransientDocument, inFlightTemplate };
    const ownProps: OwnProps = { product, sku };

    return { ownProps, stateProps, dispatchProps };
};

export const mapStateToProps = (state: RootState): StateProps => ({
    designTransferTransientDocument: state.productState.designTransferTransientDocument,
    inFlightTemplate: templateSelector.selectInflightTemplate(state),
});

export const mapDispatchToProps: DispatchProps = {
    saveCimDocToUds: templateActions.saveCimDocToUds,
    resetInFlightTemplate: templateActions.resetInFlightTemplate,
    setCurrentSkuForDesignTransfer: uiStateActions.setClearCurrentSkuForDesignTransfer,
    clearDesignTransfer: productActions.clearDesignTransfer,
};
