import React, { ChangeEvent, useEffect, useRef } from 'react';
import { noop } from 'utility/function.utility';
import { useDebounce } from 'customHooks/useDebounce';
import { TextField, Tooltip, colors } from '@cimpress/react-components';
import { useTranslation } from 'react-i18next';
import IconInformationCircle from '@cimpress-technology/react-streamline-icons/lib/IconInformationCircle';
import { BS_STYLE } from 'utility/enums';
import { getProduct } from './productClient';

const { ocean } = colors;
interface Props {
    sku?: string;
    onValid?(sku: string, name: string): void;
    onInvalid?(sku: string): void;
    onChange?(sku: string): void;
    onKeyPress(event: React.KeyboardEvent<HTMLInputElement>): void;
}

function SkuSelector({ sku, onChange: externalOnChange = noop, onValid: externalOnValid = noop, onInvalid: externalOnInvalid = noop, onKeyPress }: Props) {
    const { t } = useTranslation();
    const debouncedSku = useDebounce(sku, 1000);
    const bsStyleRef = useRef<BS_STYLE.error | BS_STYLE.success | BS_STYLE.none>(BS_STYLE.none);

    useEffect(() => {
        const onValid = (validatedSku: string, name: string) => {
            bsStyleRef.current = BS_STYLE.success;
            externalOnValid(validatedSku, name);
        };

        const onInvalid = (validatedSku: string) => {
            bsStyleRef.current = BS_STYLE.error;
            externalOnInvalid(validatedSku);
        };

        if (debouncedSku) {
            getProduct(debouncedSku)
                .then((product) => {
                    if (product && product.mcpSku === debouncedSku) {
                        onValid(product.mcpSku, product.name);
                    } else if (!product) {
                        onInvalid(debouncedSku);
                    }
                });
        }
    }, [debouncedSku, externalOnInvalid, externalOnValid]);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const updatedSku = e.currentTarget.value;
        bsStyleRef.current = BS_STYLE.none;
        externalOnChange(updatedSku);
    };

    return (
        <div className='sku-selector'>
            <TextField
                className='sku-selector__input'
                label={t('create.skuSelector.sku')}
                bsStyle={bsStyleRef.current}
                value={sku}
                onChange={onChange}
                type='text'
                required={true}
                onKeyPress={onKeyPress}
                helpText={bsStyleRef.current === BS_STYLE.error && t('create.skuSelector.skuError')}
            />
            <Tooltip direction='top' contents={t('create.skuSelector.ttSKU_Info')}>
                <div className='create__sku-info'>
                    <IconInformationCircle color={ocean.darker} />
                </div>
            </Tooltip>
        </div>
    );
}

export default SkuSelector;
