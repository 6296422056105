import { configureStore } from '@reduxjs/toolkit';
import sagaMiddlewareFactory from 'redux-saga';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { Basename, IsDevMode } from 'utility/constants';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

const sagaMiddleware = sagaMiddlewareFactory();

export const history = createBrowserHistory({
    basename: Basename,
});

const routeMiddleware = routerMiddleware(history);

export default function createStore(preloadedState?: object) {
    const store = configureStore({
        preloadedState,
        reducer: rootReducer(history),
        devTools: IsDevMode,
        middleware: [routeMiddleware, sagaMiddleware],
    });

    // subscribe to any reducer updates when running with hot reloading
    if (IsDevMode && module.hot && module.hot.accept) {
        module.hot.accept('store/rootReducer', () => store.replaceReducer(rootReducer(history)));
    }
    sagaMiddleware.run(rootSaga);

    return store;
}
