import { RootState } from 'store/rootReducer';
import { productActions, productTypes } from 'store/product';
import { Push, push } from 'connected-react-router';
import { uiStateSelector } from 'store/uiState';
import { templateTypes, templateSelector } from 'store/template';

export interface OwnProps {
    currentProduct?: productTypes.Product;
    surfaceSpecifications?: productTypes.SurfaceSpecification;
    completelyConfiguredProduct: boolean;
}

export interface StateProps {
    hasMandatoryDesignerSettings: boolean;
    inFlightTemplate: templateTypes.InFlightTemplate;
}

export interface DispatchProps {
    connectedPush: Push;
    setCurrentProduct(payload: productTypes.Product): void;
    clearProduct(): void;
}

export type AllProps = StateProps & DispatchProps & OwnProps;

export const getAllProps = (props: AllProps) => {
    const {
        currentProduct,
        surfaceSpecifications,
        completelyConfiguredProduct,
        hasMandatoryDesignerSettings,
        inFlightTemplate,
        setCurrentProduct,
        clearProduct,
        connectedPush } = props;
    const ownProps: OwnProps = { currentProduct, surfaceSpecifications, completelyConfiguredProduct };
    const stateProps: StateProps = { hasMandatoryDesignerSettings, inFlightTemplate };
    const dispatchProps: DispatchProps = { setCurrentProduct, clearProduct, connectedPush };

    return { ownProps, stateProps, dispatchProps };
};

export const mapStateToProps = (state: RootState): StateProps => ({
    hasMandatoryDesignerSettings: uiStateSelector.selectHasMandatoryDesignerSettings(state),
    inFlightTemplate: templateSelector.selectInflightTemplate(state),
});

export const mapDispatchToProps: DispatchProps = {
    setCurrentProduct: productActions.setProduct,
    clearProduct: productActions.clearProduct,
    connectedPush: push,
};
