import { takeEvery, put, all, call, StrictEffect } from 'redux-saga/effects';

import { uiStateActions, formatNotification } from 'store/uiState';
import { SNACKBAR_NOTIFICATION_TYPES } from 'utility/enums';
import { PayloadAction } from '@reduxjs/toolkit';
import { clipArtActions, clipArtTypes } from './index';
import { getClipArts, getClipArtCategories } from './service.clipart';
import { GetClipArtCategoriesResponse, GetClipArtsResponse } from './types.clipart';

export function* onLoadClipArts(action: PayloadAction<string>) {
    try {
        const subCategoryId = action.payload;
        // const subCategoryId = '39ccbf82-99ff-4b51-ad25-53fac7a76820';
        const clipArtsResponse: GetClipArtsResponse = yield call(getClipArts, subCategoryId);
        yield put(clipArtActions.loadClipArtsSuccess(clipArtsResponse.data.filter((item: clipArtTypes.ClipArt) => !!item.uploadId)));
    } catch (error) {
        yield put(clipArtActions.loadClipArtsFailure());
        yield put(uiStateActions.addNotification([formatNotification(SNACKBAR_NOTIFICATION_TYPES.Danger, { key: 'notifications.danger.clipArt' }, 0)]));
    }
}

export function* onLoadClipArtCategories() {
    try {
        const categoriesResponse: GetClipArtCategoriesResponse = yield call(getClipArtCategories);
        yield put(clipArtActions.loadClipArtCategoriesSuccess(categoriesResponse.data));
    } catch (error) {
        yield put(clipArtActions.loadClipArtCategoriesFailure());
        yield put(uiStateActions.addNotification([formatNotification(SNACKBAR_NOTIFICATION_TYPES.Danger, { key: 'notifications.danger.clipArtCategories' }, 0)]));
    }
}

export default function* clipArtSaga(): Generator<StrictEffect, StrictEffect, never> {
    return yield all([
        yield takeEvery(clipArtActions.loadClipArts, onLoadClipArts),
        yield takeEvery(clipArtActions.loadClipArtCategories, onLoadClipArtCategories),
    ]);
}
